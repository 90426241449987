import { useAppSelector } from 'hooks/useAppSystem';

export const useAppsLimit = () => {
    const statsUserLimit = useAppSelector(({ userFrontendSettings }) => userFrontendSettings.statsUserLimit);

    const userLimits = statsUserLimit?.limits.find((v) => v.id === 'applications');

    return {
        currentAppsCount: userLimits?.current || 0,
        maxAppsCount: userLimits?.max,
    };
};
