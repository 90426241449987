import { AppProfileActionTypeEnum } from 'state/appProfile/actionTypes';
import { AppProfileType } from 'state/appProfile/AppProfileType';
import { AppProfileActionType } from './actions';

const InitialState = {
    is_appstore: false,
    is_googleplay: false,
    permissions: {
        can_manage_applications: false,
        can_manage_campaigns: false,
        can_manage_competitors: false,
        can_manage_keywords: false,
        can_manage_subscriptions: false,
        can_manage_teammates: false,
        can_use_api: false,
        can_manage_reviews: false,
        can_manage_storeconsole: false,
        can_manage_integrations: false,
        can_manage_aso_dashboard: false,
    },
    isDemo: false,
    available_features: {},
    trackedApps: [],
    competitors: [],
    isLoaded: false,
    country_code: '',
    country_name: '',
    app_id: 0,
    comparativeReportBuckets: [],
    is_connected: false,
    store_id: '',
    type: 'appstore' as const,
    url: '',
};

const appProfile = (state: AppProfileType = InitialState, action: AppProfileActionType): AppProfileType => {
    switch (action.type) {
        case AppProfileActionTypeEnum.SET_APP_PROFILE: {
            return {
                ...action.data,
                isLoaded: true,
            };
        }
        case AppProfileActionTypeEnum.SET_IS_LOADING_APP_PROFILE: {
            return {
                ...state,
                isLoaded: !action.isLoading,
            };
        }
        default: {
            return state;
        }
    }
};

export default appProfile;
