export const isString = (value: any): value is string => {
    return typeof value === 'string';
};

export const isNumber = (value: any): value is number => {
    return typeof value === 'number';
};

export const isArray = <T extends any>(value: any): value is T[] => {
    return Array.isArray(value);
};

export const isUndefined = <T,>(value: T | undefined): value is undefined => {
    return value === undefined;
};

export const isNotUndefined = <T,>(value: T | undefined): value is T => {
    return !isUndefined(value);
};

export const isNull = <T,>(value: any): value is T => {
    return value === null;
};
