import { parseQueryString } from 'utils/helpers/queryString';
import { ReviewsAnalysisActionTypeListEnum } from './actionsTypes';
import { ReviewsAnalysisActionType } from './actions';
import {
    BreakDownTypeEnum,
    DataChartTypeEnum,
    DateChartTypeEnum,
    LineChartTypeEnum,
    ModeChartTypeEnum,
    ReviewsAnalysisInitialStateType,
} from './analysisTypes';

const { search, pathname } = window.location;

const { languages, breakDownType } = parseQueryString(search);

const getInitialBreakdown = () => {
    if (breakDownType) return breakDownType as BreakDownTypeEnum;

    const pathNames = pathname
        .split('/')
        .filter((path) => !isNaN(+path))
        .filter((path) => path);

    return pathNames.length ? BreakDownTypeEnum.COUNTRY : BreakDownTypeEnum.LANGUAGE;
};

const reviewsAnalysisInitialState = {
    filters: {
        languages: (typeof languages === 'string' && languages.split(',')) || [],
        countries: [],
    },
    filtersChart: {
        dataChartType: DataChartTypeEnum.CUMULATIVE,
        modeChartType: ModeChartTypeEnum.RELATIVE,
        dateChartType: DateChartTypeEnum.DAILY,
        lineChartType: LineChartTypeEnum.LINEAR,
    },
    breakDownType: getInitialBreakdown(),
};

const reviewsAnalysis = (
    state: ReviewsAnalysisInitialStateType = reviewsAnalysisInitialState,
    action: ReviewsAnalysisActionType
) => {
    switch (action.type) {
        case ReviewsAnalysisActionTypeListEnum.SET_REVIEWS_ANALYSIS_LANGUAGES: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    languages: action.languages,
                    countries: action.countries || state.filters.countries,
                },
            };
        }
        case ReviewsAnalysisActionTypeListEnum.CHANGE_REVIEWS_ANALYSIS_FILTER_CHART: {
            return {
                ...state,
                filtersChart: {
                    ...state.filtersChart,
                    [action.filterType]: action.filterActiveValue,
                },
            };
        }
        case ReviewsAnalysisActionTypeListEnum.CHANGE_REVIEWS_ANALYSIS_BREAKDOWN: {
            return {
                ...state,
                breakDownType: action.breakDownType,
            };
        }
        default: {
            return state;
        }
    }
};

export default reviewsAnalysis;
