import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getRequestInfo } from 'state/requestInfo/controller';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { removeTrackedAppAction } from 'state/trackedApps/actions';

let isLoadingFlag = false;

export const useRequestInfo = (force = false) => {
    const dispatch = useDispatch();
    const { isLoaded } = useRequestInfoService();
    const removeTrackedApp = ({ detail }: CustomEvent<{ storeId: string }>) => {
        dispatch(removeTrackedAppAction(detail.storeId));
    };

    useEffect(() => {
        const loadRequestInfo = async () => {
            if (force || (!isLoaded && !isLoadingFlag)) {
                isLoadingFlag = true;
                await dispatch(getRequestInfo());
                isLoadingFlag = false;
            }
        };
        loadRequestInfo();
    }, []);

    useEffect(() => {
        window.addEventListener('angularRemoveTrackedApp', removeTrackedApp as EventListener);
        return () => window.removeEventListener('angularRemoveTrackedApp', removeTrackedApp as EventListener);
    }, []);
};
