import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPlansData } from 'state/plans/controller';
import { usePlansGetter } from 'hooks/data/usePlansGetter';

export const usePlans = (needReloadData = false) => {
    const { isTrial, currentPlan, aso, reviews, reviewsAso, services, promo } = usePlansGetter();
    const dispatch = useDispatch();

    const [isLoadingPlansData, setIsLoadingPlansData] = useState(true);

    useEffect(() => {
        const onLoadPlansData = async () => {
            try {
                setIsLoadingPlansData(true);
                await dispatch(getPlansData());
            } finally {
                setIsLoadingPlansData(false);
            }
        };

        if (!currentPlan || needReloadData) {
            onLoadPlansData();
        } else {
            setIsLoadingPlansData(false);
        }
    }, []);

    return {
        isLoadingPlansData,
        setIsLoadingPlansData,
        isTrial,
        currentPlan,
        aso,
        reviews,
        reviewsAso,
        services,
        promo,
    };
};
