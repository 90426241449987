import { TOGGLE_TRANSLATE } from 'state/translate/actionTypes';
import { TranslateStateType } from 'state/translate/TranslateStateType';
import { ToggleTranslateActionType } from 'state/translate/actions';

const translateTranslateInitialState: TranslateStateType = {
    keywordAnalytics: false,
    missingRanking: false,
    competitorsKeywords: false,
    searchAdsKeywords: false,
    textAnalyzer: false,
    keywordShuffler: false,
    keywordAutoSuggestions: false,
    optimizerUsed: false,
    optimizerUnused: false,
    comparativeReport: false,
    topKeywords: false,
};

const translate = (state = translateTranslateInitialState, action: ToggleTranslateActionType) => {
    switch (action.type) {
        case TOGGLE_TRANSLATE: {
            return {
                ...state,
                [action.translateToolName]: !state[action.translateToolName],
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default translate;
