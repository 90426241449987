import { AppVersionsTypeEnum } from 'state/appVersions/actionsTypes';
import { AppVersionType } from 'state/appVersions/AppVersionType';
import { AppVersionsActionType } from './actions';

export type AppVersionsStateType = {
    versions: AppVersionType[];
    comparativeReportVersions: AppVersionType[];
    isAppVersionLoading: boolean;
    hasBrokenVersions: boolean;
};

const appVersionsInitialState = {
    versions: [],
    comparativeReportVersions: [],
    isAppVersionLoading: true,
    hasBrokenVersions: false,
};

const appVersions = (state: AppVersionsStateType = appVersionsInitialState, action: AppVersionsActionType) => {
    switch (action.type) {
        case AppVersionsTypeEnum.SET_VERSIONS: {
            return {
                ...state,
                versions: action.versions,
                hasBrokenVersions: action.hasBrokenVersions,
            };
        }
        case AppVersionsTypeEnum.SET_COMPARATIVE_REPORT_VERSIONS: {
            return {
                ...state,
                comparativeReportVersions: action.versions,
                hasBrokenVersions: action.hasBrokenVersions,
            };
        }
        case AppVersionsTypeEnum.SET_APP_VERSIONS_LOADING: {
            return {
                ...state,
                isAppVersionLoading: action.isAppVersionLoading,
            };
        }
        default: {
            return state;
        }
    }
};

export default appVersions;
