import { Dispatch } from 'redux';
import axios from 'axios';
import { Urls } from 'utils/Urls';
import { RequestInfoControllerInterface } from 'state/requestInfo/AppProfileControllerInterface';
import { RequestInfoResponseType } from 'state/requestInfo/RequestInfoResponseType';
import { setLoadingAction, setRequestInfoAction, setStoreConsoleAvailableAccountAction } from 'state/requestInfo/actions';
import { setUserLoggedAction } from 'state/user/actions';
import { storeConsoleUrls } from 'utils/storeConsoleUrls';
import { ThunkDispatch } from 'types/react-redux-thunk';
import { UserAvailableAccountsResponseType } from './UserAvailableAccountsResponseType';
import { requestInfoAdapter } from './requestInfoAdapter';

export const getStoreConsoleAvailableAccounts = () => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get<UserAvailableAccountsResponseType>(storeConsoleUrls.userAvailableAccounts());
        dispatch(setStoreConsoleAvailableAccountAction(response.data[0]));
    } catch (error) {
        console.error(error);
    }
};

export const getRequestInfo = () => async (dispatch: ThunkDispatch) => {
    try {
        dispatch(setLoadingAction(true));
        const response = await axios.get<RequestInfoResponseType>(Urls.request_info());

        const adaptedUserData = requestInfoAdapter(response.data.user);
        dispatch(setRequestInfoAction(adaptedUserData));

        if (adaptedUserData.storeConsole?.user.username !== 'demo') {
            dispatch(setUserLoggedAction(true));
        }
    } catch (error) {
        dispatch(setUserLoggedAction(false));
        console.error(error);

        throw error;
    } finally {
        dispatch(setLoadingAction(false));
    }
};
export const RequestInfoController: RequestInfoControllerInterface = {
    getRequestInfo,
    getStoreConsoleAvailableAccounts,
};
