import React, { FC, memo } from 'react';
import classNames from 'classnames';
import { TabControlPropsType } from 'newComponents/tab/TabControlPropsType';
import { TabWrapper } from 'newComponents/tab/TabWrapper';
import { DefaultTabControls } from 'newComponents/tab/defaultTab/DefaultTabControls';

const TableTabComponent: FC<TabControlPropsType> = ({ className, ...props }: TabControlPropsType) => (
    <TabWrapper {...props} className={classNames('border-b border-rb-border-gray', className)}>
        {DefaultTabControls}
    </TabWrapper>
);

export const DefaultTab = memo(TableTabComponent);
