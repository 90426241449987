import { useLocation } from 'react-router-dom';
import { useRoutesUrl } from 'hooks/useRoutesUrl';

export const useUserLimits = () => {
    const { pathname, search } = useLocation();

    const currentPath = `${pathname}${search}`;

    const {
        myAppsUrl,
        asoToolsCategoriesRankingUrl,
        storesAnalyticsAppStoreTrendingSearchesUrl,
        connectionsHubIntegrationsUrl,
        connectionsHubApiUrl,
        referralProgramUrl,
        accountPricingUrl,
        accountNotificationsUrl,
        accountUrl,
        accountBillingUrl,
        accountLogUrl,
        accountTeammatesUrl,
    } = useRoutesUrl();

    const pages = [
        myAppsUrl,
        asoToolsCategoriesRankingUrl,
        storesAnalyticsAppStoreTrendingSearchesUrl,
        connectionsHubIntegrationsUrl,
        connectionsHubApiUrl,
        referralProgramUrl,
        accountPricingUrl,
        accountNotificationsUrl,
        accountUrl,
        accountBillingUrl,
        accountLogUrl,
        accountTeammatesUrl,
    ];

    const filteredPages = pages.filter((page) => page.includes(currentPath));

    const hasLocation = !filteredPages.length;

    return {
        hasLocation,
    };
};
