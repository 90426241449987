import { AppProfileActionTypeEnum } from 'state/appProfile/actionTypes';
import { AppProfileResponseType } from 'state/appProfile/AppProfileResponseType';
import { Action } from 'redux';

interface SetAppProfileActionType extends Action {
    type: AppProfileActionTypeEnum.SET_APP_PROFILE;
    data: AppProfileResponseType;
}

interface SetLoadingAppProfileActionType extends Action {
    type: AppProfileActionTypeEnum.SET_IS_LOADING_APP_PROFILE;
    isLoading: boolean;
}

export type AppProfileActionType = SetAppProfileActionType | SetLoadingAppProfileActionType;

export function setAppProfileAction(data: AppProfileResponseType): SetAppProfileActionType {
    return {
        type: AppProfileActionTypeEnum.SET_APP_PROFILE,
        data,
    };
}

export function setLoadingAppProfileAction(isLoading: boolean): SetLoadingAppProfileActionType {
    return {
        type: AppProfileActionTypeEnum.SET_IS_LOADING_APP_PROFILE,
        isLoading,
    };
}
