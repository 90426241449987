import keywordAnalytics from 'state/keywordAnalytics/reducer';
import selectedKeywords from 'state/selectedKeywords/reducer';
import keywordSuggestions from 'state/keywordSuggestions/reducer';
import keywordSuggestionsModal from 'state/keywordSuggestionsModal/reducer';
import appProfile from 'state/appProfile/reducer';
import competitors from 'state/competitors/reducer';
import keywordRowsOnPage from 'state/keywordRowsOnPage/reducer';
import trackedApps from 'state/trackedApps/reducer';
import comparativeReportTable from 'state/comparativeReport/comparativeReportTable/reducer';
import comparativeReportMeta from 'state/comparativeReport/comparativeReportMeta/reducer';
import comparativeReportChart from 'state/comparativeReport/comparativerReportChart/reducer';
import appVersions from 'state/appVersions/reducer';
import requestInfo from 'state/requestInfo/reducer';
import keywordManager from 'state/keywordManager/reducer';
import keywordAnalyticsSuggestions from 'state/keywordAnalyticsSuggestions/reducer';
import translate from 'state/translate/reducer';
import updatePlanModal from 'state/updatePlanModal/reducer';
import keywordManagerSuggestions from 'state/keywordManagerSuggestions/reducer';
import keywordsShuffle from 'state/keywordsShuffle/reducer';
import user from 'state/user/reducer';
import system from 'state/system/reducer';
import benchmark from 'state/benchmark/reducer';
import keywordDensityCounter from 'state/keywordDensityCounter/reducer';
import account from 'state/account/reducer';
import keywordAutoSuggestions from 'state/keywordAutoSuggestions/reducer';
import optimizer from 'state/optimizer/reducer';
import integrations from 'state/integrations/reducer';
import asodeskHeader from 'state/asodeskHeader/reducer';
import userFrontendSettings from 'state/userFrontendSettings/reducer';
import reviewTemplates from 'state/reviewTemplates/reducer';
import reviewTags from 'state/reviews/reviewTags/reducer';
import replyBoard from 'state/reviews/replyBoard/reducer';
import analysis from 'state/reviews/analysis/reducer';
import reviewFilters from 'state/reviews/reviewFilters/reducer';
import googleConsoleIntegrations from 'state/integrationsGoogle/reducer';
import referralProgram from 'state/referralProgram/reducer';
import teammates from 'state/teammates/reducer';
import { storeConsoleAccounts } from 'state/storeConsoleAccounts/reducer';
import dashboard from 'state/dashboard/reducer';
import { storeConsoleApplications } from 'state/storeConsoleApplications/reducer';
import { storeConsoleTemplates } from 'state/storeConsoleTemplates/reducer';
import countriesLocalizations from 'state/countriesLocalizations/reducer';
import appToolBarSettings from 'state/appToolBarSettings/reducer';
import filtersPresets from 'state/filtersPresets/reducer';
import keywordAnalyzer from 'state/keywordAnalyzer/reducer';
import popup from 'state/popup/reducer';
import tagRule from 'state/reviews/tagRule/reducer';
import replyRule from 'state/reviews/replyRule/reducer';
import topKeywords from 'state/topKeywords/reducer';
import helpdeskIntegrations from 'state/integrationsHelpdesk/reducer';
import localizations from 'state/localizations/reducer';
import payment from 'state/payment/reducer';
import plans from 'state/plans/reducer';
import customReports from 'state/customReports/reducer';
import keywordsRankingDistribution from 'state/keywordsRankingDistribution/reducer';
import keywordExplorer from 'state/keywordExplorer/reducer';
import keywordExplorerAnalytics from 'state/keywordExplorerAnalytics/reducer';
import topPositionsHistory from 'state/topPositionsHistory/reducer';
import keywordExplorerSuggestions from 'state/keywordExplorerSuggestions/reducer';
import keywordProfile from 'state/keywordProfile/reducer';
import campaignPayment from 'state/campaignPayment/reducer';
import tagAnalysis from 'state/tagAnalysis/reducer';
import onboarding from 'state/onboarding/reducer';
import rustoreIntegrations from 'state/integrationsRustore/reducer';

export const rootReducer = {
    account,
    analysis,
    appProfile,
    appToolBarSettings,
    appVersions,
    asodeskHeader,
    benchmark,
    campaignPayment,
    comparativeReportChart,
    comparativeReportMeta,
    comparativeReportTable,
    competitors,
    countriesLocalizations,
    customReports,
    dashboard,
    filtersPresets,
    googleConsoleIntegrations,
    helpdeskIntegrations,
    integrations,
    keywordAnalytics,
    keywordAnalyticsSuggestions,
    keywordAnalyzer,
    keywordAutoSuggestions,
    keywordDensityCounter,
    keywordExplorer,
    keywordExplorerAnalytics,
    keywordExplorerSuggestions,
    keywordManager,
    keywordManagerSuggestions,
    keywordProfile,
    keywordRowsOnPage,
    keywordsRankingDistribution,
    keywordSuggestions,
    keywordSuggestionsModal,
    keywordsShuffle,
    localizations,
    optimizer,
    payment,
    plans,
    popup,
    referralProgram,
    replyBoard,
    replyRule,
    requestInfo,
    reviewFilters,
    reviewTags,
    reviewTemplates,
    selectedKeywords,
    storeConsoleAccounts,
    storeConsoleApplications,
    storeConsoleTemplates,
    system,
    tagRule,
    teammates,
    topKeywords,
    topPositionsHistory,
    trackedApps,
    translate,
    updatePlanModal,
    user,
    userFrontendSettings,
    tagAnalysis,
    onboarding,
    rustoreIntegrations,
};
