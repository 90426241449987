import { KeywordExplorerSuggestionsStateType } from 'state/keywordExplorerSuggestions/KeywordExplorerSuggestionsStateType';
import { KeywordExplorerSuggestionsActionType } from 'state/keywordExplorerSuggestions/actions';
import { KeywordExplorerSuggestionsEnum } from 'state/keywordExplorerSuggestions/actionsTypes';
import { SuggestionDetailsType, SuggestionsType } from 'state/keywordSuggestions/KeywordSuggestionsResponseType';
import { getKeywordSuggestionsHash } from 'state/keywordExplorerSuggestions/getKeywordSuggestionsHash';

const initialState: KeywordExplorerSuggestionsStateType = {
    isLoading: false,
    [SuggestionsType.SUGGESTIONS]: {},
    [SuggestionsType.RELATED]: {},
};

const keywordExplorerSuggestions = (state = initialState, action: KeywordExplorerSuggestionsActionType) => {
    switch (action.type) {
        case KeywordExplorerSuggestionsEnum.SET_KEYWORD_EXPLORER_SUGGESTIONS: {
            const { keyword, storeId, country, related, suggestions } = action;
            const suggestionsHash = getKeywordSuggestionsHash(keyword, country, storeId);
            return {
                ...state,
                [SuggestionsType.SUGGESTIONS]: {
                    ...state[SuggestionsType.SUGGESTIONS],
                    [suggestionsHash]: {
                        ...state[SuggestionsType.SUGGESTIONS][suggestionsHash],
                        ...suggestions,
                    },
                },
                [SuggestionsType.RELATED]: {
                    ...state[SuggestionsType.RELATED],
                    [suggestionsHash]: related,
                },
            };
        }
        case KeywordExplorerSuggestionsEnum.SET_KEYWORD_EXPLORER_SUGGESTIONS_IS_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }
        case KeywordExplorerSuggestionsEnum.SET_KEYWORD_EXPLORER_SUGGESTION_EXISTENCE_IN_APP: {
            const { keywordIds, hasInApp, appProps } = action;
            const { keyword, storeId, country, device } = appProps;
            const suggestionsHash = getKeywordSuggestionsHash(keyword, country, storeId);
            const prevSuggestionsByKeywordHash = state[SuggestionsType.SUGGESTIONS][suggestionsHash];
            const prevRelatedByKeywordHash = state[SuggestionsType.RELATED][suggestionsHash] || [];
            const updateKeywordInformation = (suggestion: SuggestionDetailsType): SuggestionDetailsType => {
                const { keywordId } = suggestion;
                return keywordIds.includes(keywordId) ? { ...suggestion, inUserKeywords: hasInApp } : suggestion;
            };

            return {
                ...state,
                [SuggestionsType.SUGGESTIONS]: {
                    ...state[SuggestionsType.SUGGESTIONS],
                    [suggestionsHash]: {
                        ...prevSuggestionsByKeywordHash,
                        [device]: prevSuggestionsByKeywordHash
                            ? prevSuggestionsByKeywordHash[device]?.map(updateKeywordInformation)
                            : [],
                    },
                },
                [SuggestionsType.RELATED]: {
                    ...state[SuggestionsType.RELATED],
                    [suggestionsHash]: prevRelatedByKeywordHash.map(updateKeywordInformation),
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordExplorerSuggestions;
