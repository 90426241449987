import { KeywordDensityCounterActionTypeListEnum } from './actionsTypes';
import { KeywordDensityCounterActionType } from './actions';
import { KeywordDensityCounterAdaptedType } from './KeywordDensityCounterResponseType';

type AvailableAddingKeywordsType = {
    [key: string]: string[];
};

export type KeywordDensityCounterStateType = {
    keywords: KeywordDensityCounterAdaptedType;
    selectedKeywords: string[];
    availableAddingKeywords: AvailableAddingKeywordsType;
};

const KeywordDensityCounterInitialState: KeywordDensityCounterStateType = {
    keywords: {},
    selectedKeywords: [],
    availableAddingKeywords: {},
};

const keywordDensityCounter = (state = KeywordDensityCounterInitialState, action: KeywordDensityCounterActionType) => {
    switch (action.type) {
        case KeywordDensityCounterActionTypeListEnum.SET_KEYWORD_DENSITY_COUNTER: {
            const availableAddingKeywords = Object.keys(action.keywords).reduce(
                (result: AvailableAddingKeywordsType, column: string) => {
                    const keywordsColumn = action.keywords[column];
                    return {
                        ...result,
                        [column]: keywordsColumn.filter((keyword) => keyword.canAdd).map(({ keyword }) => keyword),
                    };
                },
                {}
            );
            return {
                ...state,
                keywords: action.keywords,
                selectedKeywords: [],
                availableAddingKeywords,
            };
        }
        case KeywordDensityCounterActionTypeListEnum.ADD_KEYWORD_DENSITY_COUNTER_TO_KEYWORDS: {
            const { keywords, availableAddingKeywords } = state;
            const updateAvailableAddingColumnKeywords = availableAddingKeywords[action.columnId].filter((keyword) => keyword !== action.keyword);
            const updatedColumnKeywords = keywords[action.columnId].map((keywordColumn) => {
                return keywordColumn.keyword === action.keyword
                    ? {
                          ...keywordColumn,
                          canAdd: false,
                      }
                    : keywordColumn;
            });

            return {
                ...state,
                keywords: {
                    ...keywords,
                    [action.columnId]: updatedColumnKeywords,
                },
                availableAddingKeywords: {
                    ...availableAddingKeywords,
                    [action.columnId]: updateAvailableAddingColumnKeywords,
                },
            };
        }
        case KeywordDensityCounterActionTypeListEnum.ADD_COLUMN_DENSITY_COUNTER_TO_KEYWORDS: {
            const { keywords, availableAddingKeywords } = state;
            const updatedColumnKeywords = keywords[action.columnId].map((keywordColumn) => {
                return keywordColumn.canAdd
                    ? {
                          ...keywordColumn,
                          canAdd: false,
                      }
                    : keywordColumn;
            });

            return {
                ...state,
                keywords: {
                    ...keywords,
                    [action.columnId]: updatedColumnKeywords,
                },
                availableAddingKeywords: {
                    ...availableAddingKeywords,
                    [action.columnId]: [],
                },
            };
        }
        case KeywordDensityCounterActionTypeListEnum.SELECT_TOGGLE_ONE_KEYWORD_DENSITY_COUNTER: {
            let selectedKeywords = [...state.selectedKeywords];

            if (selectedKeywords.indexOf(action.keyword) !== -1) {
                selectedKeywords = selectedKeywords.filter((keyword) => keyword !== action.keyword);
            } else {
                selectedKeywords = [...selectedKeywords, action.keyword];
            }

            return {
                ...state,
                selectedKeywords,
            };
        }
        case KeywordDensityCounterActionTypeListEnum.SELECT_TOGGLE_COLUMN_KEYWORD_DENSITY_COUNTER: {
            let selectedKeywords = [...state.selectedKeywords];

            const { keywords } = state;
            const columnKeywords = keywords[action.columnId].map((keywordColumn) => keywordColumn.keyword);

            const currentColumnSelectedKeywords = selectedKeywords.filter((keyword) => columnKeywords.indexOf(keyword) !== -1);

            const otherColumnSelectedKeywords = selectedKeywords.filter((keyword) => columnKeywords.indexOf(keyword) === -1);

            if (currentColumnSelectedKeywords.length === columnKeywords.length) {
                selectedKeywords = otherColumnSelectedKeywords;
            } else {
                selectedKeywords = [...otherColumnSelectedKeywords, ...columnKeywords];
            }

            return {
                ...state,
                selectedKeywords,
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordDensityCounter;
