import { ToolTypesEnum } from 'types/toolTypes';
import { arrayMove } from 'utils/helpers/array/arrayMove';
import { Reducer } from 'redux';
import { UserFrontendSettingsInitialStateType } from 'state/userFrontendSettings/UserFrontendSettingsInitialStateType';
import { EMPTY_FILTER_CONSIST_COLUMNS } from 'modules/apps/keywordAnalytics/keywordAnalyticsTable/hiddenColumnGroups';
import { KAColumnsIdEnum } from 'modules/apps/keywordAnalytics/keywordAnalyticsTable/KAColumnsIdEnum';
import { getDisplayValue } from 'state/userFrontendSettings/sidebarDataConverter';
import { initialKeywordExplorerWidgetOrder } from 'state/userFrontendSettings/adapterFrontendSettings';
import { TagCategorySortingEnum } from 'modules/reviews/reviewTags/sortingDropdownMenu/sortingOptions';
import { UserFrontendSettingsActionTypeEnum } from './actionsTypes';
import { SidebarActionType } from './actions';
import { UserFrontendSettingsDefaultValues } from './UserFrontendSettingsDefaultValues';

const userFrontendSettingsInitialState: UserFrontendSettingsInitialStateType = {
    isOpenSidebarOnDesktop: UserFrontendSettingsDefaultValues.isOpenSidebarOnDesktop,
    isOpenSidebarOnMobile: false,
    sidebarSections: {
        ids: [
            ToolTypesEnum.APPS,
            ToolTypesEnum.APP_STORE_OPTIMIZATIONS,
            ToolTypesEnum.REVIEWS_RATINGS,
            ToolTypesEnum.APP_MONITORING,
            ToolTypesEnum.FREE_TOOLS,
            ToolTypesEnum.STORE_ANALYTICS,
            ToolTypesEnum.STORE_CONSOLE,
            ToolTypesEnum.SETTINGS,
            ToolTypesEnum.RESOURCES,
        ],
        byIds: {
            [ToolTypesEnum.APPS]: {
                id: ToolTypesEnum.APPS,
                collapsed: true,
            },
            [ToolTypesEnum.APP_STORE_OPTIMIZATIONS]: {
                id: ToolTypesEnum.APP_STORE_OPTIMIZATIONS,
                collapsed: true,
            },
            [ToolTypesEnum.REVIEWS_RATINGS]: {
                id: ToolTypesEnum.REVIEWS_RATINGS,
                collapsed: true,
            },
            [ToolTypesEnum.APP_MONITORING]: {
                id: ToolTypesEnum.APP_MONITORING,
                collapsed: true,
            },
            [ToolTypesEnum.STORE_ANALYTICS]: {
                id: ToolTypesEnum.STORE_ANALYTICS,
                collapsed: true,
            },
            [ToolTypesEnum.STORE_CONSOLE]: {
                id: ToolTypesEnum.STORE_CONSOLE,
                collapsed: true,
            },
            [ToolTypesEnum.SETTINGS]: {
                id: ToolTypesEnum.SETTINGS,
                collapsed: true,
            },
            [ToolTypesEnum.FREE_TOOLS]: {
                id: ToolTypesEnum.FREE_TOOLS,
                collapsed: true,
            },
            [ToolTypesEnum.RESOURCES]: {
                id: ToolTypesEnum.RESOURCES,
                collapsed: true,
            },
        },
    },
    favoriteTools: [],
    isReplyBoardRandomTemplateSet: false,
    isReplyBoardAutoNextReviewSet: false,
    isKeywordCountryFlagShow: UserFrontendSettingsDefaultValues.isKeywordCountryFlagShow,
    keywordAnalyticsColumnTableOrder: [],
    keywordAnalyticsColumnTableInvisibility: EMPTY_FILTER_CONSIST_COLUMNS,
    appReviewAutoTranslate: {
        appIds: [],
        appByIds: {},
    },
    showTrialExpiredPlan: true,
    showTrialExpiredTomorrowPlan: true,
    isShowWelcomeOfferModal: true,
    isGlobalAppLoaderShow: false,
    keywordExplorerWidgetOrder: initialKeywordExplorerWidgetOrder,
    tagsCategoriesSort: TagCategorySortingEnum.OLDEST,
    isNewNavigation: true,
};

const userFrontendSettings: Reducer<UserFrontendSettingsInitialStateType, SidebarActionType> = (
    state = userFrontendSettingsInitialState,
    action: SidebarActionType
) => {
    switch (action.type) {
        case UserFrontendSettingsActionTypeEnum.TOGGLE_COLLAPSED_SIDEBAR_SECTION: {
            return {
                ...state,
                sidebarSections: {
                    ...state.sidebarSections,
                    byIds: {
                        ...state.sidebarSections.byIds,
                        [action.sectionId]: {
                            ...state.sidebarSections.byIds[action.sectionId],
                            collapsed: !state.sidebarSections.byIds[action.sectionId].collapsed,
                        },
                    },
                },
            };
        }
        case UserFrontendSettingsActionTypeEnum.UPDATE_USER_FRONTEND_SETTINGS: {
            return {
                ...state,
                ...action.settings,
            };
        }
        case UserFrontendSettingsActionTypeEnum.SET_STATS_USER_LIMIT_DATA: {
            return {
                ...state,
                statsUserLimit: action.statsUserLimit,
            };
        }
        case UserFrontendSettingsActionTypeEnum.TOGGLE_OPENED_DESKTOP_SIDEBAR: {
            return {
                ...state,
                isOpenSidebarOnDesktop: !state.isOpenSidebarOnDesktop,
            };
        }
        case UserFrontendSettingsActionTypeEnum.TOGGLE_OPENED_MOBILE_SIDEBAR: {
            return {
                ...state,
                isOpenSidebarOnMobile: !state.isOpenSidebarOnMobile,
            };
        }
        case UserFrontendSettingsActionTypeEnum.CLOSE_MOBILE_SIDEBAR: {
            return {
                ...state,
                isOpenSidebarOnMobile: false,
            };
        }
        case UserFrontendSettingsActionTypeEnum.TOGGLE_FAVORITE_TOOL: {
            return {
                ...state,
                favoriteTools: state.favoriteTools.includes(action.toolName)
                    ? state.favoriteTools.filter((toolName) => toolName !== action.toolName)
                    : [...state.favoriteTools, action.toolName],
            };
        }
        case UserFrontendSettingsActionTypeEnum.CHANGE_FAVORITE_LINK_POSITION: {
            return {
                ...state,
                favoriteTools: arrayMove(state.favoriteTools, action.oldIndex, action.newIndex),
            };
        }
        case UserFrontendSettingsActionTypeEnum.SET_REPLY_BOARD_RANDOM_TEMPLATE_FLAG: {
            return {
                ...state,
                isReplyBoardRandomTemplateSet: action.flag,
            };
        }
        case UserFrontendSettingsActionTypeEnum.SET_REPLY_BOARD_AUTO_NEXT_REVIEW_FLAG: {
            return {
                ...state,
                isReplyBoardAutoNextReviewSet: action.flag,
            };
        }
        case UserFrontendSettingsActionTypeEnum.SET_KEYWORD_COUNTRY_FLAG_SHOW: {
            return {
                ...state,
                isKeywordCountryFlagShow: action.flag,
            };
        }
        case UserFrontendSettingsActionTypeEnum.SET_GLOBAL_APP_LOADER_SHOW: {
            return {
                ...state,
                isGlobalAppLoaderShow: action.flag,
            };
        }

        case UserFrontendSettingsActionTypeEnum.CHANGE_KEYWORD_ANALYTICS_TABLE_COLUMN_ORDER: {
            const columnsWithoutActionsColumn = action.columnsIds.filter((id) => id !== KAColumnsIdEnum.KEYWORD_LENGTH);
            return {
                ...state,
                keywordAnalyticsColumnTableOrder: [...columnsWithoutActionsColumn, KAColumnsIdEnum.KEYWORD_LENGTH],
            };
        }
        case UserFrontendSettingsActionTypeEnum.ADD_KEYWORD_ANALYTICS_TABLE_COLUMN_INVISIBILITY: {
            const updatedHiddenColumns = !state.keywordAnalyticsColumnTableInvisibility.includes(action.columnsId)
                ? [...state.keywordAnalyticsColumnTableInvisibility, action.columnsId]
                : state.keywordAnalyticsColumnTableInvisibility;

            return {
                ...state,
                keywordAnalyticsColumnTableInvisibility: updatedHiddenColumns,
            };
        }
        case UserFrontendSettingsActionTypeEnum.REMOVE_KEYWORD_ANALYTICS_TABLE_COLUMN_INVISIBILITY: {
            return {
                ...state,
                keywordAnalyticsColumnTableInvisibility: state.keywordAnalyticsColumnTableInvisibility.filter(
                    (columnId) => columnId !== action.columnsId
                ),
            };
        }
        case UserFrontendSettingsActionTypeEnum.ADD_APP_REVIEW_AUTO_TRANSLATE: {
            if (state.appReviewAutoTranslate.appIds.includes(action.appId)) {
                return state;
            }

            return {
                ...state,
                appReviewAutoTranslate: {
                    appByIds: {
                        ...state.appReviewAutoTranslate.appByIds,
                        [action.appId]: {
                            appId: action.appId,
                            isShowInfo: true,
                            timeStart: action.timeStart,
                        },
                    },
                    appIds: [...state.appReviewAutoTranslate.appIds, action.appId],
                },
            };
        }
        case UserFrontendSettingsActionTypeEnum.REMOVE_APP_REVIEW_AUTO_TRANSLATE: {
            return {
                ...state,
                appReviewAutoTranslate: {
                    ...state.appReviewAutoTranslate,
                    appIds: state.appReviewAutoTranslate.appIds.filter((appId) => appId !== action.appId),
                },
            };
        }
        case UserFrontendSettingsActionTypeEnum.HIDE_APP_REVIEW_AUTO_TRANSLATE_INFO: {
            return {
                ...state,
                appReviewAutoTranslate: {
                    ...state.appReviewAutoTranslate,
                    appByIds: {
                        ...state.appReviewAutoTranslate.appByIds,
                        [action.appId]: {
                            ...state.appReviewAutoTranslate.appByIds[action.appId],
                            isShowInfo: false,
                        },
                    },
                },
            };
        }
        case UserFrontendSettingsActionTypeEnum.CHANGE_SHOW_TRIAL_BY_TYPE: {
            return {
                ...state,
                [action.trialType]: action.isShow,
            };
        }
        case UserFrontendSettingsActionTypeEnum.HIDE_WO_MODAL: {
            return {
                ...state,
                isShowWelcomeOfferModal: false,
            };
        }
        case UserFrontendSettingsActionTypeEnum.ADD_USER_LIMITS_AMOUNT: {
            return {
                ...state,
                statsUserLimit: state.statsUserLimit && {
                    ...state.statsUserLimit,
                    limits: state.statsUserLimit?.limits.map((limitData) => {
                        const newCurrentValue = limitData.current + action.amount;
                        return limitData.id === action.limitType
                            ? {
                                  ...limitData,
                                  current: newCurrentValue,
                                  displayValue: getDisplayValue(newCurrentValue, limitData.max),
                              }
                            : limitData;
                    }),
                },
            };
        }
        case UserFrontendSettingsActionTypeEnum.CHANGE_KEYWORD_EXPLORER_WIDGET_ORDER: {
            return {
                ...state,
                keywordExplorerWidgetOrder: action.order,
            };
        }
        case UserFrontendSettingsActionTypeEnum.SET_TAGS_CATEGORY_SORTING: {
            return {
                ...state,
                tagsCategoriesSort: action.sorting,
            };
        }
        case UserFrontendSettingsActionTypeEnum.TOGGLE_SHOWING_NEW_NAVIGATION: {
            return {
                ...state,
                isNewNavigation: !state.isNewNavigation,
            };
        }
        case UserFrontendSettingsActionTypeEnum.SET_LAST_CURRENT_APP_ID: {
            return {
                ...state,
                lastCurrentAppId: action.appId,
            };
        }
        case UserFrontendSettingsActionTypeEnum.SET_LAST_CURRENT_LOCALE: {
            return {
                ...state,
                lastCurrentLocale: action.locale,
            };
        }
        default: {
            return state;
        }
    }
};

export default userFrontendSettings;
