import React, { FC } from 'react';
import { TextOptionType } from 'newComponents/forms/select/customComponents/options/textOption/TextOption';
import { CategoryPlanType, SubPlanType } from 'state/plans/PlansType';
import { PlanFeatures } from 'newComponents/plans/planFeatures/PlanFeatures';
import { removeDuplicates } from 'utils/helpers/array/removeDuplicates';
import { numberFormat } from 'utils/helpers/string/numberFormat';
import { withSpecificPlural } from 'utils/helpers/string/text';
import { PlanAdditionalSettings } from 'newComponents/plans/planAdditionalSettings/PlanAdditionalSettings';
import { PaymentPeriodEnum } from 'state/payment/paymentTypes';
import { LANGUAGE_RU } from 'constants/localizationsConstants';
import { PlanMeta } from 'newComponents/plans/planMeta/PlanMeta';
import { AsoDataType, REVIEW_ASO_DATA_TYPE } from './AsoContentTypes';
import { SelectPlanReviewsContainer } from '../reviewsContent/SelectPlanReviewsContainer';
import { getCurrencySymbol } from 'modules/modal/paymentModal/constants';

type PropsType = Pick<CategoryPlanType, 'plans'> & {
    setDataType: (dataType: AsoDataType) => void;
    dataType: AsoDataType;
    selectedReview: TextOptionType;
    setPlanId: (planId: number) => void;
    paymentPeriod: PaymentPeriodEnum;
    hideDescription?: boolean;
};

export const ReviewSettingsContainer: FC<PropsType> = ({
    setDataType,
    selectedReview,
    setPlanId,
    plans,
    paymentPeriod,
    dataType,
    hideDescription,
}: PropsType) => {
    const onChangeSettings = (isActive: boolean) => setDataType(isActive ? 'reviewsAso' : 'aso');

    const filteredPlans: SubPlanType[] = removeDuplicates(plans, 'reviews');

    const adaptedFeatures = filteredPlans[0]
        ? filteredPlans[0].reviewsFeatures.map(({ values }) => ({
              values,
          }))
        : [];

    const currentPrice = plans[0].pricing.find(({ id }) => id === paymentPeriod);

    return (
        <PlanAdditionalSettings
            hideDescription={hideDescription}
            title="Reply-to-Reviews Tools"
            defaultIsShowSettings={dataType === REVIEW_ASO_DATA_TYPE}
            onChangeSettings={onChangeSettings}
            {...(currentPrice?.priceDifference
                ? { meta: `+ ${getCurrencySymbol()}${numberFormat({ number: currentPrice.priceDifference })} /month` }
                : {})}
            Content={
                <div className="mt-16">
                    {filteredPlans.length !== 1 ? (
                        <SelectPlanReviewsContainer
                            plans={plans}
                            setPlanId={setPlanId}
                            selectedReview={selectedReview}
                            isBorder
                            isShadow={false}
                            paymentPeriod={paymentPeriod}
                        />
                    ) : (
                        <PlanMeta
                            title={`${numberFormat({
                                number: filteredPlans[0].reviews,
                                locale: LANGUAGE_RU,
                            })} ${withSpecificPlural(filteredPlans[0].reviews, 'reply', 'replies')}`}
                            meta="/month"
                        />
                    )}
                    <PlanFeatures features={adaptedFeatures} className="mt-16" />
                </div>
            }
        />
    );
};
