import { KeywordProfileStateType } from 'state/keywordProfile/KeywordProfileStateType';
import { KeywordProfileActionType } from 'state/keywordProfile/actions';
import { KeywordProfileActionEnum } from 'state/keywordProfile/actionsTypes';
import { getKeywordProfileHash } from 'state/keywordProfile/getKeywordProfileHash';

const initialState: KeywordProfileStateType = {};

const keywordProfile = (state = initialState, action: KeywordProfileActionType) => {
    switch (action.type) {
        case KeywordProfileActionEnum.SET_KEYWORD_PROFILE: {
            const keywordHash = getKeywordProfileHash(action);
            return {
                ...state,
                [keywordHash]: action.profile,
            };
        }
        case KeywordProfileActionEnum.FETCHING_KEYWORD_PROFILE_COUNTRY: {
            const keywordHash = getKeywordProfileHash(action);
            return {
                ...state,
                [keywordHash]: state[keywordHash].map((profile) => {
                    const { country } = profile;
                    return country === action.country ? { ...profile, status: action.status } : profile;
                }),
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordProfile;
