import React, { FC } from 'react';
import { Route, Navigate } from 'react-router-dom';
import {
    getPartialReviewsRouteAnalysisUrl,
    getPartialReviewsRouteAutoRepliesUrl,
    getPartialReviewsRouteAutoTagsUrl,
    getPartialReviewsRouteBoardUrl,
    getPartialReviewsRouteFeaturedUrl,
    getPartialReviewsRouteRatingAnalysisUrl,
    getPartialReviewsRouteTagAnalysisUrl,
    getPartialReviewsRouteTagsUrl,
    getPartialReviewsRouteTemplatesUrl,
} from 'utils/routeUrls';
import { CheckPrivateRoute } from 'modules/routers/new/CheckPrivateRoute';
import { AsyncReplyBoardPageContainer } from 'pages/reviews/replyBoard/AsyncReplyBoardPageContainer';
import { AsyncFeaturedReviewsPageContainer } from 'pages/reviews/featuredReviews/AsyncFeaturedReviewsPageContainer';
import { AsyncReviewsAnalysisPageContainer } from 'pages/reviews/reviewsAnalysis/AsyncReviewsAnalysisPageContainer';
import { AsyncTagAnalysisPageContainer } from 'pages/tagsAnalysis/AsyncTagAnalysisPageContainer';
import { AsyncRatingAnalysisPageContainer } from 'pages/reviews/ratingAnalysis/AsyncRatingAnalysisPageContainer';
import { AsyncTemplatesPageContainer } from 'pages/reviews/templates/AsyncTemplatesPageContainer';
import { AsyncReviewAutoRepliesPageContainer } from 'pages/reviews/autoReplies/AsyncReviewAutoRepliesPageContainer';
import { AsyncReviewTagsPageContainer } from 'pages/reviews/tags/AsyncReviewTagsPageContainer';
import { AsyncReviewAutoTagsPageContainer } from 'pages/reviews/autoTags/AsyncReviewAutoTagsPageContainer';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { FaroRoutes } from '@grafana/faro-react';

export const ReviewsBoardUrlRedirect: FC = () => {
    const { reviewsBoardUrl } = useRoutesUrl();

    return <Navigate to={reviewsBoardUrl} replace />;
};

export const ReviewsRoutes: FC = () => (
    <FaroRoutes>
        <Route path="*" element={<ReviewsBoardUrlRedirect />} />
        <Route path={getPartialReviewsRouteBoardUrl(':appId')} element={<AsyncReplyBoardPageContainer />} />
        <Route
            path={getPartialReviewsRouteFeaturedUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncFeaturedReviewsPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={getPartialReviewsRouteAnalysisUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncReviewsAnalysisPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route path={getPartialReviewsRouteTagAnalysisUrl(':appId')} element={<AsyncTagAnalysisPageContainer />} />
        <Route
            path={getPartialReviewsRouteRatingAnalysisUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncRatingAnalysisPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={getPartialReviewsRouteTemplatesUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncTemplatesPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={getPartialReviewsRouteAutoRepliesUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncReviewAutoRepliesPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={getPartialReviewsRouteTagsUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncReviewTagsPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={getPartialReviewsRouteAutoTagsUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncReviewAutoTagsPageContainer />
                </CheckPrivateRoute>
            }
        />
    </FaroRoutes>
);
