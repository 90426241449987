import { KeywordAutoSuggestionsActionTypeListEnum } from './actionsTypes';
import { KeywordAutoSuggestionsActionType } from './actions';
import { KeywordAutoSuggestionsTabIdsEnum, KeywordAutoSuggestionsType } from './KeywordAutoSuggestionsResponseType';

type TabType = {
    keywords: KeywordAutoSuggestionsType;
    countNotifications?: number;
    isShowPopularKeyword: boolean;
    isShowMissingKeywords: boolean;
    recordsTotal?: number;
    storeParams: KeywordAutoSuggestionsStoreParamsType;
};

export type KeywordAutoSuggestionsStoreParamsType = {
    country?: string;
    storeId?: string | number;
};

export type KeywordAutoSuggestionsStateType = {
    tabs: Record<KeywordAutoSuggestionsTabIdsEnum, TabType>;
    isAlert?: boolean;
    activeTabId: KeywordAutoSuggestionsTabIdsEnum;
};

const initialStateTab = {
    keywords: [],
    isShowPopularKeyword: true,
    isShowMissingKeywords: true,
    storeParams: {},
};

const KeywordAutoSuggestionsInitialState = {
    tabs: {
        [KeywordAutoSuggestionsTabIdsEnum.MOST_POPULAR_KEYWORDS]: initialStateTab,
        [KeywordAutoSuggestionsTabIdsEnum.COMPETITORS_BEST_KEYWORDS]: initialStateTab,
        [KeywordAutoSuggestionsTabIdsEnum.LONG_TAIL_KEYWORDS]: initialStateTab,
        [KeywordAutoSuggestionsTabIdsEnum.LEAST_COMPETITIVE_KEYWORDS]: initialStateTab,
        [KeywordAutoSuggestionsTabIdsEnum.GOOD_FOR_KEYWORD_BOOSTING]: initialStateTab,
        [KeywordAutoSuggestionsTabIdsEnum.EXPECTED_TO_BE_TRENDING]: initialStateTab,
    },
    activeTabId: KeywordAutoSuggestionsTabIdsEnum.MOST_POPULAR_KEYWORDS,
};

const keywordAutoSuggestions = (
    state: KeywordAutoSuggestionsStateType = KeywordAutoSuggestionsInitialState,
    action: KeywordAutoSuggestionsActionType
) => {
    switch (action.type) {
        case KeywordAutoSuggestionsActionTypeListEnum.SET_KEYWORD_AUTO_SUGGESTIONS: {
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [action.tabId]: {
                        ...state.tabs[action.tabId],
                        keywords: action.keywords,
                        recordsTotal: action.recordsTotal,
                        storeParams: action.storeParams,
                    },
                },
            };
        }
        case KeywordAutoSuggestionsActionTypeListEnum.SET_KEYWORD_AUTO_SUGGESTIONS_ACTIVE_TAB_ID: {
            return {
                ...state,
                activeTabId: action.tabId,
            };
        }
        case KeywordAutoSuggestionsActionTypeListEnum.TOGGLE_SHOW_MISSING_KEYWORD_AUTO_SUGGESTIONS: {
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [action.tabId]: {
                        ...state.tabs[action.tabId],
                        isShowMissingKeywords: !state.tabs[action.tabId].isShowMissingKeywords,
                    },
                },
            };
        }
        case KeywordAutoSuggestionsActionTypeListEnum.TOGGLE_SHOW_POPULAR_KEYWORD_AUTO_SUGGESTIONS: {
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [action.tabId]: {
                        ...state.tabs[action.tabId],
                        isShowPopularKeyword: !state.tabs[action.tabId].isShowPopularKeyword,
                    },
                },
            };
        }
        case KeywordAutoSuggestionsActionTypeListEnum.ADD_KEYWORDS_KEYWORD_AUTO_SUGGESTIONS: {
            const tabNames = Object.keys(state.tabs) as KeywordAutoSuggestionsTabIdsEnum[];

            const tabs = tabNames.reduce(
                (result, tabName) => ({
                    ...result,
                    [tabName]: {
                        ...state.tabs[tabName],
                        keywords: state.tabs[tabName].keywords.map((keyword) => {
                            if (action.keywords.includes(keyword.keyword)) {
                                return {
                                    ...keyword,
                                    add_keyword: false,
                                };
                            }

                            return keyword;
                        }),
                    },
                }),
                {}
            ) as Record<KeywordAutoSuggestionsTabIdsEnum, TabType>;

            return {
                ...state,
                tabs,
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordAutoSuggestions;
