import React, { FC, useState, ReactNode } from 'react';
import { OnClickPlanActionType } from 'modules/settings/plans/planTabs/useActionPropsForPlans';
import { ButtonStateEnum } from 'components/buttons/button/buttonEnum';
import { OnboardingStepPropsType, TrialStepNextData } from 'modules/modal/onboarding/choosePlanStep/trialStepTypes';
import { PlanTabsActionType } from 'modules/settings/plans/planTabs/planTabsTypes';

type TrialPlanCardContainerType = Pick<OnboardingStepPropsType<TrialStepNextData>, 'onNext'> & {
    renderPlanCard: (planActionProps: PlanTabsActionType) => ReactNode;
    planName: string;
    onLeaveRequest: (isSendRequest: boolean, planName?: string) => void;
    hasLeaveRequest: boolean;
};

const getAnalyticsPlanId = (planName: string) => {
    const adaptedPlanName = planName.toLowerCase().replace(' ', '');

    return `gm-get-trial-${adaptedPlanName}`;
};

export const TrialPlanCardContainer: FC<TrialPlanCardContainerType> = ({
    renderPlanCard,
    planName,
    onNext,
    onLeaveRequest,
    hasLeaveRequest,
}) => {
    const [isLoadingTrial, setIsLoadingTrial] = useState(false);
    const [isLoadingLeaveRequest, setIsLoadingLeaveRequest] = useState(false);

    const actionTrialProps = {
        text: 'Get free trial',
        onClick: async ({ plan }: OnClickPlanActionType) => {
            if (plan) {
                setIsLoadingTrial(true);
                await onNext({ planId: plan.id, planName: plan.name });
                setIsLoadingTrial(false);
            }
        },
        state: ButtonStateEnum.SUCCESS,
        className: 'gm-onboarding-get-trial-btn',
        textClassName: 'gm-onboarding-get-trial-btn',
        loading: isLoadingTrial,
        disabled: isLoadingTrial,
    };

    const actionLeaveRequestProps = {
        text: 'Leave request',
        onClick: async ({ plan }: OnClickPlanActionType) => {
            if (plan) {
                setIsLoadingLeaveRequest(true);
                await onLeaveRequest(true, plan.name);
                setIsLoadingLeaveRequest(false);
            }
        },
        state: ButtonStateEnum.PRIMARY,
        className: 'gm-onboarding-get-trial-btn',
        textClassName: 'gm-onboarding-get-trial-btn',
        loading: isLoadingLeaveRequest,
        disabled: isLoadingLeaveRequest,
    };

    const planActionProps = {
        ...(hasLeaveRequest ? actionLeaveRequestProps : actionTrialProps),
        id: getAnalyticsPlanId(planName),
    };

    return <>{renderPlanCard(planActionProps)}</>;
};
