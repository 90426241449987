import {
    ADD_KEYWORDS_TO_KEYWORDS_MANAGER,
    REMOVE_KEYWORDS_FROM_KEYWORDS_MANAGER,
    SET_KEYWORDS_TO_KEYWORDS_MANAGER,
    ADD_UNIQUE_KEYWORDS_TO_KEYWORDS_MANAGER,
} from 'state/keywordManager/actionTypes';
import { KeywordManagerActionType } from 'state/keywordManager/actions';

const keywordManager = (state: string[] = [], action: KeywordManagerActionType) => {
    switch (action.type) {
        case ADD_KEYWORDS_TO_KEYWORDS_MANAGER: {
            return [...action.keywordList.concat(state)];
        }
        case ADD_UNIQUE_KEYWORDS_TO_KEYWORDS_MANAGER: {
            const uniqueKeywords = action.keywordList.filter((keyword) => !state.includes(keyword));
            return [...uniqueKeywords, ...state];
        }
        case REMOVE_KEYWORDS_FROM_KEYWORDS_MANAGER: {
            return state.filter((keyword) => !action.keywordList.includes(keyword));
        }
        case SET_KEYWORDS_TO_KEYWORDS_MANAGER: {
            return [...action.keywordList];
        }
        default: {
            return state;
        }
    }
};

export default keywordManager;
