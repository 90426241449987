import { PaymentPeriodEnum } from 'state/payment/paymentTypes';
import { useAppSelector } from 'hooks/useAppSystem';
import { usePromo } from 'modules/promo/usePromo';
import { useRequestInfoService } from './data/useRequestInfoService';

export const useWelcomeOffer = () => {
    const isShowWelcomeOfferModal = useAppSelector(({ userFrontendSettings }) => userFrontendSettings.isShowWelcomeOfferModal);

    const { hasPromo } = usePromo();

    // Asodesk welcomeOffer
    const { welcomeOfferAvailable } = useRequestInfoService();
    const hasWelcomeOffer = welcomeOfferAvailable && !hasPromo;

    const getIs3MonthsWelcomeOffer = (paymentPeriod: PaymentPeriodEnum) =>
        hasWelcomeOffer && paymentPeriod === PaymentPeriodEnum.MONTHS_3;

    return {
        hasWelcomeOffer,
        isShowWelcomeOfferModal,
        getIs3MonthsWelcomeOffer,
        welcomeOfferAvailable,
    };
};
