export const DEFAULT_COUNTRY = 'us';
export const DEFAULT_COUNTRY_RU = 'ru';
export const DEFAULT_LANGUAGE = 'en';

export const DEFAULT_COUNTRIES = [DEFAULT_COUNTRY];
export const DEFAULT_LANGUAGES = [DEFAULT_LANGUAGE];

export const LANGUAGE_RU = 'ru';
export const RU_LOCALE = 'ru-RU';
export const US_LOCALE = 'en-US';
