import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import { CheckPrivateRoute } from 'modules/routers/new/CheckPrivateRoute';
import { AsyncReferralProgramPageContainer } from 'pages/settings/referralProgram/AsyncReferralProgramPageContainer';
import { FaroRoutes } from '@grafana/faro-react';

export const ReferralProgramRoutes: FC = () => (
    <FaroRoutes>
        <Route
            index
            element={
                <CheckPrivateRoute>
                    <AsyncReferralProgramPageContainer />
                </CheckPrivateRoute>
            }
        />
    </FaroRoutes>
);
