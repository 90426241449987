import React, { FC } from 'react';
import classNames from 'classnames';
import { ValueType } from 'react-select';
import { TextOption, TextOptionType } from 'newComponents/forms/select/customComponents/options/textOption/TextOption';
import { isSelectValue, Select } from 'newComponents/forms/select/Select';
import { TextSingleValue } from 'newComponents/forms/select/customComponents/singleValue/TextSingleValue';
import { CategoryPlanType } from 'state/plans/PlansType';
import { withSpecificPlural } from 'utils/helpers/string/text';
import { numberFormat } from 'utils/helpers/string/numberFormat';
import { PaymentPeriodEnum } from 'state/payment/paymentTypes';
import { LANGUAGE_RU } from 'constants/localizationsConstants';

type PropsType = Pick<CategoryPlanType, 'plans'> & {
    setPlanId: (planId: number) => void;
    selectedReview: TextOptionType;
    isBorder?: boolean;
    isShadow?: boolean;
    paymentPeriod: PaymentPeriodEnum;
};

export const SelectPlanReviewsContainer: FC<PropsType> = ({
    plans,
    setPlanId,
    selectedReview,
    isBorder = false,
    isShadow = true,
    paymentPeriod,
}: PropsType) => {
    const options = plans
        .filter(({ reviews }) => reviews)
        .map(({ id, reviews, pricing }) => {
            const currentPrice = pricing.find(({ id }) => id === paymentPeriod);

            return {
                id,
                value: `${numberFormat({ number: reviews, locale: LANGUAGE_RU })} ${withSpecificPlural(
                    reviews,
                    'reply',
                    'replies'
                )}`,
                subValue: '/month',
                meta: <span className="text-12 text-gray-400 nowrap ml-4">${currentPrice?.price} /mo</span>,
            };
        });

    const onChangeSelect = (selectedValue: ValueType<TextOptionType>) => {
        if (isSelectValue(selectedValue)) {
            setPlanId(selectedValue.id);
        }
    };

    return (
        <Select<TextOptionType>
            components={{
                Option: TextOption,
                SingleValue: TextSingleValue,
            }}
            onChangeSelect={onChangeSelect}
            isSearchable={false}
            defaultValue={selectedReview}
            options={options}
            isBorder={isBorder}
            className={classNames('rounded-4', { 'shadow-8-1': isShadow })}
        />
    );
};
