export enum PresetsTypesListEnum {
    REVIEW = 'review',
    RATING = 'rating',
}

export type FiltersPresetDataTypes = {
    id: number;
    title?: string;
    data: any;
    presetType: PresetsTypesListEnum;
    dateCreated: string;
    isCommon?: boolean;
};

export type FiltersPresetsNormalizedDataTypes = {
    ids: number[];
    byIds: {
        [id: number]: FiltersPresetDataTypes;
    };
};

export type FiltersPresetsInitialStateType = {
    [key in PresetsTypesListEnum]: FiltersPresetsNormalizedDataTypes;
};
