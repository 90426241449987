import { PopupActionType } from './actions';
import { PopupActionTypeEnum } from './actionsTypes';
import { PopupPayloadType } from './PopupTypes';

export type PopupStateType = {
    [popupName: string]: PopupPayloadType & {
        isOpen: boolean;
    };
};

const popupInitialState = {};

const popup = (state: PopupStateType = popupInitialState, action: PopupActionType) => {
    switch (action.type) {
        case PopupActionTypeEnum.TOGGLE_POPUP: {
            const isOpen = state[action.popupName] ? !state[action.popupName].isOpen : true;

            return {
                ...state,
                [action.popupName]: {
                    isOpen,
                    ...action.payload,
                },
            };
        }
        case PopupActionTypeEnum.SET_POPUP_VALUE: {
            return {
                ...state,
                [action.popupName]: {
                    ...state[action.popupName],
                    isOpen: action.isOpen,
                },
            };
        }
        case PopupActionTypeEnum.CLOSE_ALL_POPUPS: {
            const closedAllPopups: PopupStateType = {};
            Object.entries(state).forEach((values) => {
                const [popupName, popupData] = values;
                closedAllPopups[popupName] = {
                    ...popupData,
                    isOpen: false,
                };
            });

            return closedAllPopups;
        }
        case PopupActionTypeEnum.CHANGE_POPUP_PAYLOAD: {
            return {
                ...state,
                [action.popupName]: {
                    ...state[action.popupName],
                    ...action.payload,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default popup;
