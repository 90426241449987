import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { createReactRouterV6Options, getWebInstrumentations, initializeFaro, ReactIntegration } from '@grafana/faro-react';

const isDevelopment = process.env.NODE_ENV === 'development';

const demoUserEmail = 'demo@demo.demo';
const demoUserId = '4451';

export const initFaro = async (isMobile: boolean) => {
    try {
        const compression = 'gzip';

        const isTestStage = window.location.href.includes('feature-');

        initializeFaro({
            url: 'https://faro.asodesk.com/collect',
            apiKey: 'YkGrh1JZkm8KkBmzqgoXNQ==',
            dedupe: true,
            paused: isDevelopment || isTestStage,
            app: {
                name: 'frontend-asodesk',
                ...(window?.ravenSettings?.release ? { release: window.ravenSettings.release } : {}),
            },
            beforeSend: (item) => {
                return {
                    ...item,
                    payload: {
                        ...item.payload,
                        context: {
                            ...(compression ? { compression } : {}),
                            platform: isMobile ? 'mobile' : 'desktop',
                        },
                    },
                };
            },
            instrumentations: [
                ...getWebInstrumentations(),
                new ReactIntegration({
                    router: createReactRouterV6Options({
                        createRoutesFromChildren,
                        matchRoutes,
                        Routes,
                        useLocation,
                        useNavigationType,
                    }),
                }),
            ],
            user: {
                id: window?.ravenSettings?.id || demoUserId,
                email: window?.ravenSettings?.email || demoUserEmail,
            },
        });
    } catch (e) {
        console.error('Faro available only production!');
    }
};
