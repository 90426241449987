import { Dispatch } from 'redux';
import axios from 'axios';
import { apiUrls, FrontendUrls } from 'utils/Urls';
import { ThunkDispatch } from 'types/react-redux-thunk';
import { OnboardingStateType, OnboardingStepEnum } from 'state/onboarding/types';
import { setOnboardingDataAction } from 'state/onboarding/actions';
import { setStatsUserLimitData } from 'state/userFrontendSettings/controller';
import { stringifyUrl } from 'utils/helpers/queryString';
import {
    connectionsHubRouteSettingsUrl,
    getAsoToolsRouteAppProfileUrl,
    getAsoToolsRouteFindAndTrackUrl,
    getKeywordBoostRouteUrl,
    getReviewsRouteBoardUrl,
    myAppsRouteUrl,
    storesAnalyticsRouteSearchExplorerUrl,
} from 'utils/routeUrls';
import { AppResponseType } from 'state/appProfile/AppResponseType';
import { RootState } from 'state/store';

export const saveOnboardingState = ({ isReadyTour, ...onboardingData }: OnboardingStateType) => async (
    dispatch: Dispatch,
    store: () => RootState
) => {
    try {
        const {
            system: { locale, appId },
            trackedApps: { appList },
        } = store();

        const currentAppId = appId || appList[0]?.store_id;

        const currentApp: AppResponseType | undefined = appList.find((app: AppResponseType) => currentAppId === app.store_id);

        const myAppsUrl = stringifyUrl({ url: myAppsRouteUrl, query: { locale } });
        const asoToolsAppProfileUrl = stringifyUrl({
            url: getAsoToolsRouteAppProfileUrl(currentAppId),
            query: { locale },
        });
        const asoToolsFindAndTrackUrl = stringifyUrl({
            url: getAsoToolsRouteFindAndTrackUrl(currentAppId),
            query: { locale },
        });
        const reviewsBoardUrl = stringifyUrl({
            url: getReviewsRouteBoardUrl(currentAppId),
            query: { locale },
        });
        const storesAnalyticsSearchExplorerUrl = stringifyUrl({
            url: storesAnalyticsRouteSearchExplorerUrl,
            query: { locale, keyword: currentApp?.name },
        });
        const connectionsHubSettingsUrl = connectionsHubRouteSettingsUrl;
        const keywordBoostUrl = stringifyUrl({
            url: getKeywordBoostRouteUrl(currentAppId),
            query: { locale },
        });

        await axios.post<OnboardingStateType>(FrontendUrls.onboardingState(), {
            ...onboardingData,
            onboardingSteps: {
                ...onboardingData.onboardingSteps,
                ...(locale
                    ? {
                          [OnboardingStepEnum.SELECT_COUNTRY_STEP]: {
                              url: myAppsUrl,
                          },
                          [OnboardingStepEnum.ADD_FIRST_APP_STEP]: {
                              url: myAppsUrl,
                          },
                          [OnboardingStepEnum.GLOBAL_NAVIGATION_STEP]: {
                              url: myAppsUrl,
                          },
                          [OnboardingStepEnum.STORES_ANALYTICS_STEP]: {
                              url: storesAnalyticsSearchExplorerUrl,
                          },
                      }
                    : {}),
                ...(currentAppId && locale
                    ? {
                          [OnboardingStepEnum.LOCAL_NAVIGATION_AND_TOOLS_STEP]: {
                              url: asoToolsAppProfileUrl,
                          },
                          [OnboardingStepEnum.POSSIBILITIES_OF_ASODESK_STEP]: {
                              url: asoToolsFindAndTrackUrl,
                          },
                          [OnboardingStepEnum.REVIEWS_RATINGS_STEP]: {
                              url: reviewsBoardUrl,
                          },
                          [OnboardingStepEnum.KEYWORD_BOOST_STEP]: {
                              url: keywordBoostUrl,
                          },
                      }
                    : {}),
                [OnboardingStepEnum.CONNECTIONS_HUB_STEP]: {
                    url: connectionsHubSettingsUrl,
                },
            },
        });

        dispatch(setOnboardingDataAction(onboardingData));
    } catch (error) {
        console.error(error);
    }
};

export const getOnboardingState = () => async (dispatch: ThunkDispatch) => {
    try {
        const response = await axios.get<OnboardingStateType>(FrontendUrls.onboardingState());

        if (response.data.onboardingCurrentStepId) {
            dispatch(setOnboardingDataAction(response.data));
        }

        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const SET_PLAN_TRIAL_ERROR = 'Error when getting free trial. Please contact support.';

export const setOnboardingTrialPlan = (planId: number, isSendRequest?: boolean) => async (dispatch: ThunkDispatch) => {
    try {
        await axios.post(FrontendUrls.createFreeTrial(), {
            plan_id: planId,
            ...(isSendRequest ? { is_want_demo_business_plan: true } : {}),
        });
        await dispatch(setStatsUserLimitData());
    } catch (error) {
        console.error(error);

        if (error.response.data && error.response.data.detail === 'Not found.') {
            throw new Error(SET_PLAN_TRIAL_ERROR);
        }
    }
};

type SendSlackQuestionType = {
    appsAmount?: string;
    installsAmount?: string;
    contact?: string;
    userName?: string;
    businessGoal?: string;
};

export const sendSlackQuestion = ({
    appsAmount,
    installsAmount,
    contact,
    userName,
    businessGoal,
}: SendSlackQuestionType) => async (dispatch: Dispatch, store: () => RootState) => {
    const hasBody = Object.values({ appsAmount, installsAmount, contact, userName }).filter((value) => Boolean(value));

    const {
        system: { locale },
        requestInfo,
    } = store();

    const firstName = requestInfo.userFirstName;
    const country = requestInfo.userCountry;

    try {
        await axios.post(
            apiUrls.businessPlanSlackQuestion(),
            hasBody
                ? {
                      name: firstName,
                      country,
                      language: locale,
                      user_name: userName,
                      communication: contact,
                      number_of_apps: appsAmount,
                      business_goal: businessGoal,
                      number_of_installs: installsAmount,
                  }
                : undefined,
            {
                params: {
                    ...(hasBody ? { case: 'questionnaire' } : {}),
                },
            }
        );
    } catch (error) {
        console.error(error);
    }
};
