import React, { FC } from 'react';
import { PaymentPeriodEnum } from 'state/payment/paymentTypes';
import { PlanCardStateEnum } from 'newComponents/plans/planCard/PlanCard';
import { TrialPlanCardContainer } from 'modules/modal/onboarding/choosePlanStep/TrialPlanCardContainer';
import { useBusinessPeriodLimitation } from 'modules/settings/plans/planTabs/useBusinessPeriodLimitation';
import { CategoryPlanType } from 'state/plans/PlansType';
import { PLAN_ID_POWER_ASO, PLAN_ID_ULTIMATE_ASO } from 'modules/modal/onboarding/choosePlanStep/constants';
import { OnboardingStepPropsType, TrialStepNextData } from 'modules/modal/onboarding/choosePlanStep/trialStepTypes';
import { AsoPlanCardContainer } from 'modules/settings/plans/planTabs/asoContent/AsoPlanCardContainer';
import { ASO_DATA_TYPE, REVIEW_ASO_DATA_TYPE } from 'modules/settings/plans/planTabs/asoContent/AsoContentTypes';

type AsoTrialPlanStepType = Pick<OnboardingStepPropsType<TrialStepNextData>, 'onNext'> & {
    plan: CategoryPlanType;
    reviewsAso: CategoryPlanType[];
    onLeaveRequest: (isSendRequest: boolean, planName?: string) => void;
    buttonPortal?: React.RefObject<HTMLDivElement>;
    showASOReview: boolean;
};

const AsoTrialPlanStep: FC<AsoTrialPlanStepType> = ({
    plan,
    onLeaveRequest,
    onNext,
    buttonPortal,
    showASOReview,
    reviewsAso,
}: AsoTrialPlanStepType) => {
    const { needSalesManager } = useBusinessPeriodLimitation({
        planName: plan.name,
        paymentPeriod: PaymentPeriodEnum.MONTH_1,
    });

    return (
        <TrialPlanCardContainer
            key={plan.id}
            planName={plan.name}
            onLeaveRequest={onLeaveRequest}
            onNext={onNext}
            hasLeaveRequest={plan.id === PLAN_ID_POWER_ASO || plan.id === PLAN_ID_ULTIMATE_ASO}
            renderPlanCard={(planActionProps) => (
                <AsoPlanCardContainer
                    categoryPlan={plan}
                    paymentPeriod={PaymentPeriodEnum.MONTH_1}
                    reviewsAso={reviewsAso}
                    key={plan.id}
                    defaultActiveAsoType={showASOReview ? REVIEW_ASO_DATA_TYPE : ASO_DATA_TYPE}
                    action={planActionProps}
                    buttonPortal={buttonPortal}
                    state={PlanCardStateEnum.PRIMARY}
                    needSalesManager={needSalesManager}
                />
            )}
        />
    );
};

export const AsoTrialPlanStepContainer = React.memo(AsoTrialPlanStep);
