import React from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { AsodeskTooltip } from 'components/tooltips/asodeskTooltip/asodeskTooltip';
import { TooltipPropsType } from 'components/tooltips/asodeskTooltip/tooltipTypes';

type PropsType = Partial<TooltipPropsType> & {
    tooltip: React.ReactNode;
};

export const ModalTooltip: React.FC<PropsType> = ({ tooltipClassName, ...tooltipProps }: PropsType) => (
    <AsodeskTooltip
        place="top"
        clickable
        className={classNames(tooltipClassName, 'text-center')}
        key={nanoid()}
        anchor={<i className="fa fa-question-circle text-16 text-gray-200 align-middle" />}
        {...tooltipProps}
    />
);
