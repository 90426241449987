import React, { ReactNode } from 'react';
import { AsodeskTooltip } from 'components/tooltips/asodeskTooltip/asodeskTooltip';
import { useFeaturePermissionsPopup } from 'hooks/state/useFeaturePermissionsPopup';
import { AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import { InfoLink } from 'newComponents/link/infoLink/InfoLink';
import { Icons } from 'types/icon-types';
import { Icon, IconSize, lineHeights } from 'newComponents/icons/asodeskIcons/Icon';
import { TooltipPropsType } from 'components/tooltips/asodeskTooltip/tooltipTypes';

export type AnchorUpgradePlanTooltipType = {
    isDisableFeature: boolean;
    DefaultAnchorElement: React.ReactNode;
};

export type PropsType = Omit<TooltipPropsType, 'tooltip' | 'anchor'> & {
    feature: keyof AvailableFeaturesType;
    iconClassName?: string;
    iconSize?: IconSize;
    iconLineHeight?: typeof lineHeights[number];
    anchor?: ((props: AnchorUpgradePlanTooltipType) => ReactNode) | null;
};

type UpgradePlanTextPropsType = Pick<PropsType, 'feature'> & {
    text?: ReactNode;
    afterText?: ReactNode;
};

export const UpgradePlanText: React.FC<UpgradePlanTextPropsType> = ({
    feature,
    text = 'This is a paid feature. You need to ',
    afterText,
}) => {
    const { showBasePermissionModal } = useFeaturePermissionsPopup(feature);

    return (
        <>
            {text}
            <br />
            <InfoLink
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    showBasePermissionModal();
                }}
            >
                Upgrade plan
            </InfoLink>
            {afterText}
        </>
    );
};

const UpgradePlanTooltipContainer: React.FC<PropsType> = ({
    feature,
    iconClassName,
    iconSize = 20,
    anchor = null,
    iconLineHeight,
    ...tooltipProps
}: PropsType) => {
    const { isDisableFeature, showBasePermissionModal } = useFeaturePermissionsPopup(feature);
    const DefaultAnchorElement = (
        <Icon
            name={Icons.LockSolid}
            data-testid="anchor-tooltip-lock-icon"
            size={iconSize}
            className={iconClassName}
            lineHeight={iconLineHeight}
        />
    );
    const AnchorElement = anchor && anchor({ isDisableFeature, DefaultAnchorElement });

    return isDisableFeature ? (
        <AsodeskTooltip
            delayHide={1000}
            delayShow={200}
            clickable
            tooltipClassName="text-center"
            anchor={
                <span
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        showBasePermissionModal();
                    }}
                >
                    {AnchorElement || DefaultAnchorElement}
                </span>
            }
            tooltip={<UpgradePlanText feature={feature} />}
            {...tooltipProps}
        />
    ) : (
        <>{AnchorElement}</>
    );
};

export const UpgradePlanTooltip = React.memo(UpgradePlanTooltipContainer);
