import { onboardingSteps } from 'constants/onboardingSteps';
import { OnboardingStepDataType, OnboardingStepEnum } from 'state/onboarding/types';
import { useAppSelector } from 'hooks/useAppSystem';

type ResponseType = {
    onboardingCurrentStepId: OnboardingStepEnum;
    currentSegmentStepIndex: number;
    currentSegmentStepNumber: number;
    totalOnboardingStepsCount: number;
    isShowProductTour: boolean;
    isReadyTour?: boolean;
    currentSavedOnboardingData: OnboardingStepDataType;
    isLastStepOnboarding: boolean;
};

export const useOnboarding = (): ResponseType => {
    const onboardingCurrentStepId = useAppSelector((state) => state.onboarding.onboardingCurrentStepId);
    const savedOnboardingSteps = useAppSelector((state) => state.onboarding.onboardingSteps);
    const isReadyTour = useAppSelector((state) => state.onboarding.isReadyTour);
    const isShowTour = useAppSelector((state) => state.onboarding.isShowTour);
    const currentSegmentStepIndex = onboardingSteps.findIndex((step) => step.steps.includes(onboardingCurrentStepId));
    const currentSegmentStepNumber = currentSegmentStepIndex + 1;
    const totalOnboardingStepsCount = onboardingSteps.length;

    const isShowProductTour = !!isShowTour;

    const currentSavedOnboardingData = savedOnboardingSteps[onboardingCurrentStepId];

    const isLastStepOnboarding = onboardingCurrentStepId === OnboardingStepEnum.FINISH_ONBOARDING_STEP;

    return {
        onboardingCurrentStepId,
        currentSegmentStepIndex,
        currentSegmentStepNumber,
        totalOnboardingStepsCount,
        isShowProductTour,
        isReadyTour,
        currentSavedOnboardingData,
        isLastStepOnboarding,
    };
};
