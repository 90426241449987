import { Action } from 'redux';
import { CustomReportChannelType, CustomReportsListType, CustomReportType } from 'state/customReports/CustomReportsListType';
import { EmailReportHistoryType } from 'state/customReports/EmailReportHistoryType';
import { EmailReportHistoryFilterType } from 'state/customReports/EmailReportHistoryFilterType';
import { CustomReportsActionTypeEnum } from './actionsTypes';

interface SetCustomReportsListActionType extends Action {
    type: CustomReportsActionTypeEnum.SET_CUSTOM_REPORT_LIST;
    customReportsList: CustomReportsListType;
}

interface AddCustomReportActionType extends Action {
    type: CustomReportsActionTypeEnum.ADD_CUSTOM_REPORT;
    customReport: CustomReportType;
}

interface UpdateCustomReportActionType extends Action {
    type: CustomReportsActionTypeEnum.UPDATE_CUSTOM_REPORT;
    customReport: CustomReportType;
}

interface SetEditReportActionType extends Action {
    type: CustomReportsActionTypeEnum.SET_EDIT_CUSTOM_REPORT;
    reportId: number;
    channelType: CustomReportChannelType;
}

interface SetReportTypeActionType extends Action {
    type: CustomReportsActionTypeEnum.SET_EMPTY_CUSTOM_REPORT_TYPE;
    channelType: CustomReportChannelType;
}

interface SetEmailReportHistoryActionType extends Action {
    type: CustomReportsActionTypeEnum.SET_EMAIL_REPORT_HISTORY;
    emailReportHistory: EmailReportHistoryType[];
    nextEmailReportHistoryPage: null | string;
}

interface SetEmailReportHistoryFiltersActionType extends Action {
    type: CustomReportsActionTypeEnum.SET_EMAIL_REPORT_HISTORY_FILTERS;
    filters: Partial<EmailReportHistoryFilterType>;
}

interface AddEmailReportHistoryActionType extends Action {
    type: CustomReportsActionTypeEnum.ADD_EMAIL_REPORT_HISTORY;
    emailReportHistory: EmailReportHistoryType[];
    nextEmailReportHistoryPage: null | string;
}

interface SetEmailReportHistoryLoadingActionType extends Action {
    type: CustomReportsActionTypeEnum.SET_EMAIL_REPORT_HISTORY_LOADING;
    isLoading: boolean;
}

interface SetNewReportFlagActionType extends Action {
    type: CustomReportsActionTypeEnum.SET_NEW_REPORT_FLAG;
    isNew: boolean;
}

interface DeleteReportActionType extends Action {
    type: CustomReportsActionTypeEnum.DELETE_CUSTOM_REPORT;
    reportId: number;
    channelType: CustomReportChannelType;
}

interface RemoveUntrackedAppFromReportsActionType extends Action {
    type: CustomReportsActionTypeEnum.REMOVE_UNTRACKED_APP;
    storeId: string;
}

export type CustomReportActionType =
    | SetCustomReportsListActionType
    | SetEmailReportHistoryActionType
    | SetNewReportFlagActionType
    | AddCustomReportActionType
    | SetEditReportActionType
    | UpdateCustomReportActionType
    | DeleteReportActionType
    | SetReportTypeActionType
    | AddEmailReportHistoryActionType
    | SetEmailReportHistoryLoadingActionType
    | SetEmailReportHistoryFiltersActionType
    | RemoveUntrackedAppFromReportsActionType;

export const setCustomReportsListAction = (customReportsList: CustomReportsListType): SetCustomReportsListActionType => {
    return {
        type: CustomReportsActionTypeEnum.SET_CUSTOM_REPORT_LIST,
        customReportsList,
    };
};

export const setEmailReportHistoryAction = ({
    emailReportHistory,
    nextEmailReportHistoryPage,
}: {
    emailReportHistory: EmailReportHistoryType[];
    nextEmailReportHistoryPage: null | string;
}): SetEmailReportHistoryActionType => {
    return {
        type: CustomReportsActionTypeEnum.SET_EMAIL_REPORT_HISTORY,
        emailReportHistory,
        nextEmailReportHistoryPage,
    };
};

export const addEmailReportHistoryAction = ({
    emailReportHistory,
    nextEmailReportHistoryPage,
}: {
    emailReportHistory: EmailReportHistoryType[];
    nextEmailReportHistoryPage: null | string;
}): AddEmailReportHistoryActionType => {
    return {
        type: CustomReportsActionTypeEnum.ADD_EMAIL_REPORT_HISTORY,
        emailReportHistory,
        nextEmailReportHistoryPage,
    };
};

export const setNewReportFlagAction = (isNew: boolean): SetNewReportFlagActionType => {
    return {
        type: CustomReportsActionTypeEnum.SET_NEW_REPORT_FLAG,
        isNew,
    };
};

export const setEmailReportHistoryLoadingAction = (isLoading: boolean): SetEmailReportHistoryLoadingActionType => {
    return {
        type: CustomReportsActionTypeEnum.SET_EMAIL_REPORT_HISTORY_LOADING,
        isLoading,
    };
};

export const addCustomReportAction = (customReport: CustomReportType): AddCustomReportActionType => {
    return {
        type: CustomReportsActionTypeEnum.ADD_CUSTOM_REPORT,
        customReport,
    };
};

export const updateCustomReportAction = (customReport: CustomReportType): UpdateCustomReportActionType => {
    return {
        type: CustomReportsActionTypeEnum.UPDATE_CUSTOM_REPORT,
        customReport,
    };
};

export const setEmptyReportTypeAction = (channelType: CustomReportChannelType): SetReportTypeActionType => {
    return {
        type: CustomReportsActionTypeEnum.SET_EMPTY_CUSTOM_REPORT_TYPE,
        channelType,
    };
};

export const setEditReportAction = (reportId: number, channelType: CustomReportChannelType): SetEditReportActionType => {
    return {
        type: CustomReportsActionTypeEnum.SET_EDIT_CUSTOM_REPORT,
        reportId,
        channelType,
    };
};

export const deleteCustomReportAction = (reportId: number, channelType: CustomReportChannelType): DeleteReportActionType => {
    return {
        type: CustomReportsActionTypeEnum.DELETE_CUSTOM_REPORT,
        reportId,
        channelType,
    };
};

export const setEmailReportHistoryFiltersAction = (
    filters: Partial<EmailReportHistoryFilterType>
): SetEmailReportHistoryFiltersActionType => {
    return {
        type: CustomReportsActionTypeEnum.SET_EMAIL_REPORT_HISTORY_FILTERS,
        filters,
    };
};

export const removeUntrackedAppFromCustomReportsAction = (storeId: string): RemoveUntrackedAppFromReportsActionType => {
    return {
        type: CustomReportsActionTypeEnum.REMOVE_UNTRACKED_APP,
        storeId,
    };
};
