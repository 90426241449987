import { ReplyRuleActionTypeListEnum } from './actionsTypes';
import { ReviewsReplyRuleActionType } from './actions';
import { NormalizeReplyRulesType } from './replyRuleTypes';

const replyRuleInitialState = {
    replyRulesIds: [],
    replyRulesByIds: {},
};

export type ReplyRuleInitialStateType = NormalizeReplyRulesType;

const replyRule = (state: ReplyRuleInitialStateType = replyRuleInitialState, action: ReviewsReplyRuleActionType) => {
    switch (action.type) {
        case ReplyRuleActionTypeListEnum.SET_CREATE_REPLY_RULES: {
            return {
                ...state,
                ...action.normalizeData,
            };
        }
        case ReplyRuleActionTypeListEnum.CREATE_REPLY_RULE: {
            return {
                ...state,
                replyRulesIds: [...state.replyRulesIds, action.data.id],
                replyRulesByIds: {
                    ...state.replyRulesByIds,
                    [action.data.id]: action.data,
                },
            };
        }
        case ReplyRuleActionTypeListEnum.UPDATE_REPLY_RULE: {
            return {
                ...state,
                replyRulesByIds: {
                    ...state.replyRulesByIds,
                    [action.data.id]: action.data,
                },
            };
        }
        case ReplyRuleActionTypeListEnum.REMOVE_REPLY_RULE: {
            return {
                ...state,
                replyRulesIds: state.replyRulesIds.filter((replyRuleId) => replyRuleId !== action.ruleId),
            };
        }
        default: {
            return state;
        }
    }
};

export default replyRule;
