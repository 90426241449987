import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { LabelElementType } from 'components/label/LabelElement';
import { appleSearchAdsUrl, asoAuditUrl, freeVideoCourseUrl, helpCenterUrl, slackGroupLink } from 'utils/Urls';
import { Icons } from 'types/icon-types';
import { useHubspot } from 'hooks/useHubspot';
import { useAppProfileService } from 'hooks/data/useAppProfileService';
import { authRouteLogoutUrl } from 'utils/routeUrls';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { saveOnboardingState } from 'state/onboarding/controller';
import { OnboardingStepEnum } from 'state/onboarding/types';
import { useOnboarding } from 'hooks/useOnboarding';
import { useProductTour } from 'modules/modal/onboarding/useProductTour';
import { useAppType } from 'hooks/useAppType';
import { useSystem } from 'hooks/state/useSystem';
import { useAppSelector } from 'hooks/useAppSystem';
import { isNumber } from 'utils/typeGuard';
import { useNewNavigation } from './useNewNavigation';
import { useAppsLimit } from './useAppsLimit';
import { useRequestInfoService } from './data/useRequestInfoService';
import { useIsNoAppsCheck } from './useIsNoAppsCheck';

export enum SidebarCategoriesEnum {
    MY_APPS = 'my-apps',
    ASO_TOOLS = 'aso-tools',
    REVIEWS = 'reviews',
    STORES_ANALYTICS = 'stores-analytics',
    CONNECTIONS_HUB = 'connections-hub',
    KEYWORD_BOOST = 'keyword-boost',
    APPLE_SEARCH_ADS = 'apple-search-ads',
    ASO_CONSULTING = 'aso-consulting',
    ASO_COURSE = 'aso-course',
    REFERRAL_PROGRAM = 'referral-program',
    COMMUNITY = 'community',
    SCHEDULE_DEMO = 'schedule-demo',
    OLD_NAVIGATION = 'old-navigation',
    INVITE_TEAMMATES = 'invite-teammates',
    HELP = 'help',
    ACCOUNT = 'account',
    SIGN_OUT = 'sign-out',
}

export type SubCategoryType = {
    title: string;
    url?: string;
    href?: string;
    className?: string;
    description?: string;
    iconName: string;
    label?: LabelElementType;
    onClick?: () => void;
    isDisabled?: boolean;
};

export type CategoryType = {
    title: string;
    url?: string;
    href?: string;
    className?: string;
    itemClassName?: string;
    iconParams?: {
        name: Icons;
        className?: string;
    };
    children?: SubCategoryType[];
};

export type CategoriesType = {
    id?: string;
    title?: string;
    url?: string;
    href?: string;
    target?: string;
    iconParams?: {
        name: Icons;
        className: string;
        containerClassName?: string;
    };
    label?: LabelElementType;
    textTooltip?: string;
    onClick?: () => void;
    iconContainerStyle?: () => string;
    linkStyle?: () => string;
    tooltipText?: string;
    children?: CategoryType[];
    className?: string;
    appLimits?: {
        current: number;
        max?: number;
    };
    isActive?: boolean;
};

export type SidebarCategoriesType = {
    [category in SidebarCategoriesEnum]: CategoriesType;
};

export const useSidebarCategories = () => {
    const dispatch = useDispatch();

    const { appId, locale } = useSystem();

    const onboardingData = useAppSelector((state) => state.onboarding);
    const pageCategory = useAppSelector(({ system }) => system.pageCategory);
    const trackedAppsList = useAppSelector(({ trackedApps }) => trackedApps.appList);
    const lastCurrentAppId = useAppSelector(({ userFrontendSettings }) => userFrontendSettings.lastCurrentAppId);

    const { toggleShowingNewDesign, isOldUser } = useNewNavigation();
    const { currentAppsCount, maxAppsCount } = useAppsLimit();
    const { getHubspotCalendarUrl } = useHubspot();
    const { url: hubspotCalendarUrl } = getHubspotCalendarUrl();
    const { isLogged } = useRequestInfoService();
    const { isNoApps } = useIsNoAppsCheck();

    const { isAppStore } = useAppProfileService();

    const appStore = isAppStore();

    const defaultIconStyle = 'flex text-rb-font-primary bg-rb-icon-bg/65 rounded-4';
    const mainIconStyle = 'flex !text-rb-font-primary !font-semibold';

    const defaultAppId = appId || lastCurrentAppId || trackedAppsList[0]?.store_id;

    const isLastAppStoreAppType = lastCurrentAppId && isNumber(+lastCurrentAppId);

    const {
        myAppsUrl,
        asoToolsAppProfileUrl,
        asoToolsComparativeReportUrl,
        asoToolsTrafficSourcesUrl,
        asoToolsKeywordHighlightsUrl,
        asoToolsFavoriteKeywordsUrl,
        asoToolsCategoriesRankingUrl,
        asoToolsOrganicReportUrl,
        asoToolsFindAndTrackUrl,
        asoToolsKeywordChartsUrl,
        asoToolsKeywordCompetitorsUrl,
        asoToolsKeywordPhraseMixerUrl,
        asoToolsKeywordLivePositionsUrl,
        asoToolsKeywordAutoSuggestionsUrl,
        asoToolsDescriptionCreatorUrl,
        asoToolsTextMetadataBuilderUrl,
        asoToolsAppStoreLocalizationsUrl,
        storesAnalyticsSearchExplorerUrl,
        storesAnalyticsTopChartsUrl,
        storesAnalyticsCvrBenchmarkUrl,
        storesAnalyticsTopKeywordsUrl,
        storesAnalyticsAppStoreTrendingSearchesUrl,
        reviewsBoardUrl,
        reviewsFeaturedUrl,
        reviewsAnalysisUrl,
        reviewsTagAnalysisUrl,
        reviewsRatingAnalysisUrl,
        reviewsTemplatesUrl,
        reviewsAutoRepliesUrl,
        reviewsTagsUrl,
        reviewsAutoTagsUrl,
        connectionsHubSettingsUrl,
        connectionsHubTimelineUrl,
        connectionsHubIntegrationsUrl,
        connectionsHubApiUrl,
        keywordBoostUrl,
        referralProgramUrl,
        accountUrl,
        accountTeammatesUrl,
        accountBillingUrl,
        accountPricingUrl,
        accountNotificationsUrl,
        accountLogUrl,
    } = useRoutesUrl({ currentAppId: defaultAppId });

    const checkAppsAmount = (path: string) => {
        return isNoApps && isLogged ? myAppsUrl : path;
    };

    const { isShowProductTour } = useOnboarding();

    const {
        driver,
        isGlobalNavigationStepIndex,
        isLocalNavigationAndToolsStepIndex,
        isPossibilitiesOfAsodeskStepIndex,
        isReviewsRatingStepIndex,
        isStoresAnalyticsStepIndex,
        isConnectionsHubStepIndex,
        isKeywordBoostStepIndex,
    } = useProductTour();

    const { isRustoreAppType, isAppStoreAppType } = useAppType();

    const sidebarCategoriesById: SidebarCategoriesType = useMemo(
        () => ({
            [SidebarCategoriesEnum.MY_APPS]: {
                title: 'My Apps',
                url: myAppsUrl,
                isActive: pageCategory === SidebarCategoriesEnum.MY_APPS,
                iconParams: {
                    name: Icons.MyApps,
                    className: 'flex text-rb-additional-blue-100 bg-rb-additional-blue-100/15 rounded-4',
                },
                appLimits: {
                    current: trackedAppsList.length,
                    max: maxAppsCount,
                },
            },
            [SidebarCategoriesEnum.ASO_TOOLS]: {
                title: 'ASO Tools',
                url: checkAppsAmount(asoToolsAppProfileUrl),
                isActive: pageCategory === SidebarCategoriesEnum.ASO_TOOLS,
                className: 'js-global-navigation-step-tour',
                ...(isShowProductTour
                    ? {
                          onClick: async () => {
                              await dispatch(
                                  saveOnboardingState({
                                      ...onboardingData,
                                      onboardingCurrentStepId: OnboardingStepEnum.LOCAL_NAVIGATION_AND_TOOLS_STEP,
                                  })
                              );

                              window.location.href = checkAppsAmount(asoToolsAppProfileUrl);
                          },
                      }
                    : {}),
                iconParams: {
                    name: Icons.AsoTools,
                    className: 'flex text-rb-additional-lilac-100 bg-rb-additional-lilac-100/15 rounded-4',
                    containerClassName: classNames({
                        'shadow-blue-8 rounded-4': isGlobalNavigationStepIndex || isPossibilitiesOfAsodeskStepIndex,
                    }),
                },
                children: [
                    {
                        title: 'App Profile',
                        url: asoToolsAppProfileUrl,
                    },
                    {
                        title: 'Analytics',
                        children: [
                            {
                                title: 'ASO Comparative Report',
                                url: asoToolsComparativeReportUrl,
                                description: 'All ranked keywords performance overview within period',
                                iconName: Icons.IconAsoComparativeReport,
                            },
                            ...(appStore
                                ? [
                                      {
                                          title: 'Traffic Sources',
                                          url: asoToolsTrafficSourcesUrl,
                                          description: 'Mapping Impressions, Product Page Views and App Units',
                                          iconName: Icons.IconTrafficSources,
                                      },
                                  ]
                                : []),
                            {
                                title: 'Favorite Keywords',
                                url: asoToolsFavoriteKeywordsUrl,
                                description: 'Сross-country analysis of ranking',
                                iconName: Icons.IconFavoriteKeywords,
                            },
                            {
                                title: 'Keyword Highlights',
                                url: asoToolsKeywordHighlightsUrl,
                                description: 'Analysis of ranking',
                                iconName: Icons.IconSelectedKeywords,
                            },
                            {
                                title: 'Categories Ranking',
                                url: asoToolsCategoriesRankingUrl,
                                description: 'App ranking in its category',
                                iconName: Icons.IconCategoriesRanking,
                            },
                            {
                                title: 'Organic Report',
                                url: asoToolsOrganicReportUrl,
                                description: 'All ranked keywords driving installs',
                                iconName: Icons.IconOrganicReport,
                            },
                        ],
                    },
                    {
                        title: 'Keywords',
                        className: 'js-local-navigation-and-tools-step-tour',
                        itemClassName: classNames({ 'shadow-blue-8 rounded-4': isLocalNavigationAndToolsStepIndex }),
                        children: [
                            {
                                title: 'Find & Track',
                                url: asoToolsFindAndTrackUrl,
                                description: 'Manage your keywords',
                                iconName: Icons.IconFindTrack,
                                className: classNames('pointer-events-auto', {
                                    'shadow-blue-8 rounded-4': isLocalNavigationAndToolsStepIndex,
                                }),
                                ...(isShowProductTour
                                    ? {
                                          onClick: async () => {
                                              await dispatch(
                                                  saveOnboardingState({
                                                      ...onboardingData,
                                                      onboardingCurrentStepId: OnboardingStepEnum.POSSIBILITIES_OF_ASODESK_STEP,
                                                  })
                                              );

                                              driver?.moveNext();
                                          },
                                      }
                                    : {}),
                            },
                            ...(!isRustoreAppType
                                ? [
                                      {
                                          title: 'Charts',
                                          url: asoToolsKeywordChartsUrl,
                                          description: 'Visual ranking report',
                                          iconName: Icons.IconCharts,
                                      },
                                  ]
                                : []),
                            {
                                title: 'Competitors Position',
                                url: asoToolsKeywordCompetitorsUrl,
                                description: 'Ranking within competitors',
                                iconName: Icons.IconCompetitors,
                            },
                            {
                                title: 'Phrase Mixer',
                                url: asoToolsKeywordPhraseMixerUrl,
                                description: 'Rearrange 4 words to find the best',
                                iconName: Icons.IconPhraseMixer,
                            },
                            {
                                title: 'Live Positions',
                                url: asoToolsKeywordLivePositionsUrl,
                                description: 'Real-time search results',
                                iconName: Icons.IconLivePositions,
                            },
                            {
                                title: 'Auto-Suggestions',
                                url: asoToolsKeywordAutoSuggestionsUrl,
                                description: 'Best keywords determined by our algorithms',
                                iconName: Icons.IconAutoSuggestions,
                            },
                        ],
                    },
                    {
                        title: 'Page Builder',
                        children: [
                            {
                                title: 'Description Creator',
                                url: asoToolsDescriptionCreatorUrl,
                                description: 'Check word frequency in your text',
                                iconName: Icons.IconDescriptionCreator,
                            },
                            {
                                title: 'Text Metadata Builder',
                                url: asoToolsTextMetadataBuilderUrl,
                                description: 'Build metadata with selected keywords',
                                iconName: Icons.IconTextMetadataBuilder,
                            },
                            ...(isAppStoreAppType || (!appId && isLastAppStoreAppType)
                                ? [
                                      {
                                          title: 'App Store Localizations',
                                          url: asoToolsAppStoreLocalizationsUrl,
                                          description: 'Available localizations in countries',
                                          iconName: Icons.IconAppStoreLocalizations,
                                      },
                                  ]
                                : []),
                        ],
                    },
                ],
            },
            [SidebarCategoriesEnum.REVIEWS]: {
                title: 'Reviews & Ratings',
                url: checkAppsAmount(reviewsBoardUrl),
                isActive: pageCategory === SidebarCategoriesEnum.REVIEWS,
                iconParams: {
                    name: Icons.Reviews_2,
                    className: 'flex text-rb-warning-yellow bg-rb-warning-yellow/17 rounded-4',
                    containerClassName: classNames({
                        'shadow-blue-8 rounded-4': isReviewsRatingStepIndex,
                    }),
                },
                children: [
                    {
                        title: 'Reviews & Replies Board',
                        url: reviewsBoardUrl,
                    },
                    {
                        title: 'Analytics',
                        children: [
                            {
                                title: 'Featured Reviews',
                                url: reviewsFeaturedUrl,
                                iconName: Icons.IconFeaturedReviews,
                            },
                            {
                                title: 'Reviews Analysis',
                                url: reviewsAnalysisUrl,
                                iconName: Icons.IconReviewsAnalysis,
                            },
                            ...(!isRustoreAppType
                                ? [
                                      {
                                          title: 'Rating Analysis',
                                          url: reviewsRatingAnalysisUrl,
                                          iconName: Icons.IconRatingAnalysis,
                                      },
                                  ]
                                : []),
                            {
                                title: 'Tags Analysis',
                                url: reviewsTagAnalysisUrl,
                                iconName: Icons.IconTagsAnalysis,
                            },
                        ],
                    },
                    {
                        title: 'Automation',
                        children: [
                            {
                                title: 'Templates',
                                url: reviewsTemplatesUrl,
                                iconName: Icons.IconTemplates,
                            },
                            {
                                title: 'Auto-Replies',
                                url: reviewsAutoRepliesUrl,
                                iconName: Icons.IconAutoReplies,
                            },
                            {
                                title: 'Tags',
                                url: reviewsTagsUrl,
                                iconName: Icons.IconTags,
                            },
                            {
                                title: 'Auto-Tags',
                                url: reviewsAutoTagsUrl,
                                iconName: Icons.IconAutoTags,
                            },
                        ],
                    },
                ],
            },
            [SidebarCategoriesEnum.STORES_ANALYTICS]: {
                title: 'Stores Analytics',
                url: storesAnalyticsSearchExplorerUrl,
                isActive: pageCategory === SidebarCategoriesEnum.STORES_ANALYTICS,
                iconParams: {
                    name: Icons.StoreAnalytics_2,
                    className: 'flex text-rb-success-green bg-rb-success-green/17 rounded-4',
                    containerClassName: classNames({
                        'shadow-blue-8 rounded-4': isStoresAnalyticsStepIndex,
                    }),
                },
                children: [
                    {
                        title: 'Search Explorer',
                        url: storesAnalyticsSearchExplorerUrl,
                    },
                    {
                        title: 'Top Charts',
                        url: storesAnalyticsTopChartsUrl,
                    },
                    {
                        title: 'CVR Benchmarks',
                        url: storesAnalyticsCvrBenchmarkUrl,
                    },
                    {
                        title: 'Top Keywords',
                        url: storesAnalyticsTopKeywordsUrl,
                    },
                    {
                        title: 'App Store Trending Searches',
                        url: storesAnalyticsAppStoreTrendingSearchesUrl,
                    },
                ],
            },
            [SidebarCategoriesEnum.CONNECTIONS_HUB]: {
                title: 'Connections Hub',
                url: connectionsHubSettingsUrl,
                isActive: pageCategory === SidebarCategoriesEnum.CONNECTIONS_HUB,
                iconParams: {
                    name: Icons.ConnectionsHub,
                    className: 'flex text-rb-additional-purple-100 bg-rb-additional-purple-100/17 rounded-4',
                    containerClassName: classNames({
                        'shadow-blue-8 rounded-4': isConnectionsHubStepIndex,
                    }),
                },
                ...(isRustoreAppType ? { className: 'border-b-1 border-solid border-rb-border-gray pb-14 mb-10' } : {}),
                children: [
                    {
                        title: 'All Reports Settings',
                        url: connectionsHubSettingsUrl,
                    },
                    {
                        title: 'Reports Timeline',
                        url: connectionsHubTimelineUrl,
                    },
                    {
                        title: 'Integrations',
                        url: connectionsHubIntegrationsUrl,
                    },
                    {
                        title: 'API',
                        href: connectionsHubApiUrl,
                    },
                ],
            },
            [SidebarCategoriesEnum.KEYWORD_BOOST]: {
                title: 'Keyword Boost',
                url: checkAppsAmount(keywordBoostUrl),
                isActive: pageCategory === SidebarCategoriesEnum.KEYWORD_BOOST,
                iconParams: {
                    name: Icons.Boost,
                    className: 'flex text-rb-additional-pink-100 bg-rb-additional-pink-100/17 rounded-4',
                    containerClassName: classNames({
                        'shadow-blue-8 rounded-4': isKeywordBoostStepIndex,
                    }),
                },
                className: 'border-b-1 border-solid border-rb-border-gray pb-14 mb-10',
            },
            [SidebarCategoriesEnum.APPLE_SEARCH_ADS]: {
                title: 'Apple Search Ads Campaigns by CPI',
                href: appleSearchAdsUrl,
                target: '_blank',
                iconParams: {
                    name: Icons.AppleSearchAds,
                    className: defaultIconStyle,
                },
            },
            [SidebarCategoriesEnum.ASO_CONSULTING]: {
                title: 'ASO Consulting',
                href: asoAuditUrl,
                target: '_blank',
                iconParams: {
                    name: Icons.Consulting,
                    className: defaultIconStyle,
                },
            },
            [SidebarCategoriesEnum.ASO_COURSE]: {
                title: 'ASO Course',
                href: freeVideoCourseUrl,
                target: '_blank',
                iconParams: {
                    name: Icons.Cource,
                    className: defaultIconStyle,
                },
                className: 'border-b-1 border-solid border-rb-border-gray pb-14 mb-10',
            },
            [SidebarCategoriesEnum.REFERRAL_PROGRAM]: {
                title: 'Refer a Friend',
                url: referralProgramUrl,
                iconParams: {
                    name: Icons.Dollar_2,
                    className: defaultIconStyle,
                },
                isActive: pageCategory === SidebarCategoriesEnum.REFERRAL_PROGRAM,
            },
            [SidebarCategoriesEnum.COMMUNITY]: {
                title: 'Community',
                href: slackGroupLink,
                target: '_blank',
                iconParams: {
                    name: Icons.Community,
                    className: defaultIconStyle,
                },
            },
            [SidebarCategoriesEnum.SCHEDULE_DEMO]: {
                title: 'Schedule a Demo',
                href: hubspotCalendarUrl,
                target: '_blank',
                iconParams: {
                    name: Icons.Calendar,
                    className: defaultIconStyle,
                },
            },
            [SidebarCategoriesEnum.OLD_NAVIGATION]: {
                title: 'Old Navigation',
                href: '#',
                iconParams: {
                    name: Icons.Back,
                    className: defaultIconStyle,
                },
                onClick: toggleShowingNewDesign,
            },
            [SidebarCategoriesEnum.INVITE_TEAMMATES]: {
                tooltipText: 'Invite Teammates',
                url: accountTeammatesUrl,
                iconParams: {
                    name: Icons.Invite,
                    className: `${mainIconStyle} !mr-0`,
                },
            },
            [SidebarCategoriesEnum.HELP]: {
                tooltipText: 'Help Center',
                href: helpCenterUrl,
                target: '_blank',
                iconParams: {
                    name: Icons.Help,
                    className: `${mainIconStyle} !mr-0`,
                },
            },
            [SidebarCategoriesEnum.ACCOUNT]: {
                children: [
                    {
                        title: 'Personal Profile',
                        href: accountUrl,
                        iconParams: {
                            name: Icons.Profile,
                            className: mainIconStyle,
                        },
                    },
                    {
                        title: 'Teammates',
                        url: accountTeammatesUrl,
                        iconParams: {
                            name: Icons.Teammates,
                            className: mainIconStyle,
                        },
                    },
                    {
                        title: 'Billing',
                        url: accountBillingUrl,
                        iconParams: {
                            name: Icons.Billing,
                            className: mainIconStyle,
                        },
                    },
                    {
                        title: 'Pricing',
                        url: accountPricingUrl,
                        iconParams: {
                            name: Icons.Dollar_2,
                            className: mainIconStyle,
                        },
                    },
                    {
                        title: 'Notification Settings',
                        url: accountNotificationsUrl,
                        iconParams: {
                            name: Icons.Notification,
                            className: mainIconStyle,
                        },
                    },
                    {
                        title: 'Activity Log',
                        url: accountLogUrl,
                        iconParams: {
                            name: Icons.ActivityLog,
                            className: mainIconStyle,
                        },
                    },
                ],
            },
            [SidebarCategoriesEnum.SIGN_OUT]: {
                title: 'Sign Out',
                href: authRouteLogoutUrl,
                iconParams: {
                    name: Icons.SignOut,
                    className: mainIconStyle,
                },
            },
        }),
        [
            defaultAppId,
            locale,
            pageCategory,
            hubspotCalendarUrl,
            isLogged,
            currentAppsCount,
            maxAppsCount,
            appStore,
            trackedAppsList,
            isNoApps,
            isShowProductTour,
            driver,
            isGlobalNavigationStepIndex,
            isLocalNavigationAndToolsStepIndex,
            isPossibilitiesOfAsodeskStepIndex,
            isReviewsRatingStepIndex,
            isStoresAnalyticsStepIndex,
            isConnectionsHubStepIndex,
            isKeywordBoostStepIndex,
            isRustoreAppType,
            isAppStoreAppType,
        ]
    );

    const sidebarBodyCategoriesIds = [
        SidebarCategoriesEnum.MY_APPS,
        SidebarCategoriesEnum.ASO_TOOLS,
        SidebarCategoriesEnum.REVIEWS,
        SidebarCategoriesEnum.STORES_ANALYTICS,
        SidebarCategoriesEnum.CONNECTIONS_HUB,
        ...(!isRustoreAppType ? [SidebarCategoriesEnum.KEYWORD_BOOST] : []),
        SidebarCategoriesEnum.APPLE_SEARCH_ADS,
        SidebarCategoriesEnum.ASO_CONSULTING,
        SidebarCategoriesEnum.ASO_COURSE,
        SidebarCategoriesEnum.REFERRAL_PROGRAM,
        SidebarCategoriesEnum.COMMUNITY,
        ...(isLogged ? [SidebarCategoriesEnum.SCHEDULE_DEMO] : []),
        ...(isLogged && isOldUser ? [SidebarCategoriesEnum.OLD_NAVIGATION] : []),
    ];

    return {
        sidebarBodyCategoriesIds,
        sidebarCategoriesById,
    };
};
