import { useAppSelector } from 'hooks/useAppSystem';

export const useIsNoAppsCheck = () => {
    const apps = useAppSelector(({ trackedApps }) => trackedApps.appList);
    const isNoApps = apps.length === 0;

    return {
        isNoApps,
    };
};
