export type KeywordAutoSuggestionsResponseType = {
    data: {
        add_keyword: boolean;
        can_start_campaign: boolean;
        id: number;
        keyword: string;
        popularity: number;
        rank: number;
        number_of_competitors: number;
        traffic_score: number;
        translation: string;
        translation_from: string;
        countryCode: string;
        total_apps?: number;
        ipad_total_apps?: number;
    }[];
    recordsTotal: number;
};

export type KeywordAutoSuggestionsType = {
    keyword: string;
    canStartCampaign: boolean;
    add_keyword: boolean;
    isAddedKeyword: boolean;
    isLocalAddKeyword?: boolean;
    id: number;
    popularity: number;
    users_per_day: number;
    usersPerDay: number;
    countryCode: string;
    translation: string;
    translation_from: string;
    translationFrom: string;
    rankCompetitor: number;
    totalApps?: number;
    ipadTotalApps?: number;
}[];

export enum KeywordAutoSuggestionsTabIdsEnum {
    MOST_POPULAR_KEYWORDS = 'mostPopularKeywords',
    COMPETITORS_BEST_KEYWORDS = 'competitorsBestKeywords',
    LONG_TAIL_KEYWORDS = 'longTailKeywords',
    LEAST_COMPETITIVE_KEYWORDS = 'leastCompetitiveKeywords',
    GOOD_FOR_KEYWORD_BOOSTING = 'goodForKeywordsBoosting',
    EXPECTED_TO_BE_TRENDING = 'expectedToBeTrending',
}
