import { Action } from 'redux';
import { AccountFormErrorType } from 'state/account/AccountFormErrorType';
import { AccountActionEnum } from './actionsTypes';

interface SetAccountFormErrorActionType extends Action {
    type: AccountActionEnum.SET_ACCOUNT_FORM_ERRORS;
    errors: AccountFormErrorType;
}

export type AccountActionType = SetAccountFormErrorActionType;

export const setAccountFormErrorAction = (errors: AccountFormErrorType): SetAccountFormErrorActionType => ({
    type: AccountActionEnum.SET_ACCOUNT_FORM_ERRORS,
    errors,
});
