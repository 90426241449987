import { useMemo } from 'react';
import { AvailableFeaturesKeyEnum, AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import { useAppSelector } from 'hooks/useAppSystem';

export const useRequestInfoService = () => {
    const requestInfo = useAppSelector((state) => state.requestInfo);
    const isLoaded = !!Object.keys(requestInfo.availableFeatures).length;

    const isLogged = useAppSelector((state) => state.user.isLogged);

    return useMemo(() => {
        const isAvailableFeature = (feature: keyof AvailableFeaturesType) => {
            if (!isLogged) return true;

            return feature in requestInfo.availableFeatures || requestInfo.isLoading || !isLoaded;
        };

        return {
            ...requestInfo,
            ...requestInfo.permissions,
            isLoaded,
            isASC: Object.keys(requestInfo.availableFeatures).includes(AvailableFeaturesKeyEnum.APP_STORE_CONNECT),
            isGPC: Object.keys(requestInfo.availableFeatures).includes(AvailableFeaturesKeyEnum.GOOGLE_PLAY_CONSOLE),
            isAvailableFeature,
            isDisabledFeature: (feature: keyof AvailableFeaturesType) => {
                return !isAvailableFeature(feature);
            },
            isLogged,
        };
    }, [requestInfo]);
};
