import { FrontendSettingsResponseType } from 'state/userFrontendSettings/FrontendSettingsResponseType';
import { UserFrontendSettingsInitialStateType } from 'state/userFrontendSettings/UserFrontendSettingsInitialStateType';
import { normalize, schema, denormalize } from 'normalizr';

const appReviewsSchema = [new schema.Entity('appByIds', {}, { idAttribute: 'appId' })];

export const normalizeAppReviewAutoTranslate = (
    denormalizeApps: FrontendSettingsResponseType['appReviewAutoTranslate'] = []
): UserFrontendSettingsInitialStateType['appReviewAutoTranslate'] => {
    const {
        entities: { appByIds },
        result,
    } = normalize(denormalizeApps, appReviewsSchema);

    return {
        appIds: result,
        appByIds: appByIds || {},
    };
};

export const denormalizeAppReviewAutoTranslate = ({
    appIds,
    appByIds,
}: UserFrontendSettingsInitialStateType['appReviewAutoTranslate']): FrontendSettingsResponseType['appReviewAutoTranslate'] =>
    denormalize(appIds, appReviewsSchema, { appByIds });
