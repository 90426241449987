export enum SuggestionsType {
    RELATED = 'related',
    SUGGESTIONS = 'suggestions',
}

export type SuggestionDetailsType = {
    keyword: string;
    inUserKeywords: boolean;
    translation?: string;
    translationFrom?: string;
    keywordId: number;
    trafficScore: number | null | 'Calculating';
    searchAds?: number | 'Calculating' | null;
};

export type SuggestionDetailsResponseType = {
    keyword: string;
    in_userkeywords: boolean;
    translation: string;
    translation_from: string;
    id: number;
    traffic_score: number | null;
};

export type SuggestionsKeywordsType = {
    [keyword: string]: SuggestionDetailsType[];
};

export type SuggestionsKeywordsResponseType = {
    [keyword: string]: SuggestionDetailsResponseType[];
};

export type KeywordSuggestionsType = {
    noData: boolean;
    [SuggestionsType.RELATED]: SuggestionsKeywordsType;
    [SuggestionsType.SUGGESTIONS]: SuggestionsKeywordsType;
};

export type KeywordSuggestionsResponseType = {
    no_data: boolean;
    [SuggestionsType.RELATED]: SuggestionsKeywordsResponseType;
    [SuggestionsType.SUGGESTIONS]: SuggestionsKeywordsResponseType;
};
