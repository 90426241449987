import React from 'react';
import { components, MenuListComponentProps } from 'react-select';

export const MenuListWithHiddenSingleValue = <T,>({ children, ...rest }: MenuListComponentProps<T>) => {
    const isOneValue = rest.options.length === 1;

    if (isOneValue) return null;

    return <components.MenuList {...rest}>{children}</components.MenuList>;
};
