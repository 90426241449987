import React, { FC, Suspense, ReactNode } from 'react';
import { useMainLayout } from 'modules/mainLayout/useMainLayout';
import { AsyncDowngradeRemovedPlanMetaAsidePanelContainer } from 'modules/modal/downgradePlanModal/downgradeRemovedPlanMetaAsidePanel/AsyncDowngradeRemovedPlanMetaAsidePanelContainer';
import { AsyncWelcomeOfferTrialModalContainer } from 'modules/modal/welcomeOfferTrialModal/AsyncWelcomeOfferTrialModalContainer';
import { AsyncPaymentModalContainer } from 'modules/modal/paymentModal/AsyncPaymentModalContainer';
import { AsyncCampaignPaymentModalContainer } from 'modules/modal/campaignPaymentModal/AsyncCampaignPaymentModalContainer';
import { AsyncTrialExpiredModalContainer } from 'modules/modal/trialExpiredModal/AsyncTrialExpiredModalContainer';
import { AsyncDowngradePlanModalModalContainer } from 'modules/modal/downgradePlanModal/AsyncDowngradePlanModalModalContainer';
import { AsyncProductTourModalContainer } from 'modules/modal/productTour/AsyncProductTourModalContainer';
import { AsyncEnterprisePlanModalContainer } from 'modules/settings/plans/planTabs/enterprisePlanCard/enterprisePlanModal/AsyncEnterprisePlanModalContainer';
import { AsyncKeywordsRankingDistributionModalContainer } from 'modules/modal/keywordsRankingDistributionModal/AsyncKeywordsRankingDistributionModalContainer';
import { AsyncFeaturePermissionModalContainer } from 'modules/modal/featurePermissionModal/AsyncFeaturePermissionModalContainer';
import { AsyncScheduleLiveDemoModalContainer } from 'modules/modal/scheduleLiveDemoModal/AsyncScheduleLiveDemoModalContainer';
import { AsyncUpgradeTrialModalContainer } from 'modules/modal/upgradeTrialModal/AsyncUpgradeTrialModalContainer';
import { AsyncBusinessRequestModalContainer } from 'modules/modal/businessRequestModal/AsyncBusinessRequestModalContainer';
import { AsyncLiveSearchAsidePanelContainer } from 'modules/keywordExplorer/liveSearch/AsyncLiveSearchAsidePanelContainer';
import { AsyncMobileCompetitorContextMenuContainer } from 'containers/keywordExplorer/mobile/mobileCompetitorContextMenu/AsyncMobileCompetitorContextMenuContainer';
import { AsyncAddCompetitorAsidePanel } from 'modules/apps/keywordExplorer/addCompetitorAsidePanel/AsyncAddCompetitorAsidePanel';
import { AsyncAddKeywordsAsidePanel } from 'modules/apps/keywordExplorer/addKeywordsAsidePanel/AsyncAddKeywordsAsidePanel';
import { AsyncMobileDateRangePickerModalContainer } from 'components/datePicker/mobileDateRangePickerModal/AsyncMobileDateRangePickerModalContainer';
import { AsyncMobileAppInfoNotifyContainer } from 'containers/appInfoNotify/mobile/notify/AsyncMobileAppInfoNotifyContainer';
import { AsyncMobileFeatureNotifyContainer } from 'modules/componentsWithFeaturePermissionsPopup/featureTooltip/mobile/AsyncMobileFeatureNotifyContainer';
import { AskRemoveAppModalWrapperContainer } from 'modules/dashboard/askRemoveAppModal/AskRemoveAppModalWrapperContainer';
import { AsyncCreateStoreConnectionModalContainer } from 'modules/modal/createStoreConnectionModal/AsyncCreateStoreConnectionModalContainer';
import { AsyncGoogleConsoleSettingsModalContainer } from 'modules/settings/integrations/googleIntegrations/modal/AsyncGoogleConsoleSettingsModalContainer';
import { AsyncYourselfStepModalContainer } from 'modules/modal/onboarding/yourselfStep/AsyncYourselfStepModalContainer';
import { AsyncRustoreSettingsModalContainer } from 'modules/settings/integrations/rustoreIntegrations/modal/AsyncRustoreSettingsModalContainer';

export const withMainLayoutModals = (Component: FC) => ({ children }: { children: ReactNode }) => {
    const {
        isOpenWelcomeOfferTrialPopup,
        isOpenExpiredPopup,
        isOpenDowngradePlanPopup,
        isOpenProductTourPopup,
        isOpenEnterprisePlanModal,
        isOpenPaymentModal,
        isOpenCampaignPaymentModal,
        isOpenKeywordsRankingDistributionModal,
        isOpenFeaturePermissionModal,
        isOpenScheduleDemoModal,
        isOpenUpgradeTrialModal,
        isOpenBusinessRequestModal,
        isOpenLiveSearchAsidePanel,
        isOpenRemovedDowngradePlanMetaAsidePanel,
        isOpenMobileCompetitorContextMenu,
        isOpenAsidePanelAddCompetitor,
        isOpenAsidePanelAddKeywords,
        isOpenMobileDatePicker,
        isOpenMobileNotifyAppInfo,
        isOpenMobileNotifyFeature,
        isOpenAddConnectionModal,
        isOpenGoogleConnectionModal,
        isOpenRemoveAppModal,
        isOpenYourselfStep,
        isOpenRustoreConnectionModal,
    } = useMainLayout();

    return (
        <>
            <Component>{children}</Component>
            {isOpenRemovedDowngradePlanMetaAsidePanel && (
                <Suspense fallback={<div />}>
                    <AsyncDowngradeRemovedPlanMetaAsidePanelContainer />
                </Suspense>
            )}

            {isOpenWelcomeOfferTrialPopup && (
                <Suspense fallback={<div />}>
                    <AsyncWelcomeOfferTrialModalContainer />
                </Suspense>
            )}

            {isOpenPaymentModal && (
                <Suspense fallback={<div />}>
                    <AsyncPaymentModalContainer />
                </Suspense>
            )}

            {isOpenCampaignPaymentModal && (
                <Suspense fallback={<div />}>
                    <AsyncCampaignPaymentModalContainer />
                </Suspense>
            )}

            {isOpenExpiredPopup && (
                <Suspense fallback={<div />}>
                    <AsyncTrialExpiredModalContainer />
                </Suspense>
            )}

            {isOpenDowngradePlanPopup && (
                <Suspense fallback={<div />}>
                    <AsyncDowngradePlanModalModalContainer />
                </Suspense>
            )}

            {isOpenProductTourPopup && (
                <Suspense fallback={<div />}>
                    <AsyncProductTourModalContainer />
                </Suspense>
            )}

            {isOpenEnterprisePlanModal && (
                <Suspense fallback={<div />}>
                    <AsyncEnterprisePlanModalContainer />
                </Suspense>
            )}

            {isOpenKeywordsRankingDistributionModal && (
                <Suspense fallback={<div />}>
                    <AsyncKeywordsRankingDistributionModalContainer />
                </Suspense>
            )}

            {isOpenFeaturePermissionModal && (
                <Suspense fallback={<div />}>
                    <AsyncFeaturePermissionModalContainer />
                </Suspense>
            )}

            {isOpenScheduleDemoModal && (
                <Suspense fallback={<div />}>
                    <AsyncScheduleLiveDemoModalContainer />
                </Suspense>
            )}

            {isOpenUpgradeTrialModal && (
                <Suspense fallback={<div />}>
                    <AsyncUpgradeTrialModalContainer />
                </Suspense>
            )}

            {isOpenBusinessRequestModal && (
                <Suspense fallback={<div />}>
                    <AsyncBusinessRequestModalContainer />
                </Suspense>
            )}

            {isOpenLiveSearchAsidePanel && (
                <Suspense fallback={<div />}>
                    <AsyncLiveSearchAsidePanelContainer />
                </Suspense>
            )}

            {isOpenMobileCompetitorContextMenu && (
                <Suspense fallback="">
                    <AsyncMobileCompetitorContextMenuContainer />
                </Suspense>
            )}

            {isOpenAsidePanelAddCompetitor && (
                <Suspense fallback="">
                    <AsyncAddCompetitorAsidePanel />
                </Suspense>
            )}

            {isOpenAsidePanelAddKeywords && (
                <Suspense fallback="">
                    <AsyncAddKeywordsAsidePanel />
                </Suspense>
            )}

            {isOpenMobileDatePicker && (
                <Suspense fallback="">
                    <AsyncMobileDateRangePickerModalContainer />
                </Suspense>
            )}

            {isOpenMobileNotifyAppInfo && (
                <Suspense fallback="">
                    <AsyncMobileAppInfoNotifyContainer />
                </Suspense>
            )}

            {isOpenMobileNotifyFeature && (
                <Suspense fallback="">
                    <AsyncMobileFeatureNotifyContainer />
                </Suspense>
            )}

            {isOpenRemoveAppModal && <AskRemoveAppModalWrapperContainer />}
            {isOpenAddConnectionModal && <AsyncCreateStoreConnectionModalContainer />}
            {isOpenGoogleConnectionModal && <AsyncGoogleConsoleSettingsModalContainer />}
            {isOpenRustoreConnectionModal && <AsyncRustoreSettingsModalContainer />}
            {isOpenYourselfStep && <AsyncYourselfStepModalContainer />}
        </>
    );
};
