import React, { FC, ReactNode, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';

export type PropsType = {
    children: ReactNode;
    className?: string;
    columnCount?: 3 | 4 | 5;
};

const getMinHeight = (nodeList: NodeListOf<Element>) => {
    let heightNodes: number[] = [];

    nodeList.forEach((node) => {
        const { height } = node.getBoundingClientRect();

        heightNodes = [...heightNodes, height];
    });

    return Math.max(...heightNodes);
};

const setMinHeight = (nodeList: NodeListOf<Element>) => {
    const maxNodeHeading = getMinHeight(nodeList);

    nodeList.forEach((node) => {
        node.setAttribute('style', `min-height: ${maxNodeHeading}px;`);
    });
};

const setDefaultHeight = (nodeList: NodeListOf<Element>) => {
    nodeList.forEach((node) => {
        const { height } = node.getBoundingClientRect();

        node.setAttribute('style', `min-height: ${height}px;`);
    });
};

export const PlanCardsList: FC<PropsType> = ({ children, className, columnCount = 4 }: PropsType) => {
    const container = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (container && container.current) {
            const planCards = container.current.querySelectorAll('[data-plan-card]');
            const planHeadings = container.current.querySelectorAll('[data-plan-heading]');
            const planContents = container.current.querySelectorAll('[data-plan-content]');
            const settingHeadings = container.current.querySelectorAll('[data-settings-heading]');

            setMinHeight(planHeadings);
            setMinHeight(settingHeadings);
            setMinHeight(planCards);

            setDefaultHeight(planContents);
        }
    }, [container, children]);

    const gridStylesMap = {
        3: 'grid-cols-3',
        4: 'grid-cols-4',
        5: 'grid-cols-5',
    };

    return (
        <div ref={container} className={classNames('grid gap-16', gridStylesMap[columnCount], className)}>
            {children}
        </div>
    );
};
