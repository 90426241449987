import React, { FC } from 'react';
import { ReviewsPlanCardContainer } from 'modules/settings/plans/planTabs/reviewsContent/ReviewsPlanCardContainer';
import { PaymentPeriodEnum } from 'state/payment/paymentTypes';
import { PlanCardStateEnum } from 'newComponents/plans/planCard/PlanCard';
import { TrialPlanCardContainer } from 'modules/modal/onboarding/choosePlanStep/TrialPlanCardContainer';
import { useBusinessPeriodLimitation } from 'modules/settings/plans/planTabs/useBusinessPeriodLimitation';
import { CategoryPlanType } from 'state/plans/PlansType';
import { PLAN_ID_POWER_REVIEWS, PLAN_ID_ULTIMATE_REVIEWS } from 'modules/modal/onboarding/choosePlanStep/constants';
import { OnboardingStepPropsType, TrialStepNextData } from 'modules/modal/onboarding/choosePlanStep/trialStepTypes';

type ReviewsTrialPlanStepType = Pick<OnboardingStepPropsType<TrialStepNextData>, 'onNext'> & {
    plan: CategoryPlanType;
    onLeaveRequest: (isSendRequest: boolean, planName?: string) => void;
    buttonPortal?: React.RefObject<HTMLDivElement>;
};

const ReviewsTrialPlanStep: FC<ReviewsTrialPlanStepType> = ({
    plan,
    onLeaveRequest,
    onNext,
    buttonPortal,
}: ReviewsTrialPlanStepType) => {
    const { needSalesManager } = useBusinessPeriodLimitation({
        planName: plan.name,
        paymentPeriod: PaymentPeriodEnum.MONTH_1,
    });

    return (
        <TrialPlanCardContainer
            planName={plan.name}
            onLeaveRequest={onLeaveRequest}
            onNext={onNext}
            hasLeaveRequest={plan.id === PLAN_ID_POWER_REVIEWS || plan.id === PLAN_ID_ULTIMATE_REVIEWS}
            renderPlanCard={(planActionProps) => (
                <ReviewsPlanCardContainer
                    {...plan}
                    paymentPeriod={PaymentPeriodEnum.MONTH_1}
                    action={planActionProps}
                    buttonPortal={buttonPortal}
                    state={PlanCardStateEnum.PRIMARY}
                    needSalesManager={needSalesManager}
                />
            )}
        />
    );
};

export const ReviewsTrialPlanStepContainer = React.memo(ReviewsTrialPlanStep);
