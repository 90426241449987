import React, { FC } from 'react';
import { Route, Navigate } from 'react-router-dom';
import {
    getPartialAsoToolsRouteAppProfileUrl,
    partialAsoToolsRouteAppStoreLocalizationsUrl,
    getPartialAsoToolsRouteCategoriesRankingUrl,
    getPartialAsoToolsRouteComparativeReportUrl,
    getPartialAsoToolsRouteDescriptionCreatorUrl,
    getPartialAsoToolsRouteFavoriteKeywordsUrl,
    getPartialAsoToolsRouteFindAndTrackUrl,
    getPartialAsoToolsRouteKeywordAutoSuggestionsUrl,
    getPartialAsoToolsRouteKeywordChartsUrl,
    getPartialAsoToolsRouteKeywordCompetitorsUrl,
    getPartialAsoToolsRouteKeywordHighlightsUrl,
    getPartialAsoToolsRouteKeywordLivePositionsUrl,
    getPartialAsoToolsRouteKeywordPhraseMixerUrl,
    getPartialAsoToolsRouteNoLocaleUrl,
    getPartialAsoToolsRouteOrganicReportUrl,
    getPartialAsoToolsRouteTextMetadataBuilderUrl,
    getPartialAsoToolsRouteTrafficSourcesUrl,
} from 'utils/routeUrls';
import { CheckPrivateRoute } from 'modules/routers/new/CheckPrivateRoute';
import { AsyncProfilePage } from 'pages/apps/profile/AsyncProfilePage';
import { AsyncComparativeReportPageContainer } from 'pages/apps/comparativeReport/AsyncComparativeReportPageContainer';
import { AsyncAsoDashboardPageContainer } from 'pages/apps/asoDashboard/AsyncAsoDashboardPageContainer';
import { AsyncHighlightsKeywordsPageContainer } from 'pages/apps/analyze/highlightsKeywords/AsyncHighlightsKeywordsPageContainer';
import { AsyncFavoriteKeywordsPageContainer } from 'pages/apps/analyze/favoriteKeywords/AsyncFavoriteKeywordsPageContainer';
import { AsyncCategoryRankingPageContainer } from 'pages/categoryRanking/AsyncCategoryRankingPageContainer';
import { AsyncOrganicReportPageContainer } from 'pages/apps/organicReport/AsyncOrganicReportPageContainer';
import { AsyncKeywordAnalyticsPageContainer } from 'pages/apps/keywordAnalytics/AsyncKeywordAnalyticsPageContainer';
import { AsyncMonitoringPageContainer } from 'pages/apps/monitoring/AsyncMonitoringPageContainer';
import { AsyncCompetitorsPageContainer } from 'pages/apps/competitors/AsyncCompetitorsPageContainer';
import { AsyncKeywordShufflerPageContainer } from 'pages/keywordShuffler/AsyncKeywordShufflerPageContainer';
import { AsyncKeywordExplorerInAppPageContainer } from 'pages/keywordExplorer/keywordExplorerInApp/AsyncKeywordExplorerInAppPageContainer';
import { AsyncKeywordAutoSuggestionsPageContainer } from 'pages/apps/keywordAutoSuggestions/AsyncKeywordAutoSuggestionsPageContainer';
import { AsyncKeywordDensityCounterPageContainer } from 'pages/keywordDensityCounter/AsyncKeywordDensityCounterPageContainer';
import { AsyncOptimizerPageContainer } from 'pages/apps/optimizer/AsyncOptimizerPageContainer';
import { AsyncAppStoreLocalizationsPageContainer } from 'pages/freeTools/appStoreLocalizations/AsyncAppStoreLocalizationsPageContainer';
import { AsyncNoLocalePageContainer } from 'pages/apps/noLocale/AsyncNoLocalePageContainer';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { FaroRoutes } from '@grafana/faro-react';

export const AsoToolsAppProfileRedirect: FC = () => {
    const { asoToolsAppProfileUrl } = useRoutesUrl();

    return <Navigate to={asoToolsAppProfileUrl} replace />;
};

export const AsoToolsRoutes: FC = () => (
    <FaroRoutes>
        <Route path="*" element={<AsoToolsAppProfileRedirect />} />
        <Route path={getPartialAsoToolsRouteAppProfileUrl(':appId')} element={<AsyncProfilePage />} />
        <Route path={getPartialAsoToolsRouteComparativeReportUrl(':appId')} element={<AsyncComparativeReportPageContainer />} />
        <Route path={getPartialAsoToolsRouteTrafficSourcesUrl(':appId')} element={<AsyncAsoDashboardPageContainer />} />
        <Route path={getPartialAsoToolsRouteKeywordHighlightsUrl(':appId')} element={<AsyncHighlightsKeywordsPageContainer />} />
        <Route path={getPartialAsoToolsRouteFavoriteKeywordsUrl(':appId')} element={<AsyncFavoriteKeywordsPageContainer />} />
        <Route
            path={getPartialAsoToolsRouteCategoriesRankingUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncCategoryRankingPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route path={getPartialAsoToolsRouteOrganicReportUrl(':appId')} element={<AsyncOrganicReportPageContainer />} />
        <Route path={getPartialAsoToolsRouteFindAndTrackUrl(':appId')} element={<AsyncKeywordAnalyticsPageContainer />} />
        <Route
            path={getPartialAsoToolsRouteKeywordChartsUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncMonitoringPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={getPartialAsoToolsRouteKeywordCompetitorsUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncCompetitorsPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route path={getPartialAsoToolsRouteKeywordPhraseMixerUrl(':appId')} element={<AsyncKeywordShufflerPageContainer />} />
        <Route
            path={getPartialAsoToolsRouteKeywordLivePositionsUrl(':appId')}
            element={<AsyncKeywordExplorerInAppPageContainer />}
        />
        <Route
            path={getPartialAsoToolsRouteKeywordAutoSuggestionsUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncKeywordAutoSuggestionsPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={getPartialAsoToolsRouteDescriptionCreatorUrl(':appId')}
            element={<AsyncKeywordDensityCounterPageContainer />}
        />
        <Route
            path={getPartialAsoToolsRouteTextMetadataBuilderUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncOptimizerPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route path={partialAsoToolsRouteAppStoreLocalizationsUrl} element={<AsyncAppStoreLocalizationsPageContainer />} />
        <Route path={getPartialAsoToolsRouteNoLocaleUrl(':appId')} element={<AsyncNoLocalePageContainer />} />
    </FaroRoutes>
);
