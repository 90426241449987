import React from 'react';
import classNames from 'classnames';
import { Icons } from 'types/icon-types';

export const lineHeights = [12, 14, 16, 20, 24, 26, 28, 30, 32, 35, 36, 38, 40, 48, 56, 75, 88, 96, 128, 'initial'];

export type IconSize = 12 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32 | 38 | 40 | 48 | 56 | 72 | 75 | 88 | 96 | 128;

export enum IconStateEnum {
    PRIMARY = 'text-primary',
    DANGER = 'text-danger',
    SECONDARY = 'text-rb-font-secondary',
}

export type IconPropsType = React.HTMLAttributes<HTMLSpanElement> & {
    name: string | Icons;
    size?: IconSize;
    className?: string;
    lineHeight?: typeof lineHeights[number];
    isLoading?: boolean;
    state?: IconStateEnum;
};

export const Icon: React.FC<IconPropsType> = ({
    size,
    name,
    className,
    lineHeight = 'initial',
    isLoading,
    state,
    ...spanProps
}: IconPropsType) => (
    <span
        data-testid={`icon-${name}`}
        className={classNames(`icon icon-${name} inline-block align-bottom`, className, {
            'text-12 w-12 h-12': size === 12,
            'text-14 w-14 h-14': size === 14,
            'text-16 w-16 h-16': size === 16,
            'text-18 w-18 h-18': size === 18,
            'text-20 w-20 h-20': size === 20,
            'text-22 w-22 h-22': size === 22,
            'text-24 w-24 h-24': size === 24,
            'text-26 w-26 h-26': size === 26,
            'text-28 w-28 h-28': size === 28,
            'text-30 w-30 h-30': size === 30,
            'text-32 w-32 h-32': size === 32,
            'text-38 w-38 h-38': size === 38,
            'text-40 w-40 h-40': size === 40,
            'text-48 w-48 h-48': size === 48,
            'text-56 w-56 h-56': size === 56,
            'text-72 w-72 h-72': size === 72,
            'text-75 w-75 h-75': size === 75,
            'text-88 w-88 h-88': size === 88,
            'text-96 w-96 h-96': size === 96,
            'text-128 w-128 h-128': size === 128,
            'leading-initial': lineHeight === 'initial' && size !== 24,
            '!leading-30': (!lineHeights.includes(lineHeight) && size === 24) || lineHeight === 30,
            '!leading-12': lineHeight === 12,
            '!leading-14': lineHeight === 14,
            '!leading-16': lineHeight === 16,
            '!leading-20': lineHeight === 20,
            '!leading-24': lineHeight === 24,
            '!leading-26': lineHeight === 26,
            '!leading-28': lineHeight === 28,
            '!leading-32': lineHeight === 32,
            '!leading-35': lineHeight === 35,
            '!leading-36': lineHeight === 36,
            '!leading-38': lineHeight === 38,
            '!leading-40': lineHeight === 40,
            '!leading-48': lineHeight === 48,
            '!leading-56': lineHeight === 56,
            '!leading-72': lineHeight === 72,
            '!leading-75': lineHeight === 75,
            '!leading-88': lineHeight === 88,
            '!leading-96': lineHeight === 96,
            '!leading-128': lineHeight === 128,
            'animate-spin': isLoading,
            [IconStateEnum.PRIMARY]: state && state === IconStateEnum.PRIMARY,
            [IconStateEnum.DANGER]: state && state === IconStateEnum.DANGER,
            [IconStateEnum.SECONDARY]: state && state === IconStateEnum.SECONDARY,
        })}
        {...spanProps}
    />
);
