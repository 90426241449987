import React from 'react';

import { CurrencyEnum, PaymentModalStepEnum } from 'state/payment/paymentTypes';

export const paymentMeta = {
    [CurrencyEnum.RUB]: {
        currencySymbol: <span className="font-ruble">₽</span>,
    },
    [CurrencyEnum.USD]: {
        currencySymbol: '$',
    },
};

export const getCurrencySymbol = (assignedCurrency?: CurrencyEnum) =>
    paymentMeta[assignedCurrency ?? CurrencyEnum.USD].currencySymbol;

export const stepModalTitle = {
    [PaymentModalStepEnum.CONFIGURE_PAYMENT]: 'Configure plan',
    [PaymentModalStepEnum.CUSTOMER_SUPPORT_TOOLS]: "Didn't forget anything?",
    [PaymentModalStepEnum.BILLING_INFORMATION]: 'Billing information',
    [PaymentModalStepEnum.PAYMENT_DETAILS]: 'Payment details',
    [PaymentModalStepEnum.CONGRATULATIONS]: 'Congratulations',
};
