import { useEffect } from 'react';
import { useTogglePopup } from 'hooks/state/usePopup';
import { MODAL_NAME_AUTO_SCHEDULE_DEMO } from 'constants/modalNameConstants';
import { ScheduleLiveDemoModalDataType } from 'modules/modal/scheduleLiveDemoModal/ScheduleLiveDemoModalDataType';
import { useAuth } from 'hooks/useAuth';

export const useScheduleLiveDemoModalListener = () => {
    const { togglePopupWithData } = useTogglePopup<ScheduleLiveDemoModalDataType>(MODAL_NAME_AUTO_SCHEDULE_DEMO);

    const { onCheckClickAuth } = useAuth();

    const togglePopup = (event: CustomEvent) => {
        const feature = event?.detail;

        onCheckClickAuth(() => {
            if (feature) togglePopupWithData({ data: { feature } });
        });
    };

    useEffect(() => {
        window.addEventListener('openScheduleLiveDemoModal', togglePopup as EventListener);

        return () => {
            window.removeEventListener('openScheduleLiveDemoModal', togglePopup as EventListener);
        };
    }, []);
};
