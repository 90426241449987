// My apps routes
export const getOldMyAppsRouteUrl = (country: string) => `/${country}/`;

// Aso tools routes
export const getOldAsoToolsRouteBaseAppProfileUrl = (country: string, appId: string) => `/${country}/app/${appId}/`;
export const getOldAsoToolsRouteAppProfileUrl = (country: string, appId: string) => `/${country}/app/${appId}/profile`;
export const getOldAsoToolsRouteComparativeReportUrl = (country: string, appId: string) =>
    `/${country}/app/${appId}/comparative_report`;
export const getOldAsoToolsRouteTrafficSourcesUrl = (country: string, appId: string) => `/${country}/app/${appId}/aso-dashboard`;
export const getOldAsoToolsRouteKeywordHighlightsUrl = (country: string, appId: string) =>
    `/${country}/app/${appId}/keyword-highlights`;
export const getOldAsoToolsRouteFavoriteKeywordsUrl = (country: string, appId: string) =>
    `/${country}/app/${appId}/favorite-keywords`;
export const getOldAsoToolsRouteCategoriesRankingUrl = () => '/category-ranking';
export const getOldAsoToolsRouteOrganicReportUrl = (country: string, appId: string) => `/${country}/app/${appId}/organic_report`;
export const getOldAsoToolsRouteFindAndTrackUrl = (country: string, appId: string) => `/${country}/app/${appId}/statistics`;
export const getOldAsoToolsRouteKeywordChartsUrl = (country: string, appId: string) => `/${country}/app/${appId}/monitoring`;
export const getOldAsoToolsRouteKeywordCompetitorsUrl = (country: string, appId: string) =>
    `/${country}/app/${appId}/competitors`;
export const getOldAsoToolsRouteKeywordPhraseMixerUrl = () => '/keyword-shuffler';
export const getOldAsoToolsRouteKeywordLivePositionsUrl = (country: string, appId: string) =>
    `/${country}/app/${appId}/keyword_explorer`;
export const getOldAsoToolsRouteKeywordAutoSuggestionsUrl = (country: string, appId: string) =>
    `/${country}/app/${appId}/suggestions`;
export const getOldAsoToolsRouteDescriptionCreatorUrl = () => `/keyword-density-counter`;
export const getOldAsoToolsRouteTextMetadataBuilderUrl = (country: string, appId: string) => `/${country}/app/${appId}/optimizer`;
export const getOldAsoToolsRouteAppStoreLocalizationsUrl = () => '/countries-localizations';
export const getOldAsoToolsRouteNoLocaleUrl = (country: string, appId: string) => `/${country}/app/${appId}/no_locale`;

// Stores analytics routes
export const getOldStoresAnalyticsRouteSearchExplorerUrl = () => '/keyword-explorer';
export const getOldStoresAnalyticsRouteTopChartsUrl = (country: string) => `/${country}/top-charts/`;
export const getOldStoresAnalyticsRouteCvrBenchmarkUrl = () => '/cvr_benchmark';
export const getOldStoresAnalyticsRouteTopKeywordsUrl = (country: string) => `/${country}/top-keywords`;
export const getOldStoresAnalyticsRouteAppStoreTrendingSearchesUrl = (country: string) => `/${country}/trending-searches`;

// Reviews routes
export const getOldReviewsRouteBoardUrl = (country: string, appId: string) => `/${country}/app/${appId}/reply-board`;
export const getOldReviewsRouteFeaturedUrl = (country: string, appId: string) => `/${country}/app/${appId}/featured-reviews`;
export const getOldReviewsRouteAnalysisUrl = (country: string, appId: string) => `/${country}/app/${appId}/reviews-analysis`;
export const getOldReviewsRouteTagAnalysisUrl = (country: string, appId: string) => `/${country}/app/${appId}/tag-analysis`;
export const getOldReviewsRouteRatingAnalysisUrl = (country: string, appId: string) => `/${country}/app/${appId}/rating-analysis`;
export const getOldReviewsRouteTemplatesUrl = (country: string, appId: string) => `/${country}/app/${appId}/reply-templates`;
export const getOldReviewsRouteAutoRepliesUrl = (country: string, appId: string) =>
    `/${country}/app/${appId}/review-auto-replies`;
export const getOldReviewsRouteTagsUrl = (country: string, appId: string) => `/${country}/app/${appId}/review-tags`;
export const getOldReviewsRouteAutoTagsUrl = (country: string, appId: string) => `/${country}/app/${appId}/review-auto-tags`;

// Connections hub routes
export const getOldConnectionsHubRouteIntegrationsUrl = () => '/settings/integrations';
export const getOldConnectionsHubRouteAllReportsSettingsUrl = () => '/reports-alerts';
export const getOldConnectionsHubRouteApiUrl = () => '/settings/api-pricing';

// Keyword boost routes
export const getKeywordBoostRouteUrl = (country: string, appId: string) => `/${country}/app/${appId}/campaigns`;
export const getKeywordBoostRouteByCampaignIdUrl = (country: string, appId: string, campaignId: string) =>
    `/${country}/app/${appId}/campaign/${campaignId}`;

// Referral program routes
export const getOldReferralProgramRouteUrl = () => '/settings/referral-program';
