export enum UserFrontendSettingsActionTypeEnum {
    TOGGLE_COLLAPSED_SIDEBAR_SECTION = 'TOGGLE_COLLAPSED_SIDEBAR_SECTION',
    UPDATE_USER_FRONTEND_SETTINGS = 'UPDATE_USER_FRONTEND_SETTINGS',
    SET_STATS_USER_LIMIT_DATA = 'SET_STATS_USER_LIMIT_DATA',
    TOGGLE_OPENED_DESKTOP_SIDEBAR = 'TOGGLE_OPENED_DESKTOP_SIDEBAR',
    TOGGLE_OPENED_MOBILE_SIDEBAR = 'TOGGLE_OPENED_MOBILE_SIDEBAR',
    CLOSE_MOBILE_SIDEBAR = 'CLOSE_MOBILE_SIDEBAR',
    TOGGLE_FAVORITE_TOOL = 'TOGGLE_FAVORITE_TOOL',
    CHANGE_FAVORITE_LINK_POSITION = 'CHANGE_FAVORITE_LINK_POSITION',
    SET_REPLY_BOARD_RANDOM_TEMPLATE_FLAG = 'SET_REPLY_BOARD_RANDOM_TEMPLATE_FLAG',
    SET_REPLY_BOARD_AUTO_NEXT_REVIEW_FLAG = 'SET_REPLY_BOARD_AUTO_NEXT_REVIEW_FLAG',
    SET_KEYWORD_COUNTRY_FLAG_SHOW = 'SET_KEYWORD_COUNTRY_FLAG_SHOW',
    CHANGE_KEYWORD_ANALYTICS_TABLE_COLUMN_ORDER = 'CHANGE_KEYWORD_ANALYTICS_TABLE_COLUMN_ORDER',
    ADD_KEYWORD_ANALYTICS_TABLE_COLUMN_INVISIBILITY = 'ADD_KEYWORD_ANALYTICS_TABLE_COLUMN_INVISIBILITY',
    REMOVE_KEYWORD_ANALYTICS_TABLE_COLUMN_INVISIBILITY = 'REMOVE_KEYWORD_ANALYTICS_TABLE_COLUMN_INVISIBILITY',
    ADD_APP_REVIEW_AUTO_TRANSLATE = 'ADD_APP_REVIEW_AUTO_TRANSLATE',
    REMOVE_APP_REVIEW_AUTO_TRANSLATE = 'REMOVE_APP_REVIEW_AUTO_TRANSLATE',
    HIDE_APP_REVIEW_AUTO_TRANSLATE_INFO = 'HIDE_APP_REVIEW_AUTO_TRANSLATE_INFO',
    CHANGE_SHOW_TRIAL_BY_TYPE = 'CHANGE_SHOW_TRIAL_BY_TYPE',
    HIDE_WO_MODAL = 'HIDE_WO_MODAL',
    ADD_USER_LIMITS_AMOUNT = 'ADD_USER_LIMITS_AMOUNT',
    CHANGE_KEYWORD_EXPLORER_WIDGET_ORDER = 'CHANGE_KEYWORD_EXPLORER_WIDGET_ORDER',
    SET_TAGS_CATEGORY_SORTING = 'SET_TAGS_CATEGORY_SORTING',
    SET_GLOBAL_APP_LOADER_SHOW = 'SET_GLOBAL_APP_LOADER_SHOW',
    TOGGLE_SHOWING_NEW_NAVIGATION = 'TOGGLE_SHOWING_NEW_NAVIGATION',
    SET_LAST_CURRENT_APP_ID = 'SET_LAST_CURRENT_APP_ID',
    SET_LAST_CURRENT_LOCALE = 'SET_LAST_CURRENT_LOCALE',
}
