import {
    KeywordSuggestionsType,
    SuggestionDetailsType,
    SuggestionsType,
} from 'state/keywordSuggestions/KeywordSuggestionsResponseType';
import {
    ADD_KEYWORD_FROM_SUGGESTION,
    REMOVE_KEYWORD_FROM_SUGGESTION,
    SET_KEYWORD_SUGGESTION,
} from 'state/keywordSuggestions/actionTypes';

export type KeywordSuggestionsActionType = {
    type: string;
    data: KeywordSuggestionsType;
    keywordId: number;
    suggestionKeywordType: SuggestionsType;
    suggestionKeyword: SuggestionDetailsType;
    suggestionsByKeyword: string;
};

type KeywordSuggestionsStateType = {
    [keywordId: number]: KeywordSuggestionsType;
};

const keywordSuggestions = (state: KeywordSuggestionsStateType = {}, action: KeywordSuggestionsActionType) => {
    switch (action.type) {
        case SET_KEYWORD_SUGGESTION: {
            return {
                ...state,
                ...{
                    [action.keywordId]: action.data,
                },
            };
        }
        case ADD_KEYWORD_FROM_SUGGESTION: {
            const { suggestionsByKeyword, keywordId, suggestionKeywordType, suggestionKeyword } = action;

            return {
                ...state,
                [keywordId]: {
                    ...state[keywordId],
                    [suggestionKeywordType]: {
                        ...state[keywordId][suggestionKeywordType],
                        [suggestionsByKeyword]: state[keywordId][suggestionKeywordType][suggestionsByKeyword].map(
                            (suggestionKeywordData) => {
                                return suggestionKeywordData.keyword === suggestionKeyword.keyword
                                    ? { ...suggestionKeywordData, inUserKeywords: true }
                                    : suggestionKeywordData;
                            }
                        ),
                    },
                },
            };
        }
        case REMOVE_KEYWORD_FROM_SUGGESTION: {
            const { suggestionsByKeyword, keywordId, suggestionKeywordType, suggestionKeyword } = action;

            return {
                ...state,
                [keywordId]: {
                    ...state[keywordId],
                    [suggestionKeywordType]: {
                        ...state[keywordId][suggestionKeywordType],
                        [suggestionsByKeyword]: state[keywordId][suggestionKeywordType][suggestionsByKeyword].map(
                            (suggestionKeywordData) => {
                                return suggestionKeywordData.keyword === suggestionKeyword.keyword
                                    ? { ...suggestionKeywordData, inUserKeywords: false }
                                    : suggestionKeywordData;
                            }
                        ),
                    },
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordSuggestions;
