import {
    KeywordSuggestionsType,
    SuggestionDetailsType,
    SuggestionsType,
} from 'state/keywordSuggestions/KeywordSuggestionsResponseType';
import {
    ADD_KEYWORD_FROM_SUGGESTION,
    REMOVE_KEYWORD_FROM_SUGGESTION,
    SET_KEYWORD_SUGGESTION,
} from 'state/keywordSuggestions/actionTypes';

export function setKeywordSuggestions(keywordId: number, data: KeywordSuggestionsType) {
    return {
        type: SET_KEYWORD_SUGGESTION,
        data,
        keywordId,
    };
}

export function addKeywordSuggestions(
    keywordId: number,
    suggestionKeywordType: SuggestionsType,
    suggestionKeyword: SuggestionDetailsType,
    suggestionsByKeyword: string
) {
    return {
        type: ADD_KEYWORD_FROM_SUGGESTION,
        keywordId,
        suggestionKeywordType,
        suggestionKeyword,
        suggestionsByKeyword,
    };
}

export function removeKeywordSuggestions(
    keywordId: number,
    suggestionKeywordType: SuggestionsType,
    suggestionKeyword: SuggestionDetailsType,
    suggestionsByKeyword: string
) {
    return {
        type: REMOVE_KEYWORD_FROM_SUGGESTION,
        keywordId,
        suggestionKeywordType,
        suggestionKeyword,
        suggestionsByKeyword,
    };
}
