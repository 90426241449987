import { SET_COMPARATIVE_REPORT_CHART } from 'state/comparativeReport/comparativerReportChart/actionTypes';
import { SetComparativeReportChartActionType } from 'state/comparativeReport/comparativerReportChart/actions';
import { ComparativeReportDataChartType } from 'state/comparativeReport/ComparativeReportChartType';

type ActionType = SetComparativeReportChartActionType;

const initialState: ComparativeReportDataChartType[] = [];

const comparativeReportChart = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case SET_COMPARATIVE_REPORT_CHART: {
            return [...action.data];
        }
        default: {
            return state;
        }
    }
};

export default comparativeReportChart;
