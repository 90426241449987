import { KeywordExplorerAnalyticsStateType } from './keywordExplorerAnalyticsTypes';
import { KeywordExplorerAnalyticsActionType } from 'state/keywordExplorerAnalytics/actions';
import { KeywordExplorerAnalyticsActionTypeListEnum } from 'state/keywordExplorerAnalytics/actionsTypes';
import { getKEAnalyticsHash } from 'state/keywordExplorerAnalytics/getKEAnalyticsHash';

const initialState: KeywordExplorerAnalyticsStateType = {
    isLoadingByKeyword: {},
    analyticsByKeyword: {},
};

const keywordExplorerAnalytics = (
    state: KeywordExplorerAnalyticsStateType = initialState,
    action: KeywordExplorerAnalyticsActionType
) => {
    switch (action.type) {
        case KeywordExplorerAnalyticsActionTypeListEnum.SET_KE_ANALYTICS: {
            const { analytics, ...keywordProps } = action;
            const keywordHash = getKEAnalyticsHash(keywordProps);
            return {
                ...state,
                isLoadingByKeyword: {
                    ...state.isLoadingByKeyword,
                    [keywordHash]: false,
                },
                analyticsByKeyword: {
                    ...state.analyticsByKeyword,
                    [keywordHash]: analytics,
                },
            };
        }
        case KeywordExplorerAnalyticsActionTypeListEnum.SET_KE_ANALYTICS_IS_LOADING: {
            const { isLoading, ...keywordProps } = action;
            const keywordHash = getKEAnalyticsHash(keywordProps);
            return {
                ...state,
                isLoadingByKeyword: {
                    ...state.isLoadingByKeyword,
                    [keywordHash]: isLoading,
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default keywordExplorerAnalytics;
