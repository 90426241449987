import { Action } from 'redux';
import { ToggleToolNameEnum } from 'state/system/toggleToolNameEnum';
import { SidebarCategoriesEnum } from 'hooks/useSidebarCategories';
import { ToggleHidingToolsType } from 'state/system/systemTypes';
import { SystemActionTypeListEnum } from './actionsTypes';

interface SetSystemAppIdActionType extends Action {
    type: SystemActionTypeListEnum.SET_SYSTEM_APP_ID;
    appId?: string;
}

interface SetSystemLocaleActionType extends Action {
    type: SystemActionTypeListEnum.SET_SYSTEM_LOCALE;
    locale: string;
}

interface SetPageCategoryActionType extends Action {
    type: SystemActionTypeListEnum.SET_PAGE_CATEGORY;
    pageCategory: SidebarCategoriesEnum;
}

interface SetSystemToolNameActionType extends Action {
    type: SystemActionTypeListEnum.SET_SYSTEM_TOOL_NAME;
    toolName: string;
}

interface ToggleHidingToolActionType extends Action {
    type: SystemActionTypeListEnum.TOGGLE_SYSTEM_HIDING_TOOL;
    toolName: ToggleToolNameEnum;
}

interface SetToggleHidingToolsActionType extends Action {
    type: SystemActionTypeListEnum.SET_SYSTEM_HIDING_TOOLS;
    toggleHidingTools?: ToggleHidingToolsType;
}

interface SetHasPageDemoDataActionType extends Action {
    type: SystemActionTypeListEnum.SET_HAS_PAGE_DEMO_DATA;
    hasPageDemoData: boolean;
}

export type SystemActionType =
    | SetSystemAppIdActionType
    | SetSystemLocaleActionType
    | SetPageCategoryActionType
    | SetSystemToolNameActionType
    | ToggleHidingToolActionType
    | SetToggleHidingToolsActionType
    | SetHasPageDemoDataActionType;

export const setSystemAppIdAction = (appId?: string): SetSystemAppIdActionType => {
    return {
        type: SystemActionTypeListEnum.SET_SYSTEM_APP_ID,
        appId,
    };
};

export const setSystemLocaleAction = (locale: string): SetSystemLocaleActionType => {
    return {
        type: SystemActionTypeListEnum.SET_SYSTEM_LOCALE,
        locale,
    };
};

export const setPageCategoryAction = (pageCategory: SidebarCategoriesEnum): SetPageCategoryActionType => ({
    type: SystemActionTypeListEnum.SET_PAGE_CATEGORY,
    pageCategory,
});

export const setSystemToolNameAction = (toolName: string): SetSystemToolNameActionType => {
    return {
        type: SystemActionTypeListEnum.SET_SYSTEM_TOOL_NAME,
        toolName,
    };
};

export const toggleHidingToolAction = (toolName: ToggleToolNameEnum): ToggleHidingToolActionType => {
    return {
        type: SystemActionTypeListEnum.TOGGLE_SYSTEM_HIDING_TOOL,
        toolName,
    };
};

export const setToggleHidingToolsAction = (toggleHidingTools?: ToggleHidingToolsType): SetToggleHidingToolsActionType => {
    return {
        type: SystemActionTypeListEnum.SET_SYSTEM_HIDING_TOOLS,
        toggleHidingTools,
    };
};

export const setHasPageDemoDataAction = (hasPageDemoData: boolean): SetHasPageDemoDataActionType => {
    return {
        type: SystemActionTypeListEnum.SET_HAS_PAGE_DEMO_DATA,
        hasPageDemoData,
    };
};
