import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { DefaultTab } from 'newComponents/tab/defaultTab/DefaultTab';
import { ReviewsTrialPlanStepContainer } from 'modules/modal/onboarding/choosePlanStep/ReviewsTrialPlanStepContainer';
import { AsoTrialPlanStepContainer } from 'modules/modal/onboarding/choosePlanStep/AsoTrialPlanStepContainer';
import { EnterprisePlanCardContainer } from 'modules/settings/plans/planTabs/enterprisePlanCard/EnterprisePlanCardContainer';
import { PlanCardsList } from 'newComponents/plans/planCardsList/PlanCardsList';
import { usePlans } from 'hooks/data/usePlans/usePlans';
import { PLAN_ID_GURU_ASO, PLAN_ID_GURU_REVIEWS, REQUESTED_PLAN_NAME } from 'modules/modal/onboarding/choosePlanStep/constants';
import styles from 'modules/modal/onboarding/choosePlanStep/trialStep.module.scss';
import { useDispatch } from 'react-redux';
import { TrialStepNextData } from 'modules/modal/onboarding/choosePlanStep/trialStepTypes';
import { ShadowBlock, ShadowBlockPositionEnum, ShadowSizeEnum } from 'newComponents/shadowBlock/ShadowBlock';
import { BallLoader } from 'components/loader/ballLoader/BallLoader';
import { useProductAnalytics } from 'hooks/useProductAnalytics';
import { saveOnboardingState, SET_PLAN_TRIAL_ERROR, setOnboardingTrialPlan } from 'state/onboarding/controller';
import { getRequestInfo } from 'state/requestInfo/controller';
import { useTheme } from 'hooks/state/useTheme';
import { useNavigate } from 'react-router-dom';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { OnboardingStepEnum } from 'state/onboarding/types';
import { useSystem } from 'hooks/state/useSystem';
import { useAppSelector } from 'hooks/useAppSystem';
import { useScroll } from './useScroll';

export const ChoosePlanStepContainer: FC = () => {
    const [activeTab, setActiveTab] = useState(0);

    const dispatch = useDispatch();

    const { isMobile } = useTheme();

    const navigate = useNavigate();

    const { appId } = useSystem();

    const { myAppsUrl, asoToolsAppProfileUrl } = useRoutesUrl();

    const onboardingData = useAppSelector((state) => state.onboarding);

    const { sendEvent } = useProductAnalytics();

    const onNextWithGetTrial = async (nextProps?: TrialStepNextData) => {
        if (nextProps) {
            const { isSendRequest, planId, planName, requestedPlanName } = nextProps;

            const [adaptedRequestedPlanName] = requestedPlanName?.split(' ') || [];

            try {
                await dispatch(setOnboardingTrialPlan(planId, isSendRequest));
            } catch (error) {
                if (error.message === SET_PLAN_TRIAL_ERROR) {
                    toast.error(error.message);
                }
            }

            await sendEvent({
                actionName: 'plan_selected',
                toolName: 'onboarding',
                extras: {
                    name: planName,
                },
            });

            await dispatch(getRequestInfo());

            await dispatch(
                saveOnboardingState({
                    ...onboardingData,
                    onboardingSteps: {
                        ...onboardingData.onboardingSteps,
                        [OnboardingStepEnum.CHOOSE_A_PLAN_STEP]: {
                            ...onboardingData.onboardingSteps[OnboardingStepEnum.CHOOSE_A_PLAN_STEP],
                            ...(isSendRequest ? { leaveRequestPlanName: adaptedRequestedPlanName } : {}),
                        },
                    },
                    onboardingCurrentStepId: OnboardingStepEnum.FINISH_ONBOARDING_STEP,
                })
            );

            if (isMobile || !appId) {
                navigate(myAppsUrl);
            } else {
                navigate(asoToolsAppProfileUrl);
            }
        }
    };

    const { aso, reviews, reviewsAso, isLoadingPlansData } = usePlans();

    const [isLoadingLeaveRequestEnterprisePlan, setIsLoadingLeaveRequestEnterprisePlan] = useState(false);

    const { hasScroll, elementRef } = useScroll();
    const controlRefAso = useRef<HTMLDivElement | null>(null);
    const controlRefReviews = useRef<HTMLDivElement | null>(null);
    const [, setUpdateBottom] = useState(0);

    const onLeaveRequestAso = async (isSendRequest = false, requestedPlanName?: string) => {
        const trialPlanId = PLAN_ID_GURU_ASO;

        await onNextWithGetTrial({
            planId: trialPlanId,
            planName: REQUESTED_PLAN_NAME[trialPlanId],
            isSendRequest,
            requestedPlanName,
        });
    };

    const onLeaveRequestReviews = async (isSendRequest = false, requestedPlanName?: string) => {
        const trialPlanId = PLAN_ID_GURU_REVIEWS;

        await onNextWithGetTrial({
            planId: trialPlanId,
            planName: REQUESTED_PLAN_NAME[trialPlanId],
            isSendRequest,
            requestedPlanName,
        });
    };

    const columnCount = 5;

    const buttonsAso = <div className={classNames(styles.width, styles.buttons, 'grid-cols-5')} ref={controlRefAso} />;
    const buttonsReviews = <div className={classNames(styles.width, styles.buttons, 'grid-cols-5')} ref={controlRefReviews} />;

    useEffect(() => {
        const updatePortalElementForButtons = () => {
            setTimeout(() => {
                setUpdateBottom((prev) => prev + 1);
            });
        };

        updatePortalElementForButtons();
    }, [hasScroll, isLoadingPlansData, activeTab]);

    const callbackEnterprisePlanAso = async () => {
        setIsLoadingLeaveRequestEnterprisePlan(true);
        await onLeaveRequestAso(true, 'Enterprise');
        setIsLoadingLeaveRequestEnterprisePlan(false);
    };

    const callbackEnterprisePlanReviews = async () => {
        setIsLoadingLeaveRequestEnterprisePlan(true);
        await onLeaveRequestReviews(true, 'Enterprise');
        setIsLoadingLeaveRequestEnterprisePlan(false);
    };

    const tabByDevice = [
        {
            id: 0,
            text: 'ASO',
            Content: isLoadingPlansData ? (
                <div className="flex items-center justify-center min-h-510 min-w-1142">
                    <BallLoader />
                </div>
            ) : (
                <div>
                    <PlanCardsList columnCount={columnCount} className={classNames({ 'min-w-1142': isMobile })}>
                        {aso.map((plan) => (
                            <AsoTrialPlanStepContainer
                                key={plan.id}
                                plan={plan}
                                onLeaveRequest={onLeaveRequestAso}
                                onNext={onNextWithGetTrial}
                                reviewsAso={reviewsAso}
                                showASOReview={false}
                                buttonPortal={controlRefAso}
                            />
                        ))}
                        <EnterprisePlanCardContainer
                            isLoading={isLoadingLeaveRequestEnterprisePlan}
                            callback={callbackEnterprisePlanAso}
                            buttonPortal={controlRefAso}
                        />
                    </PlanCardsList>
                    <ShadowBlock
                        shadowSize={ShadowSizeEnum.SM}
                        className={classNames('sticky bottom-0 z-20 w-full !shadow-top-8-small', { 'min-w-1142': isMobile })}
                        wrapperClassName="bg-white pt-24"
                        shadowPosition={ShadowBlockPositionEnum.TOP}
                    >
                        {buttonsAso}
                    </ShadowBlock>
                </div>
            ),
            onClickHandler: () => setActiveTab(0),
        },
        {
            id: 1,
            text: 'Reply-to-Reviews',
            Content: isLoadingPlansData ? (
                <div className="flex items-center justify-center min-h-510 min-w-1142">
                    <BallLoader />
                </div>
            ) : (
                <div>
                    <PlanCardsList columnCount={columnCount} className={classNames({ 'min-w-1142': isMobile })}>
                        {reviews.map((plan) => (
                            <ReviewsTrialPlanStepContainer
                                key={plan.id}
                                plan={plan}
                                onLeaveRequest={onLeaveRequestReviews}
                                buttonPortal={controlRefReviews}
                                onNext={onNextWithGetTrial}
                            />
                        ))}
                        <EnterprisePlanCardContainer
                            isLoading={isLoadingLeaveRequestEnterprisePlan}
                            callback={callbackEnterprisePlanReviews}
                            buttonPortal={controlRefReviews}
                        />
                    </PlanCardsList>
                    <ShadowBlock
                        shadowSize={ShadowSizeEnum.SM}
                        className={classNames('sticky bottom-0 z-20 w-full !shadow-top-8-small', { 'min-w-1142': isMobile })}
                        wrapperClassName="bg-white pt-24"
                        shadowPosition={ShadowBlockPositionEnum.TOP}
                    >
                        {buttonsReviews}
                    </ShadowBlock>
                </div>
            ),
            onClickHandler: () => setActiveTab(1),
        },
    ];

    return (
        <div ref={elementRef}>
            <DefaultTab
                tabs={tabByDevice}
                activeId={activeTab}
                className="px-24 -mx-24"
                contentClassName={classNames('max-h-510 overflow-y-auto overflow-x-hidden px-24 -mx-24', {
                    '!overflow-x-auto': isMobile,
                    [styles.container]: isMobile,
                })}
            />
        </div>
    );
};
