export const MODAL_NAME_COPY_FILE = 'modal-copy-file';

export const MODAL_NAME_INTEGRATIONS_CHANGE_PASSWORD = 'modal-integrations-change-password';
export const MODAL_NAME_INTEGRATIONS_REMOVE_CONNECTION = 'modal-integrations-remove-connection';
export const MODAL_NAME_INTEGRATIONS_ADD_CONNECTION = 'modal-integrations-add-connection';

export const MODAL_NAME_REMOVE_TEMPLATE_FOLDER = 'modal-remove-template-folder';

export const MODAL_NAME_STOP_WORDS = 'modal-stop-words';
export const MODAL_NAME_ACTION_SET_OPTIMIZER = 'modal-action-set-optimizer';
export const MODAL_NAME_RESET_META_OPTIMIZER = 'modal-reset-meta-optimizer';
export const MODAL_NAME_GOOGLE_DESCRIPTION_OPTIMIZER = 'modal-google-description-optimizer';

export const MODAL_NAME_REMOVE_ALL_KEYWORDS = 'modal-remove-all-keywords';

export const MODAL_NAME_REPORT_REVIEW_MODAL = 'report-review';

export const MODAL_NAME_GOOGLE_INTEGRATIONS_SETTINGS = 'modal-integrations-google-integrations-settings';

export const MODAL_NAME_RUSTORE_INTEGRATIONS_SETTINGS = 'modal-integrations-rustore-integrations-settings';

export const MODAL_NAME_REMOVE_APP = 'modal-remove-app';

export const MODAL_NAME_CONFIRM_UNSAVED_KEYWORDS = 'modal-name-confirm-unsaved-keywords';

export const MODAL_NAME_ZENDESK_INTEGRATION = 'modal-name-zendesk-integration';
export const MODAL_NAME_OMNIDESK_INTEGRATION = 'modal-name-omnidesk-integration';
export const MODAL_NAME_USEDESK_INTEGRATION = 'modal-name-usedesk-integration';

export const MODAL_NAME_REMOVE_REVIEW_TAG = 'modal-name-remove-review-tag';
export const MODAL_NAME_REMOVE_APP_TAG_CATEGORY = 'modal-name-remove-app-tag-category';
export const MODAL_NAME_REMOVE_APP_TAG = 'modal-name-remove-app-tag';
export const MODAL_NAME_REMOVE_REVIEW_TAG_CATEGORY = 'modal-name-remove-review-tag-category';

export const MODAL_NAME_CREATE_REVIEW_TAG_RULE = 'modal-name-create-review-tag-rule';
export const MODAL_NAME_EDIT_REVIEW_TAG_RULE = 'modal-name-edit-review-tag-rule';
export const MODAL_NAME_REVIEW_TAG_LIST_RULE = 'modal-name-review-tag-list-rule';

export const MODAL_NAME_REMOVE_REVIEW_RULE = 'modal-name-remove-review-rule';

export const MODAL_NAME_CREATE_REVIEW_REPLIES_RULE = 'modal-name-create-review-replies-rule';
export const MODAL_NAME_EDIT_REVIEW_REPLIES_RULE = 'modal-name-edit-review-replies-rule';

export const MODAL_NAME_ACCEPT_AUTO_REPLIES = 'modal-name-accept-auto-replies';
export const MODAL_NAME_DECLINE_AUTO_REPLIES = 'modal-name-decline-auto-replies';

export const MODAL_NAME_MOBILE_PROFILE = 'modal-name-mobile-profile';

export const MODAL_NAME_MOBILE_SELECT_APP = 'modal-name-mobile-select-app';

export const MODAL_NAME_MOBILE_NOTIFICATIONS = 'modal-name-mobile-notifications';

export const MODAL_NAME_MOBILE_APP_SEARCH_SELECT = 'modal-name-mobile-app-search-select';

export const MODAL_NAME_MOBILE_COUNTRIES = 'modal-name-countries';

export const MODAL_NAME_PAYMENT = 'modal-name-payment';
export const MODAL_NAME_CAMPAIGN_PAYMENT = 'modal-campaign-name-payment';

export const MODAL_NAME_ENTERPRISE_PLAN = 'modal-name-enterprise-plan';

export const MODAL_NAME_KEYWORD_SUGGESTIONS = 'modal-name-keyword-suggestions';
export const MODAL_NAME_RANK_HISTORY_CHARTS = 'modal-name-rank-history-charts';

export const MODAL_NAME_KEYWORD_ANALYTICS_TABLE_FILTER = 'modal-name-keyword-analytics-table-filter';
export const MODAL_NAME_KEYWORD_ANALYTICS_SETUP_TABLE = 'modal-name-keyword-analytics-setup-table';

export const MODAL_NAME_CREATE_CUSTOM_REPORT = 'modal-name-create-custom-report';
export const MODAL_NAME_CUSTOM_REPORT_COPY_TABLE = 'modal_name_custom_report_copy_table';

export const MODAL_NAME_PRODUCT_TOUR = 'modal_name_product_tour';
export const TRIAL_EXPIRED_MODAL_NAME = 'modal_name_trial_expired';
export const WELCOME_OFFER_TRIAL_MODAL_NAME = 'welcome_offer_trial_modal_name';
export const DOWNGRADE_PLAN_MODAL_NAME = 'modal_name_downgrade_plan';

export const MODAL_NAME_UPGRADE_TRIAL_PLAN = 'modal_name_upgrade_trial_plan';

export const MODAL_NAME_FEATURE_PERMISSIONS = 'modal_name_feature_permissions';
export const MODAL_NAME_AUTO_SCHEDULE_DEMO = 'modal_name_auto_schedule_demo';
export const MODAL_NAME_BUSINESS_REQUEST = 'modal_name_business_request';

export const MODAL_NAME_KEYWORDS_RANKING_DISTRIBUTION = 'modal_name_keywords_ranking_distribution';

export const MODAL_NAME_MOBILE_KE_SEARCH_SETTINGS = 'modal-name-mobile-ke-search-settings';

export const MODAL_NAME_MOBILE_DATE_PICKER = 'modal-name-mobile-date-picker';

export const TAG_ANALYSIS_FILTERS_MOBILE_MODAL_NAME = 'tag-analysis-filters-mobile-modal-name';

export const MODAL_NAME_ONBOARDING_YOURSELF_STEP = 'modal-name-onboarding-yourself-step';
