// Auth routes
export const partialAuthRouteUrl = '/accounts/';

export const partialAuthRouteLoginUrl = 'login';
export const partialAuthRouteSignupUrl = 'signup';
export const partialAuthRouteResetPasswordUrl = 'password/reset';
export const partialAuthRouteVerifyUrl = 'verify';
export const getPartialAuthRouteResetPasswordWithKeyUrl = (key: string) => `password/reset/key/${key}`;
export const partialAuthRouteResetPasswordWithBadTokenUrl = 'password/reset/bad-token';
export const partialAuthRouteChangePasswordUrl = 'password/change';
export const partialAuthRouteLogoutUrl = 'logout';

export const authRouteLoginUrl = `${partialAuthRouteUrl}${partialAuthRouteLoginUrl}/`;
export const authRouteSignupUrl = `${partialAuthRouteUrl}${partialAuthRouteSignupUrl}/`;
export const authRouteResetPasswordUrl = `${partialAuthRouteUrl}${partialAuthRouteResetPasswordUrl}/`;
export const authRouteVerifyUrl = `${partialAuthRouteUrl}${partialAuthRouteVerifyUrl}/`;
export const getAuthRouteResetPasswordWithKeyUrl = (key: string) =>
    `${partialAuthRouteUrl}${getPartialAuthRouteResetPasswordWithKeyUrl(key)}`;
export const authRouteResetPasswordWithBadTokenUrl = `${partialAuthRouteUrl}${partialAuthRouteResetPasswordWithBadTokenUrl}`;
export const authRouteInviteRegistrationUrl = '/api/team/invite-registration';
export const authRouteChangePasswordUrl = `${partialAuthRouteUrl}${partialAuthRouteChangePasswordUrl}/`;
export const authRouteLogoutUrl = `${partialAuthRouteUrl}${partialAuthRouteLogoutUrl}/`;

// My apps routes
export const myAppsRouteUrl = '/my-apps/';

// Aso tools routes
export const partialAsoToolsRouteUrl = '/aso-tools/';

export const getPartialAsoToolsRouteAppProfileUrl = (appId?: string) => `app-profile/${appId}`;
export const getPartialAsoToolsRouteComparativeReportUrl = (appId?: string) => `aso-comparative-report/${appId}`;
export const getPartialAsoToolsRouteTrafficSourcesUrl = (appId?: string) => `traffic-sources/${appId}`;
export const getPartialAsoToolsRouteKeywordHighlightsUrl = (appId?: string) => `keyword-highlights/${appId}`;
export const getPartialAsoToolsRouteFavoriteKeywordsUrl = (appId?: string) => `favorite-keywords/${appId}`;
export const getPartialAsoToolsRouteCategoriesRankingUrl = (appId?: string) => `categories-ranking/${appId}`;
export const getPartialAsoToolsRouteOrganicReportUrl = (appId?: string) => `organic-report/${appId}`;
export const getPartialAsoToolsRouteFindAndTrackUrl = (appId?: string) => `find-and-track/${appId}`;
export const getPartialAsoToolsRouteKeywordChartsUrl = (appId?: string) => `keyword-charts/${appId}`;
export const getPartialAsoToolsRouteKeywordCompetitorsUrl = (appId?: string) => `keyword-competitors/${appId}`;
export const getPartialAsoToolsRouteKeywordPhraseMixerUrl = (appId?: string) => `keyword-phrase-mixer/${appId}`;
export const getPartialAsoToolsRouteKeywordLivePositionsUrl = (appId?: string) => `keyword-live-positions/${appId}`;
export const getPartialAsoToolsRouteKeywordAutoSuggestionsUrl = (appId?: string) => `keyword-auto-suggestions/${appId}`;
export const getPartialAsoToolsRouteDescriptionCreatorUrl = (appId?: string) => `description-creator/${appId}`;
export const getPartialAsoToolsRouteTextMetadataBuilderUrl = (appId?: string) => `text-metadata-builder/${appId}`;
export const partialAsoToolsRouteAppStoreLocalizationsUrl = `app-store-localizations`;
export const getPartialAsoToolsRouteNoLocaleUrl = (appId?: string) => `no-locale/${appId}`;

export const getAsoToolsRouteAppProfileUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteAppProfileUrl(appId)}`;
export const getAsoToolsRouteComparativeReportUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteComparativeReportUrl(appId)}`;
export const getAsoToolsRouteTrafficSourcesUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteTrafficSourcesUrl(appId)}`;
export const getAsoToolsRouteKeywordHighlightsUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteKeywordHighlightsUrl(appId)}`;
export const getAsoToolsRouteFavoriteKeywordsUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteFavoriteKeywordsUrl(appId)}`;
export const getAsoToolsRouteCategoriesRankingUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteCategoriesRankingUrl(appId)}`;
export const getAsoToolsRouteOrganicReportUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteOrganicReportUrl(appId)}`;
export const getAsoToolsRouteFindAndTrackUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteFindAndTrackUrl(appId)}`;
export const getAsoToolsRouteKeywordChartsUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteKeywordChartsUrl(appId)}`;
export const getAsoToolsRouteKeywordCompetitorsUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteKeywordCompetitorsUrl(appId)}`;
export const getAsoToolsRouteKeywordPhraseMixerUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteKeywordPhraseMixerUrl(appId)}`;
export const getAsoToolsRouteKeywordLivePositionsUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteKeywordLivePositionsUrl(appId)}`;
export const getAsoToolsRouteKeywordAutoSuggestionsUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteKeywordAutoSuggestionsUrl(appId)}`;
export const getAsoToolsRouteDescriptionCreatorUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteDescriptionCreatorUrl(appId)}`;
export const getAsoToolsRouteTextMetadataBuilderUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteTextMetadataBuilderUrl(appId)}`;
export const asoToolsRouteAppStoreLocalizationsUrl = `${partialAsoToolsRouteUrl}${partialAsoToolsRouteAppStoreLocalizationsUrl}`;
export const getAsoToolsRouteNoLocaleUrl = (appId?: string) =>
    `${partialAsoToolsRouteUrl}${getPartialAsoToolsRouteNoLocaleUrl(appId)}`;

// Stores analytics routes
export const partialStoresAnalyticsRouteUrl = '/stores-analytics/';

export const partialStoresAnalyticsRouteSearchExplorerUrl = 'search-explorer';
export const partialStoresAnalyticsRouteTopChartsUrl = 'top-charts';
export const partialStoresAnalyticsRouteCvrBenchmarkUrl = 'cvr-benchmark';
export const partialStoresAnalyticsRouteTopKeywordsUrl = 'top-keywords';
export const partialStoresAnalyticsRouteAppStoreTrendingSearchesUrl = 'app-store-trending-searches';

export const storesAnalyticsRouteSearchExplorerUrl = `${partialStoresAnalyticsRouteUrl}${partialStoresAnalyticsRouteSearchExplorerUrl}`;
export const storesAnalyticsRouteTopChartsUrl = `${partialStoresAnalyticsRouteUrl}${partialStoresAnalyticsRouteTopChartsUrl}`;
export const storesAnalyticsRouteCvrBenchmarkUrl = `${partialStoresAnalyticsRouteUrl}${partialStoresAnalyticsRouteCvrBenchmarkUrl}`;
export const storesAnalyticsRouteTopKeywordsUrl = `${partialStoresAnalyticsRouteUrl}${partialStoresAnalyticsRouteTopKeywordsUrl}`;
export const storesAnalyticsRouteAppStoreTrendingSearchesUrl = `${partialStoresAnalyticsRouteUrl}${partialStoresAnalyticsRouteAppStoreTrendingSearchesUrl}`;

// Reviews routes
export const partialReviewsRouteUrl = '/reviews/';

export const getPartialReviewsRouteBoardUrl = (appId?: string) => `board/${appId}`;
export const getPartialReviewsRouteFeaturedUrl = (appId?: string) => `featured/${appId}`;
export const getPartialReviewsRouteAnalysisUrl = (appId?: string) => `analysis/${appId}`;
export const getPartialReviewsRouteTagAnalysisUrl = (appId?: string) => `tag-analysis/${appId}`;
export const getPartialReviewsRouteRatingAnalysisUrl = (appId?: string) => `rating-analysis/${appId}`;
export const getPartialReviewsRouteTemplatesUrl = (appId?: string) => `templates/${appId}`;
export const getPartialReviewsRouteAutoRepliesUrl = (appId?: string) => `auto-replies/${appId}`;
export const getPartialReviewsRouteTagsUrl = (appId?: string) => `tags/${appId}`;
export const getPartialReviewsRouteAutoTagsUrl = (appId?: string) => `auto-tags/${appId}`;

export const getReviewsRouteBoardUrl = (appId?: string) => `${partialReviewsRouteUrl}${getPartialReviewsRouteBoardUrl(appId)}`;
export const getReviewsRouteFeaturedUrl = (appId?: string) =>
    `${partialReviewsRouteUrl}${getPartialReviewsRouteFeaturedUrl(appId)}`;
export const getReviewsRouteAnalysisUrl = (appId?: string) =>
    `${partialReviewsRouteUrl}${getPartialReviewsRouteAnalysisUrl(appId)}`;
export const getReviewsRouteTagAnalysisUrl = (appId?: string) =>
    `${partialReviewsRouteUrl}${getPartialReviewsRouteTagAnalysisUrl(appId)}`;
export const getReviewsRouteRatingAnalysisUrl = (appId?: string) =>
    `${partialReviewsRouteUrl}${getPartialReviewsRouteRatingAnalysisUrl(appId)}`;
export const getReviewsRouteTemplatesUrl = (appId?: string) =>
    `${partialReviewsRouteUrl}${getPartialReviewsRouteTemplatesUrl(appId)}`;
export const getReviewsRouteAutoRepliesUrl = (appId?: string) =>
    `${partialReviewsRouteUrl}${getPartialReviewsRouteAutoRepliesUrl(appId)}`;
export const getReviewsRouteTagsUrl = (appId?: string) => `${partialReviewsRouteUrl}${getPartialReviewsRouteTagsUrl(appId)}`;
export const getReviewsRouteAutoTagsUrl = (appId?: string) =>
    `${partialReviewsRouteUrl}${getPartialReviewsRouteAutoTagsUrl(appId)}`;

// Connections hub routes
export const partialConnectionsHubRouteUrl = '/connections-hub/';

export const partialConnectionsHubRouteSettingsUrl = 'settings';
export const partialConnectionsHubRouteTimelineUrl = 'timeline';
export const partialConnectionsHubRouteIntegrationsUrl = 'integrations';
export const partialConnectionsHubRouteApiUrl = 'api';

export const connectionsHubRouteSettingsUrl = `${partialConnectionsHubRouteUrl}${partialConnectionsHubRouteSettingsUrl}`;
export const connectionsHubRouteTimelineUrl = `${partialConnectionsHubRouteUrl}${partialConnectionsHubRouteTimelineUrl}`;
export const connectionsHubRouteIntegrationsUrl = `${partialConnectionsHubRouteUrl}${partialConnectionsHubRouteIntegrationsUrl}`;
export const connectionsHubRouteApiUrl = `${partialConnectionsHubRouteUrl}${partialConnectionsHubRouteApiUrl}`;

// Keyword boost routes
export const partialKeywordBoostRouteUrl = '/keyword-boost/';

export const getPartialKeywordBoostRouteIndexUrl = (appId?: string) => `index/${appId}`;
export const getPartialKeywordBoostRouteByCampaignIdUrl = (appId?: string, campaignId?: string) =>
    `${getPartialKeywordBoostRouteIndexUrl(appId)}/${campaignId}`;

export const getKeywordBoostRouteUrl = (appId?: string) =>
    `${partialKeywordBoostRouteUrl}${getPartialKeywordBoostRouteIndexUrl(appId)}`;
export const getKeywordBoostRouteByCampaignIdUrl = (appId?: string, campaignId?: string) =>
    `${partialKeywordBoostRouteUrl}${getPartialKeywordBoostRouteByCampaignIdUrl(appId, campaignId)}`;

// Referral program routes
export const partialReferralProgramRouteUrl = '/referral-program/';

export const referralProgramRouteUrl = partialReferralProgramRouteUrl;

// Account routes
export const partialAccountRouteUrl = '/account/';

export const partialAccountRouteTeammatesUrl = 'teammates';
export const partialAccountRouteBillingUrl = 'billing';
export const partialAccountRoutePricingUrl = 'pricing';
export const partialAccountRouteLogUrl = 'log';
export const partialAccountRouteNotificationsUrl = 'notifications';
export const getPartialAccountRouteInvoiceUrl = (subscriptionType: string, invoiceId: string) =>
    `billing/invoice/${subscriptionType}/${invoiceId}`;

export const accountRouteUrl = partialAccountRouteUrl;
export const accountRouteTeammatesUrl = `${partialAccountRouteUrl}${partialAccountRouteTeammatesUrl}`;
export const accountRouteBillingUrl = `${partialAccountRouteUrl}${partialAccountRouteBillingUrl}`;
export const accountRoutePricingUrl = `${partialAccountRouteUrl}${partialAccountRoutePricingUrl}`;
export const accountRouteLogUrl = `${partialAccountRouteUrl}${partialAccountRouteLogUrl}`;
export const accountRouteNotificationsUrl = `${partialAccountRouteUrl}${partialAccountRouteNotificationsUrl}`;
export const getAccountRouteInvoiceUrl = (subscriptionType: string, invoiceId: string) =>
    `${partialAccountRouteUrl}${getPartialAccountRouteInvoiceUrl(subscriptionType, invoiceId)}`;
