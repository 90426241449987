import React, { createContext, FC, ReactNode, useState } from 'react';

type BaseErrorType = {
    url: string;
    error: Error;
};

export type ErrorBoundaryType = {
    internalServerError?: BaseErrorType;
    setInternalServerError: (internalServerError?: BaseErrorType) => void;
};

export const ErrorBoundaryContext = createContext<ErrorBoundaryType>({
    setInternalServerError: () => {},
});

type ErrorBoundaryProviderType = {
    children: ReactNode;
};

export const ErrorBoundaryProvider: FC<ErrorBoundaryProviderType> = ({ children }: ErrorBoundaryProviderType) => {
    const [internalServerError, setInternalServerError] = useState<BaseErrorType>();

    const errors = {
        internalServerError,
        setInternalServerError,
    };

    return <ErrorBoundaryContext.Provider value={errors}>{children}</ErrorBoundaryContext.Provider>;
};
