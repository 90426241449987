import axios from 'axios';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'types/react-redux-thunk';
import { FrontendUrls, Urls } from 'utils/Urls';
import { AuthTokenRefreshResponseType } from 'state/account/AuthTokenRefreshResponseType';
import { AuthTokenObtainPairResponseType } from 'state/account/AuthTokenObtainPairResponseType';
import { setAccountFormErrorAction } from 'state/account/actions';
import { AccountResetPasswordFromKeyResponseType } from 'state/account/AccountResetPasswordFromKeyResponseType';
import { formDataHeaders } from 'constants/formDataHeaders';
import { accountFormErrorAdapter } from 'state/account/accountFormErrorAdapter';
import { AccountFormErrorType } from 'state/account/AccountFormErrorType';
import { setAccessToken } from 'state/user/controller';
import { RefreshTokenSettingsType } from 'utils/helpers/jwtTokenHelpers';
import { toast } from 'react-toastify';
import { getConfirmEmailMessage } from 'constants/account/signUpText';
import { RootState } from 'state/store';
import { AccountParamsType } from './AccountParamsType';
import { InviteRegistrationResponseType } from './InviteRegistrationResponseType';
import { TeammateSignupRequestBodyType } from './TeammateSignupRequestBodyType';

type ApiLoginParamsType = {
    password: string;
    email: string;
};

const setAdapterAccountFormErrors = (errors: AccountFormErrorType) => (dispatch: Dispatch) => {
    dispatch(setAccountFormErrorAction(accountFormErrorAdapter(errors)));
};

const errorHandler = (error: any, dispatch: ThunkDispatch) => {
    if (error.response && error.response.status === 400) {
        if (error.response.data.form_errors) dispatch(setAdapterAccountFormErrors(error.response.data.form_errors));
        if (!error.response.data.form_errors && error.response.data) {
            dispatch(setAdapterAccountFormErrors(error.response.data));
        }
    } else if (error?.response?.data?.error) toast.error(error.response.data.error);

    throw error;
};

export const login = ({ password, email }: ApiLoginParamsType) => async (dispatch: ThunkDispatch) => {
    try {
        const response = await axios.post<AuthTokenObtainPairResponseType>(Urls.auth_token_obtain_pair(), {
            password,
            username: email,
        });

        dispatch(setAccessToken(response.data.access));
    } catch (error) {
        errorHandler(error, dispatch);
    }
};

export const verifyCode = async (key: string) => {
    try {
        await axios.post(FrontendUrls.confirmEmail(), { key });
    } catch (error) {
        throw error;
    }
};

export const resendCode = () => async (dispatch: Dispatch, store: () => RootState) => {
    try {
        const { requestInfo } = store();

        await axios.post(FrontendUrls.resendCode());

        if (requestInfo.userEmail) {
            toast.success(getConfirmEmailMessage(requestInfo.userEmail));
        }
    } catch (error) {
        console.error(error);
    }
};

export const checkUserAuth = (refreshSettings: RefreshTokenSettingsType) => async (dispatch: ThunkDispatch) => {
    try {
        refreshSettings.isRefreshing = true;

        const response = await axios.post<AuthTokenRefreshResponseType>(Urls.auth_token_refresh());

        dispatch(setAccessToken(response.data.access));
        window.dispatchEvent(
            new CustomEvent('updateAccessToken', {
                detail: response.data.access,
            })
        );
    } catch (error) {
    } finally {
        refreshSettings.isRefreshing = false;
        // Вызвать все ожидающие запросы
        refreshSettings.refreshSubscribers.forEach((callback) => callback());
        refreshSettings.refreshSubscribers = [];
    }
};

export const resetPassword = (formWithEmail: FormData) => async (dispatch: ThunkDispatch) => {
    try {
        await axios.post(Urls.account_reset_password(), formWithEmail, formDataHeaders);
        dispatch(setAccountFormErrorAction({}));
    } catch (error) {
        errorHandler(error, dispatch);
    }
};

export const resetPasswordFromKey = (formWithPasswords: FormData, key: string) => async (dispatch: ThunkDispatch) => {
    try {
        const splitKey = key.split(/-/);
        const firstPartKey = splitKey.shift();
        const response = await axios.post<AccountResetPasswordFromKeyResponseType>(
            Urls.account_reset_password_from_key(firstPartKey, splitKey.join(`-`)),
            formWithPasswords,
            formDataHeaders
        );
        dispatch(setAccountFormErrorAction({}));
        const tokens = JSON.parse(response.data.html);

        dispatch(setAccessToken(tokens.access));
    } catch (error) {
        errorHandler(error, dispatch);
    }
};

export const changeUserPassword = (formWithPasswords: FormData) => async (dispatch: ThunkDispatch) => {
    try {
        await axios.post(Urls.account_change_password(), formWithPasswords, {
            headers: {
                'X-Requested-With': `XMLHttpRequest`,
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch(setAccountFormErrorAction({}));
    } catch (error) {
        errorHandler(error, dispatch);
    }
};

export const signupWithGoogle = () => async (): Promise<string | undefined> => {
    try {
        const url = Urls.google_login() + window.location.search;
        const response = await axios.get<{ url: string }>(url, {
            headers: {
                'X-Requested-With': `XMLHttpRequest`,
            },
        });

        return response.data.url;
    } catch (error) {
        console.error(error);
    }
};

export const userSignup = (form: FormData, accountsParams: AccountParamsType | null, locationSearch: string) => {
    const params = accountsParams
        ? {
              rp_bonus: accountsParams.refProgramBonus,
              rp_token: accountsParams.refProgramToken,
          }
        : null;
    return async (dispatch: ThunkDispatch) => {
        try {
            const response = await axios.post(Urls.account_signup() + locationSearch, form, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'multipart/form-data',
                },
                params,
            });
            dispatch(setAccountFormErrorAction({}));

            const { access } = JSON.parse(response.data.html);

            dispatch(setAccessToken(access));
        } catch (error) {
            errorHandler(error, dispatch);
        }
    };
};

export const teammatesSignup = (formData: FormData, token: string) => async (dispatch: ThunkDispatch) => {
    try {
        const body: TeammateSignupRequestBodyType = {
            check: true,
            check_privacy: true,
            first_name: formData.get('first_name') as string,
            password1: formData.get('password1') as string,
            material_language: formData.get('material_language') as string,
            country: formData.get('country') as string,
            token,
        };

        const { data } = await axios.post<AuthTokenObtainPairResponseType>(Urls.invite_registration(), body);

        dispatch(setAccessToken(data.access));

        return data;
    } catch (e) {
        errorHandler(e, dispatch);
        return e.response;
    }
};

export const getTeammateInfo = (token?: string) => async () => {
    try {
        const response = await axios.get<InviteRegistrationResponseType>(Urls.invite_registration(), {
            headers: {
                'X-Requested-With': `XMLHttpRequest`,
            },
            params: {
                token,
            },
        });

        return response.data;
    } catch (e) {
        return e.response;
    }
};
