import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTogglePopup } from 'hooks/state/usePopup';
import {
    MODAL_NAME_PRODUCT_TOUR,
    WELCOME_OFFER_TRIAL_MODAL_NAME,
    TRIAL_EXPIRED_MODAL_NAME,
    DOWNGRADE_PLAN_MODAL_NAME,
    MODAL_NAME_ENTERPRISE_PLAN,
    MODAL_NAME_PAYMENT,
    MODAL_NAME_CAMPAIGN_PAYMENT,
    MODAL_NAME_KEYWORDS_RANKING_DISTRIBUTION,
    MODAL_NAME_FEATURE_PERMISSIONS,
    MODAL_NAME_AUTO_SCHEDULE_DEMO,
    MODAL_NAME_UPGRADE_TRIAL_PLAN,
    MODAL_NAME_BUSINESS_REQUEST,
    MODAL_NAME_MOBILE_DATE_PICKER,
    MODAL_NAME_INTEGRATIONS_ADD_CONNECTION,
    MODAL_NAME_GOOGLE_INTEGRATIONS_SETTINGS,
    MODAL_NAME_REMOVE_APP,
    MODAL_NAME_ONBOARDING_YOURSELF_STEP,
    MODAL_NAME_RUSTORE_INTEGRATIONS_SETTINGS,
} from 'constants/modalNameConstants';
import { useTrialExpiredStorage } from 'modules/modal/planModal/hooks/useTrialExpiredStorage';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { useWelcomeOffer } from 'hooks/useWelcomeOffer';
import { useQueryString } from 'hooks/useQueryString';
import { useStatsUserLimit } from 'modules/sidebar/hooks/useStatsUserLimit';
import { useCampaignPaymentModalBridgeAngular } from 'hooks/useCampainPaymentModalBridgeAngular';
import { useLiveSearchBridgeAngular } from 'hooks/useLiveSearchBridgeAngular';
import { useFeaturePermissionModalListener } from 'hooks/listener/useFeaturePermissionModalListener';
import { useScheduleLiveDemoModalListener } from 'hooks/listener/useScheduleLiveDemoModalListener';
import { WelcomeOfferTrialModalContainerType } from 'modules/modal/welcomeOfferTrialModal/welcomeOfferTrialModalContainerTypes';
import { PaymentPopupDataType } from 'modules/modal/paymentModal/PaymentModalTypes';
import { CampaignPaymentModalTypes } from 'modules/modal/campaignPaymentModal/CampaignPaymentModalTypes';
import {
    ASIDE_PANEL_NAME_ADD_COMPETITOR,
    ASIDE_PANEL_NAME_ADD_KEYWORDS,
    ASIDE_PANEL_NAME_LIVE_SEARCH,
    REMOVED_DOWNGRADE_PLAN_META_ASIDE_PANEL,
} from 'constants/asidePanelNameConstants';
import { CONTEXT_MENU_COMPETITOR_ACTIONS } from 'constants/contextMenuMobileNameConstants';
import { NOTIFY_NAME_MOBILE_APP_INFO, NOTIFY_NAME_MOBILE_FEATURE } from 'constants/mobileNotifyNameConstants';
import { campaignCountryCodeParams, campaignIdParams } from 'state/campaignPayment/utils';
import {
    errorCardTextParams,
    paymentPeriodParams,
    planIdParams,
    selectedCardIdParams,
    stepModalIdParams,
    subscriptionIdParams,
    isShowPlanFeaturesParams,
} from 'state/payment/utils';
import { useOnboarding } from 'hooks/useOnboarding';
import { useAppSelector } from 'hooks/useAppSystem';

export const useMainLayout = () => {
    const isGlobalAppLoaderShow = useAppSelector(({ userFrontendSettings }) => userFrontendSettings.isGlobalAppLoaderShow);

    const { isShowProductTour } = useOnboarding();

    const { isShowOnboardingModal, isLoaded: isLoadedRequestInfo } = useRequestInfoService();

    const { statsUserLimit } = useStatsUserLimit();

    useCampaignPaymentModalBridgeAngular();
    useLiveSearchBridgeAngular();
    useFeaturePermissionModalListener();
    useScheduleLiveDemoModalListener();

    const { show_product_tour: isShowProductTourModal } = useQueryString();
    const location = useLocation();
    const navigate = useNavigate();

    const { hasWelcomeOffer } = useWelcomeOffer();

    const { isOpen: isOpenProductTourPopup, togglePopup: toggleProductTourPopup } = useTogglePopup(MODAL_NAME_PRODUCT_TOUR);

    const { isNeedOpenModal: isNeedTrialExpiredOpenModal } = useTrialExpiredStorage();

    const removeSearchQuery = () => {
        navigate({
            pathname: location.pathname,
        });
    };

    useEffect(() => {
        if (isLoadedRequestInfo && isShowProductTourModal) {
            if (!isNeedTrialExpiredOpenModal) {
                toggleProductTourPopup();
            }

            removeSearchQuery();
        }
    }, [isLoadedRequestInfo, isNeedTrialExpiredOpenModal]);

    const { togglePopupWithData: onToggleWelcomeOfferTrialPopup, isOpen: isOpenWelcomeOfferTrialPopup } = useTogglePopup<
        WelcomeOfferTrialModalContainerType
    >(WELCOME_OFFER_TRIAL_MODAL_NAME);

    const { togglePopup: onToggleExpiredPopup, isOpen: isOpenExpiredPopup } = useTogglePopup(TRIAL_EXPIRED_MODAL_NAME);
    const { isOpen: isOpenDowngradePlanPopup } = useTogglePopup(DOWNGRADE_PLAN_MODAL_NAME);
    const { isOpen: isOpenEnterprisePlanModal } = useTogglePopup(MODAL_NAME_ENTERPRISE_PLAN);
    const { isOpen: isOpenPaymentModal, togglePopupWithData: onTogglePaymentModal } = useTogglePopup<PaymentPopupDataType>(
        MODAL_NAME_PAYMENT
    );
    const { isOpen: isOpenCampaignPaymentModal, togglePopupWithData: onToggleCampaignPaymentModal } = useTogglePopup<
        CampaignPaymentModalTypes
    >(MODAL_NAME_CAMPAIGN_PAYMENT);
    const { isOpen: isOpenKeywordsRankingDistributionModal } = useTogglePopup(MODAL_NAME_KEYWORDS_RANKING_DISTRIBUTION);
    const { isOpen: isOpenFeaturePermissionModal } = useTogglePopup(MODAL_NAME_FEATURE_PERMISSIONS);
    const { isOpen: isOpenScheduleDemoModal } = useTogglePopup(MODAL_NAME_AUTO_SCHEDULE_DEMO);
    const { isOpen: isOpenUpgradeTrialModal } = useTogglePopup(MODAL_NAME_UPGRADE_TRIAL_PLAN);
    const { isOpen: isOpenBusinessRequestModal } = useTogglePopup(MODAL_NAME_BUSINESS_REQUEST);
    const { isOpen: isOpenLiveSearchAsidePanel } = useTogglePopup(ASIDE_PANEL_NAME_LIVE_SEARCH);

    const { isOpen: isOpenRemovedDowngradePlanMetaAsidePanel } = useTogglePopup(REMOVED_DOWNGRADE_PLAN_META_ASIDE_PANEL);

    const { isOpen: isOpenMobileCompetitorContextMenu } = useTogglePopup(CONTEXT_MENU_COMPETITOR_ACTIONS);

    const { isOpen: isOpenAsidePanelAddCompetitor } = useTogglePopup(ASIDE_PANEL_NAME_ADD_COMPETITOR);
    const { isOpen: isOpenAsidePanelAddKeywords } = useTogglePopup(ASIDE_PANEL_NAME_ADD_KEYWORDS);

    const { isOpen: isOpenMobileDatePicker } = useTogglePopup(MODAL_NAME_MOBILE_DATE_PICKER);

    const { isOpen: isOpenMobileNotifyAppInfo } = useTogglePopup(NOTIFY_NAME_MOBILE_APP_INFO);
    const { isOpen: isOpenMobileNotifyFeature } = useTogglePopup(NOTIFY_NAME_MOBILE_FEATURE);

    const { isOpen: isOpenYourselfStep, togglePopup: togglePopupOnboarding } = useTogglePopup(
        MODAL_NAME_ONBOARDING_YOURSELF_STEP
    );

    useEffect(() => {
        if (!isGlobalAppLoaderShow && isShowOnboardingModal && !isShowProductTour) {
            togglePopupOnboarding();
        }
    }, [isGlobalAppLoaderShow, isShowOnboardingModal, isShowProductTour]);

    useEffect(() => {
        if ((!campaignIdParams && stepModalIdParams) || planIdParams) {
            onTogglePaymentModal({
                data: {
                    ...(paymentPeriodParams ? { paymentPeriod: paymentPeriodParams } : {}),
                    ...(planIdParams ? { planId: planIdParams } : {}),
                    ...(subscriptionIdParams ? { subscriptionId: subscriptionIdParams } : {}),
                    ...(selectedCardIdParams && errorCardTextParams
                        ? { selectedCardId: selectedCardIdParams, errorCardText: errorCardTextParams }
                        : {}),
                    ...(isShowPlanFeaturesParams
                        ? { isShowPlanFeatures: isShowPlanFeaturesParams, modalSettings: { isShowPaymentPeriod: false } }
                        : {}),
                },
            });
        }
    }, []);

    useEffect(() => {
        if (campaignIdParams && stepModalIdParams) {
            onToggleCampaignPaymentModal({
                data: {
                    campaignId: campaignIdParams,
                    stepModalId: stepModalIdParams,
                    ...(campaignCountryCodeParams ? { campaignCountryCodeParams } : {}),
                    ...(selectedCardIdParams && errorCardTextParams
                        ? { selectedCardId: selectedCardIdParams, errorCardText: errorCardTextParams }
                        : {}),
                },
            });
        }
    }, []);

    useEffect(() => {
        if (statsUserLimit) {
            const {
                subscription: { isWelcomeOfferCurrentPlanAvailable },
            } = statsUserLimit;

            if (isLoadedRequestInfo && (!hasWelcomeOffer || (hasWelcomeOffer && !isWelcomeOfferCurrentPlanAvailable))) {
                if (isNeedTrialExpiredOpenModal) onToggleExpiredPopup();
            }

            if (isLoadedRequestInfo && hasWelcomeOffer && isWelcomeOfferCurrentPlanAvailable) {
                if (isNeedTrialExpiredOpenModal)
                    onToggleWelcomeOfferTrialPopup({
                        data: {
                            trialType: TRIAL_EXPIRED_MODAL_NAME,
                        },
                    });
            }
        }
    }, [isNeedTrialExpiredOpenModal, isLoadedRequestInfo, hasWelcomeOffer, statsUserLimit]);

    const { isOpen: isOpenAddConnectionModal } = useTogglePopup(MODAL_NAME_INTEGRATIONS_ADD_CONNECTION);
    const { isOpen: isOpenGoogleConnectionModal } = useTogglePopup(MODAL_NAME_GOOGLE_INTEGRATIONS_SETTINGS);
    const { isOpen: isOpenRustoreConnectionModal } = useTogglePopup(MODAL_NAME_RUSTORE_INTEGRATIONS_SETTINGS);
    const { isOpen: isOpenRemoveAppModal } = useTogglePopup(MODAL_NAME_REMOVE_APP);

    return {
        isOpenWelcomeOfferTrialPopup,
        isOpenExpiredPopup,
        isOpenDowngradePlanPopup,
        isOpenProductTourPopup,
        isOpenEnterprisePlanModal,
        isOpenPaymentModal,
        isOpenCampaignPaymentModal,
        isOpenKeywordsRankingDistributionModal,
        isOpenFeaturePermissionModal,
        isOpenScheduleDemoModal,
        isOpenUpgradeTrialModal,
        isOpenBusinessRequestModal,
        isOpenLiveSearchAsidePanel,
        isOpenRemovedDowngradePlanMetaAsidePanel,
        isOpenMobileCompetitorContextMenu,
        isOpenAsidePanelAddCompetitor,
        isOpenAsidePanelAddKeywords,
        isOpenMobileDatePicker,
        isOpenMobileNotifyAppInfo,
        isOpenMobileNotifyFeature,
        isOpenAddConnectionModal,
        isOpenGoogleConnectionModal,
        isOpenRemoveAppModal,
        isOpenYourselfStep,
        isOpenRustoreConnectionModal,
    };
};
