export enum ButtonStateEnum {
    DEFAULT = 'default',
    PRIMARY = 'primary',
    LIGHT_PRIMARY = 'lightPrimary',
    SECONDARY = 'secondary',
    ALERT = 'alert',
    DANGER = 'danger',
    SUCCESS = 'success',
    WARNING = 'warning',
    INFO = 'info',
    NONE = 'none',
    DARK = 'dark',
    HIGHLIGHT = 'highlight',
    WHITE = 'white',
    LIGHT_BLUE = 'lightBlue',
    OFFER = 'offer',
}

export enum ButtonSizeEnum {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    OLD_MD = 'oldMd',
    MD_WIDER = 'mdWider',
    ML = 'ml',
    LG = 'lg',
    LG_WIDER = 'lgWider',
    XL = 'xl',
    XXL = 'xxl',
}

export enum ButtonTypeEnum {
    SOLID = 'solid',
    OUTLINE = 'outline',
}
