import { Dispatch } from 'redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { PlansResponseType } from 'state/plans/PlansResponseType';
import { setPlansFeaturesInformation, setPlansData } from 'state/plans/actions';
import { apiUrls } from 'utils/Urls';
import { CouponCaseEnum } from 'state/plans/CouponCaseEnum';
import { EnterprisePlanSelectType } from 'modules/settings/plans/planTabs/enterprisePlanCard/enterprisePlanModal/modalControls/EnterprisePlanSelectContainer';
import { plansFeaturesAdapter } from 'state/plans/plansFeaturesAdapter';
import { AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import { getRequestInfo } from 'state/requestInfo/controller';
import { getSilentCurrentAppProfile } from 'state/appProfile/controller';
import { ThunkDispatch } from 'types/react-redux-thunk';
import { UpgradeTrialResponseType } from 'state/plans/UpgradeTrialResponseType';
import { StatsUserLimitIdsEnum } from 'state/userFrontendSettings/SidebarResponseType';
import { UserLimitType } from 'state/userFrontendSettings/StatsUserLimitsType';
import { upgradeTrialResponseAdapter } from 'state/plans/upgradeTrialResponseAdapter';
import { RootState } from 'state/store';
import { plansAdapter } from './plansAdapter';

export const getAdaptedPlans = async (feature?: keyof AvailableFeaturesType) => {
    const response = await axios.get<PlansResponseType>(apiUrls.plans(), {
        params: {
            feature,
        },
    });

    return plansAdapter(response.data);
};

export const getPlansData = (feature?: keyof AvailableFeaturesType) => async (dispatch: Dispatch) => {
    try {
        const adaptedData = await getAdaptedPlans(feature);
        dispatch(setPlansData(adaptedData));
    } catch (error) {
        console.error(error);
    }
};

type SendCustomPlanType = {
    keywords: EnterprisePlanSelectType['selectCounter'];
    apps: EnterprisePlanSelectType['selectCounter'];
    teammates: EnterprisePlanSelectType['selectCounter'];
    replies: EnterprisePlanSelectType['selectCounter'];
    asoFeatures?: string[];
    reviewsFeatures?: string[];
};

export const sendCustomPlan = ({
    keywords,
    apps,
    replies,
    teammates,
    asoFeatures,
    reviewsFeatures,
}: SendCustomPlanType) => async () => {
    try {
        await axios.post(apiUrls.sendCustomPlan(), {
            keywords,
            apps,
            replies,
            teammates,
            aso_features: asoFeatures,
            review_features: reviewsFeatures,
        });

        toast.success('Request has been sent. Manager will contact with you.');
    } catch (error) {
        console.error(error);
        toast.error('Error sending request, please try again.');

        throw error;
    }
};

export type SuitablePlanLimitsType = {
    apps?: number;
    teammates?: number;
    keywords?: number;
    replies?: number;
};

const getLimits = (store: () => RootState): SuitablePlanLimitsType => {
    const {
        userFrontendSettings: { statsUserLimit },
    } = store();
    let limits: SuitablePlanLimitsType = {};
    if (statsUserLimit) {
        const userLimits: UserLimitType[] = statsUserLimit.limits;
        const appLimits = userLimits.find(({ id }) => id === StatsUserLimitIdsEnum.APPLICATIONS);
        const teammatesLimits = userLimits.find(({ id }) => id === StatsUserLimitIdsEnum.TEAMMATES);
        const keywordsLimits = userLimits.find(({ id }) => id === StatsUserLimitIdsEnum.KEYWORDS);
        const repliesLimits = userLimits.find(({ id }) => id === StatsUserLimitIdsEnum.MONTHLY_REVIEW_REPLIES);

        limits = {
            apps: appLimits?.current,
            teammates: teammatesLimits?.current,
            keywords: keywordsLimits?.current,
            replies: repliesLimits?.current,
        };
    }

    return limits;
};

export const getSuitablePlan = (additionalPlanLimits: SuitablePlanLimitsType = {}) => async (
    dispatch: ThunkDispatch,
    getOwnState: () => RootState
) => {
    try {
        const params = getLimits(getOwnState);

        const mergeParams = (limits: SuitablePlanLimitsType, mergedLimits: SuitablePlanLimitsType): SuitablePlanLimitsType => {
            const mergedParams: SuitablePlanLimitsType = Object.keys(limits).reduce((previousValue, currentLimitKey) => {
                const limitKey = currentLimitKey as keyof SuitablePlanLimitsType;
                const additionalLimitAmount = mergedLimits[limitKey];
                const userLimitAmount = limits[limitKey];
                return {
                    ...previousValue,
                    [currentLimitKey]:
                        additionalLimitAmount && userLimitAmount ? additionalLimitAmount + userLimitAmount : userLimitAmount,
                };
            }, {});
            return mergedParams;
        };

        const response = await axios.get<{ suitable_plan_id: number; base_id: number }>(apiUrls.getSuitablePlan(), {
            params: mergeParams(params, additionalPlanLimits),
        });

        return response.data.base_id;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const geCoupon = async (couponCase: CouponCaseEnum) => {
    try {
        const response = await axios.get<{ coupon: string; value: number } | null>(apiUrls.geCoupon(), {
            params: {
                case: couponCase,
            },
        });

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getFeatureInformation = () => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get<AvailableFeaturesType>(apiUrls.allFeatures());

        const adaptedData = plansFeaturesAdapter(response.data);

        dispatch(setPlansFeaturesInformation(adaptedData));
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const sendSlackScheduleLiveDemoRequest = async (feature: keyof AvailableFeaturesType) => {
    try {
        await axios.post(apiUrls.scheduleLiveDemo(), { feature });
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const upgradeTrial = (feature: keyof AvailableFeaturesType) => async (dispatch: ThunkDispatch, store: () => RootState) => {
    try {
        const response = await axios.post<UpgradeTrialResponseType>(apiUrls.upgradeTrial(), {}, { params: { feature } });
        const { isNeedBusinessPlan, oldPlanName, newPlanName } = upgradeTrialResponseAdapter(response.data);

        const {
            system: { locale, appId },
            userFrontendSettings: { lastCurrentLocale, lastCurrentAppId },
        } = store();

        const updateDataRequests = [
            dispatch(getRequestInfo()),
            dispatch(getSilentCurrentAppProfile({ locale: locale || lastCurrentLocale, appId: appId || lastCurrentAppId })),
        ];
        await Promise.all(updateDataRequests);
        return {
            isNeedBusinessPlan,
            oldPlanName,
            newPlanName,
        };
    } catch (error) {
        console.error(error);
        throw error;
    }
};
