import { useMemo } from 'react';
import { StatsUserLimitIdsEnum } from 'state/userFrontendSettings/SidebarResponseType';
import { useAppSelector } from 'hooks/useAppSystem';

export const useStatsUserLimit = () => {
    const statsUserLimit = useAppSelector((state) => state.userFrontendSettings.statsUserLimit);

    return useMemo(
        () => ({
            statsUserLimit,
            isAvailableLimit: (limitId: StatsUserLimitIdsEnum) => {
                if (!statsUserLimit) return false;

                const limit = statsUserLimit.limits.find(({ id }) => id === limitId);
                return !!limit && limit.max - limit.current > 0;
            },
        }),
        [statsUserLimit]
    );
};
