import React from 'react';
import { AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import { PermissionText } from 'newComponents/text/permissionText/PermissionText';
import { AsodeskTooltip } from 'components/tooltips/asodeskTooltip/asodeskTooltip';

type PropsType = {
    feature?: keyof AvailableFeaturesType;
    additionText?: string;
    children?: React.ReactNode;
    hideTooltip?: boolean;
};

const PermissionTooltipView: React.FC<PropsType> = ({ feature, additionText, children, hideTooltip }: PropsType) => (
    <AsodeskTooltip
        anchor={children}
        delayHide={1000}
        delayShow={200}
        clickable
        tooltipClassName="text-center"
        tooltip={hideTooltip ? null : <PermissionText feature={feature} additionText={additionText} />}
    />
);

export const PermissionTooltip = React.memo(PermissionTooltipView);
