import { ElementColors } from 'constants/colorConstants';

export enum RefProgramStatusEnum {
    INVITATION_SENT = 1,
    SIGNED_UP = 2,
    SUBSCRIPTION_ACTIVATED = 3,
}

export const statusData = {
    [RefProgramStatusEnum.INVITATION_SENT]: {
        text: 'Invitation sent',
        color: ElementColors.Warning,
    },
    [RefProgramStatusEnum.SIGNED_UP]: {
        text: 'Signed up',
        color: ElementColors.CorporateBlue,
    },
    [RefProgramStatusEnum.SUBSCRIPTION_ACTIVATED]: {
        text: 'Subscription activated',
        color: ElementColors.Success,
    },
};
