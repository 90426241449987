import { Dispatch } from 'redux';
import axios from 'axios';
import { apiUrls } from 'utils/Urls';
import { plansDowngradeMetaAdapter } from 'state/plans/plansDowngradeMeta/plansDowngradeMetaAdapter';
import { PlansDowngradeMetaResponseType } from 'state/plans/plansDowngradeMeta/PlansDowngradeMetaResponseType';
import { setDowngradePlansMeta } from 'state/plans/actions';

export const getRemovedDowngradePlanMeta = (planIds: number[]) => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get<PlansDowngradeMetaResponseType>(apiUrls.getRemovedDowngradePlanMeta(), {
            params: {
                plan_ids: planIds.join(','),
            },
        });

        const adaptedData = plansDowngradeMetaAdapter(response.data);

        dispatch(setDowngradePlansMeta(adaptedData));
    } catch (error) {
        console.error(error);
    }
};

export const removedDowngradePlanMeta = (planId: number) => async () => {
    try {
        await axios.post<PlansDowngradeMetaResponseType>(apiUrls.removedDowngradePlanMeta(), {
            params: {
                plan_id: planId,
            },
        });
    } catch (error) {
        console.error(error);
    }
};
