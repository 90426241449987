import { KeywordsRankingDistributionType } from 'state/keywordsRankingDistribution/keywordsRankingDistributionType';
import { KeywordsRankingDistributionActionTypeListEnum } from './actionsTypes';
import { KeywordsRankingDistributionActionType } from './actions';

export type KeywordsRankingDistributionStateType = KeywordsRankingDistributionType;

const keywordsRankingDistributionInitialState: KeywordsRankingDistributionStateType = {
    chart: [],
    table: [],
};

const keywordsRankingDistribution = (
    state = keywordsRankingDistributionInitialState,
    action: KeywordsRankingDistributionActionType
) => {
    switch (action.type) {
        case KeywordsRankingDistributionActionTypeListEnum.SET_KEYWORDS_RANKING_DISTRIBUTION: {
            const { chart, table } = action;

            return {
                ...state,
                chart,
                table,
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordsRankingDistribution;
