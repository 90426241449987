import { TextEventsCategoriesEnum, TextEventsEnum, TextEventsLabelsEnum } from 'constants/account/textEventsGTM';

let dataLayer: Window['dataLayer'] = [];

export const initTagManager = () => {
    dataLayer = window.dataLayer;
};

export type TagManagerEventDataType = {
    event?: TextEventsEnum;
    eventCategory?: TextEventsCategoriesEnum;
    eventAction: string;
    eventLabel?: TextEventsLabelsEnum;
};

type EventPropsType = {
    event: string;
} & Partial<TagManagerEventDataType>;

export const sendEventTagManager = (eventProps: EventPropsType) => dataLayer.push(JSON.stringify(eventProps));

export const getTagManagerEventData = ({
    eventCategory,
    eventAction,
    eventLabel,
    event = TextEventsEnum.FUNNEL,
}: TagManagerEventDataType) => ({
    event,
    eventCategory,
    eventAction,
    eventLabel,
});
