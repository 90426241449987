import React, { createContext, FC, ReactNode } from 'react';
import { useCheckDeviceType, getInitialIsMobile } from 'hooks/state/useCheckDeviceType';

export type ThemeType = {
    isMobile: boolean;
};

export const ThemeContext = createContext<ThemeType>({
    isMobile: getInitialIsMobile(),
});

type PropsType = {
    children: ReactNode;
};

export const ThemeProvider: FC<PropsType> = ({ children }: PropsType) => {
    const { isMobile } = useCheckDeviceType();

    const theme = {
        isMobile,
    };

    return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};
