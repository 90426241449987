import { useEffect } from 'react';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { featurePermissionModalWithTrialCase } from 'state/popup/featurePermissionModalDispatch';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'types/react-redux-thunk';
import { useAuth } from 'hooks/useAuth';

export const useFeaturePermissionModalListener = () => {
    const dispatch = useDispatch<ThunkDispatch>();
    const { isTrial } = useRequestInfoService();

    const { onCheckClickAuth } = useAuth();

    const onShowModal = (event: CustomEvent) => {
        const feature = event?.detail;

        onCheckClickAuth(() => {
            if (feature) {
                featurePermissionModalWithTrialCase(dispatch, feature, isTrial);
            }
        });
    };

    useEffect(() => {
        window.addEventListener('openFeaturePermissionModal', onShowModal as EventListener);

        return () => {
            window.removeEventListener('openFeaturePermissionModal', onShowModal as EventListener);
        };
    }, []);
};
