import { SET_COMPARATIVE_REPORT_META } from 'state/comparativeReport/comparativeReportMeta/actionTypes';
import { SetComparativeReportMetaActionType } from 'state/comparativeReport/comparativeReportMeta/actions';
import { ComparativeReportMetaResponseType } from 'state/comparativeReport/ComparativeReportMetaResponseType';

type ActionType = SetComparativeReportMetaActionType;

const comparativeReportMeta = (state: ComparativeReportMetaResponseType = {}, action: ActionType) => {
    switch (action.type) {
        case SET_COMPARATIVE_REPORT_META: {
            return { ...action.data };
        }
        default: {
            return state;
        }
    }
};

export default comparativeReportMeta;
