import { LocalizationsType } from './LocalizationsType';
import { LocalizationsActionTypeListEnum } from './actionsTypes';
import { LocalizationsActionType } from './actions';

export type LocalizationsStateType = {
    list: LocalizationsType;
    isLoading: boolean;
};

const languageInitialState: LocalizationsStateType = {
    list: [],
    isLoading: true,
};

const localizations = (state = languageInitialState, action: LocalizationsActionType) => {
    switch (action.type) {
        case LocalizationsActionTypeListEnum.SET_LOCALIZATIONS: {
            return {
                ...state,
                list: action.list,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default localizations;
