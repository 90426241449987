import { FrontendSettingsResponseType } from 'state/userFrontendSettings/FrontendSettingsResponseType';
import { ToolTypesEnum } from 'types/toolTypes';
import { normalize, schema } from 'normalizr';
import { EMPTY_FILTER_CONSIST_COLUMNS } from 'modules/apps/keywordAnalytics/keywordAnalyticsTable/hiddenColumnGroups';
import { normalizeAppReviewAutoTranslate } from 'state/userFrontendSettings/adapterAppReviewAutoTranslate';
import { KAColumnsIdEnum } from 'modules/apps/keywordAnalytics/keywordAnalyticsTable/KAColumnsIdEnum';
import { TagCategorySortingEnum } from 'modules/reviews/reviewTags/sortingDropdownMenu/sortingOptions';
import { isUndefined } from 'utils/typeGuard';
import {
    KeywordExplorerWidgetEnum,
    SidebarSectionsByIdsType,
    SidebarSectionsIdsType,
    UserFrontendSettingsInitialStateType,
} from './UserFrontendSettingsInitialStateType';
import { UserFrontendSettingsDefaultValues } from './UserFrontendSettingsDefaultValues';

type DefaultSidebarSectionIdsType = {
    defaultIds: SidebarSectionsIdsType;
    defaultByIds: SidebarSectionsByIdsType;
};

export const initialKeywordExplorerWidgetOrder = [
    KeywordExplorerWidgetEnum.ESTIMATED_INSTALLS,
    KeywordExplorerWidgetEnum.SUGGESTIONS,
    KeywordExplorerWidgetEnum.RELATED_SUGGESTIONS,
];

export const adapterFrontendSettings = (
    {
        isOpen = UserFrontendSettingsDefaultValues.isOpenSidebarOnDesktop,
        sections,
        isReplyBoardAutoNextReviewSet,
        isReplyBoardRandomTemplateSet,
        favorites = [],
        isKeywordCountryFlagShow = UserFrontendSettingsDefaultValues.isKeywordCountryFlagShow,
        keywordAnalyticsColumnTableOrder = [],
        keywordAnalyticsColumnTableInvisibility = EMPTY_FILTER_CONSIST_COLUMNS,
        appReviewAutoTranslate,
        showTrialExpiredPlan,
        showTrialExpiredTomorrowPlan,
        keywordExplorerWidgetOrder,
        tagsCategoriesSort,
        isShowWelcomeOfferModal,
        isGlobalAppLoaderShow,
        isNewNavigation,
        lastCurrentAppId,
        lastCurrentLocale,
    }: FrontendSettingsResponseType,
    { defaultIds, defaultByIds }: DefaultSidebarSectionIdsType
): Omit<UserFrontendSettingsInitialStateType, 'isOpenSidebarOnMobile'> => {
    const sectionSchema = [new schema.Entity('sectionsByIds')];

    const {
        entities: { sectionsByIds },
        result,
    } = normalize(sections, sectionSchema);

    const localIds = result;
    let localSectionsByIds = sectionsByIds;

    // In case new sections are added to the sidebar
    if (defaultIds.length !== localIds.length) {
        const newSections = defaultIds.filter((id: ToolTypesEnum) => !result.includes(id));

        localSectionsByIds = {
            ...sectionsByIds,
            ...newSections.reduce(
                (acc: SidebarSectionsByIdsType, id: ToolTypesEnum) => ({
                    ...acc,
                    [id]: defaultByIds[id],
                }),
                {}
            ),
        };
    }

    let adaptedKeywordAnalyticsColumnTableOrder = keywordAnalyticsColumnTableOrder;
    const userHasNoOrderForEstimateInstallKAColum = adaptedKeywordAnalyticsColumnTableOrder?.every(
        (id) => id !== KAColumnsIdEnum.ESTIMATED_INSTALLS
    );

    if (userHasNoOrderForEstimateInstallKAColum && adaptedKeywordAnalyticsColumnTableOrder?.length) {
        const DIFFICULTY_INDEX = adaptedKeywordAnalyticsColumnTableOrder.findIndex(
            (columnId) => columnId === KAColumnsIdEnum.DIFFICULTY
        );
        const DEFAULT_ESTIMATE_INSTALL_ORDER =
            DIFFICULTY_INDEX !== -1 ? DIFFICULTY_INDEX + 1 : adaptedKeywordAnalyticsColumnTableOrder.length - 1;
        adaptedKeywordAnalyticsColumnTableOrder = [
            ...adaptedKeywordAnalyticsColumnTableOrder.slice(0, DEFAULT_ESTIMATE_INSTALL_ORDER),
            KAColumnsIdEnum.ESTIMATED_INSTALLS,
            ...adaptedKeywordAnalyticsColumnTableOrder.slice(DEFAULT_ESTIMATE_INSTALL_ORDER),
        ];
    }

    return {
        isOpenSidebarOnDesktop: isOpen,
        isReplyBoardAutoNextReviewSet,
        isReplyBoardRandomTemplateSet,
        favoriteTools: favorites,
        sidebarSections: {
            ids: defaultIds,
            byIds: localSectionsByIds || {},
        },
        isKeywordCountryFlagShow,
        keywordAnalyticsColumnTableOrder: adaptedKeywordAnalyticsColumnTableOrder,
        keywordAnalyticsColumnTableInvisibility,
        appReviewAutoTranslate: normalizeAppReviewAutoTranslate(appReviewAutoTranslate),
        showTrialExpiredPlan: !isUndefined(showTrialExpiredPlan) ? showTrialExpiredPlan : true,
        showTrialExpiredTomorrowPlan: !isUndefined(showTrialExpiredTomorrowPlan) ? showTrialExpiredTomorrowPlan : true,
        isShowWelcomeOfferModal: !isUndefined(isShowWelcomeOfferModal) ? isShowWelcomeOfferModal : true,
        isGlobalAppLoaderShow: !isUndefined(isGlobalAppLoaderShow) ? isGlobalAppLoaderShow : false,
        keywordExplorerWidgetOrder: keywordExplorerWidgetOrder || initialKeywordExplorerWidgetOrder,
        tagsCategoriesSort: tagsCategoriesSort || TagCategorySortingEnum.OLDEST,
        isNewNavigation: !isUndefined(isNewNavigation) ? isNewNavigation : true,
        lastCurrentAppId,
        lastCurrentLocale: lastCurrentLocale?.toLowerCase(),
    };
};
