import { TabResponseType } from 'state/keywordAnalytics/AppDataStatsResponseType';
import { TabType } from 'state/keywordAnalytics/KeywordAnalyticsDataType';

export const keywordAnalyticsTabAdapter = ({ keyword_ids, is_disabled, name, id }: TabResponseType): TabType => ({
    keywordIds: keyword_ids,
    isDisabled: is_disabled,
    name,
    id,
});

export const keywordAnalyticsTabListAdapter = (tabList: TabResponseType[]) => tabList.map(keywordAnalyticsTabAdapter);
