import {
    SHOW_LOADING_APP_DATA_STATS,
    SET_APP_DATA_STATS,
    UPDATE_ROWS_APP_DATA_STATS,
    SET_DATA_STATS_TAB,
    SET_CUSTOM_TRAFFIC_SCORE,
    UPDATE_COMPLETED_ROWS_APP_DATA_STATS,
    UPDATE_TAB_APP_DATA_STATS,
    ADD_TAB_APP_DATA_STATS,
    REMOVE_KEYWORDS_FROM_TAB_APP_DATA_STATS,
    SET_KEYWORD_RANK_HISTORY,
    SHOW_KEYWORD_RANK_HISTORY_LOADING,
    REMOVE_KEYWORDS_FROM_ALL_TABS_APP_DATA_STATS,
    REMOVE_TAB_APP_DATA_STATS,
    ADD_KEYWORD_RANK_HISTORY,
    UPDATE_IDS_FAVORITE,
    RESET_APP_DATA_STATS,
    KeywordAnalyticsActionEnum,
} from 'state/keywordAnalytics/actionTypes';
import {
    SetAppStatisticsActionType,
    UpdateRowsAppStatisticsActionType,
    RowsAppStatisticsActionType,
    SetTabAppStatisticsActionType,
    UpdateCompletedRowsAppStatisticsActionType,
    UpdateTabAppStatisticsActionType,
    AddTabAppStatisticsActionType,
    RemoveKeywordsFromTabAppStatisticsActionType,
    RemoveTabAppStatisticsActionType,
    RemoveKeywordsFromAllTabsAppStatisticsActionType,
    SetCustomTrafficScoreAppStatisticsAction,
    SetRankHistoryActionType,
    ShowRankHistoryLoadingAction,
    UpdateIdsFavoriteActionType,
    SetSelectedCompetitorsIdsActionType,
} from 'state/keywordAnalytics/actions';
import { AppDataStatsStateType, AppStatisticsRowStateType } from 'state/keywordAnalytics/AppDataStatsStateType';
import { MAIN_TAB_NAME, MAIN_TAB_ID } from 'state/keywordAnalytics/KeeywordAnalyticMainTabName';

const initialKeywordAnalyticsState: AppDataStatsStateType = {
    data: [],
    tabs: [],
    allTabsData: [],
    pages: 0,
    has_available_keywords: false,
    recordsFiltered: 0,
    recordsTotal: 0,
    result: null,
    activeTab: 0,
    rankHistory: [],
    isRankHistoryLoading: null,
    idsFavorite: [],
    selectedCompetitorsIds: [],
    isMobileShowKeywordControllerOpen: false,
};

const keywordAnalytics = (state = initialKeywordAnalyticsState, action: RowsAppStatisticsActionType) => {
    switch (action.type) {
        case SET_APP_DATA_STATS: {
            const { data: responseData, storeId } = action as SetAppStatisticsActionType;
            const tabs = [
                {
                    id: 0,
                    name: MAIN_TAB_NAME,
                    keywordIds: responseData.data.map((row) => row.id),
                    isDisabled: false,
                },
                ...responseData.tabs,
            ];
            const idsFavorite: number[] = [];
            const allTabsData: AppStatisticsRowStateType[] = responseData.data.map((row) => {
                if (row.favorite) idsFavorite.push(row.id);
                return { ...row, show: true };
            });
            return {
                ...state,
                ...responseData,
                ...{
                    tabs,
                    data: allTabsData,
                    allTabsData,
                    activeTab: MAIN_TAB_ID,
                    idsFavorite,
                    loadedDataStoreId: storeId,
                },
            };
        }
        case SET_DATA_STATS_TAB: {
            const { tabId } = action as SetTabAppStatisticsActionType;
            const tab = state.tabs.find((stateTab) => stateTab.id === tabId);
            const data = tab ? state.allTabsData.filter((row) => tab.keywordIds.includes(row.id)) : state.data;

            return {
                ...state,
                ...{
                    data,
                    activeTab: tabId,
                },
            };
        }
        case UPDATE_COMPLETED_ROWS_APP_DATA_STATS: {
            const tableData = [...state.data];
            (action as UpdateCompletedRowsAppStatisticsActionType).rows.forEach((row) => {
                const indexRow = tableData.findIndex((tableRow) => tableRow.id === row.id);
                tableData[indexRow] = {
                    ...row,
                    show: tableData[indexRow].show,
                };
            });

            return {
                ...state,
                ...{
                    data: tableData,
                },
            };
        }
        case UPDATE_IDS_FAVORITE: {
            const activeId = (action as UpdateIdsFavoriteActionType).rowId;

            const activeIsFavorite = (action as UpdateIdsFavoriteActionType).isFavorite;

            const updatedIdsFavorite = activeIsFavorite
                ? [...state.idsFavorite, activeId]
                : [...state.idsFavorite].filter((id) => id !== activeId);

            const updateByFavorites = (row: AppStatisticsRowStateType) => {
                if (activeId === row.id) {
                    return {
                        ...row,
                        favorite: activeIsFavorite,
                    };
                }

                return row;
            };

            return {
                ...state,
                data: state.data.map(updateByFavorites),
                allTabsData: state.allTabsData.map(updateByFavorites),
                idsFavorite: updatedIdsFavorite,
            };
        }
        case UPDATE_ROWS_APP_DATA_STATS: {
            const { rowIds, key, value } = action as UpdateRowsAppStatisticsActionType;
            const stateCopy = [...state.data];
            const data = stateCopy.map((row) => {
                if (rowIds.indexOf(row.id) > -1) {
                    (row[key] as AppStatisticsRowStateType[keyof AppStatisticsRowStateType]) = value;
                }
                return row;
            });

            return {
                ...state,
                ...{
                    data,
                },
            };
        }
        case UPDATE_TAB_APP_DATA_STATS: {
            const { tab } = action as UpdateTabAppStatisticsActionType;

            return {
                ...state,
                ...{
                    tabs: state.tabs.map((stateTab) => (stateTab.id === tab.id ? { ...stateTab, ...tab } : stateTab)),
                },
            };
        }
        case REMOVE_KEYWORDS_FROM_TAB_APP_DATA_STATS: {
            const { tabId, keywordIds } = action as RemoveKeywordsFromTabAppStatisticsActionType;
            const tabs = [...state.tabs];
            const updatedData = tabs.find((dataTab) => dataTab.id === tabId);
            if (updatedData) {
                updatedData.keywordIds = updatedData.keywordIds.filter((id) => keywordIds.indexOf(id) < 0);
            }

            return {
                ...state,
                ...{
                    tabs,
                },
            };
        }
        case REMOVE_KEYWORDS_FROM_ALL_TABS_APP_DATA_STATS: {
            const { keywordIds } = action as RemoveKeywordsFromAllTabsAppStatisticsActionType;
            const tabs = [...state.tabs];
            tabs.forEach((tab) => {
                tab.keywordIds = tab.keywordIds.filter((id) => keywordIds.indexOf(id) < 0);
            });

            return {
                ...state,
                ...{
                    tabs,
                },
                data: state.data.filter((keyword) => keywordIds.indexOf(keyword.id) === -1),
            };
        }
        case ADD_TAB_APP_DATA_STATS: {
            const { tab } = action as AddTabAppStatisticsActionType;
            const tabs = [...state.tabs, tab];

            return {
                ...state,
                ...{
                    tabs,
                },
            };
        }
        case REMOVE_TAB_APP_DATA_STATS: {
            const { tabId } = action as RemoveTabAppStatisticsActionType;

            return {
                ...state,
                ...{
                    tabs: state.tabs.filter((tabInfo) => tabInfo.id !== tabId),
                },
            };
        }
        case SHOW_LOADING_APP_DATA_STATS: {
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        }
        case SET_CUSTOM_TRAFFIC_SCORE: {
            const { rowId, customTrafficScore } = action as SetCustomTrafficScoreAppStatisticsAction;
            const updatedData = state.data.map((row) => {
                if (rowId === row.id) {
                    row.custom_traffic_score = customTrafficScore.length ? parseInt(customTrafficScore) : null;
                }
                return row;
            });

            return {
                ...state,
                ...{
                    data: [...updatedData],
                },
            };
        }
        case SET_KEYWORD_RANK_HISTORY: {
            const { rankHistory } = action as SetRankHistoryActionType;

            return {
                ...state,
                ...{
                    rankHistory,
                    isRankHistoryLoading: null,
                },
            };
        }
        case ADD_KEYWORD_RANK_HISTORY: {
            const { rankHistory } = action as SetRankHistoryActionType;

            return {
                ...state,
                ...{
                    rankHistory: [...state.rankHistory, ...rankHistory],
                    isRankHistoryLoading: null,
                },
            };
        }
        case SHOW_KEYWORD_RANK_HISTORY_LOADING: {
            const { showLoading } = action as ShowRankHistoryLoadingAction;

            return {
                ...state,
                ...{
                    isRankHistoryLoading: showLoading,
                },
            };
        }
        case RESET_APP_DATA_STATS: {
            return initialKeywordAnalyticsState;
        }
        case KeywordAnalyticsActionEnum.SET_SELECTED_COMPETITORS: {
            return {
                ...state,
                selectedCompetitorsIds: (action as SetSelectedCompetitorsIdsActionType).keywordIds,
            };
        }
        case KeywordAnalyticsActionEnum.TOGGLE_MOBILE_SHOW_KEYWORD_CONTROLLER: {
            return {
                ...state,
                isMobileShowKeywordControllerOpen: !state.isMobileShowKeywordControllerOpen,
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordAnalytics;
