import { PlanFeatureResponseType } from 'state/plans/PlansResponseType';
import { isArray } from 'utils/typeGuard';

export const featuresAdapter = (features: PlanFeatureResponseType[] | PlanFeatureResponseType): PlanFeatureResponseType[] => {
    if (isArray(features)) return features;

    return [features];
};

export const getToolsFromFeaturesAndPlans = (features: PlanFeatureResponseType[]): string[] => {
    const tools = features
        .filter(({ title }) => title !== 'Limits' && !title?.includes('plus'))
        .map(({ values }) => values?.map(({ name }) => name))
        .flat() as string[];

    return tools;
};
