import { DateType } from 'utils/helpers/date/date';
import { defaultDateRange } from 'constants/dateConstants';
import { DeviceTypeEnum } from 'state/appProfile/AppResponseType';
import { KeywordAnalyzerActionType } from './actions';
import { KeywordAnalyzerActionTypeListEnum } from './actionsTypes';
import { DirectionTypeEnum } from './keywordAnalyzerTypes';

export type KeywordAnalyzerStateType = {
    startDate: DateType;
    endDate: DateType;
    countries: string[];
    isShowTranslate: boolean;
    isOnlyFeatured: boolean;
    deviceType: DeviceTypeEnum;
    directionType: DirectionTypeEnum;
};

const KeywordAnalyzerInitialState: KeywordAnalyzerStateType = {
    ...defaultDateRange,
    countries: [],
    isShowTranslate: true,
    isOnlyFeatured: false,
    deviceType: DeviceTypeEnum.IPHONE,
    directionType: DirectionTypeEnum.VERTICAL,
};

const keywordAnalyzer = (state = KeywordAnalyzerInitialState, action: KeywordAnalyzerActionType) => {
    switch (action.type) {
        case KeywordAnalyzerActionTypeListEnum.CHANGE_FILTER_DATE: {
            return {
                ...state,
                ...action.datePicker,
            };
        }
        case KeywordAnalyzerActionTypeListEnum.CHANGE_FILTER_COUNTRY: {
            return {
                ...state,
                countries: action.countries,
            };
        }
        case KeywordAnalyzerActionTypeListEnum.TOGGLE_TRANSLATE_KEYWORDS: {
            return {
                ...state,
                isShowTranslate: action.isShowTranslate,
            };
        }
        case KeywordAnalyzerActionTypeListEnum.CHANGE_FILTER_DEVICE: {
            return {
                ...state,
                deviceType: action.deviceType,
            };
        }
        case KeywordAnalyzerActionTypeListEnum.TOGGLE_ONLY_FEATURED: {
            return {
                ...state,
                isOnlyFeatured: action.isOnlyFeatured,
            };
        }
        case KeywordAnalyzerActionTypeListEnum.CHANGE_FILTER_DIRECTION: {
            return {
                ...state,
                directionType: action.directionType,
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordAnalyzer;
