import {
    OptimizerMetaType,
    OptimizerStateType,
    OptimizerTabIdsEnum,
    ReportType,
    TabReportType,
} from './optimizerTypes';

export const MetaInitialState: OptimizerMetaType = {
    title: '',
    subtitle: '',
    keywords: '',
};

const TabsReportInitialState: TabReportType = {};

export const UsedReportInitialState: ReportType = {
    tabs: {
        [OptimizerTabIdsEnum.KEYWORDS_REPORT]: TabsReportInitialState,
        [OptimizerTabIdsEnum.SEARCH_QUERIES_REPORT]: TabsReportInitialState,
        [OptimizerTabIdsEnum.SEMANTIC_REPORT]: TabsReportInitialState,
    },
    activeTabId: OptimizerTabIdsEnum.KEYWORDS_REPORT,
};

export const UnusedReportInitialState: ReportType = {
    tabs: {
        [OptimizerTabIdsEnum.KEYWORDS_REPORT]: TabsReportInitialState,
        [OptimizerTabIdsEnum.SEARCH_QUERIES_REPORT]: TabsReportInitialState,
        [OptimizerTabIdsEnum.SEMANTIC_REPORT]: TabsReportInitialState,
    },
    activeTabId: OptimizerTabIdsEnum.KEYWORDS_REPORT,
};

export const OptimizerInitialState: OptimizerStateType = {
    isAscConnected: false,
    localesInfo: {
        main: {
            lang: '',
            name: '',
        },
        additional: [],
    },
    sets: [],
    initialSet: {
        meta: [],
    },
    currentSet: {
        meta: [],
    },
    tabs: [],
    stopWords: [],
    metaReport: {
        tabId: null,
        meta: [],
    },
    summaryReport: {},
    reports: {
        used: UsedReportInitialState,
        unused: UnusedReportInitialState,
    },
};
