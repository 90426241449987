export const POPULAR_TAG_SORTING_TYPE = 'popular';
export const CRITICAL_TAG_SORTING_TYPE = 'critical';
export const NEW_TAG_SORTING_TYPE = 'newTags';
export const TRENDING_UP_TAG_SORTING_TYPE = 'trendingUp';
export const TRENDING_DOWN_TAG_SORTING_TYPE = 'trendingDown';

export type TagAnalysisSortingType =
    | typeof POPULAR_TAG_SORTING_TYPE
    | typeof CRITICAL_TAG_SORTING_TYPE
    | typeof NEW_TAG_SORTING_TYPE
    | typeof TRENDING_UP_TAG_SORTING_TYPE
    | typeof TRENDING_DOWN_TAG_SORTING_TYPE;
