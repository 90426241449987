import { useSystem } from 'hooks/state/useSystem';
import {
    accountRouteBillingUrl,
    accountRouteLogUrl,
    accountRouteNotificationsUrl,
    accountRoutePricingUrl,
    accountRouteTeammatesUrl,
    accountRouteUrl,
    connectionsHubRouteApiUrl,
    connectionsHubRouteIntegrationsUrl,
    connectionsHubRouteSettingsUrl,
    connectionsHubRouteTimelineUrl,
    getAsoToolsRouteAppProfileUrl,
    asoToolsRouteAppStoreLocalizationsUrl,
    getAsoToolsRouteCategoriesRankingUrl,
    getAsoToolsRouteComparativeReportUrl,
    getAsoToolsRouteDescriptionCreatorUrl,
    getAsoToolsRouteFavoriteKeywordsUrl,
    getAsoToolsRouteFindAndTrackUrl,
    getAsoToolsRouteKeywordAutoSuggestionsUrl,
    getAsoToolsRouteKeywordChartsUrl,
    getAsoToolsRouteKeywordCompetitorsUrl,
    getAsoToolsRouteKeywordHighlightsUrl,
    getAsoToolsRouteKeywordLivePositionsUrl,
    getAsoToolsRouteKeywordPhraseMixerUrl,
    getAsoToolsRouteNoLocaleUrl,
    getAsoToolsRouteOrganicReportUrl,
    getAsoToolsRouteTextMetadataBuilderUrl,
    getAsoToolsRouteTrafficSourcesUrl,
    getKeywordBoostRouteByCampaignIdUrl,
    getKeywordBoostRouteUrl,
    getReviewsRouteAnalysisUrl,
    getReviewsRouteAutoRepliesUrl,
    getReviewsRouteAutoTagsUrl,
    getReviewsRouteBoardUrl,
    getReviewsRouteFeaturedUrl,
    getReviewsRouteRatingAnalysisUrl,
    getReviewsRouteTagAnalysisUrl,
    getReviewsRouteTagsUrl,
    getReviewsRouteTemplatesUrl,
    myAppsRouteUrl,
    referralProgramRouteUrl,
    storesAnalyticsRouteAppStoreTrendingSearchesUrl,
    storesAnalyticsRouteCvrBenchmarkUrl,
    storesAnalyticsRouteSearchExplorerUrl,
    storesAnalyticsRouteTopChartsUrl,
    storesAnalyticsRouteTopKeywordsUrl,
} from 'utils/routeUrls';
import { stringifyUrl } from 'utils/helpers/queryString';
import { PageContentEnum } from 'modules/settings/billingHistory/billingHistoryTypes';
import { AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { useAppSelector } from 'hooks/useAppSystem';

type UseRoutesUrlType = {
    currentLocale?: string;
    currentAppId?: string;
    currentKeyword?: string;
    currentAppCategoryId?: string;
    currentReviewId?: string;
    currentDate?: string;
    currentCampaignId?: string;
    currentBillingTabId?: PageContentEnum;
    currentPlanFeature?: keyof AvailableFeaturesType;
    currentCountries?: string;
    currentLanguages?: string;
};

export const ASODESK_DEMO_APP_STORE_ID = '301521403';
const ASODESK_DEMO_APP_COUNTRY = 'us';

export const useRoutesUrl = (routesParams?: UseRoutesUrlType) => {
    const {
        currentLocale,
        currentAppId,
        currentKeyword,
        currentAppCategoryId,
        currentReviewId,
        currentDate,
        currentCampaignId,
        currentBillingTabId,
        currentPlanFeature,
        currentCountries,
        currentLanguages,
    } = routesParams || {};

    const { userCountry } = useRequestInfoService();

    const lastCurrentAppId = useAppSelector((state) => state.userFrontendSettings.lastCurrentAppId);
    const lastCurrentLocale = useAppSelector((state) => state.userFrontendSettings.lastCurrentLocale);

    const { locale, appId } = useSystem();

    const { isLogged } = useAppSelector((state) => state.user);

    const getDefaultLocale = () => {
        const computedLocale = (currentLocale || locale || lastCurrentLocale || userCountry || '').toLowerCase();

        if (!isLogged) return computedLocale || ASODESK_DEMO_APP_COUNTRY;

        return computedLocale;
    };

    const getDefaultAppId = () => {
        const computedAppId = currentAppId || appId || lastCurrentAppId;

        if (!isLogged) return computedAppId || ASODESK_DEMO_APP_STORE_ID;

        return computedAppId;
    };

    const defaultLocale = getDefaultLocale();
    const defaultAppId = getDefaultAppId();

    const defaultKeyword = currentKeyword;
    const defaultAppCategoryId = currentAppCategoryId;
    const defaultReviewId = currentReviewId;
    const defaultDate = currentDate;
    const defaultCampaignId = currentCampaignId;
    const defaultBillingTabId = currentBillingTabId;
    const defaultPlanFeature = currentPlanFeature;
    const defaultCountries = currentCountries;
    const defaultLanguages = currentLanguages;

    const myAppsUrl = stringifyUrl({ url: myAppsRouteUrl, query: { locale: defaultLocale } });

    const asoToolsAppProfileUrl = stringifyUrl({
        url: getAsoToolsRouteAppProfileUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const getAsoToolsAppProfileUrl = (changedAppId = defaultAppId, changedLocale = defaultLocale) =>
        stringifyUrl({
            url: getAsoToolsRouteAppProfileUrl(changedAppId),
            query: { locale: changedLocale },
        });
    const asoToolsComparativeReportUrl = stringifyUrl({
        url: getAsoToolsRouteComparativeReportUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsTrafficSourcesUrl = stringifyUrl({
        url: getAsoToolsRouteTrafficSourcesUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsKeywordHighlightsUrl = stringifyUrl({
        url: getAsoToolsRouteKeywordHighlightsUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsFavoriteKeywordsUrl = stringifyUrl({
        url: getAsoToolsRouteFavoriteKeywordsUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsCategoriesRankingUrl = stringifyUrl({
        url: getAsoToolsRouteCategoriesRankingUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsOrganicReportUrl = stringifyUrl({
        url: getAsoToolsRouteOrganicReportUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsFindAndTrackUrl = stringifyUrl({
        url: getAsoToolsRouteFindAndTrackUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsKeywordChartsUrl = stringifyUrl({
        url: getAsoToolsRouteKeywordChartsUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsKeywordCompetitorsUrl = stringifyUrl({
        url: getAsoToolsRouteKeywordCompetitorsUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsKeywordPhraseMixerUrl = stringifyUrl({
        url: getAsoToolsRouteKeywordPhraseMixerUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsKeywordLivePositionsUrl = stringifyUrl({
        url: getAsoToolsRouteKeywordLivePositionsUrl(defaultAppId),
        query: { locale: defaultLocale, keyword: defaultKeyword },
    });
    const asoToolsKeywordAutoSuggestionsUrl = stringifyUrl({
        url: getAsoToolsRouteKeywordAutoSuggestionsUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsDescriptionCreatorUrl = stringifyUrl({
        url: getAsoToolsRouteDescriptionCreatorUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsTextMetadataBuilderUrl = stringifyUrl({
        url: getAsoToolsRouteTextMetadataBuilderUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const asoToolsAppStoreLocalizationsUrl = asoToolsRouteAppStoreLocalizationsUrl;
    const asoToolsNoLocaleUrl = stringifyUrl({
        url: getAsoToolsRouteNoLocaleUrl(defaultAppId),
        query: { locale: defaultLocale },
    });

    const storesAnalyticsSearchExplorerUrl = stringifyUrl({
        url: storesAnalyticsRouteSearchExplorerUrl,
        query: { locale: defaultLocale, keyword: defaultKeyword },
    });
    const storesAnalyticsTopChartsUrl = stringifyUrl({
        url: storesAnalyticsRouteTopChartsUrl,
        query: { locale: defaultLocale, id: defaultAppCategoryId },
    });
    const storesAnalyticsCvrBenchmarkUrl = stringifyUrl({
        url: storesAnalyticsRouteCvrBenchmarkUrl,
        query: { locale: defaultLocale },
    });
    const storesAnalyticsTopKeywordsUrl = stringifyUrl({
        url: storesAnalyticsRouteTopKeywordsUrl,
        query: { locale: defaultLocale },
    });
    const storesAnalyticsAppStoreTrendingSearchesUrl = stringifyUrl({
        url: storesAnalyticsRouteAppStoreTrendingSearchesUrl,
        query: { locale: defaultLocale },
    });

    const reviewsBoardUrl = stringifyUrl({
        url: getReviewsRouteBoardUrl(defaultAppId),
        query: {
            locale,
            review_id: defaultReviewId,
            date: defaultDate,
            reviewsLanguage: defaultLanguages,
            countries: defaultCountries,
        },
    });
    const reviewsFeaturedUrl = stringifyUrl({
        url: getReviewsRouteFeaturedUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const reviewsAnalysisUrl = stringifyUrl({
        url: getReviewsRouteAnalysisUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const reviewsTagAnalysisUrl = stringifyUrl({
        url: getReviewsRouteTagAnalysisUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const reviewsRatingAnalysisUrl = stringifyUrl({
        url: getReviewsRouteRatingAnalysisUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const reviewsTemplatesUrl = stringifyUrl({
        url: getReviewsRouteTemplatesUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const reviewsAutoRepliesUrl = stringifyUrl({
        url: getReviewsRouteAutoRepliesUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const reviewsTagsUrl = stringifyUrl({
        url: getReviewsRouteTagsUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const reviewsAutoTagsUrl = stringifyUrl({
        url: getReviewsRouteAutoTagsUrl(defaultAppId),
        query: { locale: defaultLocale },
    });

    const connectionsHubSettingsUrl = connectionsHubRouteSettingsUrl;
    const connectionsHubTimelineUrl = connectionsHubRouteTimelineUrl;
    const connectionsHubIntegrationsUrl = stringifyUrl({
        url: connectionsHubRouteIntegrationsUrl,
        query: { locale: defaultLocale },
    });
    const connectionsHubApiUrl = connectionsHubRouteApiUrl;

    const keywordBoostUrl = stringifyUrl({
        url: getKeywordBoostRouteUrl(defaultAppId),
        query: { locale: defaultLocale },
    });
    const keywordBoostByCampaignIdUrl = stringifyUrl({
        url: getKeywordBoostRouteByCampaignIdUrl(defaultAppId, defaultCampaignId),
        query: { locale: defaultLocale },
    });

    const referralProgramUrl = referralProgramRouteUrl;

    const accountUrl = accountRouteUrl;
    const accountTeammatesUrl = accountRouteTeammatesUrl;
    const accountBillingUrl = stringifyUrl({
        url: accountRouteBillingUrl,
        query: { billingTabId: defaultBillingTabId?.toString() },
    });
    const accountPricingUrl = stringifyUrl({
        url: accountRoutePricingUrl,
        query: { feature: defaultPlanFeature?.toString() },
    });
    const accountLogUrl = accountRouteLogUrl;
    const accountNotificationsUrl = accountRouteNotificationsUrl;

    return {
        myAppsUrl,
        asoToolsAppProfileUrl,
        getAsoToolsAppProfileUrl,
        asoToolsComparativeReportUrl,
        asoToolsTrafficSourcesUrl,
        asoToolsKeywordHighlightsUrl,
        asoToolsFavoriteKeywordsUrl,
        asoToolsCategoriesRankingUrl,
        asoToolsOrganicReportUrl,
        asoToolsFindAndTrackUrl,
        asoToolsKeywordChartsUrl,
        asoToolsKeywordCompetitorsUrl,
        asoToolsKeywordPhraseMixerUrl,
        asoToolsKeywordLivePositionsUrl,
        asoToolsKeywordAutoSuggestionsUrl,
        asoToolsDescriptionCreatorUrl,
        asoToolsTextMetadataBuilderUrl,
        asoToolsAppStoreLocalizationsUrl,
        asoToolsNoLocaleUrl,
        storesAnalyticsSearchExplorerUrl,
        storesAnalyticsTopChartsUrl,
        storesAnalyticsCvrBenchmarkUrl,
        storesAnalyticsTopKeywordsUrl,
        storesAnalyticsAppStoreTrendingSearchesUrl,
        reviewsBoardUrl,
        reviewsFeaturedUrl,
        reviewsAnalysisUrl,
        reviewsTagAnalysisUrl,
        reviewsRatingAnalysisUrl,
        reviewsTemplatesUrl,
        reviewsAutoRepliesUrl,
        reviewsTagsUrl,
        reviewsAutoTagsUrl,
        connectionsHubSettingsUrl,
        connectionsHubTimelineUrl,
        connectionsHubIntegrationsUrl,
        connectionsHubApiUrl,
        keywordBoostUrl,
        keywordBoostByCampaignIdUrl,
        referralProgramUrl,
        accountUrl,
        accountTeammatesUrl,
        accountBillingUrl,
        accountPricingUrl,
        accountLogUrl,
        accountNotificationsUrl,
    };
};
