import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentAppProfile, getSilentCurrentAppProfile } from 'state/appProfile/controller';
import { useAppProfileService } from 'hooks/data/useAppProfileService';
import { BaseSystemType } from 'state/system/systemTypes';

export const useAppProfileLoadMethods = () => {
    const dispatch = useDispatch();
    const [isLoadingAppProfile, setIsLoadingAppProfile] = useState(false);
    const { locale } = useAppProfileService();

    const loadAppProfile = (country: string, appId: string) => {
        dispatch(getCurrentAppProfile({ country, appId }));
    };

    const loadSilentAppProfile = (params: BaseSystemType) => {
        try {
            setIsLoadingAppProfile(true);
            dispatch(getSilentCurrentAppProfile({ locale: params.locale?.toLowerCase(), appId: params.appId }));
        } finally {
            setIsLoadingAppProfile(false);
        }
    };

    return {
        loadAppProfile,
        loadSilentAppProfile,
        isLoadingAppProfile,
        locale,
    };
};
