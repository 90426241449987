import { useNavigate } from 'react-router-dom';
import { authRouteLoginUrl } from 'utils/routeUrls';
import { useAppSelector } from 'hooks/useAppSystem';

export const useAuth = () => {
    const { isLogged } = useAppSelector((state) => state.user);

    const navigate = useNavigate();

    const onCheckClickAuth = (callback = () => {}) => {
        if (isLogged) {
            callback();
        } else {
            navigate(authRouteLoginUrl);
        }
    };

    return {
        onCheckClickAuth,
        isLogged,
    };
};
