import { Action } from 'redux';
import { OnboardingActionTypeEnum } from 'state/onboarding/actionTypes';
import { OnboardingStateType, OnboardingStepEnum } from './types';

interface SetCurrentStepActionType extends Action {
    type: OnboardingActionTypeEnum.SET_ONBOARDING_CURRENT_STEP;
    currentStep: OnboardingStepEnum;
}

interface SetReadyTourActionType extends Action {
    type: OnboardingActionTypeEnum.SET_ONBOARDING_READY_TOUR;
    isReadyTour: boolean;
}

interface SetOnboardingDataActionType extends Action {
    type: OnboardingActionTypeEnum.SET_ONBOARDING_DATA;
    data: OnboardingStateType;
}

export type OnboardingActionType = SetCurrentStepActionType | SetReadyTourActionType | SetOnboardingDataActionType;

export const setCurrentStepAction = (currentStep: OnboardingStepEnum): SetCurrentStepActionType => ({
    type: OnboardingActionTypeEnum.SET_ONBOARDING_CURRENT_STEP,
    currentStep,
});

export const setReadyTourAction = (isReadyTour: boolean): SetReadyTourActionType => ({
    type: OnboardingActionTypeEnum.SET_ONBOARDING_READY_TOUR,
    isReadyTour,
});

export const setOnboardingDataAction = (data: OnboardingStateType): SetOnboardingDataActionType => ({
    type: OnboardingActionTypeEnum.SET_ONBOARDING_DATA,
    data,
});
