import compose from 'compose-function';
import { withThemeProvider } from 'hoc/withThemeProvider';
import { withReduxProvider } from 'hoc/withReduxProvider';
import { withHubSpotProvider } from 'hoc/withHubSpotProvider';
import { withErrorBoundaryProvider } from 'hoc/withErrorBoundaryProvider';
import { withProductTour } from 'hoc/withProductTour';

export const withProviders = compose(
    withThemeProvider,
    withReduxProvider,
    withHubSpotProvider,
    withProductTour,
    withErrorBoundaryProvider
);
