import { PlansDowngradeMetaType } from 'state/plans/plansDowngradeMeta/PlansDowngradeMetaType';
import { PlansDowngradeMetaResponseType } from 'state/plans/plansDowngradeMeta/PlansDowngradeMetaResponseType';

export const plansDowngradeMetaAdapter = (planMeta: PlansDowngradeMetaResponseType): PlansDowngradeMetaType =>
    Object.keys(planMeta).reduce((result, planId) => {
        const plan = planMeta[+planId];

        return {
            ...result,
            [planId]: {
                apps: plan.apps.map(({ app_name, developer, logo }) => ({
                    name: app_name,
                    meta: developer,
                    logo,
                })),
                keywords: plan.keywords.map((keyword) => ({ name: keyword })),
                teammates: plan.teammates.map(({ name, email }) => ({
                    name,
                    meta: email,
                })),
            },
        };
    }, {});
