import React from 'react';
import { AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import { UpgradePlanText } from 'modules/componentsWithFeaturePermissionsPopup/upgradePlanTooltip/UpgradePlanTooltip';

type PropsType = {
    feature?: keyof AvailableFeaturesType;
    additionText?: string;
};

const PermissionTextComponent: React.FC<PropsType> = ({ feature, additionText }: PropsType) => {
    return feature ? <UpgradePlanText afterText={additionText} feature={feature} /> : null;
};

export const PermissionText = React.memo(PermissionTextComponent);
