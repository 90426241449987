export enum StoreConsoleApplicationsActionTypeEnum {
    RECEIVED_APP_OVERVIEW = 'Received App Overview',
    RECEIVED_VERSIONS = 'Recevied Versions',
    RECEIVED_APP_LOCALES = 'Received App Locales',
    RECEIVED_APP_FIELDS = 'Received App Fields',
    RECEIVED_QUICK_FIELDS = 'Received Quick Fields',
    RECEIVED_PRE_SUBMIT = 'Received Pre Submit',
    RECEIVED_KEYWORDS_SCORE = 'Received Keywords Score',
    RECEVIED_AUTOSAVETIME = 'Received Metadata Autosavetime',
    UPDATE_PRE_SUBMIT = 'UPDATE_PRE_SUBMIT',
    RECEIVED_APP_PROFILE = 'Received Application Profile (ASODESK)',
}
