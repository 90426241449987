export enum ReviewTemplatesActionTypeListEnum {
    SET_TEMPLATES = 'SET_TEMPLATES',
    IS_LOADING_TEMPLATES = 'IS_LOADING_TEMPLATES',
    CREATE_TEMPLATE = 'CREATE_TEMPLATE',
    REMOVE_TEMPLATE = 'REMOVE_TEMPLATE',
    UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
    CREATE_FOLDER = 'CREATE_FOLDER',
    REMOVE_FOLDER = 'REMOVE_FOLDER',
    UPDATE_FOLDER = 'UPDATE_FOLDER',
    COPY_TEMPLATE = 'COPY_TEMPLATE',
    SELECT_DIRECTORY = 'SELECT_DIRECTORY',
}
