import { AsodeskHeaderActionTypeEnum } from './actionsTypes';
import { AsodeskHeaderActionsType } from './actions';
import { AsodeskHeaderStateType } from './AsodeskHeaderStateType';

const initialState: AsodeskHeaderStateType = {
    countries: [],
    avatar: '',
    isHiddenLocaleDropdown: false,
};

const asodeskHeader = (state = initialState, action: AsodeskHeaderActionsType) => {
    switch (action.type) {
        case AsodeskHeaderActionTypeEnum.SET_HEADER_INFO: {
            return { ...state, ...action };
        }
        case AsodeskHeaderActionTypeEnum.SET_IS_HIDDEN_HEADER_LOCALE_DROPDOWN: {
            return {
                ...state,
                isHiddenLocaleDropdown: action.isHide,
            };
        }
        case AsodeskHeaderActionTypeEnum.UPDATE_CURRENT_COUNTRY_COUNTER: {
            return {
                ...state,
                countries: state.countries.map((country) => {
                    if (country.code === action.countryCode) {
                        return {
                            ...country,
                            count: action.count,
                        };
                    }

                    return country;
                }),
            };
        }
        default: {
            return state;
        }
    }
};

export default asodeskHeader;
