import React, { FC } from 'react';
import { Navigate, Route, useParams, useLocation } from 'react-router-dom';
import {
    getKeywordBoostRouteByCampaignIdUrl,
    getKeywordBoostRouteUrl,
    getOldAsoToolsRouteAppProfileUrl,
    getOldAsoToolsRouteAppStoreLocalizationsUrl,
    getOldAsoToolsRouteBaseAppProfileUrl,
    getOldAsoToolsRouteCategoriesRankingUrl,
    getOldAsoToolsRouteComparativeReportUrl,
    getOldAsoToolsRouteDescriptionCreatorUrl,
    getOldAsoToolsRouteFavoriteKeywordsUrl,
    getOldAsoToolsRouteFindAndTrackUrl,
    getOldAsoToolsRouteKeywordAutoSuggestionsUrl,
    getOldAsoToolsRouteKeywordChartsUrl,
    getOldAsoToolsRouteKeywordCompetitorsUrl,
    getOldAsoToolsRouteKeywordHighlightsUrl,
    getOldAsoToolsRouteKeywordLivePositionsUrl,
    getOldAsoToolsRouteKeywordPhraseMixerUrl,
    getOldAsoToolsRouteNoLocaleUrl,
    getOldAsoToolsRouteOrganicReportUrl,
    getOldAsoToolsRouteTextMetadataBuilderUrl,
    getOldAsoToolsRouteTrafficSourcesUrl,
    getOldConnectionsHubRouteAllReportsSettingsUrl,
    getOldConnectionsHubRouteApiUrl,
    getOldConnectionsHubRouteIntegrationsUrl,
    getOldMyAppsRouteUrl,
    getOldReferralProgramRouteUrl,
    getOldReviewsRouteAnalysisUrl,
    getOldReviewsRouteAutoRepliesUrl,
    getOldReviewsRouteAutoTagsUrl,
    getOldReviewsRouteBoardUrl,
    getOldReviewsRouteFeaturedUrl,
    getOldReviewsRouteRatingAnalysisUrl,
    getOldReviewsRouteTagAnalysisUrl,
    getOldReviewsRouteTagsUrl,
    getOldReviewsRouteTemplatesUrl,
    getOldStoresAnalyticsRouteAppStoreTrendingSearchesUrl,
    getOldStoresAnalyticsRouteCvrBenchmarkUrl,
    getOldStoresAnalyticsRouteSearchExplorerUrl,
    getOldStoresAnalyticsRouteTopChartsUrl,
    getOldStoresAnalyticsRouteTopKeywordsUrl,
} from 'utils/oldRouteUrls';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { parseQueryString } from 'utils/helpers/queryString';
import { DEFAULT_COUNTRY } from 'constants/localizationsConstants';
import { isDevelopmentMode } from 'constants/isDevelopmentMode';
import { FaroRoutes } from '@grafana/faro-react';

const useRedirectRoutes = () => {
    const { search } = useLocation();

    const {
        country: countryParam,
        store_id: appIdParam,
        keyword: keywordParam,
        date: dateParam,
        review_id: reviewIdParam,
        reviewsLanguage: reviewsLanguageParam,
        countries: countriesParam,
    }: {
        country?: string;
        store_id?: string;
        keyword?: string;
        date?: string;
        review_id?: string;
        reviewsLanguage?: string;
        countries?: string;
    } = parseQueryString(search);

    const { country, appId, campaignId } = useParams();

    const {
        myAppsUrl,
        asoToolsAppProfileUrl,
        asoToolsComparativeReportUrl,
        asoToolsTrafficSourcesUrl,
        asoToolsKeywordHighlightsUrl,
        asoToolsFavoriteKeywordsUrl,
        asoToolsCategoriesRankingUrl,
        asoToolsOrganicReportUrl,
        asoToolsFindAndTrackUrl,
        asoToolsKeywordChartsUrl,
        asoToolsKeywordCompetitorsUrl,
        asoToolsKeywordPhraseMixerUrl,
        asoToolsKeywordLivePositionsUrl,
        asoToolsKeywordAutoSuggestionsUrl,
        asoToolsDescriptionCreatorUrl,
        asoToolsTextMetadataBuilderUrl,
        asoToolsAppStoreLocalizationsUrl,
        asoToolsNoLocaleUrl,
        storesAnalyticsSearchExplorerUrl,
        storesAnalyticsTopChartsUrl,
        storesAnalyticsCvrBenchmarkUrl,
        storesAnalyticsTopKeywordsUrl,
        storesAnalyticsAppStoreTrendingSearchesUrl,
        reviewsBoardUrl,
        reviewsFeaturedUrl,
        reviewsAnalysisUrl,
        reviewsTagAnalysisUrl,
        reviewsRatingAnalysisUrl,
        reviewsTemplatesUrl,
        reviewsAutoRepliesUrl,
        reviewsTagsUrl,
        reviewsAutoTagsUrl,
        connectionsHubIntegrationsUrl,
        connectionsHubApiUrl,
        keywordBoostUrl,
        keywordBoostByCampaignIdUrl,
        referralProgramUrl,
        connectionsHubSettingsUrl,
    } = useRoutesUrl({
        currentLocale: countryParam || country || DEFAULT_COUNTRY,
        currentAppId: appIdParam || appId,
        currentKeyword: keywordParam,
        currentCampaignId: campaignId,
        currentDate: dateParam,
        currentReviewId: reviewIdParam,
        currentCountries: countriesParam,
        currentLanguages: reviewsLanguageParam,
    });

    return {
        myAppsUrl,
        asoToolsAppProfileUrl,
        asoToolsComparativeReportUrl,
        asoToolsTrafficSourcesUrl,
        asoToolsKeywordHighlightsUrl,
        asoToolsFavoriteKeywordsUrl,
        asoToolsCategoriesRankingUrl,
        asoToolsOrganicReportUrl,
        asoToolsFindAndTrackUrl,
        asoToolsKeywordChartsUrl,
        asoToolsKeywordCompetitorsUrl,
        asoToolsKeywordPhraseMixerUrl,
        asoToolsKeywordLivePositionsUrl,
        asoToolsKeywordAutoSuggestionsUrl,
        asoToolsDescriptionCreatorUrl,
        asoToolsTextMetadataBuilderUrl,
        asoToolsAppStoreLocalizationsUrl,
        asoToolsNoLocaleUrl,
        storesAnalyticsSearchExplorerUrl,
        storesAnalyticsTopChartsUrl,
        storesAnalyticsCvrBenchmarkUrl,
        storesAnalyticsTopKeywordsUrl,
        storesAnalyticsAppStoreTrendingSearchesUrl,
        reviewsBoardUrl,
        reviewsFeaturedUrl,
        reviewsAnalysisUrl,
        reviewsTagAnalysisUrl,
        reviewsRatingAnalysisUrl,
        reviewsTemplatesUrl,
        reviewsAutoRepliesUrl,
        reviewsTagsUrl,
        reviewsAutoTagsUrl,
        connectionsHubIntegrationsUrl,
        connectionsHubApiUrl,
        keywordBoostUrl,
        keywordBoostByCampaignIdUrl,
        referralProgramUrl,
        connectionsHubSettingsUrl,
    };
};

export const MyAppsRedirect: FC = () => {
    const { myAppsUrl } = useRedirectRoutes();

    return <Navigate to={myAppsUrl} replace />;
};

export const AsoToolsAppProfileRedirect: FC = () => {
    const { asoToolsAppProfileUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsAppProfileUrl} replace />;
};

export const AsoToolsComparativeReportRedirect: FC = () => {
    const { asoToolsComparativeReportUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsComparativeReportUrl} replace />;
};

export const AsoToolsTrafficSourcesRedirect: FC = () => {
    const { asoToolsTrafficSourcesUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsTrafficSourcesUrl} replace />;
};

export const AsoToolsKeywordHighlightsRedirect: FC = () => {
    const { asoToolsKeywordHighlightsUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsKeywordHighlightsUrl} replace />;
};

export const AsoToolsFavoriteKeywordsRedirect: FC = () => {
    const { asoToolsFavoriteKeywordsUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsFavoriteKeywordsUrl} replace />;
};

export const AsoToolsCategoriesRankingRedirect: FC = () => {
    const { asoToolsCategoriesRankingUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsCategoriesRankingUrl} replace />;
};

export const AsoToolsOrganicReportRedirect: FC = () => {
    const { asoToolsOrganicReportUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsOrganicReportUrl} replace />;
};

export const AsoToolsFindAndTrackRedirect: FC = () => {
    const { asoToolsFindAndTrackUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsFindAndTrackUrl} replace />;
};

export const AsoToolsKeywordChartsRedirect: FC = () => {
    const { asoToolsKeywordChartsUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsKeywordChartsUrl} replace />;
};

export const AsoToolsKeywordCompetitorsRedirect: FC = () => {
    const { asoToolsKeywordCompetitorsUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsKeywordCompetitorsUrl} replace />;
};

export const AsoToolsKeywordPhraseMixerRedirect: FC = () => {
    const { asoToolsKeywordPhraseMixerUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsKeywordPhraseMixerUrl} replace />;
};

export const AsoToolsKeywordLivePositionsRedirect: FC = () => {
    const { asoToolsKeywordLivePositionsUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsKeywordLivePositionsUrl} replace />;
};

export const AsoToolsKeywordAutoSuggestionsRedirect: FC = () => {
    const { asoToolsKeywordAutoSuggestionsUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsKeywordAutoSuggestionsUrl} replace />;
};

export const AsoToolsDescriptionCreatorRedirect: FC = () => {
    const { asoToolsDescriptionCreatorUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsDescriptionCreatorUrl} replace />;
};

export const AsoToolsTextMetadataBuilderRedirect: FC = () => {
    const { asoToolsTextMetadataBuilderUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsTextMetadataBuilderUrl} replace />;
};

export const AsoToolsAppStoreLocalizationsRedirect: FC = () => {
    const { asoToolsAppStoreLocalizationsUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsAppStoreLocalizationsUrl} replace />;
};

export const AsoToolsNoLocaleRedirect: FC = () => {
    const { asoToolsNoLocaleUrl } = useRedirectRoutes();

    return <Navigate to={asoToolsNoLocaleUrl} replace />;
};

export const StoresAnalyticsSearchExplorerRedirect: FC = () => {
    const { storesAnalyticsSearchExplorerUrl } = useRedirectRoutes();

    return <Navigate to={storesAnalyticsSearchExplorerUrl} replace />;
};

export const StoresAnalyticsTopChartsRedirect: FC = () => {
    const { storesAnalyticsTopChartsUrl } = useRedirectRoutes();

    return <Navigate to={storesAnalyticsTopChartsUrl} replace />;
};

export const StoresAnalyticsCvrBenchmarkRedirect: FC = () => {
    const { storesAnalyticsCvrBenchmarkUrl } = useRedirectRoutes();

    return <Navigate to={storesAnalyticsCvrBenchmarkUrl} replace />;
};

export const StoresAnalyticsTopKeywordsRedirect: FC = () => {
    const { storesAnalyticsTopKeywordsUrl } = useRedirectRoutes();

    return <Navigate to={storesAnalyticsTopKeywordsUrl} replace />;
};

export const StoresAnalyticsAppStoreTrendingSearchesRedirect: FC = () => {
    const { storesAnalyticsAppStoreTrendingSearchesUrl } = useRedirectRoutes();

    return <Navigate to={storesAnalyticsAppStoreTrendingSearchesUrl} replace />;
};

export const ReviewsBoardRedirect: FC = () => {
    const { reviewsBoardUrl } = useRedirectRoutes();

    return <Navigate to={reviewsBoardUrl} replace />;
};

export const ReviewsFeaturedRedirect: FC = () => {
    const { reviewsFeaturedUrl } = useRedirectRoutes();

    return <Navigate to={reviewsFeaturedUrl} replace />;
};

export const ReviewsAnalysisRedirect: FC = () => {
    const { reviewsAnalysisUrl } = useRedirectRoutes();

    return <Navigate to={reviewsAnalysisUrl} replace />;
};

export const ReviewsTagAnalysisRedirect: FC = () => {
    const { reviewsTagAnalysisUrl } = useRedirectRoutes();

    return <Navigate to={reviewsTagAnalysisUrl} replace />;
};

export const ReviewsRatingAnalysisRedirect: FC = () => {
    const { reviewsRatingAnalysisUrl } = useRedirectRoutes();

    return <Navigate to={reviewsRatingAnalysisUrl} replace />;
};

export const ReviewsTemplatesRedirect: FC = () => {
    const { reviewsTemplatesUrl } = useRedirectRoutes();

    return <Navigate to={reviewsTemplatesUrl} replace />;
};

export const ReviewsAutoRepliesRedirect: FC = () => {
    const { reviewsAutoRepliesUrl } = useRedirectRoutes();

    return <Navigate to={reviewsAutoRepliesUrl} replace />;
};

export const ReviewsTagsRedirect: FC = () => {
    const { reviewsTagsUrl } = useRedirectRoutes();

    return <Navigate to={reviewsTagsUrl} replace />;
};

export const ReviewsAutoTagsRedirect: FC = () => {
    const { reviewsAutoTagsUrl } = useRedirectRoutes();

    return <Navigate to={reviewsAutoTagsUrl} replace />;
};

export const ConnectionsHubIntegrationsRedirect: FC = () => {
    const { connectionsHubIntegrationsUrl } = useRedirectRoutes();

    return <Navigate to={connectionsHubIntegrationsUrl} replace />;
};

export const ConnectionsHubAllReportsSettingsRedirect: FC = () => {
    const { connectionsHubSettingsUrl } = useRedirectRoutes();

    return <Navigate to={connectionsHubSettingsUrl} replace />;
};

export const ConnectionsHubApiRedirect: FC = () => {
    const { connectionsHubApiUrl } = useRedirectRoutes();

    return <Navigate to={connectionsHubApiUrl} replace />;
};

export const KeywordBoostRedirect: FC = () => {
    const { keywordBoostUrl } = useRedirectRoutes();

    return <Navigate to={keywordBoostUrl} replace />;
};

export const KeywordBoostByCampaignIdRedirect: FC = () => {
    const { keywordBoostByCampaignIdUrl } = useRedirectRoutes();

    return <Navigate to={keywordBoostByCampaignIdUrl} replace />;
};

export const ReferralProgramRedirect: FC = () => {
    const { referralProgramUrl } = useRedirectRoutes();

    return <Navigate to={referralProgramUrl} replace />;
};

export const RedirectRoutes: FC = () => (
    <FaroRoutes>
        {isDevelopmentMode && <Route path="/" element={<MyAppsRedirect />} />}

        <Route path={getOldMyAppsRouteUrl(':country')} element={<MyAppsRedirect />} />

        <Route path={getOldAsoToolsRouteBaseAppProfileUrl(':country', ':appId')} element={<AsoToolsAppProfileRedirect />} />
        <Route path={getOldAsoToolsRouteAppProfileUrl(':country', ':appId')} element={<AsoToolsAppProfileRedirect />} />
        <Route
            path={getOldAsoToolsRouteComparativeReportUrl(':country', ':appId')}
            element={<AsoToolsComparativeReportRedirect />}
        />
        <Route path={getOldAsoToolsRouteTrafficSourcesUrl(':country', ':appId')} element={<AsoToolsTrafficSourcesRedirect />} />
        <Route
            path={getOldAsoToolsRouteKeywordHighlightsUrl(':country', ':appId')}
            element={<AsoToolsKeywordHighlightsRedirect />}
        />
        <Route
            path={getOldAsoToolsRouteFavoriteKeywordsUrl(':country', ':appId')}
            element={<AsoToolsFavoriteKeywordsRedirect />}
        />
        <Route path={getOldAsoToolsRouteCategoriesRankingUrl()} element={<AsoToolsCategoriesRankingRedirect />} />
        <Route path={getOldAsoToolsRouteOrganicReportUrl(':country', ':appId')} element={<AsoToolsOrganicReportRedirect />} />
        <Route path={getOldAsoToolsRouteFindAndTrackUrl(':country', ':appId')} element={<AsoToolsFindAndTrackRedirect />} />
        <Route path={getOldAsoToolsRouteKeywordChartsUrl(':country', ':appId')} element={<AsoToolsKeywordChartsRedirect />} />
        <Route
            path={getOldAsoToolsRouteKeywordCompetitorsUrl(':country', ':appId')}
            element={<AsoToolsKeywordCompetitorsRedirect />}
        />
        <Route path={getOldAsoToolsRouteKeywordPhraseMixerUrl()} element={<AsoToolsKeywordPhraseMixerRedirect />} />
        <Route
            path={getOldAsoToolsRouteKeywordLivePositionsUrl(':country', ':appId')}
            element={<AsoToolsKeywordLivePositionsRedirect />}
        />
        <Route
            path={getOldAsoToolsRouteKeywordAutoSuggestionsUrl(':country', ':appId')}
            element={<AsoToolsKeywordAutoSuggestionsRedirect />}
        />
        <Route path={getOldAsoToolsRouteDescriptionCreatorUrl()} element={<AsoToolsDescriptionCreatorRedirect />} />
        <Route
            path={getOldAsoToolsRouteTextMetadataBuilderUrl(':country', ':appId')}
            element={<AsoToolsTextMetadataBuilderRedirect />}
        />
        <Route path={getOldAsoToolsRouteAppStoreLocalizationsUrl()} element={<AsoToolsAppStoreLocalizationsRedirect />} />
        <Route path={getOldAsoToolsRouteNoLocaleUrl(':country', ':appId')} element={<AsoToolsNoLocaleRedirect />} />

        <Route path={getOldStoresAnalyticsRouteSearchExplorerUrl()} element={<StoresAnalyticsSearchExplorerRedirect />} />
        <Route path={getOldStoresAnalyticsRouteTopChartsUrl(':country')} element={<StoresAnalyticsTopChartsRedirect />} />
        <Route path={getOldStoresAnalyticsRouteCvrBenchmarkUrl()} element={<StoresAnalyticsCvrBenchmarkRedirect />} />
        <Route path={getOldStoresAnalyticsRouteTopKeywordsUrl(':country')} element={<StoresAnalyticsTopKeywordsRedirect />} />
        <Route
            path={getOldStoresAnalyticsRouteAppStoreTrendingSearchesUrl(':country')}
            element={<StoresAnalyticsAppStoreTrendingSearchesRedirect />}
        />

        <Route path={getOldReviewsRouteBoardUrl(':country', ':appId')} element={<ReviewsBoardRedirect />} />
        <Route path={getOldReviewsRouteFeaturedUrl(':country', ':appId')} element={<ReviewsFeaturedRedirect />} />
        <Route path={getOldReviewsRouteAnalysisUrl(':country', ':appId')} element={<ReviewsAnalysisRedirect />} />
        <Route path={getOldReviewsRouteTagAnalysisUrl(':country', ':appId')} element={<ReviewsTagAnalysisRedirect />} />
        <Route path={getOldReviewsRouteRatingAnalysisUrl(':country', ':appId')} element={<ReviewsRatingAnalysisRedirect />} />
        <Route path={getOldReviewsRouteTemplatesUrl(':country', ':appId')} element={<ReviewsTemplatesRedirect />} />
        <Route path={getOldReviewsRouteAutoRepliesUrl(':country', ':appId')} element={<ReviewsAutoRepliesRedirect />} />
        <Route path={getOldReviewsRouteTagsUrl(':country', ':appId')} element={<ReviewsTagsRedirect />} />
        <Route path={getOldReviewsRouteAutoTagsUrl(':country', ':appId')} element={<ReviewsAutoTagsRedirect />} />

        <Route path={getOldConnectionsHubRouteIntegrationsUrl()} element={<ConnectionsHubIntegrationsRedirect />} />
        <Route path={getOldConnectionsHubRouteAllReportsSettingsUrl()} element={<ConnectionsHubAllReportsSettingsRedirect />} />
        <Route path={getOldConnectionsHubRouteApiUrl()} element={<ConnectionsHubApiRedirect />} />

        <Route path={getKeywordBoostRouteUrl(':country', ':appId')} element={<KeywordBoostRedirect />} />
        <Route
            path={getKeywordBoostRouteByCampaignIdUrl(':country', ':appId', ':campaignId')}
            element={<KeywordBoostByCampaignIdRedirect />}
        />

        <Route path={getOldReferralProgramRouteUrl()} element={<ReferralProgramRedirect />} />
    </FaroRoutes>
);
