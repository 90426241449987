import { Dispatch } from 'redux';
import axios from 'axios';
import { appUrls, Urls } from 'utils/Urls';
import { KeywordSuggestionsControllerInterface } from 'state/keywordSuggestions/KeywordSuggestionsControllerInterface';
import {
    KeywordSuggestionsResponseType,
    SuggestionDetailsType,
    SuggestionsType,
} from 'state/keywordSuggestions/KeywordSuggestionsResponseType';
import { addKeywordSuggestions, removeKeywordSuggestions, setKeywordSuggestions } from 'state/keywordSuggestions/actions';
import { toast } from 'react-toastify';
import { AddTopKeywordSuggestionsResponseType } from 'state/keywordSuggestions/addTopKeywordSuggestionsResponseType';
import { InitKeywordSuggestionModalState } from 'state/keywordSuggestionsModal/reducer';
import { KeywordSuggestionModalController } from 'state/keywordSuggestionsModal/controller';
import { SERVER_ERROR_THROTTLING } from 'constants/serverErrorConstants';
import { ThunkDispatch } from 'types/react-redux-thunk';
import { KeywordSuggestionsTypeAdapter } from 'state/keywordSuggestions/KeywordSuggestionsTypeAdapter';
import { AvailableFeaturesKeyEnum } from 'state/appProfile/AppProfileResponseType';
import { featurePermissionModalDispatch } from 'state/popup/featurePermissionModalDispatch';
import { RootState } from 'state/store';
import { getAppProfileStore } from 'utils/storeUtils';

export const getKeywordSuggestions = (keywordId: number) => async (dispatch: Dispatch, store: () => RootState) => {
    try {
        const { country, appId } = getAppProfileStore(store);
        const response = await axios.get<KeywordSuggestionsResponseType>(
            Urls.get_suggestions(country.toLowerCase(), appId, keywordId)
        );

        dispatch(setKeywordSuggestions(keywordId, KeywordSuggestionsTypeAdapter(response.data)));
    } catch (error) {
        console.error(error);
    }
};

export const addSuggestedKeyword = (
    keywordId: number,
    type: SuggestionsType,
    suggestionKeyword: SuggestionDetailsType,
    suggestionsByKeyword: string
) => async (dispatch: ThunkDispatch, store: () => RootState) => {
    try {
        const formData = new FormData();
        formData.append('new_keyword', suggestionKeyword.keyword);

        dispatch(addKeywordSuggestions(keywordId, type, suggestionKeyword, suggestionsByKeyword));

        const params = getAppProfileStore(store);
        const response = await axios.post(appUrls('add_suggested_keyword', params), formData);

        toast.success(response.data.success);
    } catch (error) {
        dispatch(removeKeywordSuggestions(keywordId, type, suggestionKeyword, suggestionsByKeyword));

        if (error.response) {
            if (error.response.data && error.response.data.error === SERVER_ERROR_THROTTLING) {
                const { toggleSuggestionModalController } = KeywordSuggestionModalController;

                dispatch(toggleSuggestionModalController(InitKeywordSuggestionModalState));

                featurePermissionModalDispatch(dispatch, AvailableFeaturesKeyEnum.KEYWORDS, store);
            } else {
                toast.error(error.response.data.error);
            }
        }

        console.error(error);
    }
};

export const removeSuggestedKeyword = (
    keywordId: number,
    type: SuggestionsType,
    suggestionKeyword: SuggestionDetailsType,
    suggestionsByKeyword: string
) => async (dispatch: ThunkDispatch) => {
    try {
        dispatch(removeKeywordSuggestions(keywordId, type, suggestionKeyword, suggestionsByKeyword));
    } catch (error) {
        dispatch(addKeywordSuggestions(keywordId, type, suggestionKeyword, suggestionsByKeyword));

        if (error.response) {
            toast.error(error.response.data.error);
            console.error(error);
        }
    }
};

const addTopKeywordSuggestions = (countryCode?: string, appId?: string) => async (
    dispatch: ThunkDispatch,
    store: () => RootState
) => {
    try {
        const params =
            countryCode && appId
                ? {
                      country: countryCode,
                      appId,
                  }
                : getAppProfileStore(store);

        const response = await axios.post<AddTopKeywordSuggestionsResponseType>(appUrls('add_top_keywords', params));

        toast.success(response.data.success);

        return response.data;
    } catch (error) {
        if (error.response) {
            if (error.response.data && error.response.data.error === SERVER_ERROR_THROTTLING) {
                featurePermissionModalDispatch(dispatch, AvailableFeaturesKeyEnum.KEYWORDS, store);

                return;
            }
            toast.error(error.response.data.error);
        }

        return error.response.data;
    }
};

export const KeywordSuggestionsController: KeywordSuggestionsControllerInterface = {
    getKeywordSuggestions,
    addTopKeywordSuggestions,
};
