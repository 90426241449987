import { KeywordExplorerActionType } from 'state/keywordExplorer/actions';
import { KeywordExplorerActionTypeListEnum } from 'state/keywordExplorer/actionsTypes';
import { KeywordExplorerStateType } from 'state/keywordExplorer/keywordExplorerTypes';
import { getKeywordHash } from 'state/keywordExplorer/getKeywordHash';
import { getKeywordStatisticsHash } from 'state/keywordExplorer/getKeywordStatisticsHash';

const keywordExplorerInitialState = {
    apps: {
        appStoreIds: [],
        appStoreByIds: {},
    },
    appsByKeywords: {},
    statisticsByKeywords: {},
    isLoadingStatisticsByKeywords: {},
    isLoading: {},
};

const keywordExplorer = (state: KeywordExplorerStateType = keywordExplorerInitialState, action: KeywordExplorerActionType) => {
    switch (action.type) {
        case KeywordExplorerActionTypeListEnum.SET_KEYWORD_EXPLORER_DATA: {
            const { type, ...keywordExplorerProps } = action;

            return {
                ...state,
                ...keywordExplorerProps,
            };
        }

        case KeywordExplorerActionTypeListEnum.RESET_KEYWORD_EXPLORER_DATA: {
            return {
                ...state,
                apps: keywordExplorerInitialState.apps,
            };
        }

        case KeywordExplorerActionTypeListEnum.UPDATE_APP_META: {
            const { type, ...updatedAppMeta } = action;

            const {
                isFetching,
                storeId,
                meta: { name, logo, developer, rating, ratingCount },
            } = updatedAppMeta;

            return {
                ...state,
                apps: {
                    ...state.apps,
                    appStoreByIds: {
                        ...state.apps.appStoreByIds,
                        [storeId]: {
                            ...state.apps.appStoreByIds[storeId],
                            isFetching,
                            name,
                            logo,
                            developer,
                            rating,
                            ratingCount,
                        },
                    },
                },
            };
        }

        case KeywordExplorerActionTypeListEnum.SET_KEYWORD_EXPLORER_APPS_BY_KEYWORD: {
            const {
                locale,
                keyword,
                device,
                apps,
                recordsTotal,
                page,
                brandedStoreId,
                brandedAppMeta,
                translation,
                translationFrom,
                keywordId,
                estimatedInstalls,
                isFetching,
                start,
                ...keywordExplorerDescription
            } = action;
            const keywordHash = getKeywordHash(locale, keyword, device);
            const stateApps = state.appsByKeywords[keywordHash]?.apps || [];

            return {
                ...state,
                ...keywordExplorerDescription,
                appsByKeywords: {
                    ...state.appsByKeywords,
                    [keywordHash]: {
                        apps: start === 0 ? apps : [...stateApps, ...apps],
                        recordsTotal,
                        page,
                        brandedStoreId,
                        brandedAppMeta,
                        translation,
                        translationFrom,
                        ...(estimatedInstalls
                            ? { estimatedInstalls }
                            : { estimatedInstalls: state.appsByKeywords[keywordHash]?.estimatedInstalls }),
                        keywordId,
                        isFetching,
                    },
                },
            };
        }

        case KeywordExplorerActionTypeListEnum.SET_KEYWORD_EXPLORER_RANK_HISTORY: {
            const { locale, keyword, device, rankHistoryByAppLocaleId } = action;
            const keywordHash = getKeywordHash(locale, keyword, device);

            return {
                ...state,
                appsByKeywords: {
                    ...state.appsByKeywords,
                    [keywordHash]: {
                        ...state.appsByKeywords[keywordHash],
                        apps: state.appsByKeywords[keywordHash].apps.map((app) => {
                            const rankHistory = rankHistoryByAppLocaleId[app.appLocaleId];

                            if (rankHistory) {
                                return {
                                    ...app,
                                    rankHistory,
                                };
                            }

                            return app;
                        }),
                    },
                },
            };
        }

        case KeywordExplorerActionTypeListEnum.SET_IS_LOADING_KEYWORD_EXPLORER: {
            const { locale, keyword, device, isLoading } = action;
            const keywordHash = getKeywordHash(locale, keyword, device);
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [keywordHash]: isLoading,
                },
            };
        }

        case KeywordExplorerActionTypeListEnum.SET_KEYWORD_EXPLORER_STATISTICS: {
            const { locale, keyword, device, storeId, statistics } = action;
            const statisticsHash = getKeywordStatisticsHash(locale, keyword, device, storeId);
            return {
                ...state,
                statisticsByKeywords: {
                    ...state.statisticsByKeywords,
                    [statisticsHash]: {
                        ...state.statisticsByKeywords[statisticsHash],
                        ...statistics,
                    },
                },
            };
        }
        case KeywordExplorerActionTypeListEnum.SET_IS_KEYWORD_EXPLORER_STATISTICS_LOADING: {
            const { locale, keyword, device, storeId, isLoading } = action;
            const statisticsHash = getKeywordStatisticsHash(locale, keyword, device, storeId);
            return {
                ...state,
                isLoadingStatisticsByKeywords: {
                    ...state.isLoadingStatisticsByKeywords,
                    [statisticsHash]: isLoading,
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default keywordExplorer;
