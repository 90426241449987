import { HeaderInfoResponseType } from 'state/asodeskHeader/controller';
import { HeaderCountryType } from 'state/asodeskHeader/HeaderCountryType';

export const headerInfoResponseAdapter = (data: HeaderInfoResponseType) => {
    const countries: HeaderCountryType[] = data.countries.map(({ code, keyword_count, name }) => ({
        value: name,
        code: code.toLowerCase(),
        count: keyword_count,
    }));

    const userDefaultCountry = {
        value: data.current_country.name,
        code: data.current_country.code.toLowerCase(),
        count: data.current_country.keyword_count,
    };

    return {
        countries,
        userDefaultCountry,
    };
};
