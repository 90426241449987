import React, { FC, memo, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

export type PropsType = {
    text: string;
    subText?: string;
    className?: string;
    itemClassName?: string;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    tooltip?: React.ReactNode;
    meta?: ReactNode;
    description?: string;
};

const TextItemComponent: FC<PropsType> = ({
    text,
    subText,
    className,
    onClick,
    tooltip,
    meta,
    description,
    itemClassName,
}: PropsType) => (
    <div
        onClick={onClick}
        className={classNames(
            'max-w-99-pct flex !flex-nowrap items-center justify-between text-14 py-10 border-gray-300 border-b border-solid last:border-transparent',
            {
                'cursor-pointer': onClick,
            },
            className
        )}
    >
        <div className={classNames('flex text-primary truncate', itemClassName)}>
            <div className="w-100 truncate">
                <div className="flex items-center">
                    {text} {subText && <p className="text-12 text-gray-400 ml-4 truncate">{subText}</p>}
                </div>
                {description && <p className="text-12 text-gray-400 pr-14 truncate">{description}</p>}
            </div>{' '}
            {tooltip && <span className="ml-4">{tooltip}</span>}
        </div>
        {meta}
    </div>
);

export const TextItem = memo(TextItemComponent);
