import { parseQueryString } from 'utils/helpers/queryString';
import { dateJs, getNow } from 'utils/helpers/date/date';
import { defaultDateRange } from 'constants/dateConstants';
import { DEFAULT_COUNTRY } from 'constants/localizationsConstants';
import { isString } from 'utils/typeGuard';
import {
    ActionStatusReplyReviewFilterEnum,
    AutomatedReplyReviewFilterEnum,
    FavoritesReplyReviewFilterEnum,
    FiltersReviewsChangeEnum,
    FiltersReviewsEnum,
    FiltersReviewsFeaturedEnum,
    FiltersReviewsPresetEnum,
    FiltersReviewsReplyEnum,
    FiltersTagSearchTypeEnum,
    PendingReplyReviewFilterEnum,
    ReviewFiltersInitialStateType,
    ReviewSortedEnum,
    ReviewsSortDirectionsEnum,
    ReviewsSortDirectionsRequestValuesEnum,
} from './reviewFiltersTypes';

const {
    reviewsVersionDate,
    reviewsDate,
    reviewsRating,
    teammatesIds,
    reviewsReply,
    reviewsChange,
    reviewsFeatured,
    search,
    tags,
    excludeTags,
    tagSearchType,
    order,
    ratingOrder,
    reviewLengthOrder,
    reviewsLanguage,
    reviewsChangeOption,
    countries,
    pendingReplies,
    actionStatus,
    automatedReply,
    lengthFrom,
    lengthTo,
    date: activeReviewDate,
    reviewsWithoutTag,
    reviewsFavorites,
} = parseQueryString(window.location.search);

const sortingQuery = {
    order,
    ratingOrder,
    reviewLengthOrder,
};

const getDefaultDate = (jsonDate: string | string[] | null | undefined) => {
    const date = typeof jsonDate === 'string' && JSON.parse(jsonDate);

    if (!date) return null;

    return {
        startDate: date.startDate,
        endDate: date.endDate,
    };
};

const getDefaultReviewDate = () => {
    let defaultReviewDate = defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_DATE];

    if (activeReviewDate) {
        defaultReviewDate = {
            ...defaultReviewDate,
            startDate: dateJs.unix(parseFloat(activeReviewDate as string)).toISOString(),
        };
    }

    return getDefaultDate(reviewsDate) || defaultReviewDate;
};

const getInitialCountry = () => {
    const { locale } = parseQueryString(window.location.search);

    if (isString(locale)) {
        return [locale];
    }

    return [DEFAULT_COUNTRY];
};

const getDefaultCountry = () => {
    const countriesParams = typeof countries === 'string' && countries.split(',').map((countryCode) => countryCode.toLowerCase());

    return countriesParams || getInitialCountry();
};

export const defaultReviewFilterSetList = {
    [FiltersReviewsEnum.REVIEWS_PRESET]: FiltersReviewsPresetEnum.DEFAULT,
    [FiltersReviewsEnum.REVIEWS_COUNTRIES]: getDefaultCountry(),
    [FiltersReviewsEnum.REVIEWS_DATE]: defaultDateRange,
    [FiltersReviewsEnum.REVIEWS_VERSION_DATE]: {
        startDate: null,
        endDate: null,
    },
    [FiltersReviewsEnum.REVIEWS_RATING]: [],
    [FiltersReviewsEnum.REVIEWS_REPLY]: {
        type: FiltersReviewsReplyEnum.ALL,
        teammatesIds: [],
    },
    [FiltersReviewsEnum.REVIEWS_CHANGE]: {
        type: FiltersReviewsChangeEnum.ALL,
        switchOption: FiltersReviewsChangeEnum.CHANGED,
    },
    [FiltersReviewsEnum.REVIEWS_FEATURED]: FiltersReviewsFeaturedEnum.ALL,
    [FiltersReviewsEnum.REVIEWS_TAG]: {
        search: '',
        tags: [],
        excludeTags: [],
        tagSearchType: FiltersTagSearchTypeEnum.ANY,
    },
    [FiltersReviewsEnum.REVIEWS_SORT]: {
        type: ReviewSortedEnum.ORDER,
        direction: ReviewsSortDirectionsRequestValuesEnum.DATE_NEWEST,
    },
    [FiltersReviewsEnum.REVIEWS_LANGUAGE]: [],
    [FiltersReviewsEnum.REVIEWS_PENDING_REPLIES]: PendingReplyReviewFilterEnum.ALL,
    [FiltersReviewsEnum.AUTOMATED_REPLY]: AutomatedReplyReviewFilterEnum.ALL,
    [FiltersReviewsEnum.REVIEW_LENGTH]: {
        from: '',
        to: '',
    },
    [FiltersReviewsEnum.ACTION_STATUS_REPLIES]: ActionStatusReplyReviewFilterEnum.ALL,
    [FiltersReviewsEnum.REVIEWS_SEARCH_TEXT]: '',
    [FiltersReviewsEnum.REVIEWS_WITHOUT_TAG]: (typeof reviewsWithoutTag === 'string' && !!+reviewsWithoutTag) || false,
    [FiltersReviewsEnum.REVIEWS_FAVORITES]:
        (reviewsFavorites && (reviewsFavorites as FavoritesReplyReviewFilterEnum)) || FavoritesReplyReviewFilterEnum.ALL,
};

const defaultReviewDirectionFromQuery = (order ||
    ratingOrder ||
    reviewLengthOrder ||
    defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_SORT].direction) as ReviewsSortDirectionsRequestValuesEnum;

const getDefaultSortedType = () => {
    const setSorting = Object.entries(sortingQuery).find((sort) => {
        const hasDirection = sort[1] !== undefined;
        return hasDirection;
    });

    const typeSortingFromQuery = setSorting && (setSorting[0] as ReviewSortedEnum);
    return typeSortingFromQuery || defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_SORT].type;
};

export const defaultReviewFilterSetListWithParams: ReviewFiltersInitialStateType = {
    ...defaultReviewFilterSetList,
    [FiltersReviewsEnum.REVIEWS_DATE]: getDefaultReviewDate(),
    [FiltersReviewsEnum.REVIEWS_VERSION_DATE]:
        getDefaultDate(reviewsVersionDate) || defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_VERSION_DATE],
    [FiltersReviewsEnum.REVIEWS_RATING]:
        (typeof reviewsRating === 'string' && reviewsRating.split(',').map((rating) => +rating)) ||
        defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_RATING],
    [FiltersReviewsEnum.REVIEWS_REPLY]: {
        type: (reviewsReply as FiltersReviewsReplyEnum) || defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_REPLY].type,
        teammatesIds:
            (typeof teammatesIds === 'string' && teammatesIds.split(',').filter((id) => id)) ||
            defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_REPLY].teammatesIds,
    },
    [FiltersReviewsEnum.REVIEWS_CHANGE]: {
        type: (reviewsChange as FiltersReviewsChangeEnum) || defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_CHANGE].type,
        switchOption:
            (reviewsChangeOption as FiltersReviewsChangeEnum) ||
            defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_CHANGE].switchOption,
    },
    [FiltersReviewsEnum.REVIEWS_FEATURED]:
        (reviewsFeatured as FiltersReviewsFeaturedEnum) || defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_FEATURED],
    [FiltersReviewsEnum.REVIEWS_TAG]: {
        tags: (typeof tags === 'string' && JSON.parse(tags)) || defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_TAG].tags,
        excludeTags:
            (typeof excludeTags === 'string' && JSON.parse(excludeTags)) ||
            defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_TAG].excludeTags,
        tagSearchType:
            (tagSearchType as FiltersTagSearchTypeEnum) ||
            defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_TAG].tagSearchType,
    },
    [FiltersReviewsEnum.REVIEWS_SEARCH_TEXT]:
        (typeof search === 'string' && search) || defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_TAG].search,
    [FiltersReviewsEnum.REVIEWS_SORT]: {
        type: getDefaultSortedType(),
        direction: defaultReviewDirectionFromQuery,
    },
    [FiltersReviewsEnum.REVIEWS_LANGUAGE]:
        (typeof reviewsLanguage === 'string' && reviewsLanguage.split(',')) ||
        defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_LANGUAGE],
    [FiltersReviewsEnum.REVIEWS_PENDING_REPLIES]:
        (pendingReplies as PendingReplyReviewFilterEnum) ||
        defaultReviewFilterSetList[FiltersReviewsEnum.REVIEWS_PENDING_REPLIES],
    [FiltersReviewsEnum.ACTION_STATUS_REPLIES]:
        (actionStatus as ActionStatusReplyReviewFilterEnum) || ActionStatusReplyReviewFilterEnum.ALL,
    [FiltersReviewsEnum.AUTOMATED_REPLY]:
        (automatedReply as AutomatedReplyReviewFilterEnum) || AutomatedReplyReviewFilterEnum.ALL,
    [FiltersReviewsEnum.REVIEW_LENGTH]: {
        from: (lengthFrom as string) || '',
        to: (lengthTo as string) || '',
    },
};

const {
    reviewsCountries: defaultReviewsCountries,
    reviewsLanguage: defaultReviewsLanguage,
    ...defaultReviewsFilter
} = defaultReviewFilterSetList;

export const reviewFilterSetList = {
    [FiltersReviewsPresetEnum.DEFAULT]: defaultReviewsFilter,
    [FiltersReviewsPresetEnum.REQUIRE_ATTENTION]: {
        ...defaultReviewsFilter,
        [FiltersReviewsEnum.REVIEWS_DATE]: {
            startDate: getNow().subtract(360, 'day').toISOString(),
            endDate: defaultDateRange.endDate,
        },
        [FiltersReviewsEnum.REVIEWS_PRESET]: FiltersReviewsPresetEnum.REQUIRE_ATTENTION,
        [FiltersReviewsEnum.REVIEWS_RATING]: [1, 2, 3],
        [FiltersReviewsEnum.REVIEWS_REPLY]: {
            type: FiltersReviewsReplyEnum.NO_REPLIES,
            teammatesIds: [],
        },
    },
};

export const reviewFilterSetListTexts: {
    [key: string]: string;
} = {
    [FiltersReviewsPresetEnum.DEFAULT]: 'All',
    [FiltersReviewsPresetEnum.REQUIRE_ATTENTION]: 'Require attention',
    [PendingReplyReviewFilterEnum.PENDING_REPLY]: 'Pending reply',
    [PendingReplyReviewFilterEnum.ALL]: 'All',
    [FiltersReviewsReplyEnum.ALL]: 'All',
    [FiltersReviewsReplyEnum.REPLIED]: 'Replied',
    [FiltersReviewsReplyEnum.NO_REPLIES]: 'No replies',
    [FiltersReviewsChangeEnum.ALL]: 'All',
    [FiltersReviewsChangeEnum.BECAME_BETTER]: 'Became better',
    [FiltersReviewsChangeEnum.BECAME_WORSE]: 'Became worse',
    [FiltersReviewsChangeEnum.CHANGED]: 'Review changed',
    [FiltersReviewsChangeEnum.NO_CHANGES]: 'No changes',
    [FiltersReviewsFeaturedEnum.ALL]: 'All',
    [FiltersReviewsFeaturedEnum.NOT_DELETED]: 'Not deleted',
    [FiltersReviewsFeaturedEnum.FEATURED]: 'Featured',
    [FiltersReviewsFeaturedEnum.DELETED]: 'Deleted',
    [ReviewsSortDirectionsEnum.DATE_NEWEST]: 'newest',
    [ReviewsSortDirectionsEnum.DATE_OLDEST]: 'oldest',
    [ActionStatusReplyReviewFilterEnum.ALL]: 'All',
    [ActionStatusReplyReviewFilterEnum.PROCESSING]: 'Processing',
    [ActionStatusReplyReviewFilterEnum.ERROR]: 'Error',
    [FiltersReviewsReplyEnum.REPORTED_CONCERN]: 'Reported \na concern',
    [AutomatedReplyReviewFilterEnum.ONLY_AUTOMATED]: 'Only automated replies',
    [AutomatedReplyReviewFilterEnum.ALL]: 'All',
    [FavoritesReplyReviewFilterEnum.ALL]: 'All',
    [FavoritesReplyReviewFilterEnum.FAVORITES]: 'Favorites',
    [FavoritesReplyReviewFilterEnum.NOT_FAVORITES]: 'Not Favorites',
};

export const reviewsFilterPresetsIds = (isGooglePlay: boolean) => ({
    replyBoard: [
        FiltersReviewsEnum.REVIEWS_COUNTRIES,
        ...(isGooglePlay ? [FiltersReviewsEnum.REVIEWS_LANGUAGE] : []),
        FiltersReviewsEnum.REVIEWS_DATE,
        FiltersReviewsEnum.REVIEWS_RATING,
        FiltersReviewsEnum.REVIEWS_REPLY,
        FiltersReviewsEnum.REVIEWS_CHANGE,
        FiltersReviewsEnum.REVIEWS_FEATURED,
        FiltersReviewsEnum.REVIEWS_TAG,
        FiltersReviewsEnum.REVIEWS_SORT,
        FiltersReviewsEnum.REVIEWS_FAVORITES,
    ],
    reviewAnalysis: [
        ...(!isGooglePlay ? [FiltersReviewsEnum.REVIEWS_COUNTRIES] : []),
        ...(isGooglePlay ? [FiltersReviewsEnum.REVIEWS_LANGUAGE] : []),
        FiltersReviewsEnum.REVIEWS_DATE,
        FiltersReviewsEnum.REVIEWS_VERSION_DATE,
        FiltersReviewsEnum.REVIEWS_CHANGE,
        FiltersReviewsEnum.REVIEWS_FEATURED,
        FiltersReviewsEnum.REVIEWS_TAG,
    ],
});
