import React, { FC, memo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Icons } from 'types/icon-types';
import { Icon } from 'newComponents/icons/asodeskIcons/Icon';
import styles from './toggle.module.scss';

export type TogglePropsType = {
    title?: string;
    onClick?: (isActive: boolean) => void;
    isActive?: boolean;
    isDisabled?: boolean;
    titleClassName?: string;
    className?: string;
    isSmall?: boolean;
};

const ToggleComponent: FC<TogglePropsType> = ({
    title,
    isActive = false,
    onClick = () => {},
    isDisabled = false,
    titleClassName,
    className,
    isSmall,
}: TogglePropsType) => {
    const [isActiveToggle, setIsActiveToggle] = useState(isActive);

    useEffect(() => {
        setIsActiveToggle(isActive);
    }, [isActive]);

    const onToggle = () => {
        if (!isDisabled) {
            setIsActiveToggle((prevState) => !prevState);

            onClick(!isActiveToggle);
        }
    };

    const iconName = isActiveToggle ? Icons.Check : Icons.Cross;

    return (
        <div
            className={classNames('inline-flex items-center cursor-pointer', { 'cursor-not-allowed': isDisabled })}
            onClick={onToggle}
            data-testid="toggle-wrapper"
        >
            <div
                className={classNames(className, 'relative rounded-full transition duration-300 ease', styles.switch, {
                    'bg-green-300': isActiveToggle,
                    'bg-gray-200': !isActiveToggle,
                    [styles.smallTrigger]: isSmall,
                })}
                data-testid="toggle-container"
            >
                <div
                    className={classNames(
                        styles.pointer,
                        'flex items-center justify-center absolute bg-white rounded-full top-0 bottom-0 m-auto transform transition transition-all duration-300',
                        {
                            'translate-x-18 text-green-300': isActiveToggle,
                            'translate-x-2 text-gray-400': !isActiveToggle,
                        }
                    )}
                >
                    <Icon name={iconName} size={16} lineHeight={16} />
                </div>
                {isDisabled && <div className="rounded-full absolute left-0 top-0 right-0 bottom-0 bg-white bg-opacity-50" />}
            </div>
            {title && <div className={classNames('ml-4', titleClassName)}>{title}</div>}
        </div>
    );
};

export const Toggle = memo(ToggleComponent);
