export const PROMO_TYPE_BLACK_FRIDAY = 'black-friday';
export const PROMO_TYPE_CYBER_MONDAY = 'cyber-monday';
export const PROMO_TYPE_BD_COMPANY_FIRST_STAGE = 'bd-company-first-stage';
export const PROMO_TYPE_BD_COMPANY_LAST_STAGE = 'bd-company-last-stage';

export type PromoType =
    | typeof PROMO_TYPE_BLACK_FRIDAY
    | typeof PROMO_TYPE_CYBER_MONDAY
    | typeof PROMO_TYPE_BD_COMPANY_FIRST_STAGE
    | typeof PROMO_TYPE_BD_COMPANY_LAST_STAGE;
