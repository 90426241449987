import { useAppToolBar } from 'modules/appToolTopBar/useAppToolBar';
import { APPSTORE_APP_TYPE, GOOGLEPLAY_APP_TYPE, RUSTORE_APP_TYPE } from 'state/appProfile/AppResponseType';
import { useSystem } from 'hooks/state/useSystem';

export const useAppType = () => {
    const { storeType } = useAppToolBar();

    const { appId } = useSystem();

    const isAppStoreAppType = !!(appId && storeType === APPSTORE_APP_TYPE);
    const isGooglePlayAppType = !!(appId && storeType === GOOGLEPLAY_APP_TYPE);
    const isRustoreAppType = !!(appId && storeType === RUSTORE_APP_TYPE);

    return {
        isAppStoreAppType,
        isGooglePlayAppType,
        isRustoreAppType,
        storeType,
    };
};
