import { ReferralProgramTypeEnum } from './actionsTypes';
import { ReferralProgramActionType } from './actions';
import { ReferralProgramStateType, stateInvitationAdapter, stateReferralProgramAdapter } from './stateReferralProgramAdapter';

const initialState = {
    userId: 0,
    bonusCredit: 0,
    pendingBonusCredit: 0,
    inviteLink: '',
    inviteList: [],
    bonusForUser: '',
    bonusForInvitedUser: '',
};

const referralProgram = (state: ReferralProgramStateType = initialState, action: ReferralProgramActionType) => {
    switch (action.type) {
        case ReferralProgramTypeEnum.GET_REFERRAL_PROGRAM: {
            const newState = stateReferralProgramAdapter(action.data);
            return { ...newState };
        }
        case ReferralProgramTypeEnum.ADD_INVITATION_REFERRAL_PROGRAM: {
            const pendingBonusCredit = +action.invitation.bonus + state.pendingBonusCredit;
            const adaptInvitation = stateInvitationAdapter(action.invitation, state.inviteList.length);
            return {
                ...state,
                pendingBonusCredit,
                inviteList: [...state.inviteList, adaptInvitation],
            };
        }
        default: {
            return state;
        }
    }
};

export default referralProgram;
