import Cookies from 'js-cookie';
import { SidebarCategoriesEnum } from 'hooks/useSidebarCategories';
import { BaseSystemType, ToggleHidingToolsType } from 'state/system/systemTypes';
import { SystemActionTypeListEnum } from './actionsTypes';
import { SystemActionType } from './actions';
import { ToggleToolNameEnum } from './toggleToolNameEnum';

export type SystemStateType = BaseSystemType & {
    pageCategory: SidebarCategoriesEnum;
    toolName?: string;
    toggleHidingTools: ToggleHidingToolsType;
    hasPageDemoData?: false;
};

const systemInitialState: SystemStateType = {
    toggleHidingTools: {},
    pageCategory: SidebarCategoriesEnum.MY_APPS,
};

const toggleHidingToolCookieName = {
    [ToggleToolNameEnum.KEYWORD_ANALYTICS_KEYWORD_MANAGER]: 'hideKeywordManager',
    [ToggleToolNameEnum.KEYWORD_ANALYTICS_KEYWORD_RANKING_CHART]: 'hideKeywordRankingChart',
};

const system = (state = systemInitialState, action: SystemActionType) => {
    switch (action.type) {
        case SystemActionTypeListEnum.SET_SYSTEM_APP_ID: {
            return {
                ...state,
                appId: action.appId,
            };
        }

        case SystemActionTypeListEnum.SET_SYSTEM_LOCALE: {
            return {
                ...state,
                locale: action.locale,
            };
        }

        case SystemActionTypeListEnum.SET_PAGE_CATEGORY: {
            return {
                ...state,
                pageCategory: action.pageCategory,
            };
        }

        case SystemActionTypeListEnum.SET_SYSTEM_TOOL_NAME: {
            return {
                ...state,
                toolName: action.toolName,
            };
        }

        case SystemActionTypeListEnum.SET_SYSTEM_HIDING_TOOLS: {
            const getToggleHidingToolValue = (tool: ToggleToolNameEnum) => {
                return action.toggleHidingTools && action.toggleHidingTools[tool] !== undefined
                    ? action.toggleHidingTools[tool]
                    : Cookies.get(toggleHidingToolCookieName[tool]) === 'true' || false;
            };

            return {
                ...state,
                toggleHidingTools: {
                    ...action.toggleHidingTools,
                    // added cookies for support previous version
                    [ToggleToolNameEnum.KEYWORD_ANALYTICS_KEYWORD_MANAGER]: getToggleHidingToolValue(
                        ToggleToolNameEnum.KEYWORD_ANALYTICS_KEYWORD_MANAGER
                    ),
                    [ToggleToolNameEnum.KEYWORD_ANALYTICS_KEYWORD_RANKING_CHART]: getToggleHidingToolValue(
                        ToggleToolNameEnum.KEYWORD_ANALYTICS_KEYWORD_RANKING_CHART
                    ),
                },
            };
        }

        case SystemActionTypeListEnum.TOGGLE_SYSTEM_HIDING_TOOL: {
            return {
                ...state,
                toggleHidingTools: {
                    ...state.toggleHidingTools,
                    [action.toolName]: !state.toggleHidingTools[action.toolName],
                },
            };
        }

        case SystemActionTypeListEnum.SET_HAS_PAGE_DEMO_DATA: {
            return {
                ...state,
                hasPageDemoData: action.hasPageDemoData,
            };
        }

        default: {
            return state;
        }
    }
};

export default system;
