type RecurringProfileType = {
    card_brand: string;
    card_pan: string;
    created: number;
    expires: number;
};

export type StatsUserLimitResponseType = {
    permissions: {
        can_manage_applications: boolean;
        can_manage_keywords: boolean;
        can_manage_competitors: boolean;
        can_manage_subscriptions: boolean;
        can_manage_campaigns: boolean;
        can_manage_teammates: boolean;
        can_use_api: boolean;
    };
    subscription: {
        name: string;
        display_name: string;
        public: boolean;
        is_free: boolean;
        end: number;
        keyword_limit: number;
        app_limit: number;
        teammates_limit: number;
        autorenew: boolean;
        is_welcome_offer_plan_available: boolean;
        last_trial_plan_id?: number;
    };
    api_subscription: {
        name: string;
        public: boolean;
        is_free: boolean;
        end: number;
        autorenew: boolean;
    };
    app_count: number;
    keyword_count: number;
    keyword_stats_limit: number;
    keywords_left: number;
    credits_left: number;
    credits_total: number;
    teammates_count: number;
    recurring_profiles: RecurringProfileType[];
    replies_left: number;
    replies_limit: number;
};

export enum StatsUserLimitIdsEnum {
    APPLICATIONS = 'applications',
    KEYWORDS = 'keywords',
    DAILY_KEYWORDS_STATS = 'daily-keywords-stats',
    MONTHLY_REVIEW_REPLIES = 'monthly-review-replies',
    TEAMMATES = 'teammates',
    API_CREDITS = 'api-credits',
}
