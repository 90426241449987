import { host } from 'constants/host';

export const storeConsoleUrls = {
    storeConsole: (storeId?: string | number) => `${host}/api/store_console/${storeId ? `${storeId}/` : ''}`,
    application: (appId?: number) => `${host}/api/application/${appId ? `${appId}/` : ''}`,
    storeConsoleUpdate: (consoleId: string | number) => `${host}/api/store_console/${consoleId}/update_console/`,
    userAvailableAccounts: () => `${host}/api/available_accounts/`,
    appOverview: (appId: number) => `${host}/api/application/${appId}/overview/`,
    appQuickEditor: (appId: number) => `${host}/api/application/${appId}/quick_editor/`,
    appVersions: (appId: number) => `${host}/api/application/${appId}/versions/`,
    appUpdate: (appId: number) => `${host}/api/application/${appId}/update_app/`,

    appRemoveLocale: (appId: number) => `${host}/api/application/${appId}/remove_locale/`,
    appImportLocales: (appId: number) => `${host}/api/application/${appId}/import_locales/`,
    appImportTemplate: (appId: number) => `${host}/api/application/${appId}/import_template/`,
    appAddLocale: (appId: number) => `${host}/api/application/${appId}/add_locale/`,
    appEditor: (appId: number) => `${host}/api/application/${appId}/editor/`,
    appSendToAppStore: (appId: number) => `${host}/api/application/${appId}/send_to_appstore/`,
    appImportJson: (appId: number) => `${host}/api/application/${appId}/import_json/`,
    appExportJson: (appId: number) => `${host}/api/application/${appId}/export_json/`,
    appKeywordsScore: (appId: number) => `${host}/api/application/${appId}/get_keywords_score/`,

    template: (templateId?: string) => `${host}/api/metadata_template/${templateId ? `${templateId}/` : ''}`,
    templateOverview: (templateId: string) => `${host}/api/metadata_template/${templateId}/overview/`,
    templateEditor: (templateId: string) => `${host}/api/metadata_template/${templateId}/editor/`,
    templateQuickEditor: (templateId: string) => `${host}/api/metadata_template/${templateId}/quick_editor/`,
    templateAddLocale: (templateId: string) => `${host}/api/metadata_template/${templateId}/add_locale/`,
    templateRemoveLocale: (templateId: string) => `${host}/api/metadata_template/${templateId}/remove_locale/`,
    templateImportLocales: (templateId: string) => `${host}/api/metadata_template/${templateId}/import_locales/`,
    templateImportApp: () => `${host}/api/metadata_template/import_app/`,
    templateImportJson: () => `${host}/api/metadata_template/import_json/`,
    templateExportJson: (templateId: string) => `${host}/api/metadata_template/${templateId}/export_json/`,

    applicationsPage: () => `/store-console/applications/`,
    applicationPage: (appId: string) => `/store-console/applications/${appId}/`,
    applicationEditorPage: (appId: string) => `/store-console/applications/${appId}/editor/`,
    applicationQuickEditorPage: (appId: string) => `/store-console/applications/${appId}/quick-editor/`,
    applicationPresubPage: (appId: string) => `/store-console/applications/${appId}/pre-submision/`,

    templatesPage: () => `/store-console/templates/`,
    noPermissionsPage: () => `/store-console/no-permission/`,
    templatePage: (templateId: string) => `/store-console/templates/${templateId}/`,
    templateEditorPage: (templateId: string) => `/store-console/templates/${templateId}/editor`,
    templateQuickEditorPage: (templateId: string) => `/store-console/templates/${templateId}/quick-editor/`,
};
