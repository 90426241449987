import { DatePickerType } from 'components/datePicker/datePickerType';
import { BreakDownTypeEnum } from '../analysis/analysisTypes';

export enum FiltersReviewsEnum {
    REVIEWS_PRESET = 'reviewsPreset',
    REVIEWS_COUNTRIES = 'reviewsCountries',
    REVIEWS_DATE = 'reviewsDate',
    REVIEWS_VERSION_DATE = 'reviewsVersionDate',
    REVIEWS_RATING = 'reviewsRating',
    REVIEWS_REPLY = 'reviewsReply',
    REVIEWS_CHANGE = 'reviewsChange',
    REVIEWS_LANGUAGE = 'reviewsLanguage',
    REVIEWS_FEATURED = 'reviewsFeatured',
    REVIEWS_TAG = 'reviewsTag',
    REVIEWS_SEARCH_TEXT = 'searchText',
    REVIEWS_SORT = 'reviewsSort',
    REVIEWS_PENDING_REPLIES = 'pendingReplies',
    ACTION_STATUS_REPLIES = 'actionStatus',
    AUTOMATED_REPLY = 'automatedReply',
    REVIEW_LENGTH = 'reviewLength',
    REVIEWS_WITHOUT_TAG = 'reviewsWithoutTag',
    REVIEWS_FAVORITES = 'reviewsFavorites',
}

export type ReviewFiltersInitialStateType = {
    [FiltersReviewsEnum.REVIEWS_PRESET]: FiltersReviewsPresetEnum;
    [FiltersReviewsEnum.REVIEWS_COUNTRIES]: string[];
    [FiltersReviewsEnum.REVIEWS_DATE]: DatePickerType;
    [FiltersReviewsEnum.REVIEWS_VERSION_DATE]: DatePickerType;
    [FiltersReviewsEnum.REVIEWS_RATING]: number[];
    [FiltersReviewsEnum.REVIEWS_REPLY]: {
        type: FiltersReviewsReplyEnum;
        teammatesIds: string[];
    };
    [FiltersReviewsEnum.REVIEWS_CHANGE]: {
        type: FiltersReviewsChangeEnum;
        switchOption: FiltersReviewsChangeEnum;
    };
    [FiltersReviewsEnum.REVIEWS_LANGUAGE]: string[];
    [FiltersReviewsEnum.REVIEWS_FEATURED]: FiltersReviewsFeaturedEnum;
    [FiltersReviewsEnum.REVIEWS_TAG]: ReviewsFilterTagType;
    [FiltersReviewsEnum.REVIEWS_SORT]: ReviewsSortType;
    [FiltersReviewsEnum.REVIEWS_PENDING_REPLIES]: PendingReplyReviewFilterEnum;
    [FiltersReviewsEnum.REVIEWS_SEARCH_TEXT]: string;
    [FiltersReviewsEnum.ACTION_STATUS_REPLIES]: ActionStatusReplyReviewFilterEnum;
    [FiltersReviewsEnum.AUTOMATED_REPLY]: AutomatedReplyReviewFilterEnum;
    [FiltersReviewsEnum.REVIEW_LENGTH]: {
        from: string;
        to: string;
    };
    [FiltersReviewsEnum.REVIEWS_WITHOUT_TAG]: boolean;
    [FiltersReviewsEnum.REVIEWS_FAVORITES]: FavoritesReplyReviewFilterEnum;
};

export type ReviewsSortType<T extends ReviewSortedEnum = ReviewSortedEnum> = {
    type: T;
    direction: ReviewsSortDirectionsType[T];
};

export type ReviewsTagsType = {
    id: number;
    tagType: FiltersTagTypeEnum;
}[];

export type ReviewsFilterTagType = {
    tags: ReviewsTagsType;
    excludeTags: ReviewsTagsType;
    tagSearchType?: FiltersTagSearchTypeEnum;
};

export type TagPresetsType = {
    id: number;
    color: string;
    name: string;
    isBorder: boolean;
};

export type TagTypeProps = {
    [key: string]: (id: number) => TagPresetsType | null;
};

export type ReviewsFilterPresetsTagType = ReviewsFilterTagType & {
    tagsPresets: TagPresetsType[];
};

export enum FiltersReviewsPresetEnum {
    DEFAULT = 'default',
    REQUIRE_ATTENTION = 'require-attention',
}

export enum FiltersReviewsReplyEnum {
    ALL = 'all',
    REPLIED = 'replied',
    NO_REPLIES = 'no-replies',
    REPORTED_CONCERN = 'reported-concern',
}

export enum FiltersReviewsChangeEnum {
    ALL = 'all',
    CHANGED = 'reviewed-change',
    NO_CHANGES = 'no-changes',
    BECAME_WORSE = 'became-worse',
    BECAME_BETTER = 'became-better',
}

export enum FiltersReviewsFeaturedEnum {
    ALL = 'all',
    FEATURED = 'featured',
    DELETED = 'deleted',
    NOT_DELETED = 'not-deleted',
}

export type FilterSwitchType = FiltersReviewsReplyEnum | FiltersReviewsChangeEnum | FiltersReviewsFeaturedEnum | string;

export enum ReviewSortedEnum {
    ORDER = 'order',
    RATING_ORDER = 'ratingOrder',
    REVIEW_LENGTH_ORDER = 'reviewLengthOrder',
}

export enum ReviewsSortDirectionsEnum {
    DATE_OLDEST = 'oldest',
    DATE_NEWEST = 'newest',

    LONG_FIRST = 'length_desc',
    SHORT_FIRST = 'length_asc',

    HIGH_RATING_FIRST = 'rating_desc',
    LOW_RATING_FIRST = 'rating_asc',
}

export enum ReviewsSortDirectionsRequestValuesEnum {
    DATE_OLDEST = 'oldest',
    DATE_NEWEST = 'newest',

    LONG_FIRST = 'desc',
    SHORT_FIRST = 'asc',

    HIGH_RATING_FIRST = 'desc',
    LOW_RATING_FIRST = 'asc',
}

export type ReviewsSortDirectionsType = {
    [ReviewSortedEnum.ORDER]:
        | ReviewsSortDirectionsRequestValuesEnum.DATE_NEWEST
        | ReviewsSortDirectionsRequestValuesEnum.DATE_OLDEST;
    [ReviewSortedEnum.RATING_ORDER]:
        | ReviewsSortDirectionsRequestValuesEnum.LOW_RATING_FIRST
        | ReviewsSortDirectionsRequestValuesEnum.HIGH_RATING_FIRST;
    [ReviewSortedEnum.REVIEW_LENGTH_ORDER]:
        | ReviewsSortDirectionsRequestValuesEnum.LONG_FIRST
        | ReviewsSortDirectionsRequestValuesEnum.SHORT_FIRST;
};

export type ReviewSortedByDirectionsType<T extends ReviewSortedEnum = ReviewSortedEnum> = {
    [key in ReviewsSortDirectionsEnum]: {
        id: T;
        direction: ReviewsSortDirectionsType[T];
    };
};

export const reviewSortedByDirections: ReviewSortedByDirectionsType = {
    [ReviewsSortDirectionsEnum.DATE_NEWEST]: {
        id: ReviewSortedEnum.ORDER,
        direction: ReviewsSortDirectionsRequestValuesEnum.DATE_NEWEST,
    },
    [ReviewsSortDirectionsEnum.DATE_OLDEST]: {
        id: ReviewSortedEnum.ORDER,
        direction: ReviewsSortDirectionsRequestValuesEnum.DATE_OLDEST,
    },
    [ReviewsSortDirectionsEnum.HIGH_RATING_FIRST]: {
        id: ReviewSortedEnum.RATING_ORDER,
        direction: ReviewsSortDirectionsRequestValuesEnum.HIGH_RATING_FIRST,
    },
    [ReviewsSortDirectionsEnum.LOW_RATING_FIRST]: {
        id: ReviewSortedEnum.RATING_ORDER,
        direction: ReviewsSortDirectionsRequestValuesEnum.LOW_RATING_FIRST,
    },
    [ReviewsSortDirectionsEnum.LONG_FIRST]: {
        id: ReviewSortedEnum.REVIEW_LENGTH_ORDER,
        direction: ReviewsSortDirectionsRequestValuesEnum.LONG_FIRST,
    },
    [ReviewsSortDirectionsEnum.SHORT_FIRST]: {
        id: ReviewSortedEnum.REVIEW_LENGTH_ORDER,
        direction: ReviewsSortDirectionsRequestValuesEnum.SHORT_FIRST,
    },
};

export const getReviewSortDirectionType = (id: ReviewSortedEnum, direction: ReviewsSortDirectionsRequestValuesEnum) => {
    const currentSorting = Object.entries(reviewSortedByDirections).find((sorting) => {
        const sortingDescription = sorting[1];
        return sortingDescription.id === id && sortingDescription.direction === direction;
    });

    const sortingTypeWithDirection = currentSorting && (currentSorting[0] as ReviewsSortDirectionsEnum);
    return sortingTypeWithDirection;
};

export enum PendingReplyReviewFilterEnum {
    ALL = 'false',
    PENDING_REPLY = 'true',
}

export enum ActionStatusReplyReviewFilterEnum {
    ALL = 'all',
    PROCESSING = 'processing',
    ERROR = 'error',
}
export enum AutomatedReplyReviewFilterEnum {
    ALL = 'all',
    ONLY_AUTOMATED = 'automated',
}

export enum FavoritesReplyReviewFilterEnum {
    ALL = 'all',
    FAVORITES = 'favorites',
    NOT_FAVORITES = 'not-favorites',
}

export enum FiltersTagSearchTypeEnum {
    ANY = 'any',
    ALL = 'all',
}

export enum FiltersTagTypeEnum {
    WITHOUT_CATEGORY_TAG = 'without-category',
    CATEGORY_TAG = 'category',
}

export type ReviewsFilterPresetsDateType = {
    startDate: number;
    endDate: number;
};

export type ReviewsFilterPresetsVersionDateType = ReviewsFilterPresetsDateType & {
    versionsInterval: string[];
    breakDownType: BreakDownTypeEnum;
};

export type ReviewFiltersPresetsType = Omit<
    ReviewFiltersInitialStateType,
    FiltersReviewsEnum.REVIEWS_TAG | FiltersReviewsEnum.REVIEWS_DATE | FiltersReviewsEnum.REVIEWS_VERSION_DATE
> & {
    [FiltersReviewsEnum.REVIEWS_TAG]: ReviewsFilterPresetsTagType;
    [FiltersReviewsEnum.REVIEWS_DATE]: ReviewsFilterPresetsDateType;
    [FiltersReviewsEnum.REVIEWS_VERSION_DATE]: ReviewsFilterPresetsVersionDateType;
};

export type RatingAnalysisFiltersPresetsType = Pick<
    ReviewFiltersPresetsType,
    FiltersReviewsEnum.REVIEWS_COUNTRIES | FiltersReviewsEnum.REVIEWS_DATE | FiltersReviewsEnum.REVIEWS_VERSION_DATE
>;

export type LengthReviewType = {
    from: string;
    to: string;
};

export enum LengthQueryParamsEnum {
    FROM = 'lengthFrom',
    TO = 'lengthTo',
}
