import { TabType } from 'state/keywordAnalytics/KeywordAnalyticsDataType';
import { OptimizerInputNameEnum } from '../../constants/optimizerTagsCountants';

export enum OptimizerTabIdsEnum {
    KEYWORDS_REPORT = 'keywordsReport',
    SEARCH_QUERIES_REPORT = 'searchQueriesReport',
    SEMANTIC_REPORT = 'semanticReport',
}

export type OptimizerReportResponseType = {
    [key: string]: {
        id: number;
        keyword: string;
        popularity: number;
        avg_popularity: number;
        rank: 'Calculating' | number;
        ipad_rank: 'Calculating' | number;
        traffic_score: 'Calculating' | number;
        avg_traffic_score: 'Calculating' | number;
        translation: string;
        translation_from: string;
        total_apps: 'Calculating' | number;
        metadata?: string;
        count?: number;
        has_rank?: number;
        has_ipad_rank?: number;
    }[];
};

export type OptimizerKeywordReportType = {
    lang: string;
    id: number;
    keyword: string;
    popularity: number;
    avgPopularity: number;
    rank: 'Calculating' | number;
    ipadRank: 'Calculating' | number;
    usersPerDay: 'Calculating' | number;
    avgUsersRerDay: 'Calculating' | number;
    translation: string;
    translationFrom: string;
    totalApps: 'Calculating' | number;
    metaData?: string;
    count?: number;
    hasRank?: number;
    hasIpadRank?: number;
    copy?: boolean;
    add?: boolean;
};

export type OptimizerSummaryResponseType = {
    [key: string]: {
        total_count: number;
        total_rank: number;
        total_ipad_rank?: number;
        avg_traffic_score: number;
        avg_searchads_popularity: number;
    };
};

export type OptimizerSummaryReportType = {
    [key: string]: {
        totalSearch: number;
        totalRank: number;
        totalIpadRank?: number;
        avgUsersPerDay: number;
        avgPopularity: number;
    };
};

export type TagsType = string[];

export type LocaleStateType = {
    name: string;
    lang: string;
};

export type LocalesInfoType = {
    main: LocaleStateType;
    additional: LocaleStateType[];
};

export type LocalesInfoStateType = {
    main: LocaleStateType;
    additional: LocaleStateType[];
};

export type OptimizerMetaType = {
    title: string;
    keywords: string;
    subtitle: string;
};

export type OptimizerInputTagsType = {
    description: string;
    tags: TagsType;
    initialTags: TagsType;
    stringTags: string;
    initialStringTags: string;
    name: OptimizerInputNameEnum;
    limit: number;
};

export type OptimizerCountTagsType = { [key: string]: number };

export type OptimizerMetaStateType = {
    lang: string;
    isShow: boolean;
    inputs: OptimizerInputTagsType[];
    countTags: OptimizerCountTagsType;
};

export type OptimizerSetMetaType = {
    title: TagsType;
    keywords: TagsType;
    subtitle: TagsType;
};

export type LocalMetaType = {
    [key: string]: OptimizerSetMetaType;
};

export type LocalMetaConvertedType = OptimizerSetMetaType & {
    lang: string;
};

export type SetResponseType = {
    id: number;
    name: string;
    meta: LocalMetaType;
    created_at: string;
    sqr_used_words_count: number;
};

export type TabReportType = {
    keywords?: OptimizerKeywordReportType[];
    emptyTableText?: string;
};

export type StopWordsResponseType = {
    stop_words: string[];
};

export type OptimizerTagsResponseType = StopWordsResponseType & {
    app_locale_id: number;
    user_id: number;
    is_asc_connected: boolean;
    locales_info: {
        main_locale: LocaleStateType;
        additional_locales: LocaleStateType[];
    };
    opt_sets: SetResponseType[];
    initial_set_data: OptimizerMetaType;
};

export type OptimizerSetStateType = {
    id: number;
    name: string;
    meta: LocalMetaConvertedType[];
    dateCreated: string;
    searchQueriesCount: number;
};

export type OptimizerTagsConvertedResponseType = {
    isAscConnected: boolean;
    localesInfo: LocalesInfoType;
    stopWords: string[];
    sets: OptimizerSetStateType[];
    initialSet: OptimizerMetaType;
};

export type OptimizerCurrentSetType = {
    id?: number;
    name?: string;
    meta: OptimizerMetaStateType[];
    dateCreated?: string;
};

export type MetaReportType = {
    tabId: number | null;
    meta: OptimizerMetaStateType[];
};

export type ReportType = {
    activeTabId: OptimizerTabIdsEnum;
    tabs: {
        [OptimizerTabIdsEnum.SEARCH_QUERIES_REPORT]: TabReportType;
        [OptimizerTabIdsEnum.KEYWORDS_REPORT]: TabReportType;
        [OptimizerTabIdsEnum.SEMANTIC_REPORT]: TabReportType;
    };
};

export type OptimizerStateType = {
    isAscConnected: boolean;
    localesInfo: LocalesInfoStateType;
    stopWords: string[];
    tabs: TabType[];
    sets: OptimizerSetStateType[];
    initialSet: OptimizerCurrentSetType;
    currentSet: OptimizerCurrentSetType;
    metaReport: MetaReportType;
    summaryReport: OptimizerSummaryReportType;
    reports: {
        used: ReportType;
        unused: ReportType;
    };
};
