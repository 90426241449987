import { useContext } from 'react';
import { ProductTourContext } from 'provider/ProductTourProvider';

export const useProductTour = () => {
    const { driver } = useContext(ProductTourContext);

    const driverActiveIndex = driver?.getActiveIndex();

    const isCountrySelectStepIndex = driverActiveIndex === 0;
    const isAddFirstAppStepIndex = driverActiveIndex === 1;
    const isGlobalNavigationStepIndex = driverActiveIndex === 2;
    const isLocalNavigationAndToolsStepIndex = driverActiveIndex === 3;
    const isPossibilitiesOfAsodeskStepIndex = driverActiveIndex === 4;
    const isReviewsRatingStepIndex = driverActiveIndex === 5;
    const isStoresAnalyticsStepIndex = driverActiveIndex === 6;
    const isConnectionsHubStepIndex = driverActiveIndex === 7;
    const isKeywordBoostStepIndex = driverActiveIndex === 8;

    return {
        driver,
        isCountrySelectStepIndex,
        isAddFirstAppStepIndex,
        isGlobalNavigationStepIndex,
        isLocalNavigationAndToolsStepIndex,
        isPossibilitiesOfAsodeskStepIndex,
        isReviewsRatingStepIndex,
        isStoresAnalyticsStepIndex,
        isConnectionsHubStepIndex,
        isKeywordBoostStepIndex,
    };
};
