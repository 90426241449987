// @ts-nocheck
import {
    RECEIVED_MD_TEMPLATES,
    RECEIVED_MD_OVERVIEW,
    RECEVIED_MD_FIELDS,
    RECEVIED_MD_QUICK_FIELD,
    RECEIVED_MD_LOCALES,
    RECEVIED_MD_AUTOSAVETIME,
    IMPORT_MD_LOCALE,
} from 'state/storeConsoleTemplates/actionsTypes';
import { dateJs } from 'utils/helpers/date/date';

const find = require('lodash/find');

const defaultMdTemplates = {
    templates: [],
    templatesOverview: {},
    editorFields: {},
    quickFields: {},
    autoSaveTime: '',
};

export const storeConsoleTemplates = (state = defaultMdTemplates, action) => {
    switch (action.type) {
        case RECEIVED_MD_TEMPLATES:
            return {
                ...state,
                templates: action.data
                    .map((t) => {
                        t.created = dateJs(t.created_at);
                        t.updated = dateJs(t.updated_at);
                        return t;
                    })
                    .sort((a, b) => {
                        return b.created - a.created;
                    }),
            };
        case RECEIVED_MD_OVERVIEW: {
            return {
                ...state,
                templatesOverview: {
                    ...state.templatesOverview,
                    [action.data.id]: action.data,
                },
            };
        }
        case RECEVIED_MD_FIELDS:
            const templateLocales = state.templatesOverview[action.data.templateId]
                ? state.templatesOverview[action.data.templateId].template_locales
                : [];
            const foundLocale = find(templateLocales, { locale: action.data.fields.locale });
            if (foundLocale) Object.assign(foundLocale, action.data.fields);
            return {
                ...state,
                templatesOverview: {
                    ...state.templatesOverview,
                    [action.data.templateId]: {
                        ...state.templatesOverview[action.data.templateId],
                        template_locales: templateLocales,
                    },
                },
                editorFields: {
                    ...state.editorFields,
                    [action.data.templateId]: {
                        ...state.editorFields[action.data.templateId],
                        [action.data.fields.locale]: action.data.fields,
                    },
                },
            };
        case RECEVIED_MD_QUICK_FIELD:
            return {
                ...state,
                quickFields: {
                    [action.data.templateId]: {
                        ...state.editorFields[action.data.templateId],
                        [action.data.fields.field_name]: action.data.fields.locales,
                    },
                },
            };
        case RECEIVED_MD_LOCALES:
            return {
                ...state,
                templatesOverview: {
                    ...state.templatesOverview,
                    [action.data.id]: {
                        ...state.templatesOverview[action.data.id],
                        template_locales: action.data.locales,
                    },
                },
            };
        case RECEVIED_MD_AUTOSAVETIME:
            return {
                ...state,
                mdAutoSaveTime: action.data,
            };
        case IMPORT_MD_LOCALE:
            const importedLocales = action.data.overview.map((overview) => overview.code);
            const templateList = state.templatesOverview[action.data.templateId].template_locales.filter(
                (template) => !importedLocales.includes(template.code)
            );

            return {
                ...state,
                templatesOverview: {
                    ...state.templatesOverview,
                    [action.data.templateId]: {
                        ...state.templatesOverview[action.data.templateId],
                        template_locales: [...templateList, ...action.data.overview],
                    },
                },
            };
        default:
            return state;
    }
};
