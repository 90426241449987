import './ballLoader.scss';
import React from 'react';
import classNames from 'classnames';
import { BaseBallLoader } from 'components/loader/baseBallLoader/BaseBallLoader';

export type PropsType = {
    pageCenter?: boolean;
    elementCenter?: boolean;
    size?: 'md' | 'lg';
    state?: 'primary' | 'default';
    className?: string;
};

const BallLoaderElement: React.FC<PropsType> = ({ pageCenter, elementCenter, size = 'lg', state = 'primary', className }: PropsType) => (
    <BaseBallLoader
        className={classNames(
            'ballSpinFadeLoader',
            {
                pageCenter,
                elementCenter,
                [`ballSpinFadeLoader--${size}`]: size,
                [`ballSpinFadeLoader--${state}`]: state,
            },
            className
        )}
    >
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
    </BaseBallLoader>
);

export const BallLoader = React.memo(BallLoaderElement);
