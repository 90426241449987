import { BenchmarkByKeywordType } from 'state/benchmark/BenchmarkType';
import { dateFormatYYYYMM } from 'constants/dateFormatConstants';
import { getNow } from 'utils/helpers/date/date';
import { BenchmarkActionTypeListEnum } from './actionsTypes';
import { BenchmarkActionType } from './actions';

export type BenchmarkStateType = BenchmarkByKeywordType & {
    month: string;
    appType: string;
    storeType: string;
};

const benchmarkInitialState: BenchmarkStateType = {
    sourcesOrder: {},
    categories: [],
    month: getNow().subtract(1, 'months').format(dateFormatYYYYMM),
    appType: 'apps',
    storeType: 'apple-store',
};

const benchmark = (state = benchmarkInitialState, action: BenchmarkActionType) => {
    switch (action.type) {
        case BenchmarkActionTypeListEnum.GET_BENCHMARK_DATA: {
            return {
                ...state,
                categories: action.data.categories,
                sourcesOrder: action.data.sourcesOrder,
            };
        }
        case BenchmarkActionTypeListEnum.CHANGE_BENCHMARK_MONTH: {
            return {
                ...state,
                month: action.month,
            };
        }
        case BenchmarkActionTypeListEnum.CHANGE_BENCHMARK_STORE_TYPE: {
            return {
                ...state,
                storeType: action.storeType,
            };
        }
        case BenchmarkActionTypeListEnum.CHANGE_BENCHMARK_APP_TYPE: {
            return {
                ...state,
                appType: action.appType,
            };
        }
        default: {
            return state;
        }
    }
};

export default benchmark;
