import { useState } from 'react';

type UseVisibilityElementType = {
    delayShow?: number;
    delayHide?: number;
};

export const useVisibilityElement = (delayProps?: UseVisibilityElementType) => {
    const { delayShow, delayHide } = delayProps || { delayShow: 0, delayHide: 0 };

    let timeoutHide: NodeJS.Timeout;
    let timeoutShow: NodeJS.Timeout;

    const [showElement, setShowElement] = useState(false);

    const onMouseEnter = () => {
        if (timeoutHide) clearTimeout(timeoutHide);

        if (delayShow) {
            timeoutShow = setTimeout(() => setShowElement(true), delayShow);
        } else {
            setShowElement(true);
        }
    };

    const onMouseLeave = () => {
        if (timeoutShow) clearTimeout(timeoutShow);

        if (delayHide) {
            timeoutHide = setTimeout(() => {
                setShowElement(false);
            }, delayHide * 2);
        } else {
            setShowElement(false);
        }
    };

    return {
        showElement,
        setShowElement,
        onMouseEnter,
        onMouseLeave,
    };
};
