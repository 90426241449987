import { getLeftDays } from 'utils/helpers/date/date';
import { StatsUserLimitsType } from 'state/userFrontendSettings/StatsUserLimitsType';
import { accountRoutePricingUrl } from 'utils/routeUrls';
import { StatsUserLimitIdsEnum, StatsUserLimitResponseType } from './SidebarResponseType';

const MAX_LIMIT_VALUE = 999999;

export const getDisplayValue = (currentValue: number, maxValue: number): string | number => `${currentValue}/${maxValue}`;

export const statsUserLimitDataConverter = (data: StatsUserLimitResponseType): StatsUserLimitsType => {
    const currentApiCreditsValue = data.credits_total - data.credits_left;

    return {
        title: data.subscription.public ? data.subscription.display_name : 'Individual',
        ...(!data.subscription.is_free
            ? {
                  date: `${getLeftDays(data.subscription.end)} days left`,
              }
            : {}),
        hasUpgradeButton: data.subscription.keyword_limit < MAX_LIMIT_VALUE,
        url: accountRoutePricingUrl,

        // TODO: make arrays to objects
        limits: [
            ...(data.permissions.can_manage_applications
                ? [
                      {
                          id: StatsUserLimitIdsEnum.APPLICATIONS,
                          title: 'Applications',
                          url: accountRoutePricingUrl,
                          current: data.app_count,
                          max: data.subscription.app_limit,
                          displayValue: getDisplayValue(data.app_count, data.subscription.app_limit),
                      },
                  ]
                : []),
            ...(data.permissions.can_manage_keywords
                ? [
                      {
                          id: StatsUserLimitIdsEnum.KEYWORDS,
                          title: 'Keywords',
                          url: accountRoutePricingUrl,
                          current: data.keyword_count,
                          max: data.subscription.keyword_limit,
                          displayValue: getDisplayValue(data.keyword_count, data.subscription.keyword_limit),
                      },
                      {
                          id: StatsUserLimitIdsEnum.DAILY_KEYWORDS_STATS,
                          title: 'Daily Keywords Stats',
                          url: accountRoutePricingUrl,
                          current: data.keywords_left,
                          max: data.keyword_stats_limit,
                          displayValue: getDisplayValue(data.keywords_left, data.keyword_stats_limit),
                      },
                  ]
                : []),
            {
                id: StatsUserLimitIdsEnum.MONTHLY_REVIEW_REPLIES,
                title: 'Monthly Review Replies',
                url: accountRoutePricingUrl,
                current: data.replies_left,
                max: data.replies_limit,
                displayValue: getDisplayValue(data.replies_left, data.replies_limit),
            },
            ...(data.permissions.can_manage_teammates
                ? [
                      {
                          id: StatsUserLimitIdsEnum.TEAMMATES,
                          title: 'Teammates',
                          url: accountRoutePricingUrl,
                          current: data.teammates_count,
                          max: data.subscription.teammates_limit,
                          displayValue: getDisplayValue(data.teammates_count, data.subscription.teammates_limit),
                      },
                  ]
                : []),
            ...(data.permissions.can_use_api && data.api_subscription
                ? [
                      {
                          id: StatsUserLimitIdsEnum.API_CREDITS,
                          title: 'API Credits',
                          url: accountRoutePricingUrl,
                          current: currentApiCreditsValue,
                          max: data.credits_total,
                          displayValue: getDisplayValue(currentApiCreditsValue, data.credits_total),
                      },
                  ]
                : []),
        ],
        subscription: {
            isWelcomeOfferCurrentPlanAvailable: data.subscription.is_welcome_offer_plan_available,
            lastTrialPlanId: data.subscription.last_trial_plan_id,
        },
    };
};
