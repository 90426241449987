import { GooglePlayProviderType } from 'state/integrationsGoogle/googlePlayProviderListType';
import { IntegrationsGoogleActionTypeEnum } from 'state/integrationsGoogle/actionsTypes';
import { GoogleConsoleModalTabIndexEnum } from 'modules/settings/integrations/googleIntegrations/modal/GoogleConsoleModalTabIndexEnum';
import { GoogleConsoleIntegrationsActionType } from './actions';
import { InitialGooglePlayFormInModalType } from './InitialGooglePlayFormInModalType';

export type GoogleConsoleIntegrationsStateType = {
    integrations: GooglePlayProviderType[];
    activeIntegration: InitialGooglePlayFormInModalType;
    activeTabId: GoogleConsoleModalTabIndexEnum;
};

export const initialIntegrationId = -1;

export const initialActiveIntegration: InitialGooglePlayFormInModalType = {
    id: initialIntegrationId,
    storeIds: [],
    accountId: undefined,
    apiKey: undefined,
    apiSecret: undefined,
    isInvited: false,
    apps: [],
};

const initialState: GoogleConsoleIntegrationsStateType = {
    integrations: [],
    activeIntegration: initialActiveIntegration,
    activeTabId: GoogleConsoleModalTabIndexEnum.REVIEW_AND_REPLY,
};

const googleConsoleIntegrations = (state = initialState, action: GoogleConsoleIntegrationsActionType) => {
    switch (action.type) {
        case IntegrationsGoogleActionTypeEnum.SET_GOOGLE_CONSOLE_INTEGRATIONS: {
            return {
                ...state,
                integrations: action.integrations,
            };
        }
        case IntegrationsGoogleActionTypeEnum.CREATE_GOOGLE_CONSOLE_INTEGRATIONS: {
            return {
                ...state,
                integrations: [...state.integrations, action.integration],
            };
        }
        case IntegrationsGoogleActionTypeEnum.UPDATE_GOOGLE_CONSOLE_INTEGRATIONS: {
            const integrationIndex = state.integrations.findIndex(({ id }) => id === action.integration.id);
            return {
                ...state,
                integrations: [
                    ...state.integrations.slice(0, integrationIndex),
                    action.integration,
                    ...state.integrations.slice(integrationIndex + 1),
                ],
            };
        }
        case IntegrationsGoogleActionTypeEnum.DELETE_GOOGLE_CONSOLE_INTEGRATIONS: {
            return {
                ...state,
                integrations: state.integrations.filter(({ id }) => id !== action.providerId),
            };
        }
        case IntegrationsGoogleActionTypeEnum.SET_ACTIVE_GOOGLE_CONSOLE_INTEGRATIONS: {
            return {
                ...state,
                activeIntegration: action.integration,
            };
        }
        case IntegrationsGoogleActionTypeEnum.SET_ACTIVE_MODAL_TAB_GOOGLE_CONSOLE_INTEGRATIONS: {
            return {
                ...state,
                activeTabId: action.tabIndex,
            };
        }
        default: {
            return state;
        }
    }
};

export default googleConsoleIntegrations;
