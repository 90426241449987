import { AvailableFeaturesKeyEnum, AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';

export const plansFeaturesAdapter = (features: AvailableFeaturesType): AvailableFeaturesType => {
    const adaptedData: AvailableFeaturesType = {};
    Object.keys(features).forEach((featureKey) => {
        const feature = featureKey as AvailableFeaturesKeyEnum;
        const { key, name, description } = features[feature] || {};
        if (key && name && description) {
            adaptedData[feature] = { key, name, description };
        }
    });

    return adaptedData;
};
