import { UserActionType } from 'state/user/actions';
import { UserActionTypeEnum } from './actionsTypes';

export type UserStateType = {
    isLogged: boolean;
};

const userInitialState: UserStateType = {
    isLogged: false,
};

const user = (state = userInitialState, action: UserActionType) => {
    switch (action.type) {
        case UserActionTypeEnum.SET_USER_LOGGED: {
            return {
                ...state,
                isLogged: action.isLogged,
            };
        }
        default: {
            return state;
        }
    }
};

export default user;
