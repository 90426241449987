export enum TextEventsActionsEnum {
    ACCOUNT_IS_CREATED = 'Аккаунт создан',
    ACCOUNT_IS_VERIFIED = 'Аккаунт подтвержден',
}

export enum TextEventsCategoriesEnum {
    SYSTEM = 'Система',
    STANDARD = 'Стандартная воронка',
}

export enum TextEventsLabelsEnum {
    GOOGLE = 'Google',
    EMAIL = 'Email',
}

export enum TextEventsEnum {
    FUNNEL = 'funnel',
    VERIFY = 'verify',
}

export enum PaymentEventsEnum {
    CLICK_BUTTON_CONTINUE_WORKING = 'click_button_continue_working',
    CLICK_BUTTON_CLOSE_MODAL_IN_CONGRATULATIONS_STEP = 'click_button_close_modal_in_congratulations_step',
    ESC_CLICK_BUTTON_CLOSE_MODAL_IN_CONGRATULATIONS_STEP = 'esc_click_button_close_modal_in_congratulations_step',
}
