import { DeviceTypeEnum } from 'state/appProfile/AppResponseType';

export const getKeywordProfileHash = ({
    storeId,
    keyword,
    device,
}: {
    storeId: string;
    keyword: string;
    device: DeviceTypeEnum;
}) => {
    return `${storeId}_${keyword}_${device}`;
};
