import { parseQueryString } from 'utils/helpers/queryString';
import { DEFAULT_LANGUAGE } from 'constants/localizationsConstants';
import { ReplyBoardActionTypeListEnum } from './actionsTypes';
import { ReplyBoardActionType } from './actions';
import { ReplyBoardInitialStateType, ReviewAnswerStatusEnum, ReviewAnswerStatusIdEnum, ReviewsDataType } from './ReplyBoardTypes';
import { defaultAppRatingPreset } from './appRatingPresets';

const { search } = window.location;
const { review_id: activeReviewId, has_translate: hasShowTranslate } = parseQueryString(search);

const replyBoardInitialState = {
    appRatings: defaultAppRatingPreset,
    reviews: {
        ids: [],
        byIds: {},
        nextPage: null,
        activeSingleReviewId: activeReviewId ? +activeReviewId : 0,
        activeMultipleReviewIds: [],
        activeEditableReviewId: null,
        count: 0,
        countNoReplies: 0,
        canReplyReview: false,
        canReportReply: false,
        hasShowTranslate: !!(hasShowTranslate && hasShowTranslate === 'true'),
    },
    appSettings: {
        isReviewsAutoTranslate: false,
        reviewsTranslationLanguage: DEFAULT_LANGUAGE,
    },
};

type UpdatedReviewByIdsWithStatusType = Pick<ReviewsDataType, 'ids' | 'byIds'> & {
    statusName: ReviewAnswerStatusEnum;
    statusId: ReviewAnswerStatusIdEnum;
};

const updatedReviewByIdsWithStatus = ({ ids, byIds, statusName, statusId }: UpdatedReviewByIdsWithStatusType) =>
    ids.reduce((result, reviewId) => {
        const processingStatusProps = {
            status: statusName,
            statusId,
        };

        const review = byIds[reviewId];

        if (review.answer?.statusId === ReviewAnswerStatusIdEnum.PENDING_APPROVAL) {
            const updatedAnswers = review.answers.map((answer, index) => {
                if (index === 0) {
                    return {
                        ...answer,
                        ...processingStatusProps,
                    };
                }

                return answer;
            });

            return {
                ...result,
                [reviewId]: {
                    ...review,
                    ...(review.answer
                        ? {
                              answer: {
                                  ...review.answer,
                                  statusId,
                              },
                          }
                        : {}),
                    answers: updatedAnswers,
                    ...processingStatusProps,
                },
            };
        }

        return {
            ...result,
            [reviewId]: review,
        };
    }, {});

const replyBoard = (
    state: ReplyBoardInitialStateType = replyBoardInitialState,
    action: ReplyBoardActionType
): ReplyBoardInitialStateType => {
    switch (action.type) {
        case ReplyBoardActionTypeListEnum.SET_SINGLE_REVIEW_ID: {
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    activeSingleReviewId: action.id,
                    activeMultipleReviewIds: [],
                    activeEditableReviewId: null,
                },
            };
        }
        case ReplyBoardActionTypeListEnum.SET_MULTIPLE_REVIEW_IDS: {
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    activeSingleReviewId: null,
                    activeMultipleReviewIds: action.ids,
                    activeEditableReviewId: null,
                },
            };
        }
        case ReplyBoardActionTypeListEnum.UNSET_REVIEW_IDS: {
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    activeMultipleReviewIds: [],
                },
            };
        }
        case ReplyBoardActionTypeListEnum.CREATE_NEW_REVIEW_ANSWER: {
            const updatedByIds = action.reviewIds.reduce(
                (reviewById, reviewId) => ({
                    [reviewId]: {
                        ...state.reviews.byIds[reviewId],
                        answer: action.newReviewAnswer[reviewId].review,
                        answers: [action.newReviewAnswer[reviewId], ...state.reviews.byIds[reviewId].answers],
                    },
                    ...reviewById,
                }),
                {}
            );

            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: {
                        ...state.reviews.byIds,
                        ...updatedByIds,
                    },
                    activeEditableReviewId: null,
                },
            };
        }
        case ReplyBoardActionTypeListEnum.RESET_NEW_ANSWER_REVIEWS: {
            const updatedByIds = action.reviewIds.reduce(
                (reviewById, reviewId) => ({
                    [reviewId]: {
                        ...state.reviews.byIds[reviewId],
                        answers: state.reviews.byIds[reviewId].answers.map((answer) => ({
                            ...answer,
                            isNew: false,
                        })),
                        tags: state.reviews.byIds[reviewId].tags.map((tag) => ({
                            ...tag,
                            isNew: false,
                        })),
                    },
                    ...reviewById,
                }),
                {}
            );

            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: {
                        ...state.reviews.byIds,
                        ...updatedByIds,
                    },
                },
            };
        }
        case ReplyBoardActionTypeListEnum.SET_REVIEWS: {
            const {
                ids,
                byIds,
                reviewDateLastUpdated,
                reviewDateFirstCreate,
                reviewDateLastFetching,
                nextPage,
                count,
                countNoReplies,
                canReplyReview,
                canReportReply,
            } = action.reviewsData;

            const updatedIds = action.isUpdate ? [...state.reviews.ids, ...ids] : ids;
            const updatedByIds = action.isUpdate
                ? {
                      ...state.reviews.byIds,
                      ...byIds,
                  }
                : byIds;

            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    ids: updatedIds,
                    byIds: updatedByIds,
                    reviewDateLastUpdated,
                    reviewDateFirstCreate,
                    reviewDateLastFetching,
                    nextPage,
                    count,
                    countNoReplies,
                    canReplyReview,
                    canReportReply,
                },
            };
        }
        case ReplyBoardActionTypeListEnum.SET_SINGLE_REVIEW: {
            const { ids, byIds, canReplyReview, canReportReply } = action;

            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    ids,
                    byIds,
                    canReplyReview,
                    canReportReply,
                },
            };
        }
        case ReplyBoardActionTypeListEnum.SET_APP_RATINGS_REVIEWS: {
            return {
                ...state,
                appRatings: action.appRatings,
            };
        }
        case ReplyBoardActionTypeListEnum.REMOVE_USER_TAGS: {
            const updatedByIds = action.reviewIds.reduce(
                (reviewById, reviewId) => ({
                    [reviewId]: {
                        ...state.reviews.byIds[reviewId],
                        tags: state.reviews.byIds[reviewId].tags.filter((tag) => !action.tagIds.includes(tag.id)),
                    },
                    ...reviewById,
                }),
                {}
            );

            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: {
                        ...state.reviews.byIds,
                        ...updatedByIds,
                    },
                },
            };
        }
        case ReplyBoardActionTypeListEnum.ADD_USER_TAG: {
            const updatedByIds = action.reviewIds.reduce(
                (reviewById, reviewId) => ({
                    [reviewId]: {
                        ...state.reviews.byIds[reviewId],
                        tags: [...state.reviews.byIds[reviewId].tags, action.tag],
                    },
                    ...reviewById,
                }),
                {}
            );

            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: {
                        ...state.reviews.byIds,
                        ...updatedByIds,
                    },
                },
            };
        }
        case ReplyBoardActionTypeListEnum.REMOVE_LAST_DEVELOPER_ANSWER: {
            const { answers } = state.reviews.byIds[action.reviewId];
            const lastAnswersIndex = answers.length - 1;

            const updatedAnswers = [...answers];
            updatedAnswers[lastAnswersIndex] = {
                ...updatedAnswers[lastAnswersIndex],
                status: ReviewAnswerStatusEnum.PROCESSING,
            };

            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: {
                        ...state.reviews.byIds,
                        [action.reviewId]: {
                            ...state.reviews.byIds[action.reviewId],
                            answers: updatedAnswers,
                        },
                    },
                },
            };
        }
        case ReplyBoardActionTypeListEnum.EDIT_REVIEW_ANSWER: {
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    activeEditableReviewId: action.reviewId,
                },
            };
        }
        case ReplyBoardActionTypeListEnum.ACCEPT_REVIEWS_ANSWER: {
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: {
                        ...state.reviews.byIds,
                        ...updatedReviewByIdsWithStatus({
                            ids: action.reviewIds,
                            byIds: state.reviews.byIds,
                            statusName: ReviewAnswerStatusEnum.PROCESSING,
                            statusId: ReviewAnswerStatusIdEnum.PROCESSING,
                        }),
                    },
                },
            };
        }
        case ReplyBoardActionTypeListEnum.DECLINE_REVIEWS_ANSWER: {
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: {
                        ...state.reviews.byIds,
                        ...updatedReviewByIdsWithStatus({
                            ids: action.reviewIds,
                            byIds: state.reviews.byIds,
                            statusName: ReviewAnswerStatusEnum.DECLINED,
                            statusId: ReviewAnswerStatusIdEnum.DECLINED,
                        }),
                    },
                },
            };
        }
        case ReplyBoardActionTypeListEnum.UPDATE_REVIEW_TRANSLATE: {
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: {
                        ...state.reviews.byIds,
                        [action.reviewId]: {
                            ...state.reviews.byIds[action.reviewId],
                            answers: state.reviews.byIds[action.reviewId].answers.map((answer, index) => {
                                if (index === action.answerIndex) {
                                    return {
                                        ...answer,
                                        isShowTranslate:
                                            action.isShowTranslate !== undefined
                                                ? action.isShowTranslate
                                                : answer.isShowTranslate,
                                        translations: action.translations || answer.translations,
                                        translationFromLanguage: action.translationFromLanguage || answer.translationFromLanguage,
                                    };
                                }

                                return answer;
                            }),
                        },
                    },
                },
            };
        }
        case ReplyBoardActionTypeListEnum.UPDATE_APP_SETTINGS: {
            const { type, ...updatedAppSettings } = action;

            return {
                ...state,
                appSettings: {
                    ...state.appSettings,
                    ...updatedAppSettings,
                },
            };
        }
        case ReplyBoardActionTypeListEnum.TOGGLE_VISIBILITY_TRANSLATE: {
            const { isReviewsAutoTranslate, reviewsTranslationLanguage } = state.appSettings;

            const updatedReviews: ReviewsDataType['byIds'] = state.reviews.ids.reduce(
                (result, reviewId) => ({
                    ...result,
                    [reviewId]: {
                        ...state.reviews.byIds[reviewId],
                        answers: state.reviews.byIds[reviewId].answers.map((answer) => {
                            const hasTranslate = !!(answer.translations && answer.translations[reviewsTranslationLanguage]);

                            return {
                                ...answer,
                                isShowTranslate: hasTranslate && (isReviewsAutoTranslate || answer.isShowTranslate),
                            };
                        }),
                    },
                }),
                {}
            );

            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: updatedReviews,
                },
            };
        }

        case ReplyBoardActionTypeListEnum.ADD_TO_FAVORITE_REVIEW: {
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: {
                        ...state.reviews.byIds,
                        [action.reviewId]: {
                            ...state.reviews.byIds[action.reviewId],
                            isFavorite: true,
                        },
                    },
                },
            };
        }

        case ReplyBoardActionTypeListEnum.REMOVE_FROM_FAVORITE_REVIEW: {
            return {
                ...state,
                reviews: {
                    ...state.reviews,
                    byIds: {
                        ...state.reviews.byIds,
                        [action.reviewId]: {
                            ...state.reviews.byIds[action.reviewId],
                            isFavorite: false,
                        },
                    },
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default replyBoard;
