import { TrackedAppsResponseType } from 'state/trackedApps/TrackedAppsResponseType';
import { TrackedAppsType } from 'state/trackedApps/TrackedAppsType';

export const trackedAppsAdapter = ({
    num_pages,
    current_page,
    results,
    ...trackedAppsProps
}: TrackedAppsResponseType): TrackedAppsType => ({
    numPages: num_pages,
    currentPage: current_page,
    appList: results,
    ...trackedAppsProps,
});
