import { CategoryPlanType, SubPlanType } from 'state/plans/PlansType';
import { DefaultModalPlanType } from 'modules/modal/planModal/DefaultModalPlanType';

export const getCallButtonText = (comparedRealByTypePlan: SubPlanType, currentPlan: DefaultModalPlanType) => {
    const isCurrentPlan = comparedRealByTypePlan.order === currentPlan.order;
    if (isCurrentPlan) {
        return 'Stay on';
    }
    return currentPlan && comparedRealByTypePlan.order < currentPlan.order ? 'Subscribe to' : 'Upgrade to';
};

export const getCouponText = (
    discount: number | null,
    comparedRealByTypePlan?: SubPlanType,
    comparedBasePlan?: CategoryPlanType,
    currentPlan?: DefaultModalPlanType
): string => {
    if (comparedRealByTypePlan && currentPlan && comparedBasePlan) {
        const callText = getCallButtonText(comparedRealByTypePlan, currentPlan);
        return callText;
    }
    return '';
};

export const getIsGuruAso = (planName: string) => planName.toLowerCase().includes('guru aso');
export const getIsPowerAso = (planName: string) => planName.toLowerCase().includes('power aso');
export const getIsUltimateAso = (planName: string) => planName.toLowerCase().includes('ultimate aso');

export const getIsAsoBusinessPlan = (planName: string) =>
    getIsGuruAso(planName) || getIsPowerAso(planName) || getIsUltimateAso(planName);

// TODO: add for plan new prop is_only_annual to backend
export const checkIsBusiness = (planName: string) => {
    const isBusiness = planName.toLowerCase().includes('business');
    const isPowerAso = getIsPowerAso(planName);
    const isPowerReviews = planName.toLowerCase().includes('power reviews');
    const isUltimate = planName.toLowerCase().includes('ultimate');

    return isBusiness || isPowerAso || isUltimate || isPowerReviews;
};
