import { OnboardingActionTypeEnum } from 'state/onboarding/actionTypes';
import { OnboardingStepEnum, OnboardingStateType } from './types';
import { OnboardingActionType } from './actions';

export const initialState: OnboardingStateType = {
    isShowTour: false,
    isReadyTour: false,
    onboardingCurrentStepId: OnboardingStepEnum.YOURSELF_STEP_FIRST,
    onboardingSteps: {
        [OnboardingStepEnum.YOURSELF_STEP_FIRST]: {
            companyName: '',
            kindOfWork: 'Product Management',
            role: 'Business owner/Founder',
        },
        [OnboardingStepEnum.YOURSELF_STEP_SECOND]: {
            peopleCount: 'Only me',
            approximatelyPeopleCount: '1 - 19',
            appsCount: 'I don’t have an app',
        },
        [OnboardingStepEnum.YOURSELF_STEP_THIRD]: {
            hearAboutUs: 'Search Engine (Google, Bing, Yandex, etc.)',
        },
        [OnboardingStepEnum.YOURSELF_STEP_FOURTH]: {},
        [OnboardingStepEnum.SELECT_COUNTRY_STEP]: {},
        [OnboardingStepEnum.ADD_FIRST_APP_STEP]: {},
        [OnboardingStepEnum.GLOBAL_NAVIGATION_STEP]: {},
        [OnboardingStepEnum.LOCAL_NAVIGATION_AND_TOOLS_STEP]: {},
        [OnboardingStepEnum.POSSIBILITIES_OF_ASODESK_STEP]: {},
        [OnboardingStepEnum.REVIEWS_RATINGS_STEP]: {},
        [OnboardingStepEnum.STORES_ANALYTICS_STEP]: {},
        [OnboardingStepEnum.CONNECTIONS_HUB_STEP]: {},
        [OnboardingStepEnum.KEYWORD_BOOST_STEP]: {},
        [OnboardingStepEnum.SCHEDULE_A_DEMO_STEP]: {},
        [OnboardingStepEnum.CHOOSE_A_PLAN_STEP]: {},
        [OnboardingStepEnum.FINISH_ONBOARDING_STEP]: {},
    },
};

const onboarding = (state = initialState, action: OnboardingActionType) => {
    switch (action.type) {
        case OnboardingActionTypeEnum.SET_ONBOARDING_CURRENT_STEP:
            return {
                ...state,
                onboardingCurrentStepId: action.currentStep,
            };
        case OnboardingActionTypeEnum.SET_ONBOARDING_DATA:
            return {
                ...state,
                ...action.data,
            };
        case OnboardingActionTypeEnum.SET_ONBOARDING_READY_TOUR:
            return {
                ...state,
                isReadyTour: action.isReadyTour,
            };
        default: {
            return state;
        }
    }
};

export default onboarding;
