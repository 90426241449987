export const ASIDE_PANEL_NAME_CREATE_REVIEW_TAG = 'aside-panel-create-review-tag';
export const ASIDE_PANEL_NAME_EDIT_REVIEW_TAG = 'aside-panel-edit-review-tag';
export const ASIDE_PANEL_NAME_CREATE_REVIEW_TAG_CATEGORY = 'aside-panel-create-review-tag-category';
export const ASIDE_PANEL_NAME_EDIT_REVIEW_TAG_CATEGORY = 'aside-panel-edit-review-tag-category';
export const ASIDE_PANEL_NAME_SET_TAG_TIMER = 'aside-panel-set-tag-timer';
export const ASIDE_PANEL_NAME_IMPORT_TEMPLATES = 'aside-panel-import-templates';
export const ASIDE_PANEL_NAME_ADD_COMPETITOR = 'aside-panel-add-competitor';
export const ASIDE_PANEL_NAME_ADD_KEYWORDS = 'aside-panel-add-keywords';

export const ASIDE_PANEL_NAME_AUTO_TRANSLATE = 'aside-panel-auto-translate';

export const ASIDE_PANEL_NAME_LIVE_SEARCH = 'aside-panel-live-search';

export const ASIDE_PANEL_KE_METRICS = 'aside-panel-ke-metrics';

export const REMOVED_DOWNGRADE_PLAN_META_ASIDE_PANEL = 'removed-downgrade-plan-meta-aside-panel';
