import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { ButtonElement, ButtonElementType } from 'components/buttons/button/ButtonElement';
import { ButtonStateEnum } from 'components/buttons/button/buttonEnum';
import ReactDOM from 'react-dom';
import { PlanPrice } from '../planPrice/PlanPrice';
import styles from './planCard.module.scss';

export enum PlanCardStateEnum {
    DEFAULT = 'default',
    PRIMARY = 'primary',
    INFO = 'info',
    LIGHT = 'light',
    OFFER = 'offer',
}

export type PropsType = {
    state?: PlanCardStateEnum;
    title: ReactNode;
    description?: ReactNode;
    price?: ReactNode;
    oldPrice?: string;
    period?: ReactNode;
    totalPrice?: string;
    buttonDescription?: string;
    savingPrice?: string;
    badgeText?: string;
    TopControls?: ReactNode;
    Content?: ReactNode;
    AdditionalSettings?: ReactNode;
    action?: ButtonElementType;
    isShowTotalBlock?: boolean;
    isHeadingAutoHeight?: boolean;
    isContentAutoHeight?: boolean;
    className?: string;
    buttonPortal?: React.RefObject<HTMLDivElement>;
    hideDescription?: boolean;
    contentClassName?: string;
    classNameMeta?: string;
    planOfferText?: string;
    PriceMeta?: ReactNode;
    Badge?: ReactNode;
};

const MAP_BUTTON_STATES = {
    [PlanCardStateEnum.DEFAULT]: ButtonStateEnum.LIGHT_PRIMARY,
    [PlanCardStateEnum.PRIMARY]: ButtonStateEnum.PRIMARY,
    [PlanCardStateEnum.INFO]: ButtonStateEnum.PRIMARY,
    [PlanCardStateEnum.LIGHT]: ButtonStateEnum.DEFAULT,
    [PlanCardStateEnum.OFFER]: ButtonStateEnum.OFFER,
};

export const PlanCard: FC<PropsType> = ({
    state = PlanCardStateEnum.DEFAULT,
    title,
    description,
    price,
    oldPrice,
    period,
    totalPrice,
    buttonDescription,
    savingPrice,
    badgeText,
    TopControls,
    Content,
    AdditionalSettings,
    action,
    isShowTotalBlock = true,
    isHeadingAutoHeight = true,
    isContentAutoHeight = true,
    className,
    buttonPortal,
    hideDescription = false,
    contentClassName,
    classNameMeta,
    planOfferText = 'One time offer',
    PriceMeta,
    Badge,
}: PropsType) => {
    const ButtonAction = <ButtonElement state={MAP_BUTTON_STATES[state]} {...action} isRounded isBlock />;
    const showDescription = !hideDescription;
    const isPlanOfferState = state === PlanCardStateEnum.OFFER;

    return (
        <div>
            <div
                className={classNames('flex flex-col rounded-8 flex-1', className, {
                    'border border-solid border-gray-300': state === PlanCardStateEnum.LIGHT,
                })}
                data-plan-card
            >
                <div
                    className={classNames('relative p-24 flex flex-col rounded-t-8', {
                        'bg-blue-600': state === PlanCardStateEnum.DEFAULT,
                        'bg-primary': state === PlanCardStateEnum.PRIMARY,
                        '!bg-info': state === PlanCardStateEnum.INFO,
                        [styles.bgPlanOffer]: isPlanOfferState,
                        'bg-gray-50 border-b border-solid border-gray-300': state === PlanCardStateEnum.LIGHT,
                        '!pb-0': TopControls,
                    })}
                    {...(isHeadingAutoHeight ? { 'data-plan-heading': '' } : {})}
                >
                    <div className="flex-1">
                        <div
                            className={classNames('text-24 font-bold mb-8 pr-24', {
                                'text-white': state !== PlanCardStateEnum.LIGHT,
                                'text-gray-500': state === PlanCardStateEnum.LIGHT,
                            })}
                        >
                            {title}
                        </div>
                        {price && (
                            <PlanPrice
                                className={classNames('mt-12', {
                                    'mb-16': description,
                                    '!text-white': state !== PlanCardStateEnum.LIGHT,
                                    'text-gray-500': state === PlanCardStateEnum.LIGHT,
                                })}
                                classNameMeta={classNames(classNameMeta, {
                                    'text-white': state !== PlanCardStateEnum.LIGHT,
                                    'text-gray-500': state === PlanCardStateEnum.LIGHT,
                                })}
                                price={price}
                                oldPrice={oldPrice}
                                period={period}
                            />
                        )}
                        {PriceMeta}
                        {isPlanOfferState && (
                            <div
                                className={classNames(
                                    'text-14 uppercase text-offer tracking-7 px-24 py-10 bg-white font-bold -mx-24 shadow-8-1',
                                    { 'mb-16': description && showDescription }
                                )}
                            >
                                {planOfferText}
                            </div>
                        )}
                        {description && showDescription && (
                            <div className="text-13 text-white text-opacity-90">{description}</div>
                        )}
                        {badgeText && (
                            <div className="absolute top-0 right-0 overflow-hidden h-142 w-142">
                                {badgeText && <div className={styles.badge}>{badgeText}</div>}
                            </div>
                        )}
                        {Badge}
                    </div>
                    {TopControls && <div className="mt-20 -mb-16 -mx-8">{TopControls}</div>}
                </div>
                <div
                    className={classNames('flex flex-col px-16 py-24 bg-opacity-70 flex-1 rounded-b-8', contentClassName, {
                        '!pt-40': !!TopControls,
                        'bg-blue-50 shadow-inset-1': state !== PlanCardStateEnum.LIGHT,
                        'bg-white': state === PlanCardStateEnum.LIGHT,
                    })}
                >
                    {Content && (
                        <div className="flex-1" {...(isContentAutoHeight ? { 'data-plan-content': '' } : {})}>
                            {Content}
                        </div>
                    )}
                    {AdditionalSettings}
                    <div className="mt-24">
                        {isShowTotalBlock && (
                            <div
                                className={classNames('text-center text-14 h-24', {
                                    'mb-16': !buttonPortal?.current,
                                })}
                            >
                                {totalPrice && (
                                    <>
                                        Total <span className="font-bold">{totalPrice}</span>{' '}
                                        {savingPrice && <>(saving {savingPrice})</>}
                                    </>
                                )}
                                {buttonDescription && <p className="text-gray-500">{buttonDescription}</p>}
                            </div>
                        )}
                        {action && (
                            <>
                                {buttonPortal?.current
                                    ? ReactDOM.createPortal(ButtonAction, buttonPortal?.current)
                                    : ButtonAction}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
