import { AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import { getCookieAccessToken } from 'utils/axios-settings/user-token-service';

export type RefreshTokenSettingsType = { isRefreshing: boolean; refreshSubscribers: (() => void)[] };

export type JWTDecodedTokenType = {
    exp: string;
};

export const isTokenExpire = (accessToken?: string) => {
    const decodedAccessToken = accessToken && jwtDecode<JWTDecodedTokenType>(accessToken);
    const tokenExpire = decodedAccessToken && parseInt(decodedAccessToken.exp, 10);

    return tokenExpire && Date.now() >= tokenExpire * 1000;
};

type UpdateAccessTokenType = {
    refreshSettings: RefreshTokenSettingsType;
    config: AxiosRequestConfig;
    updateToken: () => Promise<void>;
};

export const updateAccessToken = async ({ refreshSettings, config, updateToken }: UpdateAccessTokenType) => {
    if (isTokenExpire(getCookieAccessToken())) {
        // Если access token истек и нет активного процесса обновления токена, запустить процесс обновления
        if (!refreshSettings.isRefreshing) {
            refreshSettings.isRefreshing = true;

            // Запоминаем запрос, который инициировал обновление токена
            const retryOriginalRequest = new Promise<AxiosRequestConfig>((resolve) => {
                refreshSettings.refreshSubscribers.push(() => {
                    config.headers.Authorization = `Token ${getCookieAccessToken()}`;
                    resolve(config);
                });
            });

            // Отправить запрос на обновление токена
            try {
                await updateToken();
            } catch (error) {
                // Ошибка при обновлении токена, очищаем очередь запросов
                refreshSettings.refreshSubscribers = [];
            } finally {
                refreshSettings.isRefreshing = false;
            }

            // Возвращаем исходный запрос для его повторного выполнения с обновленным токеном
            return retryOriginalRequest;
        }

        // Возвращаем Promise, который разрешится после обновления токена
        return new Promise<AxiosRequestConfig>((resolve) => {
            refreshSettings.refreshSubscribers.push(() => {
                config.headers.Authorization = `Token ${getCookieAccessToken()}`;
                resolve(config);
            });
        });
    }
};
