import { TOGGLE_KEYWORD_SUGGESTION_MODAL } from "state/keywordSuggestionsModal/actionTypes";
import { SuggestionModalDataType } from "state/keywordSuggestionsModal/controller";

type actionType = {
    type: string,
    keywordInfo: SuggestionModalDataType,
}

export const closeAllModal = 0;
export const InitKeywordSuggestionModalState: SuggestionModalDataType = {
    keywordId: 0,
    keyword: ''
};

const keywordSuggestionsModal = (state = InitKeywordSuggestionModalState, action: actionType) => {
    switch (action.type) {
        case TOGGLE_KEYWORD_SUGGESTION_MODAL: {
            return {
                ...state,
                ...{
                    keywordId: state.keywordId > closeAllModal ? closeAllModal : action.keywordInfo.keywordId,
                    keyword: action.keywordInfo.keyword
                }
            }
        }
        default: {
            return state;
        }
    }
};

export default keywordSuggestionsModal;
