import {
    defaultEndDateRange,
    defaultLastTwoWeekDateChartDateRange,
    defaultSecondsFromNowChartDateRange,
    defaultStartChartDateRange,
    defaultStartDateRange,
} from 'utils/helpers/date/date';

export const defaultDateRange = {
    startDate: defaultStartDateRange,
    endDate: defaultEndDateRange,
};

export const defaultChartDateRange = {
    defaultStartDate: defaultStartChartDateRange,
    secondsFromNow: defaultSecondsFromNowChartDateRange,
    lastTwoWeekDate: defaultLastTwoWeekDateChartDateRange,
};

export const startOfEpoch = 1441065600000; // 1 sept 2015

export const oneDayInSeconds = 24 * 3600 * 1000;

export const oneHourInMilliseconds = 3600;

export const ONE_SECOND = 1000;
