export enum ButtonStateEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export enum ButtonSizeEnum {
    MD = 'md',
    SM = 'sm',
    XS = 'xs',
}
