import React, { FC } from 'react';

type PropsType = {
    title: string;
    meta: string;
};

export const PlanMeta: FC<PropsType> = ({ title, meta }: PropsType) => (
    <div className="flex items-center text-14 font-semibold mb-12 text-gray-600">
        {title}
        <span className="text-12 text-gray-400 ml-4 font-normal">{meta}</span>
    </div>
);
