import './select.scss';
import classNames from 'classnames';
import React, { memo, useState, MutableRefObject, ReactNode, useEffect } from 'react';
import ReactSelect, { Props, ValueType } from 'react-select';
import { LoadingIndicator } from 'newComponents/forms/select/customComponents/LoadingIndicator';
import { MenuListWithHiddenSingleValue } from 'newComponents/forms/select/customComponents/menuList/MenuListWithHiddenSingleValue';
import { ModalTooltip } from 'newComponents/modal/ModalTooltip';
import { DropdownIndicator } from './customComponents/DropdownIndicator';
import { IndicatorSeparator } from './customComponents/IndicatorSeparator';
import { ValueContainer } from './customComponents/ValueContainer';

export enum SelectSizeEnum {
    XS = 'xs',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
}

export enum SelectStateEnum {
    DEFAULT = 'default',
    DANGER = 'danger',
    DISABLED = 'disabled',
}

export type SelectComponentPropsType<T> = Props<T> & {
    size?: SelectSizeEnum;
    state?: SelectStateEnum;
    onChangeSelect?: (selectedOption: ValueType<T>) => void;
    updateSelectedOptions?: (selectedOption: ValueType<T>) => ValueType<T>;
    isBorder?: boolean;
    isOptionBorderHas?: boolean;
    tooltipText?: ReactNode;
};

type MemoWithGeneric = <T>(props: SelectComponentPropsType<T>) => JSX.Element;

export const isSelectValue = <T,>(value: ValueType<T>): value is T => !!value && !Array.isArray(value);
export const isSelectMultiValue = <T,>(value: ValueType<T>): value is T[] => !!value && Array.isArray(value);

type SelectRefType<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null;

const SelectComponent = React.forwardRef(
    <T,>(
        {
            size = SelectSizeEnum.LG,
            state = SelectStateEnum.DEFAULT,
            components,
            defaultValue,
            onChangeSelect = () => {},
            updateSelectedOptions = (selectedOption) => selectedOption,
            hideSelectedOptions = false,
            className,
            isOptionBorderHas,
            isBorder = true,
            tooltipText,
            ...props
        }: SelectComponentPropsType<T>,
        ref: SelectRefType<ReactSelect<T>>
    ) => {
        const [selectedOption, setSelectedOption] = useState<ValueType<T>>(defaultValue);

        useEffect(() => {
            setSelectedOption(defaultValue);
        }, [defaultValue]);

        const onChangeSelector = (value: ValueType<T>) => {
            const updatedValue = updateSelectedOptions(value);
            setSelectedOption(updatedValue);
            onChangeSelect(updatedValue);
        };

        const selectComponents = {
            IndicatorSeparator,
            DropdownIndicator,
            LoadingIndicator,
            ValueContainer,
            MenuList: MenuListWithHiddenSingleValue,
            ...components,
        };

        const Select = (
            <ReactSelect
                ref={ref}
                className={classNames(className, 'baseSelector', `baseSelector--${size}`, `baseSelector--${state}`, {
                    'baseSelector--disabled': props.isDisabled,
                    'baseSelector--noBorder': !isBorder,
                    'baseSelector--optionBorder': isOptionBorderHas,
                    'baseSelector--noData': props?.options?.length === 1,
                    'flex-1': tooltipText,
                })}
                classNamePrefix="baseSelector"
                onChange={onChangeSelector}
                components={selectComponents}
                value={selectedOption}
                hideSelectedOptions={hideSelectedOptions}
                {...props}
            />
        );

        return tooltipText ? (
            <div className="flex items-center relative">
                {Select} <ModalTooltip tooltip={tooltipText} tooltipClassName="absolute -right-24" />
            </div>
        ) : (
            Select
        );
    }
);

export const Select = memo(SelectComponent) as MemoWithGeneric;
