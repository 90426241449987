import React, { FC } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { Icon } from 'newComponents/icons/asodeskIcons/Icon';
import { Icons } from 'types/icon-types';
import { AsodeskTooltip } from 'components/tooltips/asodeskTooltip/asodeskTooltip';

export type PlanFeaturesType = {
    features: {
        title?: string;
        values?: {
            name?: string;
            description?: string;
        }[];
    }[];
    className?: string;
};

export const PlanFeatures: FC<PlanFeaturesType> = ({ features, className }: PlanFeaturesType) => (
    <div className={classNames('flex-1', className)}>
        {features.map(({ title, values }, featureIndex) => (
            <div
                className={classNames('text-gray-600 last:mb-0', { 'mb-10': title, 'mb-12': title && !values })}
                key={featureIndex}
            >
                {title && (
                    <div className={classNames('flex text-14', { 'font-bold': values })}>
                        <Icon name={Icons.Check} size={24} lineHeight={24} className="mr-8 text-success" />
                        {title}
                    </div>
                )}
                {values && (
                    <ul className={classNames('m-0 pl-0 list-none', { '!pl-32': title })}>
                        {values.map(({ name, description }, valueIndex) => {
                            const tooltipId = `feature-${featureIndex}-${valueIndex}-${nanoid()}`;

                            return (
                                <li className="text-13 !leading-20" key={tooltipId}>
                                    {description ? (
                                        <AsodeskTooltip
                                            anchor={
                                                <span className="border-b border-dashed border-gray-600 border-opacity-37 cursor-default">
                                                    {name}
                                                </span>
                                            }
                                            tooltip={description}
                                            tooltipClassName="max-w-380"
                                        />
                                    ) : (
                                        <>{name}</>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        ))}
    </div>
);
