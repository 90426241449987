import { RefProgramStatusEnum } from 'constants/referralProgramConstants';
import { InvitationDataAdapterType, ReferralProgramDataAdapterType } from './responseDataReferralProgramAdapter';

export type InviteListType = {
    index?: number;
    email: string;
    status: RefProgramStatusEnum;
    bonus: number | null;
    lastUpdated: string;
};

export type InviteListPendingBonusType = {
    inviteList: InviteListType[];
    pendingBonusCredit: number;
}

export type ReferralProgramStateType = InviteListPendingBonusType & {
    userId: number;
    bonusCredit: number;
    inviteLink: string;
    bonusForUser: string;
    bonusForInvitedUser: string;
};

export const stateInvitationAdapter = (invitation: InvitationDataAdapterType, index: number): InviteListType => {
    const { status, bonus, ...others } = invitation;
    const isUserSubscription = status === RefProgramStatusEnum.SUBSCRIPTION_ACTIVATED;
    return {
        ...others,
        index: index + 1,
        status,
        bonus: isUserSubscription ? +bonus : null,
    };
};

export const stateInviteListPendingBonusAdapter = (list: InvitationDataAdapterType[]): InviteListPendingBonusType => {
    let pendingBonusCredit = 0;
    const inviteList = list.map((item, index) => {
        const isUserSubscription = item.status === RefProgramStatusEnum.SUBSCRIPTION_ACTIVATED;
        pendingBonusCredit = isUserSubscription ? pendingBonusCredit : pendingBonusCredit + +item.bonus;
        return stateInvitationAdapter(item, index);
    });
    return {
        pendingBonusCredit,
        inviteList,
    };
};

export const stateReferralProgramAdapter = (data: ReferralProgramDataAdapterType): ReferralProgramStateType => {
    const { bonusCredit, inviteList, ...others } = data;
    const listAdapter = stateInviteListPendingBonusAdapter(inviteList);
    return {
        ...others,
        bonusCredit: +bonusCredit,
        pendingBonusCredit: listAdapter.pendingBonusCredit,
        inviteList: listAdapter.inviteList,
    };
};
