import {
    ActiveTypeSecurityCodeEnum,
    IntegrationsDataItemType,
    IntegrationsDataType,
    AuthIntegrationStatusEnum,
} from './IntegrationsResponseType';
import { IntegrationsActionTypeListEnum } from './actionsTypes';
import { IntegrationsActionType } from './actions';

export type IntegrationsInitialStateType = {
    storeList: IntegrationsDataType;
    activeStore: IntegrationsDataItemType;
    activeTypeSecurityCode: ActiveTypeSecurityCodeEnum;
};

const integrationsInitialState = {
    storeList: [],
    activeStore: {
        id: null,
        email: null,
        userName: null,
        authIntegrationStatus: AuthIntegrationStatusEnum.NOT_AUTHORIZED,
        storeIds: [],
    },
    activeTypeSecurityCode: ActiveTypeSecurityCodeEnum.DEVICE,
};

const integrations = (state: IntegrationsInitialStateType = integrationsInitialState, action: IntegrationsActionType) => {
    switch (action.type) {
        case IntegrationsActionTypeListEnum.SET_INTEGRATIONS_DATA: {
            return {
                ...state,
                storeList: action.data,
            };
        }
        case IntegrationsActionTypeListEnum.SET_ACTIVE_STORE: {
            return {
                ...state,
                activeStore: action.store,
            };
        }
        case IntegrationsActionTypeListEnum.REMOVE_STORE_CONNECTION: {
            return {
                ...state,
                storeList: state.storeList.filter((store) => store.id !== action.id),
            };
        }
        case IntegrationsActionTypeListEnum.CREATE_STORE_CONNECTION: {
            return {
                ...state,
                storeList: [action.store, ...state.storeList],
                activeStore: {
                    ...state.activeStore,
                    ...action.store,
                },
                activeTypeSecurityCode: ActiveTypeSecurityCodeEnum.DEVICE,
            };
        }
        case IntegrationsActionTypeListEnum.UPDATE_STORE_SESSION: {
            return {
                ...state,
                storeList: state.storeList.map((store) => {
                    if (store.id === state.activeStore.id) {
                        return {
                            ...store,
                            authIntegrationStatus: AuthIntegrationStatusEnum.AUTHORIZED,
                        };
                    }

                    return store;
                }),
                activeTypeSecurityCode: ActiveTypeSecurityCodeEnum.DEVICE,
            };
        }
        case IntegrationsActionTypeListEnum.SEND_SMS_STORE_CODE: {
            return {
                ...state,
                activeTypeSecurityCode: ActiveTypeSecurityCodeEnum.SMS,
            };
        }
        case IntegrationsActionTypeListEnum.CHANGE_TYPE_SECURITY_CODE: {
            return {
                ...state,
                activeTypeSecurityCode: action.typeSecurityCode,
            };
        }
        default: {
            return state;
        }
    }
};

export default integrations;
