export const RECEIVED_MD_TEMPLATES = 'Recevied Metadata Templates';
export const RECEIVED_MD_OVERVIEW = 'Recevied Metadata Overview';
export const RECEVIED_MD_FIELDS = 'Received Metadata Fields';
export const RECEVIED_MD_QUICK_FIELD = 'Recevied Metadata Quick Fields';
export const RECEIVED_MD_LOCALES = 'Received Metadata Locales';
export const RECEVIED_MD_AUTOSAVETIME = 'Received Metadata Autosavetime';
export const IMPORT_MD_LOCALE = 'IMPORT_MD_LOCALE';

export default {
    RECEIVED_MD_TEMPLATES,
    RECEIVED_MD_OVERVIEW,
    RECEVIED_MD_FIELDS,
    RECEVIED_MD_QUICK_FIELD,
    RECEIVED_MD_LOCALES,
    RECEVIED_MD_AUTOSAVETIME,
};
