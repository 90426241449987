import { CountriesLocalizationsActionTypeListEnum } from './actionsTypes';
import { CountriesLocalizationsActionType } from './actions';
import { CountriesLocalizationsType } from './countriesLocalizationsType';

export type CountriesLocalizationsInitialStateType = CountriesLocalizationsType;

const countriesLocalizationsInitialState: CountriesLocalizationsType = [];

const countriesLocalizations = (
    state: CountriesLocalizationsInitialStateType = countriesLocalizationsInitialState,
    action: CountriesLocalizationsActionType
) => {
    switch (action.type) {
        case CountriesLocalizationsActionTypeListEnum.SET_COUNTRIES_LOCALIZATIONS: {
            return action.countriesLocalizations;
        }
        default: {
            return state;
        }
    }
};

export default countriesLocalizations;
