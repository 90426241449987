import './baseBallLoader.scss';
import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

type PropsType = {
    children: ReactNode;
    className?: string;
};

export const BaseBallLoader: FC<PropsType> = ({ children, className }) => (
    <div className={classNames('loaderInnerBall', className)}>{children}</div>
);
