export type AppCategoriesType = {
    id: string;
    name: string;
};

export type AppHasInAppsType = {
    id: string;
    name: string;
    price: string | number;
    pricing_parameters: string;
};

export const GOOGLEPLAY_APP_TYPE = 'googleplay';
export const APPSTORE_APP_TYPE = 'appstore';
export const RUSTORE_APP_TYPE = 'rustore';

export enum DeviceTypeEnum {
    IPHONE = 'iphone',
    IPAD = 'ipad',
    GOOGLE = 'google',
    RUSTORE = 'rustore',
}

export const deviceNamesByDeviceType: { [key in DeviceTypeEnum]: string } = {
    [DeviceTypeEnum.IPAD]: 'iPad',
    [DeviceTypeEnum.IPHONE]: 'iPhone',
    [DeviceTypeEnum.GOOGLE]: 'Android',
    [DeviceTypeEnum.RUSTORE]: 'RuStore',
};

export type AppStoreType = typeof APPSTORE_APP_TYPE | typeof GOOGLEPLAY_APP_TYPE | typeof RUSTORE_APP_TYPE;

type AppStoreDeviceResponseType = 'iPhone XS Max' | 'iPhone 6 Plus' | 'iPad Pro_2018' | 'iPad Pro' | 'Apple Watch';
type GooglePlayDeviceResponseType = 'phone';

export type AppInStoreType = {
    is_app: boolean;
    is_bundle: boolean;
    is_developer: boolean;
    is_editorial: boolean;
    is_fetching: boolean;
    is_inapp: boolean;
};

export type AppResponseType = AppInStoreType & {
    applocale_id: number;
    categories: AppCategoriesType[];
    category: string | null;
    competitors: AppResponseType[];
    developer: string;
    editorial_type: string | null;
    has_inapps: AppHasInAppsType[] | boolean | null;
    inapp_type: null;
    is_free: boolean;
    is_grouping: boolean;
    is_on_market: boolean;
    latest_release: string | null;
    logo: string;
    name: string;
    position?: number;
    price: string;
    rating: number | null;
    rating_all_versions: number | null;
    store_id: string;
    type: AppStoreType;
    url: string;
    version: string | null;
    isDemo: boolean;
    country_code: string;
    description?: string;
    rating_count?: number;
    screenshots?: {
        device_type: AppStoreDeviceResponseType | GooglePlayDeviceResponseType;
        id: number;
        screenshots: string[];
    }[];
    installs?: number;
    size?: string | number;
};
