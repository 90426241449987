import { CardBrandEnum } from 'utils/helpers/checkCardBrand';
import { PriceType, RecalculatePriceType, TaxType, TotalPriceType } from 'state/payment/basePaymentTypes';

export enum PaymentModalStepEnum {
    CONFIGURE_PAYMENT,
    CUSTOMER_SUPPORT_TOOLS,
    BILLING_INFORMATION,
    PAYMENT_DETAILS,
    CONGRATULATIONS,
}

export enum PaymentTypeEnum {
    PLATRON = 'platron',
    STRIPE = 'stripe',
}

export enum PaymentPeriodEnum {
    MONTH_1 = '1 month',
    MONTHS_3 = '3 months',
    MONTHS_6 = '6 months',
    ANNUAL = 'annual',
}

export type PlanMetaType = {
    keywordsCount?: number;
    appsCount?: number;
};

export type PlanType = PlanMetaType & {
    id: number;
    name: string;
    category: PlanCategoryEnum;
    pricing: PricingType[];
    isValidCoupon: boolean;
    validationCouponMessage?: string;
};

export enum PlanCategoryEnum {
    STARTUP = 1,
    PLUS,
    BUSINESS,
    CUSTOM,
}

export enum BillingUserType {
    CORPORATE,
    INDIVIDUAL,
}

export enum PlanTypeEnum {
    COMMON = 'Common',
    PACKAGE = 'Package',
    SPECIAL = 'Special',
}

export type DiscountType = {
    discount?: number;
    totalPriceDiscount?: number;
};

export type PromotionType = {
    promotionPercent?: number;
    promotionPrice?: number;
};

export type BonusCreditType = {
    bonusCredit: number;
};

export type PricingType = DiscountType &
    PromotionType &
    TotalPriceType &
    PriceType &
    TaxType &
    BonusCreditType & {
        id: PaymentPeriodEnum;
    };

export type BillingInformationType = {
    fullName?: string;
    companyName?: string;
    vatNumber?: string;
    isValidVatNumber?: boolean;
    country?: string;
    city?: string;
    address?: string;
    userType: BillingUserType;
    isAutoRenew: boolean;
};

export type CardType = {
    id: number;
    paymentType: PaymentTypeEnum;
    partCardNumber: string;
    cardExpires: string;
    cardBrand: CardBrandEnum;
    isConfirmedCard: boolean;
    isDefault: boolean;
    redirectUrl?: string;
    errorMessage?: string;
    countryCode: string;
};

export type UpdatedCardType = { id: number } & Partial<Omit<CardType, 'id'>>;

export type ValidateVatNumberType = {
    isValidVatNumber?: boolean;
    error?: string;
};

export type PayType = {
    redirectUrl?: string;
};

export enum SubscriptionStatusEnum {
    DRAFT = 'draft',
    PAID = 'paid',
    CANCELLED = 'cancelled',
}

export enum SubscriptionTypeEnum {
    SUBSCRIPTIONS = 'subscriptions',
    API_SUBSCRIPTIONS = 'api_subscriptions',
    CAMPAIGNS = 'campaigns',
    SERVICES = 'services',
}

export enum CurrencyEnum {
    USD = 'USD',
    RUB = 'RUB',
}

export type SubscriptionType = DiscountType &
    PromotionType &
    Pick<TotalPriceType, 'totalPrice'> &
    Partial<PriceType> &
    Partial<TaxType> &
    Partial<BonusCreditType> &
    PlanMetaType & {
        id: string;
        name?: string;
        category?: PlanCategoryEnum;
        priceCurrency: CurrencyEnum;
        paymentPeriod?: PaymentPeriodEnum;

        status: SubscriptionStatusEnum;
        startDate?: string;
        duration?: number;

        // only for campaigns
        keywords?: string[];
        keywordInstalls?: number;

        planType?: PlanTypeEnum;
        country?: string;
    };

export type UpdatedSubscriptionType = { id: string } & Partial<Omit<SubscriptionType, 'id'>>;

export type SubscriptionsType = {
    [SubscriptionTypeEnum.SUBSCRIPTIONS]: SubscriptionType[];
    [SubscriptionTypeEnum.API_SUBSCRIPTIONS]: SubscriptionType[];
    [SubscriptionTypeEnum.CAMPAIGNS]: SubscriptionType[];
    [SubscriptionTypeEnum.SERVICES]: SubscriptionType[];
};

export type InvoiceSubscriptionType = {
    id: number;
    startDate: string;
    paymentDate: string;
    description: string;
    duration: number;
    quantity: number;
    orderId: string;
    agreement: string;
    invoiceNumberString: string;
    actNumberString: string;
    clientName: string;
    clientCompany: string;
    clientAddress: string;
    clientCity: string;
    clientCountry: string;
    clientPhone: string;
    clientTaxNumber: string;
    company: string;
    companyAddress: string;
    companyCity: string;
    companyCountry: string;
    companyPhone: string;
    companyTaxNumber: string;
    orderType: number;
    ceoAccSignature: string;
    ceoSignature: string;
    ceoEn: string;
    ceoRu: string;
    isCampaign?: boolean;
    language: string;
    vatIncluded: boolean;
    bankCountry?: string;
    vatPercent: number;
    vatPrice: number;
    subTotal: number;
    total: number;
    currency: CurrencyEnum;
};

export type RecalculateSubscriptionType = RecalculatePriceType;

export type PaymentModalSettingsType = {
    isEditSubscription: boolean;
    isShowPaymentPeriod: boolean;
    isEnterPromoCode: boolean;
    isShowPlanPricePeriod: boolean;
};
