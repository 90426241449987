import { FiltersPresetsInitialStateType, PresetsTypesListEnum } from './FiltersPresetsTypes';
import { FiltersPresetsActionType } from './actions';
import { FiltersPresetsActionTypeListEnum } from './actionsTypes';

const filtersPresetsInitialState = {
    [PresetsTypesListEnum.REVIEW]: {
        ids: [],
        byIds: {},
    },
    [PresetsTypesListEnum.RATING]: {
        ids: [],
        byIds: {},
    },
};

const filtersPresets = (state: FiltersPresetsInitialStateType = filtersPresetsInitialState, action: FiltersPresetsActionType) => {
    switch (action.type) {
        case FiltersPresetsActionTypeListEnum.SET_FILTERS_PRESETS: {
            return {
                ...state,
                [action.presetType]: action.normalizedData,
            };
        }
        case FiltersPresetsActionTypeListEnum.ADD_FILTERS_PRESET: {
            const { ids, byIds } = state[action.presetType];
            return {
                ...state,
                [action.presetType]: {
                    ids: [...ids, action.data.id],
                    byIds: {
                        ...byIds,
                        [action.data.id]: action.data,
                    },
                },
            };
        }
        case FiltersPresetsActionTypeListEnum.REMOVE_FILTERS_PRESET: {
            const { ids, byIds } = state[action.presetType];
            const newIds = ids.filter((presetId) => presetId !== action.id);
            const newByIds = newIds.reduce((acc, presetId) => {
                return {
                    ...acc,
                    [presetId]: byIds[presetId],
                };
            }, {});
            return {
                ...state,
                [action.presetType]: {
                    ids: newIds,
                    byIds: newByIds,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default filtersPresets;
