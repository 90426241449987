export enum KeywordExplorerActionTypeListEnum {
    SET_KEYWORD_EXPLORER_DATA = 'SET_KEYWORD_EXPLORER_DATA',
    RESET_KEYWORD_EXPLORER_DATA = 'RESET_KEYWORD_EXPLORER_DATA',
    UPDATE_APP_META = 'UPDATE_APP_META',
    SET_KEYWORD_EXPLORER_APPS_BY_KEYWORD = 'SET_KEYWORD_EXPLORER_APPS_BY_KEYWORD',
    SET_KEYWORD_EXPLORER_RANK_HISTORY = 'SET_KEYWORD_EXPLORER_RANK_HISTORY',
    SET_IS_LOADING_KEYWORD_EXPLORER = 'SET_IS_LOADING_KEYWORD_EXPLORER',
    SET_KEYWORD_EXPLORER_STATISTICS = 'SET_KEYWORD_EXPLORER_STATISTICS',
    SET_IS_KEYWORD_EXPLORER_STATISTICS_LOADING = 'SET_IS_KEYWORD_EXPLORER_STATISTICS_LOADING',
}
