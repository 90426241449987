import { UpgradeTrialResponseType } from 'state/plans/UpgradeTrialResponseType';

export const upgradeTrialResponseAdapter = ({
    need_business_plan: isNeedBusinessPlan,
    old_plan: oldPlanName,
    new_plan: newPlanName,
}: UpgradeTrialResponseType) => ({
    isNeedBusinessPlan,
    oldPlanName,
    newPlanName,
});
