import { ReviewTemplateDirectoriesType } from 'state/reviewTemplates/reviewTemplateDirectoriesType';
import { ReviewTemplatesActionTypeListEnum } from './actionsTypes';
import { ReviewTemplatesActionType } from './actions';

export type ReviewTemplatesStateType = {
    directories: ReviewTemplateDirectoriesType;
    isLoading: boolean;
    isLoaded: boolean;
    selectedDirectory: number;
};

const defaultDirectory = 0;

const templatesInitialState: ReviewTemplatesStateType = {
    directories: [],
    isLoading: false,
    isLoaded: false,
    selectedDirectory: defaultDirectory,
};

const findNecessaryDirectory = (directories: ReviewTemplateDirectoriesType, directoryId: number) => {
    const newDirectories = [...directories];
    let foundedDirectory = newDirectories.find((folder) => folder.id === directoryId);
    if (!foundedDirectory) {
        directories.forEach((folder) => {
            if (!foundedDirectory) {
                foundedDirectory = folder.children.find((subFolder) => subFolder.id === directoryId);
            }
        });
    }

    return {
        foundedDirectory,
        newDirectories,
    };
};

const updateItemInArray = <T extends { id: string | number }>(array: T[], updatedFolder: T) => {
    const folderIndex = array.findIndex((subfolder) => subfolder.id === updatedFolder.id);
    return array.map((subfolder, index) => (folderIndex === index ? updatedFolder : subfolder));
};

const reviewTemplates = (state = templatesInitialState, action: ReviewTemplatesActionType) => {
    switch (action.type) {
        case ReviewTemplatesActionTypeListEnum.SET_TEMPLATES: {
            return {
                ...state,
                directories: action.directories,
                isLoaded: true,
                isLoading: false,
            };
        }
        case ReviewTemplatesActionTypeListEnum.IS_LOADING_TEMPLATES: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }
        case ReviewTemplatesActionTypeListEnum.SELECT_DIRECTORY: {
            return {
                ...state,
                selectedDirectory: action.directory,
            };
        }
        case ReviewTemplatesActionTypeListEnum.COPY_TEMPLATE: {
            const { foundedDirectory: fromDirectory } = findNecessaryDirectory(state.directories, action.fromDirectoryId);

            const { foundedDirectory: toDirectory, newDirectories } = findNecessaryDirectory(
                state.directories,
                action.directoryId
            );

            if (fromDirectory && toDirectory) {
                const movedTemplate = fromDirectory.templates.find((template) => template.id === action.templateId);
                if (movedTemplate) toDirectory.templates = [...toDirectory.templates, movedTemplate];
            }

            return {
                ...state,
                directories: newDirectories,
            };
        }
        case ReviewTemplatesActionTypeListEnum.CREATE_TEMPLATE: {
            const { foundedDirectory: templateDirectory, newDirectories } = findNecessaryDirectory(
                state.directories,
                action.directoryId
            );
            if (templateDirectory) templateDirectory.templates = [...templateDirectory.templates, action.template];

            return {
                ...state,
                directories: newDirectories,
            };
        }
        case ReviewTemplatesActionTypeListEnum.REMOVE_TEMPLATE: {
            const { foundedDirectory: templateDirectory, newDirectories } = findNecessaryDirectory(
                state.directories,
                action.directoryId
            );
            if (templateDirectory) {
                templateDirectory.templates = templateDirectory.templates.filter((template) => template.id !== action.templateId);
            }

            return {
                ...state,
                directories: newDirectories,
            };
        }
        case ReviewTemplatesActionTypeListEnum.UPDATE_TEMPLATE: {
            const { foundedDirectory: templateDirectory, newDirectories } = findNecessaryDirectory(
                state.directories,
                action.directoryId
            );
            if (templateDirectory) {
                templateDirectory.templates = updateItemInArray(templateDirectory.templates, action.template);
            }

            return {
                ...state,
                directories: newDirectories,
            };
        }
        case ReviewTemplatesActionTypeListEnum.CREATE_FOLDER: {
            if (action.refFolderId) {
                const { foundedDirectory: folderDirectory, newDirectories } = findNecessaryDirectory(
                    state.directories,
                    action.refFolderId
                );
                if (folderDirectory) folderDirectory.children = [...folderDirectory.children, action.folder];

                return {
                    ...state,
                    directories: newDirectories,
                };
            }

            return {
                ...state,
                directories: [...state.directories, action.folder],
            };
        }
        case ReviewTemplatesActionTypeListEnum.REMOVE_FOLDER: {
            if (action.parentFolderId) {
                const { foundedDirectory: folderDirectory, newDirectories } = findNecessaryDirectory(
                    state.directories,
                    action.parentFolderId
                );
                if (folderDirectory) {
                    folderDirectory.children = folderDirectory.children.filter((subfolder) => subfolder.id !== action.folderId);
                }

                return {
                    ...state,
                    directories: newDirectories,
                };
            }

            return {
                ...state,
                directories: state.directories.filter(({ id: folderId }) => folderId !== action.folderId),
            };
        }
        case ReviewTemplatesActionTypeListEnum.UPDATE_FOLDER: {
            const { folder } = action;

            if (folder.refToRoot) {
                const { foundedDirectory: folderDirectory, newDirectories } = findNecessaryDirectory(
                    state.directories,
                    folder.refToRoot
                );
                if (folderDirectory) {
                    folderDirectory.children = updateItemInArray(folderDirectory.children, folder);
                }

                return {
                    ...state,
                    directories: newDirectories,
                };
            }

            const newState = { ...state };
            newState.directories = updateItemInArray(newState.directories, folder);

            return newState;
        }
        default: {
            return state;
        }
    }
};

export default reviewTemplates;
