import { useMemo } from 'react';
import { AvailableFeaturesKeyEnum, AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import { APPSTORE_APP_TYPE, DeviceTypeEnum, RUSTORE_APP_TYPE } from 'state/appProfile/AppResponseType';
import { useAppSelector } from 'hooks/useAppSystem';
import { useSearchAds } from './useSearchAds';

export const useAppProfileService = () => {
    const appProfile = useAppSelector((state) => state.appProfile);

    const { isSearchAdsEnabled } = useSearchAds(appProfile.country_code);
    const isSpecificStoreFormat = appProfile.is_editorial || appProfile.is_bundle || appProfile.is_inapp;

    const isLogged = useAppSelector((state) => state.user.isLogged);

    const getDeviceType = () => {
        if (appProfile.type === APPSTORE_APP_TYPE) {
            return DeviceTypeEnum.IPHONE;
        }

        if (appProfile.type === RUSTORE_APP_TYPE) {
            return DeviceTypeEnum.RUSTORE;
        }

        return DeviceTypeEnum.GOOGLE;
    };

    const getStoreName = () => {
        if (appProfile.type === APPSTORE_APP_TYPE) {
            return 'App Store';
        }

        if (appProfile.type === RUSTORE_APP_TYPE) {
            return 'RuStore';
        }

        return 'Google Play';
    };

    return useMemo(() => {
        return {
            isAppStore: () => {
                return appProfile.is_appstore;
            },
            isTheSameStoreType: (type: string) => {
                return appProfile.type === type;
            },
            isTheSameApp: (storeId?: string, appId?: number) => {
                return appProfile.app_id === appId || appProfile.store_id === storeId;
            },
            isAvailableFeature: (feature: keyof AvailableFeaturesType) => {
                if (!isLogged) return true;

                return appProfile && feature in appProfile.available_features;
            },
            isLogout: () => {
                return appProfile && !appProfile.logged_in;
            },
            getBuckets: () => {
                return appProfile.comparativeReportBuckets || [];
            },
            isTabsAvailable: (amountTabs: number) => {
                return (
                    isLogged &&
                    appProfile &&
                    AvailableFeaturesKeyEnum.KA_TABS_AVAILABLE in appProfile.available_features &&
                    (appProfile.available_features[AvailableFeaturesKeyEnum.KA_TABS_AVAILABLE]?.value || 0) > amountTabs
                );
            },
            isCompetitorLimitReached: (amountCompetitors: number) => {
                return appProfile.competitor_limit === undefined || amountCompetitors >= appProfile.competitor_limit;
            },
            isTheSameLocale: (locale?: string) => {
                return appProfile.country_code.toLowerCase() === locale?.toLowerCase();
            },
            isTheSameAppAndLocale: (storeId?: string | number, locale?: string) => {
                return (
                    locale && appProfile.country_code.toLowerCase() === locale.toLowerCase() && appProfile.store_id === storeId
                );
            },
            isTrackedApp: (storeId?: string | number) => {
                return appProfile?.trackedApps?.some((app) => app.store_id === storeId?.toString());
            },
            comparativeReportBuckets: appProfile.comparativeReportBuckets,
            isConnected: appProfile.is_connected,
            isGooglePlay: appProfile.is_googleplay,
            isDemoApp: appProfile.isDemo,
            isAppProfileLoaded: !!appProfile.name,
            appProfile,
            appName: appProfile.name,
            storeName: getStoreName(),
            isApp: appProfile.is_app,
            isNotApp: isSpecificStoreFormat || appProfile.is_developer,
            isDemo: appProfile.isDemo,
            appProfileLoaded: appProfile.isLoaded,
            hasASCConnect: appProfile && appProfile.is_connected,
            isFetching: appProfile.is_fetching,
            isSearchAdsEnabled: appProfile.is_appstore && isSearchAdsEnabled,
            isWeekExport: appProfile && 'week-export' in appProfile.available_features,
            isSimpleExport: appProfile && 'simple-export' in appProfile.available_features,
            canUseActivityLog: appProfile && 'activity-log' in appProfile.available_features,
            canShowComparativeReport: appProfile && 'comparative-report' in appProfile.available_features,
            canUpdateKeywords: 'can-update-keywords' in appProfile.available_features && !appProfile.isDemo,
            canManageKeywords: appProfile.permissions.can_manage_keywords && !appProfile.isDemo,
            canManageCompetitors: appProfile.permissions.can_manage_competitors && !appProfile.isDemo,
            canManageApplications: appProfile.permissions.can_manage_applications,
            appId: appProfile.app_id,
            storeId: appProfile.store_id,
            appCountry: appProfile.country_code.toLowerCase(),
            isLoadedApp: appProfile.isLoaded,
            isOnMarket: appProfile.is_on_market,
            isEditorial: appProfile.is_editorial,
            isBundle: appProfile.is_bundle,
            isInApp: appProfile.is_inapp,
            appLogo: appProfile.logo,
            isSpecificStoreFormat,
            isDeveloper: appProfile.is_developer,
            hasGooglePlayIntegration:
                appProfile.is_googleplay &&
                appProfile.google_integration_features &&
                (appProfile.google_integration_features.reply || appProfile.google_integration_features.report),
            storeLink: appProfile.url,
            storeType: appProfile.type,
            isAppStoreType: appProfile.is_appstore,
            deviceType: getDeviceType(),
            locale: appProfile.country_code.toLowerCase(),
            storeEvents: appProfile.store_events
                ? appProfile.store_events.map(({ date, text, countries = [] }) => ({
                      text,
                      date: Number(date),
                      countries: countries.map((country) => country?.toLowerCase()),
                  }))
                : [],
        };
    }, [appProfile]);
};

export type UseAppProfileServiceType = ReturnType<typeof useAppProfileService>;
