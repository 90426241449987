import { Dispatch } from 'redux';
import { toggleKeywordSuggestionModal } from 'state/keywordSuggestionsModal/actions';
import { KeywordSuggestionsModalControllerInterface } from 'state/keywordSuggestionsModal/KeywordSuggestionsModalControllerInterface';

export type SuggestionModalDataType = {
    keywordId: number;
    keyword: string;
};

export const toggleSuggestionModalController = (keyword: SuggestionModalDataType) => (dispatch: Dispatch) =>
    dispatch(toggleKeywordSuggestionModal(keyword));

export const KeywordSuggestionModalController: KeywordSuggestionsModalControllerInterface = {
    toggleSuggestionModalController,
};
