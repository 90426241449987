import '../publicPath';
import '../sentry/sentryInit';
import '../polyfills/polyfills';
import './basePageStyles';

import React, { FC, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Route, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider, Outlet } from 'react-router-dom';
import { FrontendUrls, Urls } from 'utils/Urls';
import { AsyncError404Page } from 'pages/errors/404/AsyncError404Page';
import { updateViewport } from 'utils/helpers/updateViewport';
import { isProductionStage } from 'utils/checkStage';
import { initTagManager } from 'utils/analytics/tagManager/tagManager';
import { MainLayout } from 'components/HOC/MainLayoutContainer';
import { AxiosInterceptorContainer } from 'containers/AxiosInterceptorContainer';
import { Toast } from 'components/toast/toast';
import {
    authRouteInviteRegistrationUrl,
    myAppsRouteUrl,
    partialAccountRouteUrl,
    partialAsoToolsRouteUrl,
    partialAuthRouteUrl,
    partialConnectionsHubRouteUrl,
    partialKeywordBoostRouteUrl,
    partialReferralProgramRouteUrl,
    partialReviewsRouteUrl,
    partialStoresAnalyticsRouteUrl,
} from 'utils/routeUrls';
import { AuthRoutes } from 'modules/routers/new/auth/AuthRoutes';
import { AsoToolsRoutes } from 'modules/routers/new/asoTools/AsoToolsRoutes';
import { StoresAnalyticsRoutes } from 'modules/routers/new/storesAnalytics/StoresAnalyticsRoutes';
import { ReviewsRoutes } from 'modules/routers/new/reviews/ReviewsRoutes';
import { ConnectionsHubRoutes } from 'modules/routers/new/connectionsHub/ConnectionsHubRoutes';
import { KeywordBoostRoutes } from 'modules/routers/new/keywordBoost/KeywordBoostRoutes';
import { ReferralProgramRoutes } from 'modules/routers/new/referralProgram/ReferralProgramRoutes';
import { AccountRoutes } from 'modules/routers/new/account/AccountRoutes';
import { MyAppsRoutes } from 'modules/routers/new/myApps/MyAppsRoutes';
import { RedirectRoutes } from 'modules/routers/new/redirect/RedirectRoutes';
import { CheckAuthRoute } from 'modules/routers/new/CheckAuthRoute';
import { AsyncTeammateSignupPageContainer } from 'pages/account/teammateSignup/AsyncTeammateSignupPageContainer';
import { AsyncUnsubscribePageContainer } from 'pages/settings/unsubscribe/AsyncUnsubscribePageContainer';
import { CheckPrivateRoute } from 'modules/routers/new/CheckPrivateRoute';
import { withProviders } from 'hoc/withProviders';
import { CheckUserLimitRoute } from 'modules/routers/new/CheckUserLimitRoute';
import { BallLoader } from 'components/loader/ballLoader/BallLoader';

updateViewport();

if (isProductionStage) {
    initTagManager();
}

const NewEntryWithProviders = withProviders(() => (
    <AxiosInterceptorContainer>
        <Suspense fallback={<BallLoader pageCenter />}>
            <Outlet />
        </Suspense>
        <Toast />
    </AxiosInterceptorContainer>
));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<NewEntryWithProviders />}>
            <Route path="*" element={<RedirectRoutes />} />

            {/* Error routes */}
            <Route path={FrontendUrls.notFound()} element={<AsyncError404Page />} />

            {/* Auth routes */}
            <Route path={`${partialAuthRouteUrl}*`} element={<AuthRoutes />} />
            <Route
                path={authRouteInviteRegistrationUrl}
                element={
                    <CheckAuthRoute>
                        <AsyncTeammateSignupPageContainer />
                    </CheckAuthRoute>
                }
            />

            <Route
                element={
                    <CheckUserLimitRoute>
                        <MainLayout />
                    </CheckUserLimitRoute>
                }
            >
                {/* My apps routes */}
                <Route path={`${myAppsRouteUrl}*`} element={<MyAppsRoutes />} />

                {/* Aso tools routes */}
                <Route path={`${partialAsoToolsRouteUrl}*`} element={<AsoToolsRoutes />} />

                {/* Stores analytics routes */}
                <Route path={`${partialStoresAnalyticsRouteUrl}*`} element={<StoresAnalyticsRoutes />} />

                {/* Reviews routes */}
                <Route path={`${partialReviewsRouteUrl}*`} element={<ReviewsRoutes />} />

                {/* Connections hub routes */}
                <Route path={`${partialConnectionsHubRouteUrl}*`} element={<ConnectionsHubRoutes />} />

                {/* Keyword boost routes */}
                <Route path={`${partialKeywordBoostRouteUrl}*`} element={<KeywordBoostRoutes />} />

                {/* Referral program routes */}
                <Route path={`${partialReferralProgramRouteUrl}*`} element={<ReferralProgramRoutes />} />

                {/* Account routes */}
                <Route path={`${partialAccountRouteUrl}*`} element={<AccountRoutes />} />

                {/* Old unsubscribe route */}
                <Route
                    path={Urls.unsubscribeLink(':userName', ':subscriptionId')}
                    element={
                        <CheckPrivateRoute>
                            <AsyncUnsubscribePageContainer />
                        </CheckPrivateRoute>
                    }
                />
            </Route>

            {/* System routes */}
            <Route path="*" element={<Navigate to={FrontendUrls.notFound()} replace />} />
        </Route>
    )
);

export const NewEntry: FC = () => <RouterProvider router={router} />;

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
    const container = document.getElementById('asodesk-react-root') as HTMLElement;
    const root = createRoot(container);

    root.render(<NewEntry />);
}

if (module.hot) {
    module.hot.accept();
}
