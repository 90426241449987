import { TeammatesActionTypeListEnum } from './actionsTypes';
import { TeammatesActionType } from './actions';
import {TeammatesType} from "./TeammatesTypes";

export type TeammatesStateType = {
    teammates: TeammatesType;
};

const teammatesInitialState: TeammatesStateType = {
    teammates: [],
};

const system = (state = teammatesInitialState, action: TeammatesActionType) => {
    switch (action.type) {
        case TeammatesActionTypeListEnum.SET_TEAMMATES: {
            return {
                ...state,
                teammates: action.teammates,
            };
        }
        default: {
            return state;
        }
    }
};

export default system;
