import React, { memo, FC, ReactNode } from 'react';

export type PropsType = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    children?: ReactNode;
};

const InfoLinkComponent: FC<PropsType> = ({ children, ...linkProps }: PropsType) => (
    <a
        className="text !text-light-blue-500 !no-underline hover:no-underline hover:text-light-blue-400 focus:text-light-blue-700 focus:no-underline"
        rel="noreferrer"
        {...linkProps}
    >
        {children}
    </a>
);

export const InfoLink = memo(InfoLinkComponent);
