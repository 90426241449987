import React, { FC } from 'react';
import classNames from 'classnames';
import { Icons } from 'types/icon-types';
import { Icon } from 'newComponents/icons/asodeskIcons/Icon';

type OnlyCurrentPaymentPeriodLabelType = {
    isOnlyAnnual?: boolean;
};

export const OnlyCurrentPaymentPeriodLabel: FC<OnlyCurrentPaymentPeriodLabelType> = ({ isOnlyAnnual = false }) => (
    <div
        className={classNames(
            'flex items-center text-12 font-bold uppercase text-white !bg-success border-4 border-blue-10 rounded-8 py-4 tracking-7 relative top-4',
            { 'pr-4 pl-12 justify-between': isOnlyAnnual, 'px-4 py-10 justify-center': !isOnlyAnnual }
        )}
    >
        <span>Only annual{!isOnlyAnnual ? ' or 3 mo.' : ''}</span>
        {isOnlyAnnual && <Icon name={Icons.AnnualCalendar} size={30} lineHeight={30} />}
    </div>
);
