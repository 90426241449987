import { useResizeObserverCallback } from 'hooks/useResizeObserverCallback';
import { useEffect, useRef, useState } from 'react';

export const useScroll = () => {
    const elementRef = useRef<HTMLDivElement>(null);
    const [hasScroll, setHasScroll] = useState(false);

    const resize = () => {
        const needScroll = elementRef.current && elementRef.current.scrollHeight > window.innerHeight;
        setHasScroll(!!needScroll);
    };

    useResizeObserverCallback(elementRef, resize);

    useEffect(() => {
        window.addEventListener('resize', resize);
        window.dispatchEvent(new Event('resize'));

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    return {
        hasScroll,
        elementRef,
    };
};
