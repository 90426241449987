import React, { FC } from 'react';
import { FaroRoutes } from '@grafana/faro-react';
import { Route, Navigate } from 'react-router-dom';
import {
    partialStoresAnalyticsRouteAppStoreTrendingSearchesUrl,
    partialStoresAnalyticsRouteCvrBenchmarkUrl,
    partialStoresAnalyticsRouteSearchExplorerUrl,
    partialStoresAnalyticsRouteTopChartsUrl,
    partialStoresAnalyticsRouteTopKeywordsUrl,
} from 'utils/routeUrls';
import { AsyncTopChartsPageContainer } from 'pages/storeAnalytics/topCharts/AsyncTopChartsPageContainer';
import { AsyncKeywordExplorerOutAppPageContainer } from 'pages/keywordExplorer/keywordExplorerOutApp/AsyncKeywordExplorerOutAppPageContainer';
import { AsyncBenchmarkPageContainer } from 'pages/benchmark/AsyncBenchmarkPageContainer';
import { AsyncTopKeywordsPageContainer } from 'pages/storeAnalytics/topKeywords/AsyncTopKeywordsPageContainer';
import { AsyncTrendingSearchesPageContainer } from 'pages/storeAnalytics/trendingSearches/AsyncTrendingSearchesPageContainer';
import { useRoutesUrl } from 'hooks/useRoutesUrl';

export const StoresAnalyticsSearchExplorerRedirect: FC = () => {
    const { storesAnalyticsSearchExplorerUrl } = useRoutesUrl();

    return <Navigate to={storesAnalyticsSearchExplorerUrl} replace />;
};

export const StoresAnalyticsRoutes: FC = () => (
    <FaroRoutes>
        <Route path="*" element={<StoresAnalyticsSearchExplorerRedirect />} />
        <Route path={partialStoresAnalyticsRouteSearchExplorerUrl} element={<AsyncKeywordExplorerOutAppPageContainer />} />
        <Route path={partialStoresAnalyticsRouteTopChartsUrl} element={<AsyncTopChartsPageContainer />} />
        <Route path={partialStoresAnalyticsRouteCvrBenchmarkUrl} element={<AsyncBenchmarkPageContainer />} />
        <Route path={partialStoresAnalyticsRouteTopKeywordsUrl} element={<AsyncTopKeywordsPageContainer />} />
        <Route path={partialStoresAnalyticsRouteAppStoreTrendingSearchesUrl} element={<AsyncTrendingSearchesPageContainer />} />
    </FaroRoutes>
);
