import { Action } from 'redux';
import { UserActionTypeEnum } from './actionsTypes';

interface SetUserLoggedActionType extends Action {
    type: UserActionTypeEnum.SET_USER_LOGGED;
    isLogged: boolean;
}

export type UserActionType = SetUserLoggedActionType;

export const setUserLoggedAction = (isLogged: boolean): SetUserLoggedActionType => ({
    type: UserActionTypeEnum.SET_USER_LOGGED,
    isLogged,
});
