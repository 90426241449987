import { sentProductAnalyticsEvent } from 'state/productAnalytics/controller';
import { SentProductAnalyticsEventType } from 'state/productAnalytics/productAnalyticsTypes';

export const useProductAnalytics = () => {
    const sendEvent = (eventProps: SentProductAnalyticsEventType) => sentProductAnalyticsEvent(eventProps);

    return {
        sendEvent,
    };
};
