export const KEYWORD_SEPARATES_REGEXP = /[\u000A|\u002C|\uFF0C|\u3001|\u060C]/g;
export const KEYWORD_SEPARATES_OPTIMIZER_REGEXP = 'U0020|u002C|uFF0C|u3001|u060C';
export const KEYWORD_NEW_LINE_SEPARATES_REGEXP = '\t|\n|\n\r|u000A';
export const EMOJI_REGEXP = /(\u25A0|[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD10-\uDDFF]|\ufe0f|\ufe0e)\s?/g;
export const SPECIAL_SYMBOLS_REGEXP = /(\.{2,3})|([-—.,()?!\[\]{}"':;*])$|^([-—.,()?!\[\]{}"':;*])/g;
export const DIGITAL_AND_SYMBOLS_REGEXP = /[\d$-/:-?{-~!"^_`]/g;
export const EMAIL_REGEXP = /\S+@\S+\.\S+/;
export const COUNTRY_CODE_REGEXP = /^[A-Z]{2}$/;
export const LINK_REGEXP = /^(?:\w+:)?\/\/([^\s.]+\.\S{2})\S*$/;
export const FOUR_NUMBERS_REGEXP = /^[0-9]{4}$/;
export const NUMBERS_REGEXP = /[0-9]$/;
export const ALL_NUMBERS_REGEXP = /[0-9]*/;
export const ALL_SPACES_REGEXP = /\s/g;
export const ALL_UNDERSCORES_REGEXP = /_/g;
export const DIGITAL_AND_SPACES_REGEXP = /[0-9|\s]$/;
export const EXTRA_SPACES_REGEXP = /\s{2,}/g;
export const COMMA_IN_THE_END_REGEXP = /[,]$/gm;
