import { config } from 'constants/backendConfig';

export const useSearchAds = (countryCode: string) => {
    const searchAdsCountries = config.SEARCHADS_COUNTRIES || [];

    const isSearchAdsEnabled = searchAdsCountries.some((searchAdsCountryCode: string) => {
        return searchAdsCountryCode.toLowerCase() === countryCode.toLowerCase();
    });

    return {
        isSearchAdsEnabled,
    };
};
