import { useMemo } from 'react';
import { useAppProfileService } from 'hooks/data/useAppProfileService';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { useAppSelector } from 'hooks/useAppSystem';

export const useCompetitorsAndTrackedAppsService = () => {
    const { appList: trackedApps, isLoaded: isLoadedTrackedApps } = useAppSelector((state) => state.trackedApps);
    const competitors = useAppSelector((state) => state.competitors);
    const { isCompetitorLimitReached } = useAppProfileService();
    const { canManageApplications, canManageCompetitors } = useRequestInfoService();
    const storeIdFromUrl = useAppSelector((state) => state.system.appId);

    const baseServiceMethods = {
        isTracked: (storeId?: string) => {
            return trackedApps?.some((app) => app.store_id === storeId);
        },
        isCompetitor: (storeId?: string) => {
            return competitors.some((app) => app.store_id === storeId);
        },
        isCurrentAppTracked: trackedApps?.some((app) => app.store_id === storeIdFromUrl),
    };

    const trackedAppsStoreIds = useMemo(() => trackedApps?.map((app) => app.store_id), [trackedApps]);

    return {
        ...baseServiceMethods,
        competitors,
        canManageCompetitors,
        canManageApplications,
        trackedApps,
        isLoadedTrackedApps,
        amountTrackedApps: trackedApps?.length,
        getTrackedAppsStoreIds: () => trackedAppsStoreIds,
        canAddToMyApps: (storeId: string) => {
            return !baseServiceMethods.isTracked(storeId) && canManageApplications;
        },
        canRemoveCurrentApp: storeIdFromUrl && baseServiceMethods.isTracked(storeIdFromUrl) && canManageApplications,
        canAddCompetitor: () => {
            return canManageCompetitors && !isCompetitorLimitReached(competitors.length);
        },
        isCompetitorListEmpty: () => {
            return competitors.length === 0;
        },
        isCurrentApp: (storeId: string) => {
            return storeId === storeIdFromUrl;
        },
        canAddToMyCompetitors: (storeId: string) => {
            return (
                !baseServiceMethods.isCompetitor(storeId) && !isCompetitorLimitReached(competitors.length) && canManageCompetitors
            );
        },
        getAppsFromIds: (storeIds: string[]) => {
            return trackedApps?.filter((app) => storeIds.includes(app.store_id));
        },
        getCurrentApp: (appStoreId: string) => trackedApps?.filter((app) => app.store_id === appStoreId)[0],
        isTheSameApp: (appStoreId: string) => {
            return storeIdFromUrl === appStoreId;
        },
    };
};
