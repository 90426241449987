import React, { useEffect, useState, FC, memo, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { saveFrontendSettings, setStatsUserLimitData, updateSidebarSettings } from 'state/userFrontendSettings/controller';
import { BallLoader } from 'components/loader/ballLoader/BallLoader';
import { MainLayoutContainer } from 'modules/mainLayout/MainLayoutContainer';
import { getTrackedApps } from 'state/trackedApps/controller';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { getRequestInfo } from 'state/requestInfo/controller';
import {
    setGlobalAppLoaderShowAction,
    setLastCurrentAppIdAction,
    setLastCurrentLocaleAction,
} from 'state/userFrontendSettings/actions';
import { useSystem } from 'hooks/state/useSystem';
import { useSystemSetter } from 'hooks/state/useSystemSetter';
import { useAppProfile } from 'hooks/state/useAppProfile';
import { getOnboardingState } from 'state/onboarding/controller';
import { useAppSelector } from 'hooks/useAppSystem';
import { useDispatch } from 'react-redux';

export const MainLayout: FC = memo(() => {
    useSystemSetter();
    useAppProfile();

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const isGlobalAppLoaderShow = useAppSelector(({ userFrontendSettings }) => userFrontendSettings.isGlobalAppLoaderShow);

    const { locale, appId } = useSystem();

    const { userCountry, isLoaded: isLoadedRequestInfo, isShowOnboardingModal } = useRequestInfoService();

    const lastCurrentLocale = useAppSelector((state) => state.userFrontendSettings.lastCurrentLocale);

    const userLocale = (locale || lastCurrentLocale || userCountry || '').toLowerCase();

    useEffect(() => {
        const loadingRequestInfo = async () => {
            try {
                await dispatch(getRequestInfo());
            } catch (e) {
                setLoading(false);
            }
        };

        loadingRequestInfo();
    }, []);

    useEffect(() => {
        const loadingTrackedApps = async () => {
            try {
                if (userLocale) {
                    await dispatch(getTrackedApps(userLocale));
                }
            } catch (e) {
                setLoading(false);
            }
        };

        isLoadedRequestInfo && loadingTrackedApps();
    }, [isLoadedRequestInfo, userLocale]);

    useEffect(() => {
        const loadingData = async () => {
            try {
                await dispatch(setStatsUserLimitData());
                isShowOnboardingModal && (await dispatch(getOnboardingState()));
            } finally {
                setLoading(false);
                await dispatch(setGlobalAppLoaderShowAction(false));
                await dispatch(saveFrontendSettings());
            }
        };

        isLoadedRequestInfo && loadingData();
    }, [isLoadedRequestInfo, isShowOnboardingModal]);

    useEffect(() => {
        const onSetLastCurrentSystemValue = async () => {
            await dispatch(updateSidebarSettings());

            if (appId || locale) {
                appId && dispatch(setLastCurrentAppIdAction(appId));
                locale && dispatch(setLastCurrentLocaleAction(locale));
                await dispatch(saveFrontendSettings());
            }
        };

        isLoadedRequestInfo && onSetLastCurrentSystemValue();
    }, [isLoadedRequestInfo, appId, locale]);

    return (
        <>
            {loading || isGlobalAppLoaderShow ? (
                <BallLoader pageCenter />
            ) : (
                <MainLayoutContainer>
                    <Suspense fallback={<BallLoader pageCenter />}>
                        <Outlet />
                    </Suspense>
                </MainLayoutContainer>
            )}
        </>
    );
});
