import { UserRequestInfoResponseType } from './RequestInfoResponseType';
import { UserRequestInfoType } from './RequestInfoType';

export const requestInfoAdapter = ({
    default_reviews_translation_language,
    show_modal_trial_expired,
    show_modal_trial_expired_tomorrow,
    show_onboarding,
    current_plan,
    is_trial,
    first_name,
    company_name,
    verified,
    permissions,
    trial_days,
    is_product_tour_cs,
    is_trial_allowed,
    limits_exceeded,
    storeconsole: { appstore_status_color, ...storeconsole },
    user_inf,
    user_limit,
    available_features,
    is_connect,
    email,
    email_confirm_count,
    country,
    welcome_offer_available,
    date_joined,
}: UserRequestInfoResponseType): UserRequestInfoType => ({
    permissions: {
        canManageApplications: permissions.can_manage_applications,
        canManageCampaigns: permissions.can_manage_campaigns,
        canManageCompetitors: permissions.can_manage_competitors,
        canManageKeywords: permissions.can_manage_keywords,
        canManageSubscriptions: permissions.can_manage_subscriptions,
        canManageTeammates: permissions.can_manage_teammates,
        canUseApi: permissions.can_use_api,
        canManageReviews: permissions.can_manage_reviews,
        canManageStoreConsole: permissions.can_manage_storeconsole,
        canManageIntegrations: permissions.can_manage_integrations,
        canManageAsoDashboard: permissions.can_manage_aso_dashboard,
    },
    defaultReviewsTranslationLanguage: default_reviews_translation_language,
    isShowTrialExpiredModal: show_modal_trial_expired,
    isShowTrialExpiredTomorrowModal: show_modal_trial_expired_tomorrow,
    isShowOnboardingModal: show_onboarding,
    currentPlan: current_plan,
    isTrial: is_trial,
    userFirstName: first_name,
    userCompanyName: company_name || '',
    isVerifiedAccount: verified,
    trialDays: trial_days,
    isTrialAllowed: is_trial_allowed,
    isConnect: is_connect,
    isProductTourCs: is_product_tour_cs,
    showOnboarding: show_onboarding,
    showModalTrialExpired: show_modal_trial_expired,
    showModalTrialExpiredTomorrow: show_modal_trial_expired_tomorrow,
    storeConsole: {
        appstoreStatusColor: appstore_status_color,
        ...storeconsole,
    },
    availableFeatures: available_features,
    isCurrentPlanLimitsExceeded: limits_exceeded,
    userInfiniteSettingAmount: user_inf,
    userLimit: user_limit,
    emailConfirmCount: email_confirm_count,
    userCountry: country,
    userEmail: email,
    verified,
    welcomeOfferAvailable: welcome_offer_available,
    userDateJoined: date_joined,
});
