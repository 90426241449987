import 'styles/driverPopup.scss';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { AllowedButtons, driver as Driver, Driver as DriverType, DriveStep, Popover } from 'driver.js';
import { useOnboarding } from 'hooks/useOnboarding';
import { useOnboardingStepsData } from 'modules/modal/onboarding/yourselfStep/stepsData/useOnboardingStepsData';
import { onboardingSteps } from 'constants/onboardingSteps';
import { OnboardingStepEnum } from 'state/onboarding/types';
import loader from 'images/inlineStyleImages/loader/keyword-loader.svg';
import { useTheme } from 'hooks/state/useTheme';
import { useDispatch } from 'react-redux';
import { setCurrentStepAction } from 'state/onboarding/actions';
import { saveOnboardingState } from 'state/onboarding/controller';
import { useAppSelector } from 'hooks/useAppSystem';

type InitContextType = {
    driver?: DriverType;
};

const initContext: InitContextType = {};

export const ProductTourContext = React.createContext(initContext);

type PropsType = {
    children: ReactNode;
};

const STEP_ELEMENTS_MAP: {
    [key in OnboardingStepEnum]?: { element: string } & Pick<Popover, 'side' | 'align' | 'popoverClass'>;
} = {
    [OnboardingStepEnum.SELECT_COUNTRY_STEP]: {
        element: '.js-select-country-step-tour',
        side: 'right',
        align: 'center',
        popoverClass: 'translate-x-24',
    },
    [OnboardingStepEnum.ADD_FIRST_APP_STEP]: {
        element: '.js-add-first-app-step-tour',
        side: 'left',
        align: 'center',
        popoverClass: '-translate-x-8',
    },
    [OnboardingStepEnum.GLOBAL_NAVIGATION_STEP]: {
        element: '.js-global-navigation-step-tour',
        side: 'right',
        align: 'center',
        popoverClass: 'translate-x-8',
    },
    [OnboardingStepEnum.LOCAL_NAVIGATION_AND_TOOLS_STEP]: {
        element: '.js-local-navigation-and-tools-step-tour',
        side: 'right',
        align: 'start',
        popoverClass: 'translate-x-182',
    },
    [OnboardingStepEnum.POSSIBILITIES_OF_ASODESK_STEP]: {
        element: '.js-possibilities-of-asodesk-tour',
        side: 'right',
        align: 'end',
        popoverClass: '!right-16 !bottom-16 !inset-auto !top-auto',
    },
    [OnboardingStepEnum.REVIEWS_RATINGS_STEP]: {
        element: '.js-reviews-ratings-step-tour',
        side: 'right',
        align: 'end',
        popoverClass: '!right-16 !bottom-16 !inset-auto !top-auto',
    },
    [OnboardingStepEnum.STORES_ANALYTICS_STEP]: {
        element: '.js-stores-analytics-step-tour',
        side: 'right',
        align: 'end',
        popoverClass: '!right-16 !bottom-16 !inset-auto !top-auto',
    },
    [OnboardingStepEnum.CONNECTIONS_HUB_STEP]: {
        element: '.js-connections-hub-step-tour',
        side: 'right',
        align: 'end',
        popoverClass: '!right-16 !bottom-16 !inset-auto !top-auto',
    },
    [OnboardingStepEnum.KEYWORD_BOOST_STEP]: {
        element: '.js-keyword-boost-step-tour',
        side: 'right',
        align: 'end',
        popoverClass: '!right-16 !bottom-16 !inset-auto !top-auto',
    },
};

export const ProductTourProvider: FC<PropsType> = ({ children }: PropsType) => {
    const dispatch = useDispatch();

    const [driver, setDriver] = useState<DriverType | undefined>();

    const { isMobile } = useTheme();

    const { isShowProductTour, isReadyTour, onboardingCurrentStepId, currentSavedOnboardingData } = useOnboarding();

    const onboardingData = useAppSelector((state) => state.onboarding);

    const { stepsData } = useOnboardingStepsData();

    useEffect(() => {
        if (isShowProductTour) {
            if (currentSavedOnboardingData?.url && !currentSavedOnboardingData.url.includes(window.location.pathname)) {
                window.location.href = currentSavedOnboardingData.url;
            }
        }
    }, [isShowProductTour]);

    useEffect(() => {
        if (!isMobile && isReadyTour && isShowProductTour) {
            const driverInstance = Driver({
                showProgress: true,
                animate: false,
                allowClose: false,
                doneBtnText: 'Next',
                prevBtnText: 'Back',
                nextBtnText: 'Next',
                progressText: '{{current}} / {{total}}',
                overlayColor: 'transparent',
                steps: onboardingSteps[2].steps.reduce<DriveStep[]>((result, stepId) => {
                    const step = STEP_ELEMENTS_MAP[stepId];

                    if (step) {
                        const { title, description, next, back } = stepsData[stepId] || {};

                        const disableButtons = [
                            ...(!back?.onClick ? ['previous'] : []),
                            ...(!next?.onClick ? ['next'] : []),
                        ] as AllowedButtons[];

                        return [
                            ...result,
                            {
                                element: step.element,
                                popover: {
                                    title,
                                    side: step.side,
                                    align: step.align,
                                    description: description as string,
                                    popoverClass: step.popoverClass,
                                    disableButtons,
                                    onPrevClick: async () => {
                                        const prevButton = document.querySelector('.driver-popover-prev-btn');

                                        if (prevButton) {
                                            prevButton.classList.add('driver-popover-btn-disabled');
                                            prevButton.innerHTML = `<img src="${loader}" style="filter: brightness(0) invert(1)" class="w-20" />${prevButton.innerHTML}`;
                                        }
                                        back?.onClick && (await back.onClick());

                                        driverInstance.movePrevious();
                                    },
                                    onNextClick: async () => {
                                        const nextButton = document.querySelector('.driver-popover-next-btn');

                                        if (nextButton) {
                                            nextButton.classList.add('driver-popover-btn-disabled');
                                            nextButton.innerHTML = `${nextButton.innerHTML}<img src="${loader}" style="filter: brightness(0) invert(1)" class="w-20" />`;
                                        }
                                        next?.onClick && (await next.onClick());

                                        driverInstance.moveNext();
                                    },
                                },
                            },
                        ];
                    }

                    return result;
                }, []),
                onPopoverRender: (popover) => {
                    popover.title.innerHTML = `<span class="flex items-center px-4 py-2 rounded-10 bg-rb-bg-white text-rb-accent-blue-default font-bold text-10 mr-8 h-16">${popover.progress.innerHTML}</span>${popover.title.innerHTML}`;
                    popover.previousButton.innerHTML = `<span class="relative -z-1 icon icon-chevron-left inline-block align-bottom text-white mr-4 text-12 w-12 h-12 !leading-12"></span>${popover.previousButton.innerHTML}`;
                    popover.nextButton.innerHTML = `${popover.nextButton.innerHTML}<span class="relative -z-1 icon icon-chevron-right inline-block align-bottom text-white ml-4 text-12 w-12 h-12 !leading-12"></span>`;
                },
            });

            const currentTourStepIndex = onboardingSteps[2].steps.findIndex((stepId) => stepId === onboardingCurrentStepId);

            driverInstance.drive(currentTourStepIndex);

            setDriver(driverInstance);
        }

        if (isShowProductTour && isMobile) {
            driver && driver.destroy();
            dispatch(setCurrentStepAction(OnboardingStepEnum.SCHEDULE_A_DEMO_STEP));
            dispatch(
                saveOnboardingState({
                    ...onboardingData,
                    onboardingCurrentStepId: OnboardingStepEnum.SCHEDULE_A_DEMO_STEP,
                    isShowTour: false,
                })
            );
        }
    }, [isReadyTour, isShowProductTour, isMobile]);

    const value = {
        driver,
    };

    return (
        <ProductTourContext.Provider value={value}>
            {!isMobile && isShowProductTour && !isReadyTour && (
                <div className="fixed top-0 bottom-0 left-0 right-0 z-1000000001" />
            )}
            {children}
        </ProductTourContext.Provider>
    );
};
