import { Action } from 'redux';
import { AppLocalesResultType, AppLocalesPageInfoType } from 'state/dashboard/AppLocalesResultType';
import { DashboardActionTypeEnum } from './actionsTypes';

interface InitEmptyDashboardAppsActionType extends Action {
    type: DashboardActionTypeEnum.INIT_EMPTY_DASHBOARD_APPS;
}
interface SetDashboardAppsActionType extends Action {
    type: DashboardActionTypeEnum.SET_DASHBOARD_APPS;
    apps: AppLocalesResultType[];
    pageInfo: AppLocalesPageInfoType;
}

interface AddNextPageDashboardAppsActionType extends Action {
    type: DashboardActionTypeEnum.ADD_NEXT_PAGE;
    apps: AppLocalesResultType[];
    pageInfo: AppLocalesPageInfoType;
    page: number;
}

interface ToggleDashboardFavoriteAppActionType extends Action {
    type: DashboardActionTypeEnum.TOGGLE_DASHBOARD_FAVORITE_APP;
    storeId: string;
    isFavorite: boolean;
}

interface RemoveDashboardAppActionActionType extends Action {
    type: DashboardActionTypeEnum.REMOVE_DASHBOARD_APP;
    storeId: string;
}

export type DashboardActionType =
    | InitEmptyDashboardAppsActionType
    | SetDashboardAppsActionType
    | ToggleDashboardFavoriteAppActionType
    | RemoveDashboardAppActionActionType
    | AddNextPageDashboardAppsActionType;

export const initEmptyDashboardAppsAction = (): InitEmptyDashboardAppsActionType => {
    return {
        type: DashboardActionTypeEnum.INIT_EMPTY_DASHBOARD_APPS,
    };
};
export const setDashboardAppsAction = (
    apps: AppLocalesResultType[],
    pageInfo: AppLocalesPageInfoType
): SetDashboardAppsActionType => {
    return {
        type: DashboardActionTypeEnum.SET_DASHBOARD_APPS,
        apps,
        pageInfo,
    };
};

export const addNextPageDashboardAppsAction = (
    apps: AppLocalesResultType[],
    pageInfo: AppLocalesPageInfoType,
    page: number
): AddNextPageDashboardAppsActionType => {
    return {
        type: DashboardActionTypeEnum.ADD_NEXT_PAGE,
        apps,
        pageInfo,
        page,
    };
};

export const toggleDashboardFavoriteAppAction = (storeId: string, isFavorite: boolean): ToggleDashboardFavoriteAppActionType => {
    return {
        type: DashboardActionTypeEnum.TOGGLE_DASHBOARD_FAVORITE_APP,
        storeId,
        isFavorite,
    };
};

export const removeDashboardAppAction = (storeId: string): RemoveDashboardAppActionActionType => {
    return {
        type: DashboardActionTypeEnum.REMOVE_DASHBOARD_APP,
        storeId,
    };
};
