import 'react-toastify/dist/ReactToastify.css';
import './toast.scss';

import React from 'react';
import { toast, Slide, ToastContainer } from 'react-toastify';

window.toast = toast;

export const Toast: React.FC = () => (
    <ToastContainer
        position="bottom-left"
        autoClose={3000}
        transition={Slide}
        hideProgressBar
        newestOnTop
        closeOnClick
        draggable
        pauseOnHover
    />
);
