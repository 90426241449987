import { useDispatch } from 'react-redux';
import { changePopupPayloadAction, closeAllPopupsAction, setPopupValueAction, togglePopupAction } from 'state/popup/actions';
import { PopupPayloadType } from 'state/popup/PopupTypes';
import { useAppSelector } from 'hooks/useAppSystem';

type UseTogglePopupReturnType<T> = {
    isOpen: boolean;
    data: T;
    togglePopup: () => void;
    togglePopupWithData: (payload?: PopupPayloadType<T>) => void;
    closeAllPopups: () => void;
    changePopupPayload: (payload: Partial<PopupPayloadType<T>>) => void;
    setPopupValue: (isOpen: boolean) => void;
    state?: string;
};

export const useTogglePopup = <T extends any>(popupName: string): UseTogglePopupReturnType<T> => {
    const dispatch = useDispatch();
    const popup = useAppSelector((state) => state.popup[popupName]);

    const toggle = () => {
        dispatch(togglePopupAction(popupName));
    };

    const toggleWithData = <T,>(payload?: PopupPayloadType<T>) => {
        dispatch(togglePopupAction(popupName, payload));
    };

    const closeAllPopups = () => {
        dispatch(closeAllPopupsAction());
    };

    const changePopupPayload = <T,>(payload: Partial<PopupPayloadType<T>>) => {
        dispatch(changePopupPayloadAction(popupName, payload));
    };

    const setPopupValue = (isOpen: boolean) => dispatch(setPopupValueAction({ popupName, isOpen }));

    return {
        isOpen: popup ? popup.isOpen : false,
        data: popup?.data,
        togglePopup: toggle,
        togglePopupWithData: toggleWithData,
        closeAllPopups,
        changePopupPayload,
        setPopupValue,
        state: popup?.state,
    };
};
