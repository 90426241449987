import { TopPositionHistoryEnum } from 'state/topPositionsHistory/actionsTypes';
import { TopPositionHistoryActionType } from 'state/topPositionsHistory/actions';
import { getTopPositionKeywordHash } from 'state/topPositionsHistory/getTopPositionKeywordHash';
import { TopPositionsHistoryStateType } from 'state/topPositionsHistory/TopPositionsHistoryStateType';

const initialState: TopPositionsHistoryStateType = {};

const topPositionsHistory = (state = initialState, action: TopPositionHistoryActionType) => {
    switch (action.type) {
        case TopPositionHistoryEnum.SET_TOP_POSITION_HISTORY: {
            const { country, history, keyword } = action;
            const keywordHash = getTopPositionKeywordHash(keyword, country);
            return {
                ...state,
                [keywordHash]: {
                    ...(state[keywordHash] ? state[keywordHash] : {}),
                    ...history,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default topPositionsHistory;
