import { RequestInfoActionEnum } from 'state/requestInfo/actionTypes';
import { Action } from 'redux';
import { UserAvailableAccountType } from './UserAvailableAccountsResponseType';
import { UserRequestInfoType } from './RequestInfoType';

export interface SetRequestInfoActionType extends Action {
    type: RequestInfoActionEnum.SET_REQUEST_INFO;
    user: UserRequestInfoType;
}

export interface CleanRequestInfoActionType extends Action {
    type: RequestInfoActionEnum.CLEAN_REQUEST_INFO;
}

export interface SetStoreConsoleAvailableAccountActionType extends Action {
    type: RequestInfoActionEnum.SET_AVAILABLE_ACCOUNT;
    account: UserAvailableAccountType;
}

export interface SetStoreConsoleLoadingActionType extends Action {
    type: RequestInfoActionEnum.SET_REQUEST_INFO_LOADING;
    isLoading: boolean;
}

export interface SetVisibilityOnboardingActionType extends Action {
    type: RequestInfoActionEnum.SET_VISIBILITY_ONBOARDING;
    isShowOnboardingModal: boolean;
}

export type RequestInfoActionType =
    | SetRequestInfoActionType
    | SetStoreConsoleAvailableAccountActionType
    | CleanRequestInfoActionType
    | SetStoreConsoleLoadingActionType
    | SetVisibilityOnboardingActionType;

export const setRequestInfoAction = (user: UserRequestInfoType): SetRequestInfoActionType => {
    return {
        type: RequestInfoActionEnum.SET_REQUEST_INFO,
        user,
    };
};

export const cleanRequestInfoAction = (): CleanRequestInfoActionType => {
    return {
        type: RequestInfoActionEnum.CLEAN_REQUEST_INFO,
    };
};

export const setStoreConsoleAvailableAccountAction = (
    account: UserAvailableAccountType
): SetStoreConsoleAvailableAccountActionType => {
    return {
        type: RequestInfoActionEnum.SET_AVAILABLE_ACCOUNT,
        account,
    };
};

export const setLoadingAction = (isLoading: boolean): SetStoreConsoleLoadingActionType => {
    return {
        type: RequestInfoActionEnum.SET_REQUEST_INFO_LOADING,
        isLoading,
    };
};

export const setVisibilityOnboardingAction = (isShowOnboardingModal: boolean): SetVisibilityOnboardingActionType => {
    return {
        type: RequestInfoActionEnum.SET_VISIBILITY_ONBOARDING,
        isShowOnboardingModal,
    };
};
