import queryString, { ParsedQuery } from 'query-string';

export const parseQueryString = (query: string) => queryString.parse(query);

export const stringifyParsedQuery = (parsed: { [key: string]: any }) => queryString.stringify(parsed);

type StringifyUrlType = {
    url?: string;
    query: ParsedQuery;
    isOrigin?: boolean;
};

export const stringifyUrl = ({ url = window.location.href, query, isOrigin }: StringifyUrlType) => {
    const { origin } = window.location;

    let computedUrl = decodeURIComponent(queryString.stringifyUrl({ url, query }));

    if (!isOrigin) {
        computedUrl = computedUrl.replace(origin, '');
    }

    return computedUrl;
};

export const resetUrlQueryParams = () => {
    const { origin, pathname } = window.location;

    window.history.pushState({}, document.title, `${origin}${pathname}`);
};
