export enum OptimizerTypeListEnum {
    GET_SET_TAGS_OPTIMIZER = 'GET_SET_TAGS_OPTIMIZER',
    GET_TABS_OPTIMIZER = 'GET_TABS_OPTIMIZER',
    UPDATE_TAGS_OPTIMIZER = 'UPDATE_TAGS_OPTIMIZER',
    TOGGLE_VISIBLE_ADD_LANGUAGE_OPTIMIZER = 'TOGGLE_VISIBLE_ADD_LANGUAGE_OPTIMIZER',
    SET_KEYWORDS_TO_STOP_WORDS = 'SET_KEYWORDS_TO_STOP_WORDS',
    SAVE_KEYWORDS_TO_STOP_WORDS = 'SAVE_KEYWORDS_TO_STOP_WORDS',
    OPEN_SET_OPTIMIZER = 'OPEN_SET_OPTIMIZER',
    REMOVE_SET_OPTIMIZER = 'REMOVE_SET_OPTIMIZER',
    UPDATE_SET_OPTIMIZER = 'UPDATE_SET_OPTIMIZER',
    SAVE_SET_OPTIMIZER = 'SAVE_SET_OPTIMIZER',
    SET_OPTIMIZER_META = 'SET_OPTIMIZER_META',
    SET_OPTIMIZER_REPORT_ACTIVE_TAB_ID = 'SET_OPTIMIZER_REPORT_ACTIVE_TAB_ID',
    SET_OPTIMIZER_DATA_REPORT = 'SET_OPTIMIZER_DATA_REPORT',
    SET_OPTIMIZER_SUMMARY_REPORT = 'SET_OPTIMIZER_SUMMARY_REPORT',
    RESET_OPTIMIZER_DATA_REPORT = 'RESET_OPTIMIZER_DATA_REPORT',
}
