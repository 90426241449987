import { parseQueryString } from 'utils/helpers/queryString';
import { PaymentModalStepEnum, PaymentPeriodEnum, PaymentTypeEnum, SubscriptionTypeEnum } from './paymentTypes';

const { search } = window.location;
const {
    paymentType,
    subscriptionId,
    stepModalId,
    planId,
    isEditSubscription,
    isShowPaymentPeriod,
    isEnterPromoCode,
    subscriptionType,
    selectedCardId,
    errorCardText,
    isShowPlanPricePeriod,
    paymentPeriod,
    isShowPlanFeatures,
} = parseQueryString(search);

const getBooleanParam = (param: string | string[] | null | undefined) => {
    if (param === undefined || param === null) return undefined;

    return !!+(param as string);
};

export const paymentPeriodParams = paymentPeriod as PaymentPeriodEnum;
export const paymentTypeParams = paymentType as PaymentTypeEnum;
export const subscriptionIdParams = subscriptionId as string;
export const stepModalIdParams = +(stepModalId as string) as PaymentModalStepEnum;
export const subscriptionTypeParams = (subscriptionType as unknown) as SubscriptionTypeEnum;
export const planIdParams = +(planId as string) as number;
export const isEditSubscriptionParams = getBooleanParam(isEditSubscription);
export const isShowPaymentPeriodParams = getBooleanParam(isShowPaymentPeriod);
export const isEnterPromoCodeParams = getBooleanParam(isEnterPromoCode);
export const isShowPlanPricePeriodParams = getBooleanParam(isShowPlanPricePeriod);
export const isShowPlanFeaturesParams = !!isShowPlanFeatures;
export const selectedCardIdParams = +(selectedCardId as string) as number;
export const errorCardTextParams = errorCardText as string;

export const requestPaymentTypeMap = {
    [PaymentTypeEnum.PLATRON]: 0,
    [PaymentTypeEnum.STRIPE]: 1,
};
