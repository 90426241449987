import React, { useRef, useEffect, FC, Suspense } from 'react';
import classNames from 'classnames';
import { useVisibilityElement } from 'hooks/useVisibilityElement';
import { AsyncTooltipContent } from 'components/tooltips/asodeskTooltip/AsyncTooltipContent';
import { TooltipPropsType } from 'components/tooltips/asodeskTooltip/tooltipTypes';

const AsodeskTooltipComponent: FC<TooltipPropsType> = ({
    tooltip,
    anchor = <i className="fa fa-question-circle cursor" />,
    tooltipClassName,
    className,
    isBlock = false,
    renderContent,
    delayHide,
    delayShow,
    place = 'top',
    clickable = false,
    inPortal = true,
}: TooltipPropsType) => {
    const { showElement, setShowElement, onMouseEnter, onMouseLeave } = useVisibilityElement({ delayShow, delayHide });

    const referenceElement = useRef(null);

    useEffect(() => {
        const hideTooltip = () => setShowElement(false);

        document.addEventListener('scroll', hideTooltip);

        if (!clickable) {
            document.addEventListener('click', hideTooltip);
        }

        return () => {
            document.removeEventListener('scroll', hideTooltip);
            document.removeEventListener('click', hideTooltip);
        };
    }, []);

    return (
        <>
            <span
                ref={referenceElement}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onTouchStart={onMouseEnter}
                onTouchEnd={onMouseLeave}
                className={classNames(className, {
                    block: isBlock,
                    'inline-block': !isBlock,
                })}
            >
                {anchor}
            </span>
            {showElement && tooltip && (
                <Suspense fallback={<div />}>
                    <AsyncTooltipContent
                        tooltip={tooltip}
                        referenceElement={referenceElement.current}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        showElement={showElement}
                        tooltipClassName={tooltipClassName}
                        place={place}
                        inPortal={inPortal}
                        renderContent={renderContent}
                        clickable={clickable}
                    />
                </Suspense>
            )}
        </>
    );
};

export const AsodeskTooltip = React.memo(AsodeskTooltipComponent);
