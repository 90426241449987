import { RootState } from 'state/store';

export const getAppProfileStore = (store: () => RootState) => {
    const {
        appProfile: { country_code: countryCode, store_id: storeId, name, isDemo },
    } = store();

    return {
        country: countryCode && countryCode.toLowerCase(),
        appId: isDemo ? 'demo' : storeId,
        name,
    };
};
