import { KeywordAnalyticsSuggestionsActionType } from 'state/keywordAnalyticsSuggestions/actions';
import { KeywordAnalyticsSuggestionsStateType } from 'state/keywordAnalyticsSuggestions/KeywordAnalyticsSuggestionsStateType';
import { KeywordAnalyticsSuggestionsActionTypeEnum } from 'state/keywordAnalyticsSuggestions/actionTypes';

const initialState = {
    keywords: [],
    loading: false,
    recordsTotal: 0,
};

const keywordAnalyticsSuggestions = (
    state: KeywordAnalyticsSuggestionsStateType = initialState,
    action: KeywordAnalyticsSuggestionsActionType
) => {
    switch (action.type) {
        case KeywordAnalyticsSuggestionsActionTypeEnum.SET_KEYWORD_ANALYTICS_SUGGESTIONS: {
            return {
                ...state,
                keywords: [...action.keywords],
                loading: false,
            };
        }
        case KeywordAnalyticsSuggestionsActionTypeEnum.SET_KEYWORD_ANALYTICS_SUGGESTIONS_RECORDS_TOTAL: {
            return {
                ...state,
                recordsTotal: action.recordsTotal,
            };
        }
        case KeywordAnalyticsSuggestionsActionTypeEnum.ADD_KEYWORD_ANALYTICS_SUGGESTIONS: {
            const keywordList = [...state.keywords];

            action.keywordIds.forEach((keywordId) => {
                const keyword = keywordList.find((word) => keywordId === word.id);
                if (keyword) keyword.add_keyword = false;
            });

            return {
                ...state,
                keywords: keywordList,
            };
        }
        case KeywordAnalyticsSuggestionsActionTypeEnum.SET_KEYWORD_ANALYTICS_LOADING: {
            return {
                ...state,
                loading: action.loading,
            };
        }
        case KeywordAnalyticsSuggestionsActionTypeEnum.SET_KEYWORD_ANALYTICS_STORE_INFO: {
            return {
                ...state,
                loadedStoreId: action.storeId,
                loadedStoreLocale: action.locale,
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordAnalyticsSuggestions;
