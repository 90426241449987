import { CustomReportsListType, CustomReportType } from 'state/customReports/CustomReportsListType';
import { CustomReportsActionTypeEnum } from 'state/customReports/actionsTypes';
import { CustomReportActionType } from 'state/customReports/actions';
import { EmailReportHistoryType } from 'state/customReports/EmailReportHistoryType';
import { EmailReportHistoryFilterType } from 'state/customReports/EmailReportHistoryFilterType';
import { ReviewChangeRequestEnum } from 'state/integrationsHelpdesk/reviewChangeRequestEnum';
import { MaxReviewLengthRequestEnum } from 'state/integrationsHelpdesk/maxReviewLengthRequestEnum';
import { ImportHistoryRequestEnum } from 'state/integrationsHelpdesk/importHistoryRequestEnum';

export type CustomReportsStateType = {
    isCustomReportListLoading: boolean;
    isEmailReportHistoryLoading: boolean;
    customReportsList: CustomReportsListType;
    emailReportHistory: EmailReportHistoryType[];
    isCreateNew: boolean;
    report: CustomReportType | null;
    reportEmptyTemplate: CustomReportType;
    nextEmailReportHistoryPage: null | string;
    emailReportHistoryFilter: EmailReportHistoryFilterType;
};

const initialState: CustomReportsStateType = {
    isCustomReportListLoading: true,
    isEmailReportHistoryLoading: true,
    nextEmailReportHistoryPage: null,
    customReportsList: [],
    emailReportHistory: [],
    isCreateNew: true,
    report: null,
    reportEmptyTemplate: {
        id: 0,
        type: 'email',
        isActive: true,
        channelNames: [],
        appStoreIds: [],
        countriesCodes: [],
        keywordReport: { daily: false, onlyFavoritesKeywords: false, weekly: false, monthly: false, isActive: false },
        reviewsReport: { daily: false, weekly: false, monthly: false, isActive: false },
        appOverviewReport: { daily: false, weekly: false, monthly: false, isActive: false },
        asoReport: { daily: false, weekly: false, monthly: false, isActive: false },
        updatedMetaAlert: { isActive: false },
        trendingSearchAlert: { isActive: false },
        featuredAppAlert: { isActive: false },
        negativeReviewsAlert: { isActive: false, reviewsCount: 0, daysPeriod: 0 },
        newReviewWithTagAlert: { isActive: false, reviewTagIds: [] },
        newReviewAlert: { isActive: false },
        replyToReviewsReport: {
            isActive: false,
            rating: null,
            byChanges: ReviewChangeRequestEnum.ALL,
            contentLength: MaxReviewLengthRequestEnum.ALL,
            preLoadCount: ImportHistoryRequestEnum.IMPORT_100,
        },
    },
    emailReportHistoryFilter: {},
};

const customReports = (state = initialState, action: CustomReportActionType) => {
    switch (action.type) {
        case CustomReportsActionTypeEnum.SET_CUSTOM_REPORT_LIST: {
            return {
                ...state,
                customReportsList: action.customReportsList,
                isCustomReportListLoading: false,
            };
        }
        case CustomReportsActionTypeEnum.SET_EMAIL_REPORT_HISTORY: {
            return {
                ...state,
                emailReportHistory: action.emailReportHistory,
                isEmailReportHistoryLoading: false,
                nextEmailReportHistoryPage: action.nextEmailReportHistoryPage,
            };
        }
        case CustomReportsActionTypeEnum.ADD_EMAIL_REPORT_HISTORY: {
            return {
                ...state,
                emailReportHistory: [...state.emailReportHistory, ...action.emailReportHistory],
                isEmailReportHistoryLoading: false,
                nextEmailReportHistoryPage: action.nextEmailReportHistoryPage,
            };
        }
        case CustomReportsActionTypeEnum.SET_EMAIL_REPORT_HISTORY_LOADING: {
            return {
                ...state,
                isEmailReportHistoryLoading: action.isLoading,
            };
        }
        case CustomReportsActionTypeEnum.SET_NEW_REPORT_FLAG: {
            return {
                ...state,
                isCreateNew: action.isNew,
            };
        }
        case CustomReportsActionTypeEnum.SET_EMAIL_REPORT_HISTORY_FILTERS: {
            return {
                ...state,
                emailReportHistoryFilter: {
                    ...state.emailReportHistoryFilter,
                    ...action.filters,
                },
            };
        }
        case CustomReportsActionTypeEnum.ADD_CUSTOM_REPORT: {
            return {
                ...state,
                customReportsList: [...state.customReportsList, action.customReport],
            };
        }
        case CustomReportsActionTypeEnum.UPDATE_CUSTOM_REPORT: {
            const reportIndex = state.customReportsList.findIndex(
                (report) => report.id === action.customReport.id && report.type === action.customReport.type
            );
            return {
                ...state,
                customReportsList: [
                    ...state.customReportsList.slice(0, reportIndex),
                    action.customReport,
                    ...state.customReportsList.slice(reportIndex + 1),
                ],
            };
        }
        case CustomReportsActionTypeEnum.SET_EDIT_CUSTOM_REPORT: {
            return {
                ...state,
                isCreateNew: false,
                report: state.customReportsList.find(({ id, type }) => action.reportId === id && action.channelType === type),
            };
        }
        case CustomReportsActionTypeEnum.DELETE_CUSTOM_REPORT: {
            return {
                ...state,
                customReportsList: state.customReportsList.filter(
                    ({ id, type }) => !(action.reportId === id && action.channelType === type)
                ),
            };
        }
        case CustomReportsActionTypeEnum.SET_EMPTY_CUSTOM_REPORT_TYPE: {
            return {
                ...state,
                report: null,
                reportEmptyTemplate: {
                    ...state.reportEmptyTemplate,
                    type: action.channelType,
                },
            };
        }
        case CustomReportsActionTypeEnum.REMOVE_UNTRACKED_APP: {
            return {
                ...state,
                customReportsList: state.customReportsList.map((customReport) => ({
                    ...customReport,
                    appStoreIds: customReport.appStoreIds.filter((storeId) => storeId !== action.storeId),
                })),
            };
        }
        default: {
            return state;
        }
    }
};

export default customReports;
