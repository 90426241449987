export enum TagCategorySortingEnum {
    OLDEST,
    NEWEST,
    ALPHABET,
}

export type SortingOptionType = { key: TagCategorySortingEnum; title: string };

export const sortingOptions: SortingOptionType[] = [
    {
        key: TagCategorySortingEnum.OLDEST,
        title: 'Oldest first',
    },
    {
        key: TagCategorySortingEnum.NEWEST,
        title: 'New first',
    },
    {
        key: TagCategorySortingEnum.ALPHABET,
        title: 'A → Z',
    },
];
