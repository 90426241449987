export enum IntegrationsActionTypeListEnum {
    SET_INTEGRATIONS_DATA = 'SET_INTEGRATIONS_DATA',
    SET_ACTIVE_STORE = 'SET_ACTIVE_STORE',
    CHANGE_STORE_PASSWORD = 'CHANGE_STORE_PASSWORD',
    CHANGE_TYPE_SECURITY_CODE = 'CHANGE_TYPE_SECURITY_CODE',
    UPDATE_STORE_SESSION = 'UPDATE_STORE_SESSION',
    SEND_AUTH_STORE_CODE = 'SEND_AUTH_STORE_CODE',
    SEND_SMS_STORE_CODE = 'SEND_SMS_STORE_CODE',
    REMOVE_STORE_CONNECTION = 'REMOVE_STORE_CONNECTION',
    CREATE_STORE_CONNECTION = 'CREATE_STORE_CONNECTION',
}
