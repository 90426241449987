import { useEffect } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

export const useResizeObserverCallback = <T extends HTMLElement>(elementRef: React.RefObject<T>, callback: () => void) => {
    useEffect(() => {
        const element = elementRef.current;

        const resizeObserver = new ResizeObserver((entries) => {
            const resizableElement = entries[0].target as T;
            if (resizableElement) {
                callback();
            }
        });

        if (element) resizeObserver.observe(element);

        return () => {
            if (element) resizeObserver.unobserve(element);
        };
    }, [elementRef]);
};
