import { ReviewChangeRequestEnum } from 'state/integrationsHelpdesk/reviewChangeRequestEnum';
import { ImportHistoryRequestEnum } from 'state/integrationsHelpdesk/importHistoryRequestEnum';
import { MaxReviewLengthRequestEnum } from 'state/integrationsHelpdesk/maxReviewLengthRequestEnum';
import { AppStoreType } from 'state/appProfile/AppResponseType';
import { HelpdeskSettingsStepEnum } from 'state/integrationsHelpdesk/helpdeskSettingsStepEnum';
import { HelpdeskGroupType } from 'state/integrationsHelpdesk/helpdeskGroupType';
import { AuthIntegrationStatusEnum } from 'state/integrations/IntegrationsResponseType';

export enum HelpdeskTypeIntegrationEnum {
    ZENDESK = 'zendesk',
    OMNIDESK = 'omnidesk',
    USEDESK = 'usedesk',
}

export type HelpdeskIntegrationAppType = {
    storeId: string;
    settings: number;
    countries?: string[];
    languages?: string[];
    byChanges: ReviewChangeRequestEnum;
    rating: number[];
    contentLength: MaxReviewLengthRequestEnum;
    preLoadCount: ImportHistoryRequestEnum;
    attachInfoAsTags: boolean;
    externalAgentId?: number;
    externalGroupId?: number;
    storeType: AppStoreType;
    agentEmail?: string;
    agentId?: number;
};

export type HelpdeskIntegrationType = {
    id: number;
    subdomain: string;
    accountAdmin: number;
    creator: number;
    token: string;
    accountEmail: string;
    applications: HelpdeskIntegrationAppType[];
    authIntegrationStatus: AuthIntegrationStatusEnum;
    syncTemplates?: boolean;
};

export type HelpdeskIntegrationListType = HelpdeskIntegrationType[];

type SelectedHelpdeskIntegrationAppsValueType = HelpdeskIntegrationAppType & {
    wasUpdated?: boolean;
};

export type SelectedHelpdeskIntegrationAppsType = {
    [storeId: string]: SelectedHelpdeskIntegrationAppsValueType;
};

export type InitialCreateIntegrationType = {
    step: HelpdeskSettingsStepEnum;
    lastAchievedStep: HelpdeskSettingsStepEnum;
    selectedApps: SelectedHelpdeskIntegrationAppsType;
    currentApp?: HelpdeskIntegrationAppType;
    selectedIntegration?: HelpdeskIntegrationType;
    groups: HelpdeskGroupType[];
};
