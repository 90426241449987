import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hubspotParams, loadHubspotScripts } from 'utils/hubspotUtils';
import { getHubspotToken } from 'state/hubspot/controller';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';

const initContext: { hasLoaded: boolean; isHidden: boolean; setIsHidden: (isHidden: boolean) => void } = {
    hasLoaded: false,
    isHidden: false,
    setIsHidden: () => {},
};

export const HubSpotContext = React.createContext(initContext);

type PropsType = {
    children: ReactNode;
};

export const HubSpotProvider: FC<PropsType> = ({ children }: PropsType) => {
    const dispatch = useDispatch();

    const { isLogged, isLoading: isLoadingRequestInfo, userEmail } = useRequestInfoService();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const { search } = useLocation();

    useEffect(() => {
        const formButton = document.createElement('div');
        formButton.className = 'hs-cta-trigger-button hs-cta-trigger-button-66729040315 overflow-hidden w-0';

        document.body.appendChild(formButton);
    }, []);

    useEffect(() => {
        const loadHubspotToken = async () => {
            const hubspotToken = await dispatch(getHubspotToken());

            window.hsConversationsSettings = {
                identificationEmail: userEmail,
                identificationToken: hubspotToken,
            };
        };

        const initHubspot = async () => {
            if (isLogged) {
                await loadHubspotToken();
            }

            const hsConversationsOnReadyCallback = () => setHasLoaded(true);

            loadHubspotScripts(hsConversationsOnReadyCallback);
        };

        !isLoadingRequestInfo && initHubspot();

        return () => {
            window.hsConversationsOnReady = [];
            window.hsConversationsSettings = {};
        };
    }, [isLogged, isLoadingRequestInfo]);

    useEffect(() => {
        const isNotOpenWithQueryParams = hubspotParams.every((param) => !search.includes(param));
        if (!isHidden && hasLoaded && isNotOpenWithQueryParams) {
            window.HubSpotConversations.widget.refresh({ openToNewThread: true });
        }
    }, [search]);

    const value = {
        hasLoaded,
        setIsHidden,
        isHidden,
    };

    return <HubSpotContext.Provider value={value}>{children}</HubSpotContext.Provider>;
};
