import { Action } from 'redux';
import { ToolTypesEnum } from 'types/toolTypes';
import { StatsUserLimitIdsEnum } from 'state/userFrontendSettings/SidebarResponseType';
import { TagCategorySortingEnum } from 'modules/reviews/reviewTags/sortingDropdownMenu/sortingOptions';
import { PromoType } from 'modules/promo/promoTypes';
import { UserFrontendSettingsActionTypeEnum } from './actionsTypes';
import { KeywordExplorerWidgetEnum, UserFrontendSettingsInitialStateType } from './UserFrontendSettingsInitialStateType';
import { StatsUserLimitsType } from './StatsUserLimitsType';

interface ToggleCollapsedSidebarSectionActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.TOGGLE_COLLAPSED_SIDEBAR_SECTION;
    sectionId: ToolTypesEnum;
}

interface UpdateSidebarSettingsActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.UPDATE_USER_FRONTEND_SETTINGS;
    settings: UserFrontendSettingsInitialStateType;
}

interface SetStatsUserLimitDataActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.SET_STATS_USER_LIMIT_DATA;
    statsUserLimit: StatsUserLimitsType;
}

interface AddUserLimitsAmountActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.ADD_USER_LIMITS_AMOUNT;
    limitType: StatsUserLimitIdsEnum;
    amount: number;
}

interface ToggleOpenedDesktopSidebarActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.TOGGLE_OPENED_DESKTOP_SIDEBAR;
}

interface ToggleOpenedMobileSidebarActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.TOGGLE_OPENED_MOBILE_SIDEBAR;
}

interface CloseMobileSidebarActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.CLOSE_MOBILE_SIDEBAR;
}

interface ToggleFavoriteToolActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.TOGGLE_FAVORITE_TOOL;
    toolName: string;
}

interface ChangeFavoriteLinkPositionActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.CHANGE_FAVORITE_LINK_POSITION;
    oldIndex: number;
    newIndex: number;
}

interface SetReplyBoardRandomTemplateFlagActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.SET_REPLY_BOARD_RANDOM_TEMPLATE_FLAG;
    flag: boolean;
}

interface SetReplyBoardAutoNextReviewFlagActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.SET_REPLY_BOARD_AUTO_NEXT_REVIEW_FLAG;
    flag: boolean;
}

interface SetKeywordCountryFlagShowActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.SET_KEYWORD_COUNTRY_FLAG_SHOW;
    flag: boolean;
}

interface SetGlobalAppLoaderAction extends Action {
    type: UserFrontendSettingsActionTypeEnum.SET_GLOBAL_APP_LOADER_SHOW;
    flag: boolean;
}

interface ChangeKeywordAnalyticsTableColumnOrderActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.CHANGE_KEYWORD_ANALYTICS_TABLE_COLUMN_ORDER;
    columnsIds: string[];
}

interface AddKeywordAnalyticsTableColumnInvisibilityActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.ADD_KEYWORD_ANALYTICS_TABLE_COLUMN_INVISIBILITY;
    columnsId: string;
}

interface RemoveKeywordAnalyticsTableColumnInvisibilityActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.REMOVE_KEYWORD_ANALYTICS_TABLE_COLUMN_INVISIBILITY;
    columnsId: string;
}

interface AddAppReviewAutoTranslateActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.ADD_APP_REVIEW_AUTO_TRANSLATE;
    appId: string;
    timeStart: number;
}

interface RemoveAppReviewAutoTranslateActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.REMOVE_APP_REVIEW_AUTO_TRANSLATE;
    appId: string;
}

interface HideAppReviewAutoTranslateInfoActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.HIDE_APP_REVIEW_AUTO_TRANSLATE_INFO;
    appId: string;
}

interface ChangeShowTrialByTypeActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.CHANGE_SHOW_TRIAL_BY_TYPE;
    trialType: TrialType;
    isShow: boolean;
}

interface HideWOModalActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.HIDE_WO_MODAL;
}

interface ChangeKeywordExplorerWidgetOrderActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.CHANGE_KEYWORD_EXPLORER_WIDGET_ORDER;
    order: KeywordExplorerWidgetEnum[];
}

interface SetTagsCategorySortingActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.SET_TAGS_CATEGORY_SORTING;
    sorting: TagCategorySortingEnum;
}

interface SetGlobalAppLoaderActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.SET_GLOBAL_APP_LOADER_SHOW;
    flag: boolean;
}

interface ToggleShowingNewNavigationActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.TOGGLE_SHOWING_NEW_NAVIGATION;
}

interface SetLastCurrentAppIdActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.SET_LAST_CURRENT_APP_ID;
    appId: string;
}

interface SetLastCurrentLocaleActionType extends Action {
    type: UserFrontendSettingsActionTypeEnum.SET_LAST_CURRENT_LOCALE;
    locale: string;
}

export type SidebarActionType =
    | ToggleCollapsedSidebarSectionActionType
    | UpdateSidebarSettingsActionType
    | SetStatsUserLimitDataActionType
    | ToggleOpenedDesktopSidebarActionType
    | ToggleOpenedMobileSidebarActionType
    | ToggleFavoriteToolActionType
    | ChangeFavoriteLinkPositionActionType
    | SetReplyBoardRandomTemplateFlagActionType
    | SetReplyBoardAutoNextReviewFlagActionType
    | CloseMobileSidebarActionType
    | SetKeywordCountryFlagShowActionType
    | ChangeKeywordAnalyticsTableColumnOrderActionType
    | AddKeywordAnalyticsTableColumnInvisibilityActionType
    | RemoveKeywordAnalyticsTableColumnInvisibilityActionType
    | AddAppReviewAutoTranslateActionType
    | RemoveAppReviewAutoTranslateActionType
    | HideAppReviewAutoTranslateInfoActionType
    | ChangeShowTrialByTypeActionType
    | HideWOModalActionType
    | AddUserLimitsAmountActionType
    | ChangeKeywordExplorerWidgetOrderActionType
    | SetTagsCategorySortingActionType
    | SetGlobalAppLoaderActionType
    | ToggleShowingNewNavigationActionType
    | SetLastCurrentAppIdActionType
    | SetLastCurrentLocaleActionType;

export const toggleCollapsedSidebarSectionAction = (sectionId: ToolTypesEnum): ToggleCollapsedSidebarSectionActionType => ({
    type: UserFrontendSettingsActionTypeEnum.TOGGLE_COLLAPSED_SIDEBAR_SECTION,
    sectionId,
});

export const updateSidebarSettingsAction = (settings: UserFrontendSettingsInitialStateType): UpdateSidebarSettingsActionType => ({
    type: UserFrontendSettingsActionTypeEnum.UPDATE_USER_FRONTEND_SETTINGS,
    settings,
});

export const setStatsUserLimitDataAction = (statsUserLimit: StatsUserLimitsType): SetStatsUserLimitDataActionType => ({
    type: UserFrontendSettingsActionTypeEnum.SET_STATS_USER_LIMIT_DATA,
    statsUserLimit,
});

export const toggleOpenedDesktopSidebarAction = (): ToggleOpenedDesktopSidebarActionType => ({
    type: UserFrontendSettingsActionTypeEnum.TOGGLE_OPENED_DESKTOP_SIDEBAR,
});

export const toggleOpenedMobileSidebarAction = (): ToggleOpenedMobileSidebarActionType => ({
    type: UserFrontendSettingsActionTypeEnum.TOGGLE_OPENED_MOBILE_SIDEBAR,
});

export const closeMobileSidebarAction = (): CloseMobileSidebarActionType => ({
    type: UserFrontendSettingsActionTypeEnum.CLOSE_MOBILE_SIDEBAR,
});

export const toggleFavoriteToolAction = (toolName: string): ToggleFavoriteToolActionType => ({
    type: UserFrontendSettingsActionTypeEnum.TOGGLE_FAVORITE_TOOL,
    toolName,
});

export const changeFavoriteLinkPositionAction = (oldIndex: number, newIndex: number): ChangeFavoriteLinkPositionActionType => ({
    type: UserFrontendSettingsActionTypeEnum.CHANGE_FAVORITE_LINK_POSITION,
    oldIndex,
    newIndex,
});

export const setReplyBoardRandomTemplateFlagAction = (flag: boolean): SetReplyBoardRandomTemplateFlagActionType => ({
    type: UserFrontendSettingsActionTypeEnum.SET_REPLY_BOARD_RANDOM_TEMPLATE_FLAG,
    flag,
});

export const setReplyBoardAutoNextReviewFlagAction = (flag: boolean): SetReplyBoardAutoNextReviewFlagActionType => ({
    type: UserFrontendSettingsActionTypeEnum.SET_REPLY_BOARD_AUTO_NEXT_REVIEW_FLAG,
    flag,
});

export const setKeywordCountryFlagShowAction = (flag: boolean): SetKeywordCountryFlagShowActionType => ({
    type: UserFrontendSettingsActionTypeEnum.SET_KEYWORD_COUNTRY_FLAG_SHOW,
    flag,
});

export const setGlobalAppLoaderShowAction = (flag: boolean): SetGlobalAppLoaderAction => ({
    type: UserFrontendSettingsActionTypeEnum.SET_GLOBAL_APP_LOADER_SHOW,
    flag,
});

export const changeKeywordAnalyticsTableColumnOrderAction = (
    columnsIds: string[]
): ChangeKeywordAnalyticsTableColumnOrderActionType => ({
    type: UserFrontendSettingsActionTypeEnum.CHANGE_KEYWORD_ANALYTICS_TABLE_COLUMN_ORDER,
    columnsIds,
});

export const addKeywordAnalyticsTableColumnInvisibilityAction = (
    columnsId: string
): AddKeywordAnalyticsTableColumnInvisibilityActionType => ({
    type: UserFrontendSettingsActionTypeEnum.ADD_KEYWORD_ANALYTICS_TABLE_COLUMN_INVISIBILITY,
    columnsId,
});

export const removeKeywordAnalyticsTableColumnInvisibilityAction = (
    columnsId: string
): RemoveKeywordAnalyticsTableColumnInvisibilityActionType => ({
    type: UserFrontendSettingsActionTypeEnum.REMOVE_KEYWORD_ANALYTICS_TABLE_COLUMN_INVISIBILITY,
    columnsId,
});

export const addAppReviewAutoTranslateAction = (appId: string, timeStart: number): AddAppReviewAutoTranslateActionType => ({
    type: UserFrontendSettingsActionTypeEnum.ADD_APP_REVIEW_AUTO_TRANSLATE,
    appId,
    timeStart,
});

export const removeAppReviewAutoTranslateAction = (appId: string): RemoveAppReviewAutoTranslateActionType => ({
    type: UserFrontendSettingsActionTypeEnum.REMOVE_APP_REVIEW_AUTO_TRANSLATE,
    appId,
});

export const hideAppReviewAutoTranslateInfoAction = (appId: string): HideAppReviewAutoTranslateInfoActionType => ({
    type: UserFrontendSettingsActionTypeEnum.HIDE_APP_REVIEW_AUTO_TRANSLATE_INFO,
    appId,
});

type TrialType = 'showTrialExpiredPlan' | 'showTrialExpiredTomorrowPlan';

export const changeShowTrialByTypeAction = (trialType: TrialType, isShow: boolean): ChangeShowTrialByTypeActionType => ({
    type: UserFrontendSettingsActionTypeEnum.CHANGE_SHOW_TRIAL_BY_TYPE,
    trialType,
    isShow,
});

export const hideWOModalAction = (): HideWOModalActionType => ({
    type: UserFrontendSettingsActionTypeEnum.HIDE_WO_MODAL,
});

export const addUserLimitsAmountAction = (limitType: StatsUserLimitIdsEnum, amount: number): AddUserLimitsAmountActionType => ({
    type: UserFrontendSettingsActionTypeEnum.ADD_USER_LIMITS_AMOUNT,
    limitType,
    amount,
});

export const changeKeywordExplorerWidgetOrderAction = (
    order: KeywordExplorerWidgetEnum[]
): ChangeKeywordExplorerWidgetOrderActionType => ({
    type: UserFrontendSettingsActionTypeEnum.CHANGE_KEYWORD_EXPLORER_WIDGET_ORDER,
    order,
});

export const setTagsCategorySortingAction = (sorting: TagCategorySortingEnum): SetTagsCategorySortingActionType => ({
    type: UserFrontendSettingsActionTypeEnum.SET_TAGS_CATEGORY_SORTING,
    sorting,
});

export const setLastCurrentAppIdAction = (appId: string): SetLastCurrentAppIdActionType => ({
    type: UserFrontendSettingsActionTypeEnum.SET_LAST_CURRENT_APP_ID,
    appId,
});

export const setLastCurrentLocaleAction = (locale: string): SetLastCurrentLocaleActionType => ({
    type: UserFrontendSettingsActionTypeEnum.SET_LAST_CURRENT_LOCALE,
    locale,
});
