import { TrackedAppActionTypeEnum } from 'state/trackedApps/actionTypes';
import { TrackedAppsType } from 'state/trackedApps/TrackedAppsType';
import { TrackedAppsActionType } from './actions';

export type TrackedAppStateType = TrackedAppsType & {
    isLoaded: boolean;
};

const initialState: TrackedAppStateType = {
    appList: [],
    count: 0,
    numPages: 0,
    currentPage: 1,
    isLoaded: false,
};

const trackedApps = (state: TrackedAppStateType = initialState, action: TrackedAppsActionType) => {
    switch (action.type) {
        case TrackedAppActionTypeEnum.SET_TRACKED_APP: {
            return {
                appList: action.appList,
                count: action.count,
                numPages: action.numPages,
                currentPage: action.currentPage,
                isLoaded: true,
            };
        }
        case TrackedAppActionTypeEnum.ADD_TRACKED_APP: {
            return {
                ...state,
                appList: [...state.appList, action.app],
            };
        }
        case TrackedAppActionTypeEnum.REMOVE_TRACKED_APP: {
            return {
                ...state,
                appList: state.appList.filter((app) => app.store_id !== action.appId),
            };
        }
        default: {
            return state;
        }
    }
};

export default trackedApps;
