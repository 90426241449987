import { AccountActionEnum } from 'state/account/actionsTypes';
import { AccountActionType } from 'state/account/actions';
import { AccountFormErrorType } from 'state/account/AccountFormErrorType';

export type AccountStateType = {
    errors: AccountFormErrorType;
};

const initialState: AccountStateType = {
    errors: {},
};

const account = (state = initialState, action: AccountActionType) => {
    switch (action.type) {
        case AccountActionEnum.SET_ACCOUNT_FORM_ERRORS: {
            return {
                ...state,
                errors: action.errors,
            };
        }
        default: {
            return state;
        }
    }
};

export default account;
