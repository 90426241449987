import Cookies from 'js-cookie';
import { LOCAL_STORAGE_OLD_KEY_ACCESS, LOCAL_STORAGE_OLD_KEY_REFRESH } from 'constants/localStorageKeyConstants';

const removeOldAccessTokenStorage = () => {
    localStorage.removeItem(LOCAL_STORAGE_OLD_KEY_ACCESS);
};

const getOldAccessTokenStorage = () => localStorage.getItem(LOCAL_STORAGE_OLD_KEY_ACCESS);

const expiresDaysAccessCookie = 60;

export const setCookieAccessToken = (accessToken: string) => {
    Cookies.set('Authorization', `Token:${accessToken}`, {
        expires: expiresDaysAccessCookie,
    });
};

export const getCookieAccessToken = () => {
    const cookieToken = Cookies.get('Authorization');
    const oldAccessTokenStorage = getOldAccessTokenStorage();

    if (cookieToken) {
        return cookieToken.replace('Token:', '');
    }
    // In the old implementation, the access token was stored in localStorage,
    // this is necessary to support backward compatibility
    if (oldAccessTokenStorage) {
        setCookieAccessToken(oldAccessTokenStorage);

        removeOldAccessTokenStorage();

        return oldAccessTokenStorage;
    }

    return undefined;
};

export const removeUserTokens = () => {
    // This cookie used for "hijack" login
    Cookies.remove('sessionid');
    // This storage variable for logout in other tabs code/core/static/js/modules/windows-listener.ts:12
    localStorage.setItem('asodeskLogout', new Date().getTime().toString());

    removeOldAccessTokenStorage();
    localStorage.removeItem(LOCAL_STORAGE_OLD_KEY_REFRESH);
    Cookies.remove('Authorization');
};
