import { useEffect } from 'react';
import { useTogglePopup } from 'hooks/state/usePopup';
import { LiveSearchAsidePanelContainerType } from 'modules/keywordExplorer/liveSearch/LiveSearchAsidePanelContainer';
import { ASIDE_PANEL_NAME_LIVE_SEARCH } from 'constants/asidePanelNameConstants';

export const useLiveSearchBridgeAngular = () => {
    const { togglePopupWithData: onToggleLiveSearchAsidePanel } = useTogglePopup<LiveSearchAsidePanelContainerType>(
        ASIDE_PANEL_NAME_LIVE_SEARCH
    );

    const openLiveSearchPanel = ({ detail: { keyword } }: CustomEvent<{ keyword: string }>) => {
        onToggleLiveSearchAsidePanel({
            data: {
                keyword,
            },
        });
    };

    useEffect(() => {
        window.addEventListener('openLiveSearchPanel', openLiveSearchPanel as EventListener);

        return () => {
            window.removeEventListener('openLiveSearchPanel', openLiveSearchPanel as EventListener);
        };
    }, []);
};
