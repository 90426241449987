import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import { AsyncDashboardPageContainer } from 'pages/dashboard/AsyncDashboardPageContainer';
import { FaroRoutes } from '@grafana/faro-react';

export const MyAppsRoutes: FC = () => (
    <FaroRoutes>
        <Route index element={<AsyncDashboardPageContainer />} />
    </FaroRoutes>
);
