import { useTogglePopup } from 'hooks/state/usePopup';
import { MODAL_NAME_BUSINESS_REQUEST } from 'constants/modalNameConstants';
import { PaymentPeriodEnum } from 'state/payment/paymentTypes';
import { BusinessRequestModalDataType } from 'modules/modal/businessRequestModal/BusinessRequestModalDataType';
import { checkIsBusiness } from 'modules/modal/planModal/utils';

export type UseBusinessPeriodLimitationType = {
    planName: string;
    paymentPeriod: PaymentPeriodEnum;
};

export const useBusinessPeriodLimitation = ({ planName, paymentPeriod }: UseBusinessPeriodLimitationType) => {
    const { togglePopupWithData } = useTogglePopup<BusinessRequestModalDataType>(MODAL_NAME_BUSINESS_REQUEST);

    const isBusiness = checkIsBusiness(planName);
    const monthPaymentPeriod = paymentPeriod === PaymentPeriodEnum.MONTH_1;

    const needSalesManager = isBusiness && monthPaymentPeriod;

    return {
        needSalesManager,
        businessActions: needSalesManager
            ? {
                  text: 'Contact sales',
                  onClick: () => {
                      togglePopupWithData({ data: { isNeedSlackRequest: true } });
                  },
              }
            : undefined,
    };
};
