import React, { FC } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { CheckPrivateRoute } from 'modules/routers/new/CheckPrivateRoute';
import { AsyncSettingsProfilePageContainer } from 'pages/settings/profile/AsyncSettingsProfilePageContainer';
import {
    getPartialAccountRouteInvoiceUrl,
    partialAccountRouteBillingUrl,
    partialAccountRouteLogUrl,
    partialAccountRouteNotificationsUrl,
    partialAccountRoutePricingUrl,
    partialAccountRouteTeammatesUrl,
} from 'utils/routeUrls';
import { AsyncTeammatesPageContainer } from 'pages/settings/teammates/AsyncTeammatesPageContainer';
import { AsyncBillingHistoryPageContainer } from 'pages/settings/billingHistory/AsyncBillingHistoryPageContainer';
import { AsyncPlansPageContainer } from 'pages/settings/plans/AsyncPlansPageContainer';
import { AsyncActivityLogPageContainer } from 'pages/activityLog/AsyncActivityLogPageContainer';
import { AsyncNotificationsPageContainer } from 'pages/settings/notifications/AsyncNotificationsPageContainer';
import { AsyncInvoicePageContainer } from 'pages/settings/invoice/AsyncInvoicePageContainer';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { FaroRoutes } from '@grafana/faro-react';

export const AccountProfileRedirect: FC = () => {
    const { accountUrl } = useRoutesUrl();

    return <Navigate to={accountUrl} replace />;
};

export const AccountRoutes: FC = () => (
    <FaroRoutes>
        <Route path="*" element={<AccountProfileRedirect />} />
        <Route
            index
            element={
                <CheckPrivateRoute>
                    <AsyncSettingsProfilePageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={partialAccountRouteTeammatesUrl}
            element={
                <CheckPrivateRoute>
                    <AsyncTeammatesPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={partialAccountRouteBillingUrl}
            element={
                <CheckPrivateRoute>
                    <AsyncBillingHistoryPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={partialAccountRoutePricingUrl}
            element={
                <CheckPrivateRoute>
                    <AsyncPlansPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={partialAccountRouteLogUrl}
            element={
                <CheckPrivateRoute>
                    <AsyncActivityLogPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={partialAccountRouteNotificationsUrl}
            element={
                <CheckPrivateRoute>
                    <AsyncNotificationsPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={getPartialAccountRouteInvoiceUrl(':subscriptionType', ':invoiceId')}
            element={
                <CheckPrivateRoute>
                    <AsyncInvoicePageContainer />
                </CheckPrivateRoute>
            }
        />
    </FaroRoutes>
);
