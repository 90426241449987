export enum OptimizerInputNameEnum {
    TITLE = 'title',
    SUBTITLE = 'subtitle',
    KEYWORDS = 'keywords',
}

export type OptimizerInputNamesType = {
    label: string;
    name: OptimizerInputNameEnum;
    short: string;
};

export const optimizerInputs: {
    [key: string]: OptimizerInputNamesType[];
} = {
    appstore: [
        {
            label: 'Name',
            name: OptimizerInputNameEnum.TITLE,
            short: 'N',
        },
        {
            label: 'Subtitle',
            name: OptimizerInputNameEnum.SUBTITLE,
            short: 'ST',
        },
        {
            label: 'Keywords',
            name: OptimizerInputNameEnum.KEYWORDS,
            short: 'KW',
        },
    ],
    googleplay: [
        {
            label: 'Name',
            name: OptimizerInputNameEnum.TITLE,
            short: 'N',
        },
        {
            label: 'Short Description',
            name: OptimizerInputNameEnum.SUBTITLE,
            short: 'SD',
        },
        {
            label: 'Description',
            name: OptimizerInputNameEnum.KEYWORDS,
            short: 'D',
        },
    ],
    rustore: [
        {
            label: 'Name',
            name: OptimizerInputNameEnum.TITLE,
            short: 'N',
        },
        {
            label: 'Short Description',
            name: OptimizerInputNameEnum.SUBTITLE,
            short: 'SD',
        },
        {
            label: 'Description',
            name: OptimizerInputNameEnum.KEYWORDS,
            short: 'D',
        },
    ],
};

export const reportsList = [
    {
        isUsedReport: false,
    },
    {
        isUsedReport: true,
    },
];
