import { config } from 'constants/backendConfig';

export const colorPickerColors = [
    '#366AD9',
    '#BC566B',
    '#FF0035',
    '#933346',
    '#6BBC56',
    '#2CD700',
    '#166A00',
    '#E19B0B',
    '#FFAD05',
    '#BE8000',
    '#8368C3',
    '#AB88FF',
    '#3C4C9D',
    '#569EBC',
    '#66CEFA',
    '#256F8E',
    '#56BCA7',
    '#56E3C6',
    '#329480',
    '#999999',
    '#C2C2C2',
    '#666666',
    '#4A4F63',
    '#8F9AC8',
    '#303547',
    '#46A9FF',
    '#74BEFF',
    '#548BBB',
    '#FD79A8',
    '#E84393',
    '#FF7675',
];

export const DEFAULT_COLOR_INDEX = 0;
export const WHITE_COLOR_KEY = 0;
export const SUCCESS_COLOR = 2;
export const DANGER_COLOR = 4;
export const DEFAULT_COLOR = 0;

const colors = config.USER_KEYWORD_COLORS;

export const colorList: string[] = Object.values(colors);
export enum ElementColors {
    CorporateBlue = '#366AD9',
    Red = '#bc566b',
    LightGray = '#ddd',
    Gray_500 = '#FAFAFA',
    LightGray2 = '#aaa',
    Gray = '#808080',
    Gray1 = '#999999',
    Green = '#66c658',
    MediumGray = '#eeeeee',
    Success = '#6bbc56',
    Yellow = '#f5b22a', // 10% ligther then Warning
    Warning = '#e19b0b',
    White = '#fff',
    DarkGray = '#333',
    LightSky = '#f5fafd',
    LightPurple = '#7B8BCA',
    Primary = '#5C69BC',
}
export const chartColors = [
    ElementColors.CorporateBlue,
    ElementColors.Red,
    '#fb9b0b', // 3 orange
    ElementColors.Green,
    '#ffb1af', // 5 violet
    '#56bca7', // 6 asure
    '#e300ff', // 7 dark red
    ElementColors.Gray,
    '#dae76a', // 9 lignt yellow
    '#75fffc', // 10 light blue
];
export const comparativeReportChartColors = [
    ElementColors.Green,
    '#B1DE57',
    '#D4CC57',
    '#EBC954',
    '#F79952',
    ElementColors.Red,
    '#4A4F63',
];

export enum RatingChartColorsEnum {
    TOTAL = '#351C75',
    STAR_5 = '#79B281',
    STAR_4 = '#A3C76B',
    STAR_3 = '#F3C849',
    STAR_2 = '#E7923E',
    STAR_1 = '#E06846',
    AVERAGE = '#5085C5',
    AVERAGE_ALL_TIME = '#4A4F63',
}
