export type IntegrationsResponseType = IntegrationsItemResponseType[];

export type IntegrationsItemResponseType = {
    id: number;
    account_admin: number;
    email: string;
    provider_name: string;
    store_name: string;
    storeids: string[];
    timestamp: number;
    is_managed?: boolean;
    status: AuthIntegrationStatusEnum;
};

export type IntegrationsDataType = IntegrationsDataItemType[];

export type IntegrationsDataItemType = {
    id: number | null;
    email: string | null;
    userName: string | null;
    authIntegrationStatus: AuthIntegrationStatusEnum;
    storeIds: string[];
    isManaged?: boolean;
};

export enum AuthIntegrationStatusEnum {
    AUTHORIZED = 'Authorized',
    NOT_AUTHORIZED = 'Not authorized',
    WAITING_INVITATION = 'Waiting for invitation',
}

export enum ActiveTypeSecurityCodeEnum {
    DEVICE = 'device',
    SMS = 'sms',
}
