import { isIOS } from 'utils/helpers/platforms/platforms';

export const updateViewport = () => {
    if (isIOS()) {
        const viewport = document.querySelector<HTMLMetaElement>('meta[name="viewport"]');

        if (viewport !== null) {
            viewport.content = `${viewport.content},maximum-scale=1`;
        }
    }
};
