import React, { FC } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { AsyncLoginPageContainer } from 'pages/account/login/AsyncLoginPageContainer';
import { AsyncSignupPageContainer } from 'pages/account/signup/AsyncSignupPageContainer';
import { AsyncResetPasswordPageContainer } from 'pages/account/resetPassword/AsyncResetPasswordPageContainer';
import { AsyncVerifyAccountPage } from 'pages/account/verifyAccount/AsyncVerifyAccountPageContainer';
import { AsyncChangePasswordPageContainer } from 'pages/account/changePassword/AsyncChangePasswordPageContainer';
import { AsyncBadTokenResetPasswordPageContainer } from 'pages/account/badTokenResetPassword/AsyncBadTokenResetPasswordPageContainer';
import {
    partialAuthRouteChangePasswordUrl,
    partialAuthRouteLoginUrl,
    partialAuthRouteLogoutUrl,
    partialAuthRouteResetPasswordUrl,
    partialAuthRouteResetPasswordWithBadTokenUrl,
    getPartialAuthRouteResetPasswordWithKeyUrl,
    partialAuthRouteSignupUrl,
    partialAuthRouteVerifyUrl,
} from 'utils/routeUrls';
import { CheckAuthRoute } from 'modules/routers/new/CheckAuthRoute';
import { CheckPrivateRoute } from 'modules/routers/new/CheckPrivateRoute';
import { AsyncChangePasswordLoggedUserPageContainer } from 'pages/account/changePassword/AsyncChangePasswordLoggedUserPageContainer';
import { AsyncLogoutPageContainer } from 'pages/account/logout/AsyncLogoutPageContainer';
import { FaroRoutes } from '@grafana/faro-react';

export const AuthRoutes: FC = () => (
    <FaroRoutes>
        <Route
            path={partialAuthRouteLoginUrl}
            element={
                <CheckAuthRoute>
                    <AsyncLoginPageContainer />
                </CheckAuthRoute>
            }
        />
        <Route
            path={partialAuthRouteSignupUrl}
            element={
                <CheckAuthRoute>
                    <AsyncSignupPageContainer />
                </CheckAuthRoute>
            }
        />
        <Route
            path={partialAuthRouteResetPasswordUrl}
            element={
                <CheckAuthRoute>
                    <AsyncResetPasswordPageContainer />
                </CheckAuthRoute>
            }
        />
        <Route
            path={partialAuthRouteVerifyUrl}
            element={
                <CheckAuthRoute>
                    <AsyncVerifyAccountPage />
                </CheckAuthRoute>
            }
        />
        <Route
            path={getPartialAuthRouteResetPasswordWithKeyUrl(':key')}
            element={
                <CheckAuthRoute>
                    {window.isResetPasswordTokenFail ? (
                        <Navigate to={partialAuthRouteResetPasswordWithBadTokenUrl} replace />
                    ) : (
                        <AsyncChangePasswordPageContainer />
                    )}
                </CheckAuthRoute>
            }
        />
        <Route
            path={partialAuthRouteResetPasswordWithBadTokenUrl}
            element={
                <CheckAuthRoute>
                    <AsyncBadTokenResetPasswordPageContainer />
                </CheckAuthRoute>
            }
        />
        <Route
            path={partialAuthRouteChangePasswordUrl}
            element={
                <CheckPrivateRoute>
                    <AsyncChangePasswordLoggedUserPageContainer />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={partialAuthRouteLogoutUrl}
            element={
                <CheckPrivateRoute>
                    <AsyncLogoutPageContainer />
                </CheckPrivateRoute>
            }
        />
    </FaroRoutes>
);
