import { lazy, ComponentType } from 'react';
import { LOCAL_STORAGE_KEY_PAGE_FORCE_REFRESHED } from 'constants/localStorageKeyConstants';

export const lazyWithRetry = <T extends ComponentType<any>>(componentImport: () => Promise<{ default: T }>) =>
    // @ts-ignore set for catch "ChunkLoadError: Loading chunk X failed." error
    lazy<T>(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(LOCAL_STORAGE_KEY_PAGE_FORCE_REFRESHED) || 'false'
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(LOCAL_STORAGE_KEY_PAGE_FORCE_REFRESHED, 'false');

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                window.localStorage.setItem(LOCAL_STORAGE_KEY_PAGE_FORCE_REFRESHED, 'true');
                return window.location.reload();
            }

            throw error;
        }
    });
