import { RustoreProviderType } from './rustoreProviderListType';
import { RustoreIntegrationsActionType } from './actions';
import { InitialRustoreFormInModalType } from './InitialRustoreFormInModalType';
import { IntegrationsRustoreActionTypeEnum } from './actionsTypes';

export type RustoreIntegrationsStateType = {
    integrations: RustoreProviderType[];
    activeIntegration: InitialRustoreFormInModalType;
};

export const initialIntegrationId = -1;

export const initialActiveIntegration: InitialRustoreFormInModalType = {
    id: initialIntegrationId,
    storeIds: [],
    keyId: null,
    privateKey: null,
    apps: [],
};

const initialState: RustoreIntegrationsStateType = {
    integrations: [],
    activeIntegration: initialActiveIntegration,
};

const rustoreIntegrations = (state = initialState, action: RustoreIntegrationsActionType) => {
    switch (action.type) {
        case IntegrationsRustoreActionTypeEnum.SET_RUSTORE_CONSOLE_INTEGRATIONS: {
            return {
                ...state,
                integrations: action.integrations,
            };
        }
        case IntegrationsRustoreActionTypeEnum.CREATE_RUSTORE_CONSOLE_INTEGRATIONS: {
            return {
                ...state,
                integrations: [...state.integrations, action.integration],
            };
        }
        case IntegrationsRustoreActionTypeEnum.UPDATE_RUSTORE_CONSOLE_INTEGRATIONS: {
            const integrationIndex = state.integrations.findIndex(({ id }) => id === action.integration.id);
            return {
                ...state,
                integrations: [
                    ...state.integrations.slice(0, integrationIndex),
                    action.integration,
                    ...state.integrations.slice(integrationIndex + 1),
                ],
            };
        }
        case IntegrationsRustoreActionTypeEnum.DELETE_RUSTORE_CONSOLE_INTEGRATIONS: {
            return {
                ...state,
                integrations: state.integrations.filter(({ id }) => id !== action.providerId),
            };
        }
        case IntegrationsRustoreActionTypeEnum.SET_ACTIVE_RUSTORE_CONSOLE_INTEGRATIONS: {
            return {
                ...state,
                activeIntegration: action.integration,
            };
        }
        default: {
            return state;
        }
    }
};

export default rustoreIntegrations;
