import {
    ADD_SELECTED_KEYWORDS,
    REMOVE_ALL_SELECTED_KEYWORDS,
    SelectedKeywordsActionEnum,
    TOGGLE_ALL_SELECTED_KEYWORDS,
    TOGGLE_SELECTED_KEYWORDS,
} from 'state/selectedKeywords/actionTypes';
import {
    addOneSelectedKeywordAction,
    RemoveSelectedKeywordsActionType,
    updateSelectedKeywordsAction,
} from 'state/selectedKeywords/actions';
import { AppStatisticsRowStateType } from 'state/keywordAnalytics/AppDataStatsStateType';

export type ActionType = updateSelectedKeywordsAction | addOneSelectedKeywordAction | RemoveSelectedKeywordsActionType;

const selectedKeywords = (state: AppStatisticsRowStateType[] = [], action: ActionType) => {
    switch (action.type) {
        case TOGGLE_SELECTED_KEYWORDS: {
            const newKeywordList = [...state];

            (action as updateSelectedKeywordsAction).keywordRows.forEach((row) => {
                const indexSelectedKeyword = newKeywordList.findIndex((newRow) => newRow.id === row.id);
                if (indexSelectedKeyword === -1) {
                    newKeywordList.push(row);
                } else {
                    newKeywordList.splice(indexSelectedKeyword, 1);
                }
            });

            return newKeywordList;
        }
        case ADD_SELECTED_KEYWORDS: {
            return Array.from(new Set([...state, ...(action as updateSelectedKeywordsAction).keywordRows]));
        }
        case SelectedKeywordsActionEnum.REMOVE_SELECTED_KEYWORDS: {
            return state.filter(({ id }) => {
                return (action as RemoveSelectedKeywordsActionType).keywordIds.indexOf(id) < 0;
            });
        }
        case TOGGLE_ALL_SELECTED_KEYWORDS: {
            const currentAction = action as updateSelectedKeywordsAction;
            return state.length < currentAction.keywordRows.length ? currentAction.keywordRows : [];
        }
        case REMOVE_ALL_SELECTED_KEYWORDS: {
            return [];
        }
        default: {
            return state;
        }
    }
};

export default selectedKeywords;
