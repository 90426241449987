import React, { FC, useMemo } from 'react';
import { PlanCard, PlanCardStateEnum } from 'newComponents/plans/planCard/PlanCard';
import { Icon } from 'newComponents/icons/asodeskIcons/Icon';
import { Icons } from 'types/icon-types';
import { useTogglePopup } from 'hooks/state/usePopup';
import { MODAL_NAME_ENTERPRISE_PLAN } from 'constants/modalNameConstants';
import { usePlansGetter } from 'hooks/data/usePlansGetter';
import { PlanFeatures } from 'newComponents/plans/planFeatures/PlanFeatures';
import { withPlural } from 'utils/helpers/string/text';
import {
    minKeywordsCount,
    minAppsCount,
    minTeammatesCount,
    enterprisePlanTitle,
    enterprisePlanDescription,
} from 'modules/settings/plans/planTabs/enterprisePlanCard/enterprisePlanConstants';
import { numberFormat } from 'utils/helpers/string/numberFormat';
import { LANGUAGE_RU } from 'constants/localizationsConstants';
import { EnterprisePlanPopupDataType } from 'modules/settings/plans/planTabs/enterprisePlanCard/enterprisePlanModal/EnterprisePlanModalContainer';
import { OnlyCurrentPaymentPeriodLabel } from 'modules/settings/plans/planTabs/OnlyCurrentPaymentPeriodLabel';
import { PlanFeatureResponseType } from 'state/plans/PlansResponseType';

type PropsType = EnterprisePlanPopupDataType & {
    buttonPortal?: React.RefObject<HTMLDivElement>;
    isLoading?: boolean;
};

export const EnterprisePlanCardContainer: FC<PropsType> = ({ buttonPortal, callback, isLoading }: PropsType) => {
    const { togglePopupWithData: onOpenEnterprisePlanModal } = useTogglePopup<EnterprisePlanPopupDataType>(
        MODAL_NAME_ENTERPRISE_PLAN
    );

    const {
        enterprisePlan: { additionalFeatures, customerSupportFeatures },
    } = usePlansGetter();

    const features = useMemo(() => {
        const businessPlanFeatures = [...customerSupportFeatures, ...additionalFeatures]
            .map(({ values }) => values)
            .flat() as PlanFeatureResponseType['values'];

        return [
            {
                title: 'Limits',
                values: [
                    {
                        name: `From ${numberFormat({ number: minKeywordsCount, locale: LANGUAGE_RU })} ${withPlural(
                            'keyword',
                            minKeywordsCount
                        )}`,
                    },
                    {
                        name: `From ${minAppsCount} ${withPlural('app', minAppsCount)}`,
                    },
                    {
                        name: "135 competitors' apps per country",
                    },
                    {
                        name: `From ${minTeammatesCount} ${withPlural('teammate', minTeammatesCount)}`,
                    },
                    {
                        name: '15 000 daily keyword stats',
                    },
                    {
                        name: '10 000 API credits',
                    },
                ],
            },
            {
                title: 'Ultimate Plan features + optional',
                values: businessPlanFeatures,
            },
        ];
    }, [additionalFeatures, customerSupportFeatures]);

    const actionProps = {
        text: 'Configure Enterprise Plan',
        onClick: () => onOpenEnterprisePlanModal({ data: { callback } }),
        loading: isLoading,
        disabled: isLoading,
    };

    return (
        <PlanCard
            className="!overflow-visible"
            title={enterprisePlanTitle}
            description={
                <p>
                    <span className="absolute -top-10 -right-10 rounded-full w-75 h-75 bg-white flex items-center">
                        <Icon name={Icons.Gear} size={75} lineHeight={75} className="text-info" />
                    </span>
                    {enterprisePlanDescription}
                </p>
            }
            TopControls={<OnlyCurrentPaymentPeriodLabel isOnlyAnnual />}
            state={PlanCardStateEnum.INFO}
            Content={<PlanFeatures features={features} />}
            action={actionProps}
            buttonPortal={buttonPortal}
        />
    );
};
