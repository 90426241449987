import { Dispatch } from 'redux';
import axios from 'axios';
import { cleanRequestInfoAction } from 'state/requestInfo/actions';
import { removeUserTokens, setCookieAccessToken } from 'utils/axios-settings/user-token-service';
import { Urls } from 'utils/Urls';
import { setUserLoggedAction } from './actions';

export const logoutState = () => (dispatch: Dispatch) => {
    removeUserTokens();
    dispatch(cleanRequestInfoAction());
    dispatch(setUserLoggedAction(false));
};

export const logoutUser = () => async (dispatch: Dispatch) => {
    try {
        await axios.get(Urls.auth_logout());
    } catch (e) {
    } finally {
        logoutState()(dispatch);
    }
};

export const setAccessToken = (accessToken: string) => () => {
    setCookieAccessToken(accessToken);
};
