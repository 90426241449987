import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import minMax from 'dayjs/plugin/minMax';
import isoWeek from 'dayjs/plugin/isoWeek';

export type DateType = string | number | Date;

dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(isoWeek);

export const formatSingleDate = (date: number) => (date < 10 ? `0${date}` : date);

export const getFullDate = ({ date, hasFormat }: { date: Date; hasFormat?: boolean }) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const amPmFormat = hours >= 12 ? 'PM' : 'AM';

    return {
        year,
        month: hasFormat ? formatSingleDate(month) : month,
        day: hasFormat ? formatSingleDate(day) : day,
        hours: hasFormat ? formatSingleDate(hours) : hours,
        minutes: hasFormat ? formatSingleDate(minutes) : minutes,
        seconds: hasFormat ? formatSingleDate(seconds) : seconds,
        amPmFormat,
    };
};

export const getNowFullDate = ({ hasFormat = false }: { hasFormat?: boolean }) => {
    const nowDate = new Date();

    return getFullDate({ date: nowDate, hasFormat });
};

export const getUTCStartDayTimestamp = (date: DateType) => dayjs(date).utc().startOf('day').valueOf();

export const getUTCEndDayUnix = (date: DateType) => dayjs(date).utc().endOf('day').unix();

export const getUTCStartDayWithComparisonUnix = (date: DateType) => {
    if (dayjs(date).utc().startOf('day').day() !== dayjs(date).day()) {
        return dayjs(date).add(1, 'day').utc().startOf('day').unix();
    }

    return dayjs(date).utc().startOf('day').unix();
};

export const getMondayDayWeekTimestamp = (date: DateType) => dayjs(date).utc().startOf('week').add(1, 'day').valueOf();
export const getMondayDayMonthTimestamp = (date: DateType) => dayjs(date).utc().startOf('month').add(1, 'day').valueOf();

type FormatDateType = {
    date: DateType;
    format: string;
};
export const getFormatDate = ({ date, format }: FormatDateType) => dayjs(date).format(format);

export const getLeftDays = (date: number) => dayjs(dayjs.unix(date)).diff(dayjs(), 'day');

export const fullDateFormat = (date: DateType) => `${dayjs(date).format('DD MMMM, YYYY')} at ${dayjs(date).format('h:mm A')}`;

export const getUnix = (date: DateType): number => dayjs(date).unix();

export const getNow = () => dayjs();

export const getNowUnix = (): number => getNow().unix();
export const getNowTimestamp = (): number => getNow().valueOf();

export const getEndOfThisDay = () => getNow().endOf('day').toISOString();
export const getStartOfThisDay = () => getNow().startOf('day').toISOString();

export const defaultStartDateRange = getNow().subtract(1, 'months').startOf('day').toISOString();
export const defaultEndDateRange = getNow().endOf('day').toISOString();

export const defaultStartChartDateRange = getNow().subtract(1, 'months').startOf('day').toISOString();
export const defaultSecondsFromNowChartDateRange = (getNow().unix() - getNow().subtract(1, 'months').unix()) / 1000;
export const defaultLastTwoWeekDateChartDateRange = getNow().subtract(14, 'day').startOf('day').toISOString();

export const checkDateRange = (date: DateType) =>
    dayjs(date).unix() > dayjs(defaultStartChartDateRange).unix() && dayjs(date).unix() < getNow().endOf('day').unix();

export const dateJs = dayjs;

const offsetTimezoneSeconds = getNow().utcOffset() * 60;

export const getStartDateWithTimezoneOffset = (startDate: DateType) =>
    dayjs(startDate).startOf('day').unix() + offsetTimezoneSeconds;
export const getEndDateWithTimezoneOffset = (endDate: DateType) => dayjs(endDate).endOf('day').unix() + offsetTimezoneSeconds;
