import { ReviewTagType } from '../reviewTags/reviewTagStructureType';

type AnswerIdType = number | string;

export type ReplyBoardAppSettings = {
    isReviewsAutoTranslate: boolean;
    reviewsTranslationLanguage: string;
};

export type ReplyBoardInitialStateType = {
    appRatings: AppsRatingTypes;
    reviews: ReviewsDataType & {
        activeSingleReviewId: number | null;
        activeMultipleReviewIds: number[];
        activeEditableReviewId: number | null;
    };
    appSettings: ReplyBoardAppSettings;
};

export type ReviewsTagType = ReviewTagType & { originalId: number; isNew: boolean };

type TeammateType = {
    name: string;
    email: string;
};

export type ReviewsDataType = {
    ids: number[];
    byIds: {
        [id: number]: {
            id: number;
            rating: number;
            title?: string;
            author: string;
            review: {
                text: string;
                date: string;
                dateWithoutFormat: string;
                isReported: boolean;
            };
            answer?: {
                text: string;
                date: string;
                dateWithoutFormat: string;
                statusId: ReviewAnswerStatusIdEnum | null;
                actionId?: number;
                replyRuleName: string | null;
                replyRuleAuthor: string | null;
                teammate?: TeammateType;
            };
            languageCode?: string;
            answers: AnswerByIdType[];
            tags: ReviewsTagType[];
            isFeatured: boolean;
            isEdited: boolean;
            isDeleted?: boolean;
            featuredNum: number | null;
            version: string | null;
            manufacturer?: string | null;
            androidVersion?: string | null;
            brand?: string | null;
            models?: string[] | null;
            deviceId?: string | null;
            versionCode?: number | null;
            versionName?: string | null;
            countryCode: string;
            actionId?: number;
            isFavorite: boolean;
            deletedAt?: string;
            createdAt?: string;
        };
    };
    reviewDateLastUpdated?: string | null;
    reviewDateLastFetching?: string | null;
    reviewDateFirstCreate?: string | null;
    nextPage: string | null;
    count: number;
    countNoReplies: number;
    canReplyReview: boolean;
    canReportReply: boolean;
    hasShowTranslate?: boolean;
};

export type ReviewsTranslateType = {
    translationFromLanguage?: string;
    translations?: {
        [language: string]: {
            title: string;
            content: string;
        };
    };
};

export type AnswerByIdType = ReviewsTranslateType & {
    id: AnswerIdType;
    rating?: number;
    title?: string;
    author?: string;
    isDevelop?: boolean;
    review: {
        text: string;
        date: string;
        dateWithoutFormat: string;
    };
    isNew?: boolean;
    status: ReviewAnswerStatusEnum;
    statusId: ReviewAnswerStatusIdEnum;
    actionId: number;
    isReport?: boolean;
    lastError: {
        error?: string;
        link?: string;
    };
    teammate?: TeammateType;
    isShowTranslate: boolean;
};

export type AppsRatingTypes = {
    ratings: number[];
    totalRatings: number;
};

export enum ReviewAnswerStatusEnum {
    PROCESSING = 'Processing',
    DECLINED = 'Declined',
    SEND_VIA_DEV_CONSOLE = 'Sent via Developer Console',
    SEND_VIA_ASO_DESK = 'Sent via Asodesk',
}

export enum ReviewAnswerStatusIdEnum {
    PROCESSING,
    SENT,
    ERROR,
    UNABLE_TO_SENT,
    WAS_THROTTLED,
    PENDING_APPROVAL,
    DECLINED,
}

export type UpdateReviewTranslateType = {
    reviewId: number;
    answerIndex: number;
    defaultLanguage: string;
    isShowTranslate?: boolean;
    translations?: ReviewsTranslateType['translations'];
    translationFromLanguage?: ReviewsTranslateType['translationFromLanguage'];
};
