import { ToggleUpdatePlanModalType, UpdatePlanModalStateType } from "state/updatePlanModal/actions";
import { TOGGLE_UPDATE_PLAN_MODAL } from "state/updatePlanModal/actionTypes";

const InitialUpdatePlanModalState: UpdatePlanModalStateType = {
    show: false,
    updateFeature: null
};

const updatePlanModal = (state = InitialUpdatePlanModalState, action: ToggleUpdatePlanModalType) => {
    switch (action.type) {
        case TOGGLE_UPDATE_PLAN_MODAL: {
            return {
                ...state,
                ...action
            }
        }
        default: {
            return state;
        }
    }
};

export default updatePlanModal;
