import { useRef } from 'react';
import { accountRoutePricingUrl } from 'utils/routeUrls';
import darkBirthdayLogo from 'images/inlineStyleImages/account/asodesk-dark-logo-bd.svg';
import lightBirthdayLogo from 'images/inlineStyleImages/account/asodesk-light-logo-bd.svg';
import { PaymentPeriodEnum } from 'state/payment/paymentTypes';
import {
    PROMO_TYPE_BD_COMPANY_FIRST_STAGE,
    PROMO_TYPE_BD_COMPANY_LAST_STAGE,
    PROMO_TYPE_BLACK_FRIDAY,
    PROMO_TYPE_CYBER_MONDAY,
    PromoType,
} from 'modules/promo/promoTypes';

type PromoDataType = {
    promoName: string;
    url: string;
    promoLogoUrl?: {
        darkPromoLogoUrl: string;
        lightPromoLogoUrl: string;
    };
    percentByPaymentPeriod: {
        [PaymentPeriodEnum.MONTH_1]: number;
        [PaymentPeriodEnum.MONTHS_3]: number;
        [PaymentPeriodEnum.ANNUAL]: number;
    };
    woPercentByPaymentPeriod: {
        [PaymentPeriodEnum.MONTH_1]: number;
        [PaymentPeriodEnum.MONTHS_3]: number;
        [PaymentPeriodEnum.ANNUAL]: number;
    };
};

const PROMO_DATA_MAP: { [key in PromoType]: PromoDataType } = {
    [PROMO_TYPE_BLACK_FRIDAY]: {
        promoName: 'Black Friday',
        url: accountRoutePricingUrl,
        percentByPaymentPeriod: {
            [PaymentPeriodEnum.MONTH_1]: 0,
            [PaymentPeriodEnum.MONTHS_3]: 15,
            [PaymentPeriodEnum.ANNUAL]: 40,
        },
        woPercentByPaymentPeriod: {
            [PaymentPeriodEnum.MONTH_1]: 0,
            [PaymentPeriodEnum.MONTHS_3]: 30,
            [PaymentPeriodEnum.ANNUAL]: 40,
        },
    },
    [PROMO_TYPE_CYBER_MONDAY]: {
        promoName: 'Cyber Monday',
        url: accountRoutePricingUrl,
        percentByPaymentPeriod: {
            [PaymentPeriodEnum.MONTH_1]: 0,
            [PaymentPeriodEnum.MONTHS_3]: 10,
            [PaymentPeriodEnum.ANNUAL]: 30,
        },
        woPercentByPaymentPeriod: {
            [PaymentPeriodEnum.MONTH_1]: 0,
            [PaymentPeriodEnum.MONTHS_3]: 30,
            [PaymentPeriodEnum.ANNUAL]: 30,
        },
    },
    [PROMO_TYPE_BD_COMPANY_FIRST_STAGE]: {
        promoName: 'BIRTHDAY',
        url: accountRoutePricingUrl,
        promoLogoUrl: {
            darkPromoLogoUrl: darkBirthdayLogo,
            lightPromoLogoUrl: lightBirthdayLogo,
        },
        percentByPaymentPeriod: {
            [PaymentPeriodEnum.MONTH_1]: 0,
            [PaymentPeriodEnum.MONTHS_3]: 15,
            [PaymentPeriodEnum.ANNUAL]: 40,
        },
        woPercentByPaymentPeriod: {
            [PaymentPeriodEnum.MONTH_1]: 0,
            [PaymentPeriodEnum.MONTHS_3]: 30,
            [PaymentPeriodEnum.ANNUAL]: 40,
        },
    },
    [PROMO_TYPE_BD_COMPANY_LAST_STAGE]: {
        promoName: 'BIRTHDAY',
        url: accountRoutePricingUrl,
        promoLogoUrl: {
            darkPromoLogoUrl: darkBirthdayLogo,
            lightPromoLogoUrl: lightBirthdayLogo,
        },
        percentByPaymentPeriod: {
            [PaymentPeriodEnum.MONTH_1]: 0,
            [PaymentPeriodEnum.MONTHS_3]: 10,
            [PaymentPeriodEnum.ANNUAL]: 30,
        },
        woPercentByPaymentPeriod: {
            [PaymentPeriodEnum.MONTH_1]: 0,
            [PaymentPeriodEnum.MONTHS_3]: 30,
            [PaymentPeriodEnum.ANNUAL]: 30,
        },
    },
};

export const usePromo = () => {
    const { current: promoType } = useRef<PromoType | undefined>();

    const promoData = promoType && PROMO_DATA_MAP[promoType];

    const hasPromo = !!promoType;

    return {
        promoType,
        promoData,
        hasPromo,
    };
};
