export const SET_APP_DATA_STATS = 'SET_APP_DATA_STATS';
export const SET_DATA_STATS_TAB = 'SET_DATA_STATS_TAB';
export const SET_CUSTOM_TRAFFIC_SCORE = 'SET_CUSTOM_TRAFFIC_SCORE';
export const UPDATE_ROWS_APP_DATA_STATS = 'UPDATE_ROWS_APP_DATA_STATS';
export const UPDATE_IDS_FAVORITE = 'UPDATE_IDS_FAVORITE';
export const UPDATE_COMPLETED_ROWS_APP_DATA_STATS = 'UPDATE_COMPLETED_ROWS_APP_DATA_STATS';
export const SHOW_LOADING_APP_DATA_STATS = 'SHOW_LOADING_APP_DATA_STATS';
export const UPDATE_TAB_APP_DATA_STATS = 'UPDATE_TAB_APP_DATA_STATS';
export const ADD_TAB_APP_DATA_STATS = 'ADD_TAB_APP_DATA_STATS';
export const REMOVE_KEYWORDS_FROM_TAB_APP_DATA_STATS = 'REMOVE_KEYWORDS_FROM_TAB_APP_DATA_STATS';
export const REMOVE_KEYWORDS_FROM_ALL_TABS_APP_DATA_STATS = 'REMOVE_KEYWORDS_FROM_ALL_TABS_APP_DATA_STATS';
export const REMOVE_TAB_APP_DATA_STATS = 'REMOVE_TAB_APP_DATA_STATS';
export const SET_KEYWORD_RANK_HISTORY = 'SET_KEYWORD_RANK_HISTORY';
export const ADD_KEYWORD_RANK_HISTORY = 'ADD_KEYWORD_RANK_HISTORY';
export const SHOW_KEYWORD_RANK_HISTORY_LOADING = 'SHOW_KEYWORD_RANK_HISTORY_LOADING';
export const RESET_APP_DATA_STATS = 'RESET_APP_DATA_STATS';

export enum KeywordAnalyticsActionEnum {
    SET_SELECTED_COMPETITORS = 'SET_SELECTED_COMPETITORS',
    TOGGLE_MOBILE_SHOW_KEYWORD_CONTROLLER = 'TOGGLE_MOBILE_SHOW_KEYWORD_CONTROLLER',
}
