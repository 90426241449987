import React, { useEffect, useState, FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useRequestInfo } from 'hooks/state/useRequestInfo';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { useAppSelector } from 'hooks/useAppSystem';

export const CheckAuthRoute: FC = ({ children }) => {
    useRequestInfo();

    const { isLoaded: isLoadedRequestInfo } = useRequestInfoService();

    const [redirectUrl, setRedirectUrl] = useState<string>();

    const { isVerifiedAccount, userCountry } = useRequestInfoService();

    const isLogged = useAppSelector((state) => state.user.isLogged);

    const { myAppsUrl } = useRoutesUrl({ currentLocale: userCountry?.toLowerCase() });

    useEffect(() => {
        if (isLoadedRequestInfo && myAppsUrl && isLogged && isVerifiedAccount) {
            setRedirectUrl(myAppsUrl);
        }
    }, [isLoadedRequestInfo, myAppsUrl, isLogged, isVerifiedAccount]);

    return <>{redirectUrl ? <Navigate to={redirectUrl} replace /> : children}</>;
};
