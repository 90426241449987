import {
    SetComparativeReportTableDataActionType,
    UpdateComparativeReportAddBtnActionType
} from "state/comparativeReport/comparativeReportTable/actions";
import {
    SET_COMPARATIVE_REPORT_TABLE_DATA,
    UPDATE_COMPARATIVE_REPORT_ADD_BTN
} from "state/comparativeReport/comparativeReportTable/actionTypes";
import { ComparativeReportTableStateType } from "state/comparativeReport/comparativeReportTable/ComparativeReportTableStateType";

type ActionType = SetComparativeReportTableDataActionType | UpdateComparativeReportAddBtnActionType;

const initialState: ComparativeReportTableStateType = {
    data: [],
    loading: true
};

const comparativeReportTable = (state = initialState, action: ActionType) => {

    switch (action.type) {
        case SET_COMPARATIVE_REPORT_TABLE_DATA: {
            return {...(action as SetComparativeReportTableDataActionType).data};
        }
        case UPDATE_COMPARATIVE_REPORT_ADD_BTN: {
            const keywordIds = (action as UpdateComparativeReportAddBtnActionType).keywordIds;

            const newState = {
                ...state,
                data: [...state.data]
            };
            newState.data = newState.data.map((keywordData) => {
                let newKeywordData = keywordData;
                if (keywordIds.indexOf(keywordData.id) > -1) {
                    newKeywordData = {
                        ...keywordData,
                        can_add: false
                    };
                }
                return newKeywordData
            });

            return newState;
        }
        default: {
            return state;
        }
    }
};

export default comparativeReportTable;
