export enum CustomReportsActionTypeEnum {
    SET_CUSTOM_REPORT_LIST = 'SET_CUSTOM_REPORT_LIST',
    SET_EMAIL_REPORT_HISTORY = 'SET_EMAIL_REPORT_HISTORY',
    SET_EMAIL_REPORT_HISTORY_LOADING = 'SET_EMAIL_REPORT_HISTORY_LOADING',
    ADD_EMAIL_REPORT_HISTORY = 'ADD_EMAIL_REPORT_HISTORY',
    SET_EMAIL_REPORT_HISTORY_FILTERS = 'SET_EMAIL_REPORT_HISTORY_FILTERS',
    SET_NEW_REPORT_FLAG = 'SET_NEW_REPORT_FLAG',
    ADD_CUSTOM_REPORT = 'ADD_CUSTOM_REPORT',
    SET_EDIT_CUSTOM_REPORT = 'SET_EDIT_CUSTOM_REPORT',
    UPDATE_CUSTOM_REPORT = 'UPDATE_CUSTOM_REPORT',
    DELETE_CUSTOM_REPORT = 'DELETE_CUSTOM_REPORT',
    SET_EMPTY_CUSTOM_REPORT_TYPE = 'SET_EMPTY_CUSTOM_REPORT_TYPE',
    REMOVE_UNTRACKED_APP = 'REMOVE_UNTRACKED_APP',
}
