import React, { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { Toggle } from 'newComponents/toggle/Toggle';
import { Icons } from 'types/icon-types';
import { Icon } from 'newComponents/icons/asodeskIcons/Icon';
import { PlanSubMeta } from 'newComponents/plans/planSubMeta/PlanSubMeta';
import styles from './planAdditionalSettings.module.scss';

export enum StatePlanAdditionalSettingsEnum {
    DEFAULT = 'default',
    PRIMARY = 'primary',
}

export type PropsType = {
    title: string;
    defaultIsShowSettings?: boolean;
    onChangeSettings?: (isShowSettings: boolean) => void;
    Content: ReactNode;
    meta?: string;
    className?: string;
    classContent?: string;
    isShowCheckIcon?: boolean;
    state?: StatePlanAdditionalSettingsEnum;
    hideDescription?: boolean;
};

export const PlanAdditionalSettings: FC<PropsType> = ({
    title,
    defaultIsShowSettings = false,
    Content,
    onChangeSettings = () => {},
    meta,
    className,
    classContent,
    isShowCheckIcon,
    state = StatePlanAdditionalSettingsEnum.DEFAULT,
    hideDescription = false,
}: PropsType) => {
    const [isShowSettings, setIsShowSettings] = useState(defaultIsShowSettings);

    const onChangeToggle = (isActive: boolean) => {
        setIsShowSettings(isActive);
        onChangeSettings(isActive);
    };

    const isDefaultState = state === StatePlanAdditionalSettingsEnum.DEFAULT;
    const isPrimaryState = state === StatePlanAdditionalSettingsEnum.PRIMARY;
    const showDescriptionSettings = isShowSettings && !hideDescription;

    return (
        <>
            <div
                className={classNames('px-12 py-20 -mx-15 mt-22 relative', className, {
                    'border-b border-gray-300': showDescriptionSettings,
                    [styles.container]: showDescriptionSettings,
                    [styles.default]: isDefaultState,
                    [styles.primary]: isPrimaryState,
                })}
                data-settings-heading
            >
                <div className={classNames('flex items-center justify-between')}>
                    <div className="mr-16">
                        <span className="flex text-14 text-gray-600 font-bold">
                            {isShowCheckIcon && (
                                <Icon name={Icons.Check} size={24} lineHeight={24} className="mr-8 text-success" />
                            )}
                            {title}
                        </span>{' '}
                        {meta && <PlanSubMeta>{meta}</PlanSubMeta>}
                    </div>
                    <Toggle onClick={onChangeToggle} isActive={isShowSettings} />
                </div>
            </div>
            {showDescriptionSettings && (
                <div
                    className={classNames('px-12 bg-white -mx-15 pb-20', classContent, {
                        'bg-white': isDefaultState,
                        'bg-gray-50': isPrimaryState,
                    })}
                >
                    {Content}
                </div>
            )}
        </>
    );
};
