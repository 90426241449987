export const config = window.config || {
    // variables for dev mode:
    SEARCHADS_COUNTRIES: ['US'],
    KEYWORD_STATS_PAGE_SIZE: 5,
    USER_KEYWORD_COLORS: { 0: 'default', 1: 'info', 2: 'success', 3: 'warning', 4: 'danger', 5: 'alert', 6: 'brown' },
    TERMS_OF_USE_URL: ``,
    PRIVACY_POLICY_URL: ``,
    API_TERMS_OF_USE_URL: ``,
    ANGLE_URL: `https://angleconnect.com/`,
    INVALID_EMAIL: false,
    NOT_CONFIRMED_EMAIL: false,
    SESSION_COOKIE_DOMAIN: '',
};

export const { hijack } = window;
