export const setReactChartsSize = () => {
    setTimeout(() => {
        import('highcharts/highstock').then((Highcharts) => {
            Highcharts.charts.forEach((chart) => chart && chart.setSize());
        });

        import('highcharts').then((Highcharts) => {
            Highcharts.charts.forEach((chart) => chart && chart.setSize());
            window.dispatchEvent(new CustomEvent('reactSidebarToggle'));
        });
    }, 600);
};
