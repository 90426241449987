import { KeywordManagerSuggestionsActionTypeListEnum } from 'state/keywordManagerSuggestions/actions';
import { KeywordManagerSuggestionsActionTypes } from 'state/keywordManagerSuggestions/actionTypes';
import { KeywordManagerSuggestionsStateType } from 'state/keywordManagerSuggestions/KeywordManagerSuggestionsStateType';

const initialState: KeywordManagerSuggestionsStateType = {
    competitorsSuggestions: {},
    searchAdsSuggestion: {},
    textAnalyzer: {},
    keywordShuffler: {},
};

const keywordManagerSuggestions = (state = initialState, action: KeywordManagerSuggestionsActionTypeListEnum) => {
    switch (action.type) {
        case KeywordManagerSuggestionsActionTypes.SET_SUGGESTIONS: {
            const keywordManagerTool = state[action.tool];

            return {
                ...state,
                [action.tool]: {
                    ...keywordManagerTool,
                    [action.competitorId]: {
                        ...action.suggestionInfo,
                        storeLocale: action.storeLocale,
                    },
                },
            };
        }
        case KeywordManagerSuggestionsActionTypes.UPDATE_ADD_KEYWORD_FLAG_FOR_ALL_SUGGESTIONS: {
            const { keywords: addedByUserKeywords } = action;
            const newState: KeywordManagerSuggestionsStateType = initialState;

            const toolNames = Object.keys(state) as Array<keyof KeywordManagerSuggestionsStateType>;

            toolNames.forEach((toolName) => {
                const appIds = Object.keys(newState[toolName]);
                appIds.forEach((appId) => {
                    if (toolName === 'textAnalyzer') {
                        const updatedKeywords = state[toolName][appId].keywords.map(({ add_keyword, keyword, ...otherProps }) => {
                            return {
                                ...otherProps,
                                keyword,
                                add_keyword: !addedByUserKeywords.includes(keyword),
                            };
                        });
                        newState[toolName][appId] = {
                            ...state[toolName][appId],
                            keywords: updatedKeywords,
                        };
                    } else {
                        const updatedKeywords = state[toolName][appId].keywords.map(({ add_keyword, keyword, ...otherProps }) => {
                            return {
                                ...otherProps,
                                keyword,
                                add_keyword: !addedByUserKeywords.includes(keyword),
                            };
                        });
                        newState[toolName][appId] = {
                            ...state[toolName][appId],
                            keywords: updatedKeywords,
                        };
                    }
                });
            });

            return newState;
        }
        case KeywordManagerSuggestionsActionTypes.CLEAR_SUGGESTIONS: {
            return { ...initialState };
        }
        case KeywordManagerSuggestionsActionTypes.SET_TEXT_ANALYZE: {
            return {
                ...state,
                textAnalyzer: {
                    ...state.textAnalyzer,
                    [action.appId]: action.suggestions,
                },
            };
        }
        case KeywordManagerSuggestionsActionTypes.SET_KEYWORD_SHUFFLER: {
            return {
                ...state,
                keywordShuffler: {
                    ...state.keywordShuffler,
                    [action.appId]: action.suggestions,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordManagerSuggestions;
