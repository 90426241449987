import { parseQueryString, stringifyParsedQuery } from 'utils/helpers/queryString';
import {
    OPEN_CONTACT_US_HS_EVENT_NAME,
    OPEN_DOWNGRADE_HS_EVENT_NAME,
    OPEN_FEATURE_HS_EVENT_NAME,
    OPEN_IMPORT_HS_EVENT_NAME,
    NAME_OPEN_TRIAL_HS_EVENT,
    OPEN_TRIAL_END_HS_EVENT_NAME,
} from 'constants/hubspot/hubspotEventNameConstants';

export const hubspotPopupQueryParamName = 'hubspot_welcome';

export const hubspotParams = [
    OPEN_IMPORT_HS_EVENT_NAME,
    OPEN_CONTACT_US_HS_EVENT_NAME,
    OPEN_DOWNGRADE_HS_EVENT_NAME,
    OPEN_TRIAL_END_HS_EVENT_NAME,
    OPEN_FEATURE_HS_EVENT_NAME,
    NAME_OPEN_TRIAL_HS_EVENT,
    hubspotPopupQueryParamName,
];

export const addedNewHubspotGetParam = (hubspotEventName: string) => {
    const { origin, pathname, search } = window.location;

    const { ...restParams } = parseQueryString(search);

    const updatedParams = {
        ...restParams,
        [hubspotEventName]: true,
    };

    const params = stringifyParsedQuery(updatedParams);

    window.history.pushState({}, document.title, `${origin}${pathname}?${params}`);
};

const prodHubspotId = 26542506;
const testHubspotId = 26693710;

export const loadHubspotScripts = (hsConversationsOnReadyCallback: () => void) => {
    const script = document.createElement('script');
    script.src = `//js.hs-scripts.com/${prodHubspotId}.js`;
    script.async = true;

    window.hsConversationsOnReady = [
        () => {
            hsConversationsOnReadyCallback();
        },
    ];

    document.body.appendChild(script);
};
