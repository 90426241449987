export enum KAColumnsIdEnum {
    MOVEMENT = 'position_info.change',
    ID = 'id',
    IPAD_MOVEMENT = 'ipad_position_info.change',
    IN_NAME = 'in_name',
    IN_SUBTITLE = 'in_subtitle',
    SUGGESTIONS_COUNT = 'suggestions_count',
    DAILY_IMPRESSIONS = 'users_per_day',
    KEYWORD = 'keyword',
    SEARCH_ADS_POPULARITY = 'searchads_popularity',
    POSITION_INFO = 'position_info.position',
    BEST_RANK = 'best_rank',
    IPAD_RANK = 'ipad_position_info.position',
    IPAD_BEST_RANK = 'ipad_best_rank',
    IPAD_TOTAL_APPS = 'ipad_total_apps',
    RANK_HISTORY = 'note',
    ADDED_ON = 'timestamp',
    DIFFICULTY = 'difficulty',
    TOTAL_APPS = 'total_apps',
    TRANSLATE_FROM = 'translation_from',
    KEYWORD_LENGTH = 'custom_traffic_score',
    COLOR = 'color',
    ESTIMATED_INSTALLS = 'estimated_installs',
}
