import { useEffect } from 'react';
import { useTogglePopup } from 'hooks/state/usePopup';
import { CampaignPaymentModalTypes } from 'modules/modal/campaignPaymentModal/CampaignPaymentModalTypes';
import { MODAL_NAME_CAMPAIGN_PAYMENT } from 'constants/modalNameConstants';
import { useAuth } from 'hooks/useAuth';

export const useCampaignPaymentModalBridgeAngular = () => {
    const { togglePopupWithData } = useTogglePopup<CampaignPaymentModalTypes>(MODAL_NAME_CAMPAIGN_PAYMENT);

    const { onCheckClickAuth } = useAuth();

    const openCampaignPaymentPanel = ({ detail: { campaignId } }: CustomEvent<{ campaignId: string }>) => {
        onCheckClickAuth(() => {
            togglePopupWithData({
                data: {
                    campaignId,
                },
            });
        });
    };

    useEffect(() => {
        window.addEventListener('openPaymentCampaignModal', openCampaignPaymentPanel as EventListener);

        return () => {
            window.removeEventListener('openPaymentCampaignModal', openCampaignPaymentPanel as EventListener);
        };
    }, []);
};
