if (window.ravenSettings) {
    const { RAVEN_CONFIG_FRONT, ENVIRONMENT, email, id, release } = window.ravenSettings;

    import(/* webpackPreload: true */ '@sentry/browser').then(({ init }) => {
        init({
            dsn: RAVEN_CONFIG_FRONT,
            release: release && release.replace(/[\/|static]/g, ''),
            environment: ENVIRONMENT,
            initialScope: {
                user: { email, id },
            },
            tracesSampleRate: 0.5,
        });
    });
}
