import { setShowExpiredFlag } from 'state/userFrontendSettings/controller';
import { useDispatch } from 'react-redux';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { useReloadPlanInfo } from 'hooks/useReloadPlanInfo';
import { useAppSelector } from 'hooks/useAppSystem';

export const useTrialExpiredStorage = () => {
    const dispatch = useDispatch();

    const { isShowTrialExpiredModal, isLoading } = useRequestInfoService();
    const { reloadPlanInfo } = useReloadPlanInfo();

    const { showTrialExpiredPlan } = useAppSelector(({ userFrontendSettings }) => userFrontendSettings);

    const hideTrialExpired = async () => {
        await dispatch(setShowExpiredFlag(false));
    };

    const saveNoShowModal = async () => {
        await hideTrialExpired();
        await reloadPlanInfo();
    };

    return {
        saveNoShowModal,
        isNeedOpenModal: isShowTrialExpiredModal && !isLoading && showTrialExpiredPlan,
        isShowTrialExpiredModal,
        hideTrialExpired,
    };
};
