import { FeaturePermissionModalType } from 'modules/modal/featurePermissionModal/FeaturePermissionModalDataType';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { featurePermissionModalWithTrialCase } from 'state/popup/featurePermissionModalDispatch';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'types/react-redux-thunk';

export const useFeaturePermissionsPopup = (feature?: FeaturePermissionModalType) => {
    const { isAvailableFeature } = useRequestInfoService();
    const dispatch = useDispatch<ThunkDispatch>();
    const { isTrial } = useRequestInfoService();

    const isFeatureAvailable = !!(feature && isAvailableFeature(feature));
    const isDisableFeature = !isFeatureAvailable;

    const showBasePermissionModal = () => {
        if (feature) {
            featurePermissionModalWithTrialCase(dispatch, feature, isTrial);
        }
    };

    return {
        showBasePermissionModal,
        showPermissionModalIfNotAvailable: () => {
            if (isDisableFeature) {
                showBasePermissionModal();
            }
        },
        isDisableFeature,
        isAvailableFeature,
        isFeatureAvailable,
    };
};
