import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { TabControlWrapperPropsType } from 'newComponents/tab/TabControlPropsType';
import { TabContent } from 'newComponents/tab/TabContent';

const TabWrapperComponent: FC<TabControlWrapperPropsType> = ({
    tabs,
    activeId,
    children,
    className,
    tabClassName,
    contentClassName,
}: TabControlWrapperPropsType) => {
    const [activeTabId, setActiveTabId] = useState(activeId);

    useEffect(() => {
        setActiveTabId(activeId);
    }, [activeId]);

    const changedTab = useMemo(
        () =>
            tabs.map((tab) => ({
                ...tab,
                onClickHandler: () => {
                    setActiveTabId(tab.id);
                    tab.onClickHandler && tab.onClickHandler(tab.id);
                },
            })),
        [tabs]
    );

    return (
        <>
            {children && children({ tabs: changedTab, activeId: activeTabId, className, tabClassName })}
            <TabContent tabs={changedTab} activeId={activeTabId} contentClassName={contentClassName} />
        </>
    );
};

export const TabWrapper = memo(TabWrapperComponent);
