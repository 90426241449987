import { defaultDateRange } from 'constants/dateConstants';
import { DatePickerType } from 'components/datePicker/datePickerType';
import { TagAnalysisActionType } from 'state/tagAnalysis/actions';
import { AppOptionType } from 'newComponents/forms/select/customComponents/options/appOption/AppOption';
import { TagAnalysisActionTypeListEnum } from 'state/tagAnalysis/actionsTypes';
import { TagAnalysisReportType, TagAnalysisStatisticType } from 'state/tagAnalysis/tagAnalysisType';
import {
    CRITICAL_TAG_SORTING_TYPE,
    NEW_TAG_SORTING_TYPE,
    POPULAR_TAG_SORTING_TYPE,
    TRENDING_DOWN_TAG_SORTING_TYPE,
    TRENDING_UP_TAG_SORTING_TYPE,
} from 'state/tagAnalysis/baseTagAnalysisTypes';

export type TagAnalysisStateType = {
    filters: {
        date: DatePickerType;
        app?: AppOptionType;
        countries: string[];
        languages: string[];
        versions?: string[];
    };
    stats: TagAnalysisStatisticType;
    report: TagAnalysisReportType;
};

const INITIAL_STATS_DATA = {
    current: 0,
    previous: 0,
    diff: 0,
};

export const tagAnalysisInitialState: TagAnalysisStateType = {
    filters: {
        date: defaultDateRange,
        countries: [],
        languages: [],
        versions: undefined,
    },
    stats: {
        reviews: INITIAL_STATS_DATA,
        taggedReviews: INITIAL_STATS_DATA,
        manualTagged: INITIAL_STATS_DATA,
        autoTagged: INITIAL_STATS_DATA,
        reviewsWithMostPopularTag: 0,
    },
    report: {
        [CRITICAL_TAG_SORTING_TYPE]: [],
        [NEW_TAG_SORTING_TYPE]: [],
        [POPULAR_TAG_SORTING_TYPE]: [],
        [TRENDING_DOWN_TAG_SORTING_TYPE]: [],
        [TRENDING_UP_TAG_SORTING_TYPE]: [],
        allReviews: [],
    },
};

const tagAnalysis = (state = tagAnalysisInitialState, action: TagAnalysisActionType) => {
    switch (action.type) {
        case TagAnalysisActionTypeListEnum.CHANGE_FILTER_DATE: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    date: action.date,
                },
            };
        }

        case TagAnalysisActionTypeListEnum.CHANGE_FILTER_APP: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    app: action.app,
                },
            };
        }

        case TagAnalysisActionTypeListEnum.CHANGE_FILTER_COUNTRIES: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    countries: action.countries,
                },
            };
        }

        case TagAnalysisActionTypeListEnum.CHANGE_FILTER_LANGUAGES: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    languages: action.languages,
                },
            };
        }

        case TagAnalysisActionTypeListEnum.CHANGE_FILTER_VERSIONS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    versions: action.versions,
                },
            };
        }

        case TagAnalysisActionTypeListEnum.SET_STATS_DATA: {
            return {
                ...state,
                stats: action.stats,
            };
        }

        case TagAnalysisActionTypeListEnum.SET_REPORT_DATA: {
            return {
                ...state,
                report: action.report,
            };
        }

        default: {
            return state;
        }
    }
};

export default tagAnalysis;
