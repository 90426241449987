import { ServicesSubPlanResponseType } from 'state/plans/PlansResponseType';
import { ServicesSubPlanType } from 'state/plans/PlansType';
import { featuresAdapter, getToolsFromFeaturesAndPlans } from 'state/plans/basePlansAdapter';

export const servicesSubPlanAdapter = (plans: ServicesSubPlanResponseType[]): ServicesSubPlanType[] =>
    plans.map(({ features, pricing, ...planProps }) => ({
        features: featuresAdapter(features),
        tools: getToolsFromFeaturesAndPlans(featuresAdapter(features)),
        pricing: pricing.map(
            ({
                price_saving,
                duration_months,
                total_price,
                total_price_discount,
                price,
                price_difference,
                price_difference_rub,
                ...priceProps
            }) => ({
                priceSaving: price_saving,
                durationMonths: duration_months,
                totalPrice: total_price,
                totalPriceDiscount: total_price_discount,
                price: +price,
                priceDifference: price_difference,
                priceDifferenceRub: price_difference_rub,
                ...priceProps,
            })
        ),
        ...planProps,
    }));
