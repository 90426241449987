import { AppResponseType } from 'state/appProfile/AppResponseType';
import { TrackedAppActionTypeEnum } from 'state/trackedApps/actionTypes';
import { Action } from 'redux';
import { TrackedAppsType } from 'state/trackedApps/TrackedAppsType';

interface SetTrackedAppsActionType extends TrackedAppsType {
    type: TrackedAppActionTypeEnum.SET_TRACKED_APP;
}

interface AddTrackedAppActionType extends Action {
    type: TrackedAppActionTypeEnum.ADD_TRACKED_APP;
    app: AppResponseType;
}

interface RemoveTrackedAppActionType extends Action {
    type: TrackedAppActionTypeEnum.REMOVE_TRACKED_APP;
    appId: string;
}

export type TrackedAppsActionType = SetTrackedAppsActionType | AddTrackedAppActionType | RemoveTrackedAppActionType;

export function setTrackedAppsAction(props: TrackedAppsType): SetTrackedAppsActionType {
    return {
        type: TrackedAppActionTypeEnum.SET_TRACKED_APP,
        ...props,
    };
}

export function addTrackedAppAction(app: AppResponseType): AddTrackedAppActionType {
    return {
        type: TrackedAppActionTypeEnum.ADD_TRACKED_APP,
        app,
    };
}

export function removeTrackedAppAction(appId: string): RemoveTrackedAppActionType {
    return {
        type: TrackedAppActionTypeEnum.REMOVE_TRACKED_APP,
        appId,
    };
}
