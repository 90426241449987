import React, { ReactNode, FC } from 'react';
import { OptionProps } from 'react-select';
import { TextItem } from 'newComponents/listItem/textItem/TextItem';
import classNames from 'classnames';
import { BaseOptionType } from 'newComponents/forms/select/SelectTypes';

export type TextOptionType<T extends {} = any> = BaseOptionType<T, string> & {
    tooltip?: ReactNode;
    subValue?: string;
    className?: string;
};

export const TextOption: FC<OptionProps<TextOptionType>> = ({ innerProps, data, className }: OptionProps<TextOptionType>) => (
    <div
        className={classNames(
            'group -mx-16 px-16 cursor-pointer hover:bg-primary hover:bg-opacity-5',
            data.optionClassName,
            className
        )}
    >
        <TextItem
            text={data.value}
            tooltip={data.tooltip}
            meta={data.meta}
            description={data.description}
            {...innerProps}
            className="!border-gray-300 group-last:!border-transparent"
        />
    </div>
);
