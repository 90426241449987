import { usePlansGetter } from 'hooks/data/usePlansGetter';
import { useTogglePopup } from 'hooks/state/usePopup';
import { CustomerSupportPlanType, PaymentPopupDataType } from 'modules/modal/paymentModal/PaymentModalTypes';
import { DOWNGRADE_PLAN_MODAL_NAME, MODAL_NAME_PAYMENT } from 'constants/modalNameConstants';
import { DowngradePlanModalDataType } from 'modules/modal/downgradePlanModal/DowngradePlanModalDataType';
import { PaymentPeriodEnum, SubscriptionTypeEnum } from 'state/payment/paymentTypes';
import { SubPlanType } from 'state/plans/PlansType';

export type OnClickPlanActionType = {
    subscriptionType?: SubscriptionTypeEnum;
    paymentPeriod?: PaymentPeriodEnum;
    customerSupportPlan?: CustomerSupportPlanType;
    plan?: SubPlanType;
};

export const useActionPropsForPlans = () => {
    const { currentPlan } = usePlansGetter();
    const { togglePopupWithData: onTogglePaymentModal } = useTogglePopup<PaymentPopupDataType>(MODAL_NAME_PAYMENT);
    const { togglePopupWithData: onToggleDowngradePlanModal } = useTogglePopup<DowngradePlanModalDataType>(
        DOWNGRADE_PLAN_MODAL_NAME
    );

    const actionProps = {
        text: 'Subscribe',
        onClick: ({ subscriptionType, paymentPeriod, customerSupportPlan, plan }: OnClickPlanActionType) => {
            const isDowngradeByPlanId = plan?.order && currentPlan && currentPlan.order > plan.order;

            if (isDowngradeByPlanId && plan?.id) {
                onToggleDowngradePlanModal({
                    data: {
                        planId: plan.id,
                    },
                });
            } else {
                onTogglePaymentModal({
                    data: {
                        planId: plan?.id,
                        subscriptionType,
                        paymentPeriod,
                        ...(customerSupportPlan && { customerSupportPlan }),
                    },
                });
            }
        },
    };

    return {
        actionProps,
    };
};
