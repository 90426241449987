import { AppUntrackedInfoResponseType } from 'state/trackedApps/AppUntrackedInfoResponseType';
import { AppUntrackedInfoType } from 'state/trackedApps/AppUntrackedInfoType';

export const adaptAppUntrackedInfoResponse = (response: AppUntrackedInfoResponseType): AppUntrackedInfoType => {
    const {
        slack_reports: slackReports,
        auto_reply_rules: autoReplyRules,
        auto_tag_rules: autoTagRules,
        email_reports: emailReports,
    } = response;

    return {
        slackReports,
        autoReplyRules,
        autoTagRules,
        emailReports,
    };
};
