import { Action } from 'redux';
import { PopupActionTypeEnum } from './actionsTypes';
import { PopupPayloadType } from './PopupTypes';

interface TogglePopupActionType extends Action {
    type: PopupActionTypeEnum.TOGGLE_POPUP;
    popupName: string;
    payload?: PopupPayloadType;
}

interface SetPopupValueActionType extends Action {
    type: PopupActionTypeEnum.SET_POPUP_VALUE;
    popupName: string;
    isOpen: boolean;
}

interface CloseAllPopupActionType extends Action {
    type: PopupActionTypeEnum.CLOSE_ALL_POPUPS;
}

interface ChangePopupPayloadActionType extends Action {
    type: PopupActionTypeEnum.CHANGE_POPUP_PAYLOAD;
    popupName: string;
    payload: Partial<PopupPayloadType>;
}

export type PopupActionType =
    | TogglePopupActionType
    | SetPopupValueActionType
    | CloseAllPopupActionType
    | ChangePopupPayloadActionType;

export const togglePopupAction = (popupName: string, payload?: PopupPayloadType): TogglePopupActionType => ({
    type: PopupActionTypeEnum.TOGGLE_POPUP,
    popupName,
    payload,
});

type SetPopupValueType = {
    popupName: string;
    isOpen: boolean;
};

export const setPopupValueAction = ({ popupName, isOpen }: SetPopupValueType): SetPopupValueActionType => ({
    type: PopupActionTypeEnum.SET_POPUP_VALUE,
    popupName,
    isOpen,
});

export const closeAllPopupsAction = (): CloseAllPopupActionType => ({
    type: PopupActionTypeEnum.CLOSE_ALL_POPUPS,
});

export const changePopupPayloadAction = (
    popupName: string,
    payload: Partial<PopupPayloadType>
): ChangePopupPayloadActionType => ({
    type: PopupActionTypeEnum.CHANGE_POPUP_PAYLOAD,
    popupName,
    payload,
});
