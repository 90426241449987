import { togglePopupAction } from 'state/popup/actions';
import { MODAL_NAME_FEATURE_PERMISSIONS, MODAL_NAME_UPGRADE_TRIAL_PLAN } from 'constants/modalNameConstants';
import { AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import { ThunkDispatch } from 'types/react-redux-thunk';
import { RootState } from 'state/store';

export const featurePermissionModalWithTrialCase = (
    dispatch: ThunkDispatch,
    feature: keyof AvailableFeaturesType,
    isTrial: boolean
) => {
    if (isTrial) {
        dispatch(
            togglePopupAction(MODAL_NAME_UPGRADE_TRIAL_PLAN, {
                data: { feature },
            })
        );
    } else {
        dispatch(
            togglePopupAction(MODAL_NAME_FEATURE_PERMISSIONS, {
                data: { feature },
            })
        );
    }
};

export const featurePermissionModalDispatch = (
    dispatch: ThunkDispatch,
    feature: keyof AvailableFeaturesType,
    store: () => RootState
) => {
    const { requestInfo } = store();

    featurePermissionModalWithTrialCase(dispatch, feature, requestInfo.isTrial);
};
