// TODO: moved this plan ids in backend
export const PLAN_ID_GURU_ASO = 1246;
export const PLAN_ID_GURU_REVIEWS = 1248;

export const PLAN_ID_POWER_ASO = 1261;
export const PLAN_ID_POWER_REVIEWS = 1252;

export const PLAN_ID_ULTIMATE_ASO = 1251;
export const PLAN_ID_ULTIMATE_REVIEWS = 1314;

export const REQUESTED_PLAN_NAME = {
    [PLAN_ID_GURU_ASO]: 'Guru ASO',
    [PLAN_ID_GURU_REVIEWS]: 'Guru Reviews',
};
