import { Action } from 'redux';
import { PlansActionTypeEnum } from 'state/plans/actionTypes';
import { PlansDowngradeMetaType } from 'state/plans/plansDowngradeMeta/PlansDowngradeMetaType';
import { AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import { PlansType } from './PlansType';

interface SetPlansDataActionType extends Action {
    type: PlansActionTypeEnum.SET_PLANS_DATA;
    data: PlansType;
}

interface SetPlansFeaturesActionType extends Action {
    type: PlansActionTypeEnum.SET_PLANS_FEATURES;
    data: AvailableFeaturesType;
}

interface SetDowngradePlansMetaActionType extends Action {
    type: PlansActionTypeEnum.SET_DOWNGRADE_PLANS_META;
    data: PlansDowngradeMetaType;
}

export type PlansActionType = SetPlansDataActionType | SetPlansFeaturesActionType | SetDowngradePlansMetaActionType;

export const setPlansData = (data: PlansType): SetPlansDataActionType => ({
    type: PlansActionTypeEnum.SET_PLANS_DATA,
    data,
});

export const setPlansFeaturesInformation = (data: AvailableFeaturesType): SetPlansFeaturesActionType => ({
    type: PlansActionTypeEnum.SET_PLANS_FEATURES,
    data,
});

export const setDowngradePlansMeta = (data: PlansDowngradeMetaType): SetDowngradePlansMetaActionType => ({
    type: PlansActionTypeEnum.SET_DOWNGRADE_PLANS_META,
    data,
});
