import { Action } from 'redux';
import { AsodeskHeaderActionTypeEnum } from './actionsTypes';
import { HeaderCountryType } from 'state/asodeskHeader/HeaderCountryType';

interface SetHeaderInfoActionType extends Action {
    type: AsodeskHeaderActionTypeEnum.SET_HEADER_INFO;
    countries: HeaderCountryType[];
    userDefaultCountry: HeaderCountryType;
    avatar: string;
}

interface SetHideLocaleHeaderDropdownType extends Action {
    type: AsodeskHeaderActionTypeEnum.SET_IS_HIDDEN_HEADER_LOCALE_DROPDOWN;
    isHide: boolean;
}

interface UpdateCurrentCountryCounterActionType extends Action {
    type: AsodeskHeaderActionTypeEnum.UPDATE_CURRENT_COUNTRY_COUNTER;
    countryCode: string;
    count: number;
}

export type AsodeskHeaderActionsType =
    | SetHeaderInfoActionType
    | SetHideLocaleHeaderDropdownType
    | UpdateCurrentCountryCounterActionType;

export const setHeaderInfoAction = (
    countries: HeaderCountryType[],
    userDefaultCountry: HeaderCountryType,
    avatar: string
): SetHeaderInfoActionType => {
    return {
        type: AsodeskHeaderActionTypeEnum.SET_HEADER_INFO,
        countries,
        userDefaultCountry,
        avatar,
    };
};

export const setHideLocaleHeaderDropdownAction = (isHide: boolean): SetHideLocaleHeaderDropdownType => {
    return {
        type: AsodeskHeaderActionTypeEnum.SET_IS_HIDDEN_HEADER_LOCALE_DROPDOWN,
        isHide,
    };
};

export const updateCurrentCountryCounterAction = (countryCode: string, count: number): UpdateCurrentCountryCounterActionType => {
    return {
        type: AsodeskHeaderActionTypeEnum.UPDATE_CURRENT_COUNTRY_COUNTER,
        countryCode,
        count,
    };
};
