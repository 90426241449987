import React, { FC, useState, useEffect } from 'react';
import { PaymentPeriodEnum, SubscriptionTypeEnum } from 'state/payment/paymentTypes';
import { CategoryPlanType, PlansType } from 'state/plans/PlansType';
import { PlanCard, PlanCardStateEnum } from 'newComponents/plans/planCard/PlanCard';
import { PlanFeatures } from 'newComponents/plans/planFeatures/PlanFeatures';
import { withPlural, withSpecificPlural } from 'utils/helpers/string/text';
import { numberFormat } from 'utils/helpers/string/numberFormat';
import { LANGUAGE_RU } from 'constants/localizationsConstants';
import { useActionPropsForPlans } from 'modules/settings/plans/planTabs/useActionPropsForPlans';
import { PlanTabsActionType } from 'modules/settings/plans/planTabs/planTabsTypes';
import { OnlyCurrentPaymentPeriodLabel } from 'modules/settings/plans/planTabs/OnlyCurrentPaymentPeriodLabel';
import { useBusinessPeriodLimitation } from 'modules/settings/plans/planTabs/useBusinessPeriodLimitation';
import { getIsAsoBusinessPlan } from 'modules/modal/planModal/utils';
import { AsoFreeInfo } from 'modules/settings/plans/planTabs/asoContent/asoFreeInfo/AsoFreeInfo';
import { usePlanTabs } from 'modules/settings/plans/planTabs/usePlanTabs';
import { useWelcomeOffer } from 'hooks/useWelcomeOffer';
import { THREE_MONTHS_PERIOD } from 'modules/settings/plans/planTabs/constants';
import { getCurrencySymbol } from 'modules/modal/paymentModal/constants';
import { SelectPlanKeywordsContainer } from './SelectPlanKeywordsContainer';
import { ASO_DATA_TYPE, AsoDataType, REVIEW_ASO_DATA_TYPE } from './AsoContentTypes';
import { ReviewSettingsContainer } from './ReviewSettingsContainer';

type PropsType = Pick<PlansType, 'reviewsAso'> & {
    categoryPlan: CategoryPlanType;
    paymentPeriod: PaymentPeriodEnum;
    defaultActiveAsoType?: AsoDataType;

    buttonPortal?: React.RefObject<HTMLDivElement>;
    action?: PlanTabsActionType;
    hideDescription?: boolean;
    onChangePlanId?: (planId: number) => void;
    badgeText?: string;
    state?: PlanCardStateEnum;
    needSalesManager?: boolean;
    businessActions?: {
        text: string;
        onClick: () => void;
    };
    isShowActions?: boolean;
};

export const AsoPlanCardContainer: FC<PropsType> = ({
    hideDescription = false,
    categoryPlan,
    paymentPeriod,
    reviewsAso,
    defaultActiveAsoType = ASO_DATA_TYPE,
    buttonPortal,
    action,
    onChangePlanId,
    badgeText,
    state,
    needSalesManager,
    businessActions,
    isShowActions = true,
}: PropsType) => {
    const [dataType, setDataType] = useState<AsoDataType>(defaultActiveAsoType);
    const [currentPlanId, setCurrentPlanId] = useState(categoryPlan.plans[0].id);

    const { statePlanCard, planCardPeriod } = usePlanTabs({ state, isBestPlan: categoryPlan.isBestPlan, paymentPeriod });

    useEffect(() => {
        setDataType(defaultActiveAsoType);
    }, [defaultActiveAsoType]);

    const { getIs3MonthsWelcomeOffer } = useWelcomeOffer();

    const is3MonthsWelcomeOffer = getIs3MonthsWelcomeOffer(paymentPeriod);

    const isReviewsAsoDataType = dataType === REVIEW_ASO_DATA_TYPE;

    const currentPlan = categoryPlan.plans.find(({ id }) => id === currentPlanId);
    const currentReviewsPlan = reviewsAso.find(({ id }) => id === currentPlan?.reviewAsoId)?.plans[0];
    const currentPrice = isReviewsAsoDataType
        ? currentReviewsPlan?.pricing.find(({ id }) => id === paymentPeriod)
        : currentPlan?.pricing.find(({ id }) => id === paymentPeriod);
    const currentReviewsPrice = currentReviewsPlan?.pricing.find(({ id }) => id === paymentPeriod);
    const currentPlanByType = isReviewsAsoDataType ? currentReviewsPlan : currentPlan;
    const currentPlanIdByType = currentPlanByType?.id;

    useEffect(() => {
        if (onChangePlanId && currentPlanIdByType !== undefined) {
            onChangePlanId(currentPlanIdByType);
        }
    }, [currentPlanIdByType]);

    const customerSupportPlan =
        currentReviewsPlan && currentReviewsPrice && currentPlan && !isReviewsAsoDataType
            ? {
                  planId: currentPlan.id,
                  customerSupportPlanId: currentReviewsPlan.id,
                  priceDifference: currentReviewsPrice.priceDifference,
                  priceDifferenceRub: currentReviewsPrice.priceDifferenceRub,
                  replies: currentReviewsPlan.reviews,
                  features: currentReviewsPlan.reviewsFeatures,
              }
            : undefined;

    if (!currentPlan || !currentPrice || !currentReviewsPlan) return null;

    const selectedKeyword = {
        id: currentPlan.id,
        value: `${numberFormat({ number: currentPlan.keywords, locale: LANGUAGE_RU })} ${withPlural(
            'keyword',
            currentPlan.keywords
        )}`,
    };

    const selectedReview = {
        id: currentPlan.id,
        value: `${numberFormat({ number: currentPlan.reviews, locale: LANGUAGE_RU })} ${withSpecificPlural(
            currentPlan.reviews,
            'reply',
            'replies'
        )}`,
    };

    const oldPriceAsoPlan = is3MonthsWelcomeOffer ? currentPrice.priceSaving * THREE_MONTHS_PERIOD : currentPrice.priceSaving;

    const isDiscount = oldPriceAsoPlan > currentPrice.price;
    const features = hideDescription ? currentPlan.features.filter(({ title }) => title === 'Limits') : currentPlan.features;

    const hasFreeAsoCard = getIsAsoBusinessPlan(categoryPlan.name) && paymentPeriod === PaymentPeriodEnum.ANNUAL;

    const getPriceAsoPlan = () => {
        if (needSalesManager) return '';

        return `${getCurrencySymbol()}${numberFormat({
            number: is3MonthsWelcomeOffer ? currentPrice.totalPrice : currentPrice.price,
        })}`;
    };

    return (
        <PlanCard
            hideDescription={hideDescription}
            title={categoryPlan.name}
            description={categoryPlan.description}
            {...(categoryPlan.isBestPlan ? { badgeText: 'Best value' } : {})}
            {...(badgeText ? { badgeText } : {})}
            price={getPriceAsoPlan()}
            period={planCardPeriod}
            {...(isDiscount ? { oldPrice: `${getCurrencySymbol()}${numberFormat({ number: oldPriceAsoPlan })}` } : {})}
            {...(currentPrice.totalPriceDiscount
                ? { savingPrice: `${getCurrencySymbol()}${numberFormat({ number: currentPrice.totalPriceDiscount })}` }
                : {})}
            totalPrice={needSalesManager ? '' : `${getCurrencySymbol()}${numberFormat({ number: currentPrice.totalPrice })}`}
            buttonDescription={needSalesManager ? 'Check prices with manager' : ''}
            state={statePlanCard}
            TopControls={
                needSalesManager ? (
                    <OnlyCurrentPaymentPeriodLabel />
                ) : (
                    <SelectPlanKeywordsContainer
                        plans={categoryPlan.plans}
                        setPlanId={setCurrentPlanId}
                        selectedKeyword={selectedKeyword}
                        paymentPeriod={paymentPeriod}
                        isReviewsAsoDataType={isReviewsAsoDataType}
                        reviewsAso={reviewsAso}
                    />
                )
            }
            Content={
                <>
                    <PlanFeatures features={features} />
                    {hasFreeAsoCard && <AsoFreeInfo planName={categoryPlan.name} />}
                </>
            }
            AdditionalSettings={
                <ReviewSettingsContainer
                    hideDescription={hideDescription}
                    paymentPeriod={paymentPeriod}
                    dataType={dataType}
                    setDataType={setDataType}
                    selectedReview={selectedReview}
                    setPlanId={setCurrentPlanId}
                    plans={[currentReviewsPlan]}
                />
            }
            {...(isShowActions
                ? {
                      action: {
                          ...action,
                          onClick: () => {
                              action?.onClick({
                                  subscriptionType: SubscriptionTypeEnum.SUBSCRIPTIONS,
                                  paymentPeriod,
                                  customerSupportPlan,
                                  plan: currentPlanByType,
                              });
                          },
                          ...(businessActions || {}),
                      },
                  }
                : {})}
            buttonPortal={buttonPortal}
        />
    );
};

export const AsoPlanCardWithPaymentModalActionContainer = (props: Omit<PropsType, 'action'>) => {
    const { actionProps } = useActionPropsForPlans();

    const { categoryPlan, paymentPeriod } = props;

    const { needSalesManager, businessActions } = useBusinessPeriodLimitation({
        planName: categoryPlan.name,
        paymentPeriod,
    });

    return (
        <AsoPlanCardContainer
            action={actionProps}
            {...props}
            categoryPlan={categoryPlan}
            paymentPeriod={paymentPeriod}
            needSalesManager={needSalesManager}
            businessActions={businessActions}
        />
    );
};
