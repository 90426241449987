import { Dispatch } from 'redux';
import axios from 'axios';
import { Urls } from 'utils/Urls';
import { AppProfileControllerInterface } from 'state/appProfile/AppProfileControllerInterface';
import { setAppProfileAction, setLoadingAppProfileAction } from 'state/appProfile/actions';
import { AppProfileResponseType } from 'state/appProfile/AppProfileResponseType';
import { setCompetitorsAction } from 'state/competitors/actions';
import { AppsRequestParamsType } from 'state/AppsRequestParamsType';
import { isString } from 'utils/typeGuard';
import { BaseSystemType } from 'state/system/systemTypes';
import { RootState } from 'state/store';
import { getAppProfileStore } from 'utils/storeUtils';

export const getCurrentAppProfile = (params: AppsRequestParamsType) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoadingAppProfileAction(true));
        const response = await axios.get<AppProfileResponseType>(Urls.profileData(params.country, params.appId));

        if (!isString(response.data)) {
            dispatch(setAppProfileAction(response.data));
            dispatch(setCompetitorsAction(response.data.competitors || []));
        }
        dispatch(setLoadingAppProfileAction(false));
    } catch (error) {
        console.error(error);
    }
};

export const getSilentCurrentAppProfile = (params: BaseSystemType) => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get<AppProfileResponseType>(Urls.profileData(params.locale, params.appId));

        if (!isString(response.data)) {
            dispatch(setAppProfileAction(response.data));
            dispatch(setCompetitorsAction(response.data.competitors || []));
        }
    } catch (error) {
        console.error(error);
    }
};

export const getAppProfile = (appId: string) => async (dispatch: Dispatch, store: () => RootState) => {
    try {
        const { country } = getAppProfileStore(store);

        const response = await axios.get<AppProfileResponseType>(Urls.profileData(country, appId));

        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const AppProfileController: AppProfileControllerInterface = {
    getCurrentAppProfile,
    getAppProfile,
};
