import React, { FC, useEffect } from 'react';
import { ValueType } from 'react-select';
import { TextOption, TextOptionType } from 'newComponents/forms/select/customComponents/options/textOption/TextOption';
import { isSelectValue, Select } from 'newComponents/forms/select/Select';
import { TextSingleValue } from 'newComponents/forms/select/customComponents/singleValue/TextSingleValue';
import { CategoryPlanType } from 'state/plans/PlansType';
import { withPlural } from 'utils/helpers/string/text';
import { numberFormat } from 'utils/helpers/string/numberFormat';
import { PaymentPeriodEnum } from 'state/payment/paymentTypes';
import { LANGUAGE_RU } from 'constants/localizationsConstants';

type PropsType = Pick<CategoryPlanType, 'plans'> & {
    setPlanId: (planId: number) => void;
    selectedKeyword: TextOptionType;
    paymentPeriod: PaymentPeriodEnum;
    isReviewsAsoDataType: boolean;
    reviewsAso: CategoryPlanType[];
};

export const SelectPlanKeywordsContainer: FC<PropsType> = ({
    plans,
    setPlanId,
    selectedKeyword,
    paymentPeriod,
    isReviewsAsoDataType,
    reviewsAso,
}: PropsType) => {
    const options = plans
        .filter(({ keywords }) => keywords)
        .map(({ id, keywords, pricing, reviewAsoId }) => {
            const currentReviewPlan = reviewsAso.find((categoryPlan) => categoryPlan.id === reviewAsoId);

            const defaultPricing = isReviewsAsoDataType ? currentReviewPlan?.plans[0].pricing : pricing;

            const currentPrice = defaultPricing?.find(({ id }) => id === paymentPeriod);

            return {
                id,
                value: `${numberFormat({ number: keywords, locale: LANGUAGE_RU })} ${withPlural('keyword', keywords)}`,
                meta: <span className="text-12 text-gray-400 nowrap ml-4">${currentPrice?.price} /mo</span>,
            };
        });

    const onChangeSelect = (selectedValue: ValueType<TextOptionType>) => {
        if (isSelectValue(selectedValue)) {
            setPlanId(selectedValue.id);
        }
    };

    useEffect(() => {
        setPlanId(selectedKeyword.id);
    }, []);

    return (
        <Select<TextOptionType>
            components={{
                Option: TextOption,
                SingleValue: TextSingleValue,
            }}
            onChangeSelect={onChangeSelect}
            isSearchable={false}
            defaultValue={selectedKeyword}
            options={options}
            isBorder={false}
            className="shadow-8-1 rounded-4"
        />
    );
};
