import { NormalizedObjectType } from 'types/normalizedTypes';
import { ReviewTagsTypeEnum } from './actionsTypes';
import { ReviewTagsActionType } from './actions';
import { ReviewTagNormalizeCategoryType, ReviewTagType } from './reviewTagStructureType';

export type ReviewTagsStateType = {
    tags: NormalizedObjectType<ReviewTagType>;
    categories: NormalizedObjectType<ReviewTagNormalizeCategoryType>;
    categoryIds: number[];
    tagsWithoutCategoriesIds: number[];
    isLoaded: boolean;
};

const initialState: ReviewTagsStateType = {
    categories: {},
    tags: {},
    categoryIds: [],
    tagsWithoutCategoriesIds: [],
    isLoaded: false,
};

const reviewTags = (state = initialState, action: ReviewTagsActionType) => {
    switch (action.type) {
        case ReviewTagsTypeEnum.SET_REVIEW_TAGS: {
            return {
                ...state,
                categories: action.structure.entities.hasOwnProperty('categories') ? action.structure.entities.categories : {},
                tags: action.structure.entities.hasOwnProperty('tags') ? action.structure.entities.tags : {},
                categoryIds: action.structure.result.categories,
                tagsWithoutCategoriesIds: action.structure.result.tagsWithoutCategories,
                isLoaded: true,
            };
        }
        case ReviewTagsTypeEnum.ADD_REVIEW_TAGS_CATEGORY: {
            const { category } = action;
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [category.id]: category,
                },
                categoryIds: [...state.categoryIds, category.id],
            };
        }
        case ReviewTagsTypeEnum.REMOVE_REVIEW_TAGS_CATEGORY: {
            const { categoryId } = action;
            const { [categoryId]: deletedCategory, ...restCategories } = state.categories;
            return {
                ...state,
                categories: restCategories,
                categoryIds: state.categoryIds.filter((id) => categoryId !== id),
                tagsWithoutCategoriesIds: [...state.tagsWithoutCategoriesIds, ...deletedCategory.tags],
            };
        }
        case ReviewTagsTypeEnum.ADD_REVIEW_TAG: {
            const { tag } = action;

            return {
                ...state,
                tags: {
                    ...state.tags,
                    [tag.id]: tag,
                },
                categories: tag.category
                    ? {
                          ...state.categories,
                          [tag.category]: {
                              ...state.categories[tag.category],
                              tags: [...state.categories[tag.category].tags, tag.id],
                          },
                      }
                    : state.categories,
                tagsWithoutCategoriesIds: tag.category
                    ? state.tagsWithoutCategoriesIds
                    : [...state.tagsWithoutCategoriesIds, tag.id],
            };
        }
        case ReviewTagsTypeEnum.REMOVE_REVIEW_TAG: {
            const { tagId, categoryId } = action;
            const { [tagId]: deletedTag, ...restTags } = state.tags;
            return {
                ...state,
                tags: restTags,
                categories: categoryId
                    ? {
                          ...state.categories,
                          [categoryId]: {
                              ...state.categories[categoryId],
                              tags: state.categories[categoryId].tags.filter((id) => id !== tagId),
                          },
                      }
                    : state.categories,
                tagsWithoutCategoriesIds: categoryId
                    ? state.tagsWithoutCategoriesIds
                    : state.tagsWithoutCategoriesIds.filter((id) => id !== tagId),
            };
        }
        case ReviewTagsTypeEnum.UPDATE_REVIEW_TAG_CATEGORIES: {
            const { categories } = action;
            return {
                ...state,
                categories: {
                    ...state.categories,
                    ...categories,
                },
            };
        }
        case ReviewTagsTypeEnum.UPDATE_REVIEW_TAG: {
            const updatedTagId = action.tag.id;
            const newTagCategoryId = action.tag.category; // null
            const oldTagIdCategoryId = state.tags[updatedTagId].category; // null

            const isUpdatedTagCategory = newTagCategoryId !== oldTagIdCategoryId;

            const { tag } = action;

            const oldTagCategory = oldTagIdCategoryId
                ? {
                      [oldTagIdCategoryId]: {
                          ...state.categories[oldTagIdCategoryId],
                          tags: state.categories[oldTagIdCategoryId].tags.filter((tagId) => tagId !== updatedTagId),
                      },
                  }
                : {};

            const newTagCategory = newTagCategoryId
                ? {
                      [newTagCategoryId]: {
                          ...state.categories[newTagCategoryId],
                          tags: [...state.categories[newTagCategoryId].tags, updatedTagId],
                      },
                  }
                : {};

            let updatedTagsWithoutCategoriesIds = state.tagsWithoutCategoriesIds;

            if (newTagCategoryId || oldTagIdCategoryId) {
                updatedTagsWithoutCategoriesIds = !newTagCategoryId
                    ? [...state.tagsWithoutCategoriesIds, updatedTagId]
                    : state.tagsWithoutCategoriesIds.filter((tagId) => tagId !== updatedTagId);
            }

            return {
                ...state,
                categories: {
                    ...state.categories,
                    ...(isUpdatedTagCategory
                        ? {
                              ...newTagCategory,
                              ...oldTagCategory,
                          }
                        : {}),
                },
                tags: {
                    ...state.tags,
                    [tag.id]: tag,
                },
                tagsWithoutCategoriesIds: updatedTagsWithoutCategoriesIds,
            };
        }
        default: {
            return state;
        }
    }
};

export default reviewTags;
