import React, { FC, memo, ReactNode } from 'react';
import classNames from 'classnames';

export type PropsType = {
    price: ReactNode;
    oldPrice?: string;
    period?: ReactNode;
    className?: string;
    classNameMeta?: string;
};

const PlanPriceComponent: FC<PropsType> = ({ price, oldPrice, period, className, classNameMeta }: PropsType) => (
    <div className={classNames('flex items-start text-gray-600 text-40', className)}>
        <div>{price}</div>
        <div className={classNames('ml-8 text-16 text-opacity-70 font-semibold', classNameMeta)}>
            {oldPrice && <div className="line-through font-normal">{oldPrice}</div>}
            {period && <div className="nowrap">/{period}</div>}
        </div>
    </div>
);

export const PlanPrice = memo(PlanPriceComponent);
