import { OptimizerActionType } from './actions';
import { OptimizerTypeListEnum } from './actionsTypes';
import {OptimizerMetaStateType, OptimizerStateType, ReportType} from './optimizerTypes';
import { StateOptimizerAdapter } from './stateOptimizerAdapter';
import { MetaInitialState, OptimizerInitialState, UnusedReportInitialState, UsedReportInitialState } from './initialState';

const optimizer = (state = OptimizerInitialState, action: OptimizerActionType): OptimizerStateType => {
    switch (action.type) {
        case OptimizerTypeListEnum.GET_SET_TAGS_OPTIMIZER: {
            const { data, isAppStore, limits } = action;
            const initialSetMeta = StateOptimizerAdapter.getInitialMeta(
                data.initialSet,
                isAppStore,
                data.stopWords,
                data.localesInfo,
                limits
            );
            const toLowerCaseStopWords = data.stopWords.map((stopWord) => stopWord.toLowerCase());
            return {
                ...state,
                ...data,
                stopWords: toLowerCaseStopWords,
                initialSet: {
                    meta: initialSetMeta,
                },
                currentSet: {
                    meta: initialSetMeta,
                },
            };
        }
        case OptimizerTypeListEnum.GET_TABS_OPTIMIZER: {
            return {
                ...state,
                tabs: action.tabs,
            };
        }
        case OptimizerTypeListEnum.UPDATE_TAGS_OPTIMIZER: {
            const { newInput, language, isAppStore, hasSameTags } = action;
            return {
                ...state,
                currentSet: {
                    ...state.currentSet,
                    meta: StateOptimizerAdapter.updateMeta(
                        state.currentSet.meta,
                        newInput,
                        language,
                        hasSameTags,
                        isAppStore ? state.stopWords : null
                    ),
                },
            };
        }
        case OptimizerTypeListEnum.TOGGLE_VISIBLE_ADD_LANGUAGE_OPTIMIZER: {
            return {
                ...state,
                currentSet: {
                    ...state.currentSet,
                    meta: state.currentSet.meta.map((item: OptimizerMetaStateType) => {
                        if (item.lang === action.language) {
                            return {
                                ...item,
                                isShow: action.isShow,
                                inputs: action.isShow
                                    ? StateOptimizerAdapter.getInitialInputs(MetaInitialState, true, action.limits)
                                    : item.inputs,
                            };
                        }
                        return item;
                    }),
                },
            };
        }
        case OptimizerTypeListEnum.SET_KEYWORDS_TO_STOP_WORDS: {
            return {
                ...state,
                stopWords: action.keywordList,
            };
        }
        case OptimizerTypeListEnum.SAVE_KEYWORDS_TO_STOP_WORDS: {
            return {
                ...state,
                stopWords: action.keywordList,
                currentSet: {
                    ...state.currentSet,
                    meta: StateOptimizerAdapter.updateMetaCounts(state.currentSet.meta, action.keywordList),
                },
            };
        }
        case OptimizerTypeListEnum.OPEN_SET_OPTIMIZER: {
            const { isAppStore, setId } = action;
            const newSet = state.sets.find((set) => set.id === setId);
            const currentMetaSet = newSet
                ? StateOptimizerAdapter.getCurrentMeta(newSet.meta, state.initialSet.meta, isAppStore, state.stopWords)
                : [];
            return {
                ...state,
                currentSet: {
                    ...newSet,
                    meta: currentMetaSet,
                },
            };
        }
        case OptimizerTypeListEnum.REMOVE_SET_OPTIMIZER: {
            return {
                ...state,
                sets: state.sets.filter((set) => set.id !== action.setId),
                currentSet: state.currentSet.id === action.setId ? state.initialSet : state.currentSet,
            };
        }
        case OptimizerTypeListEnum.UPDATE_SET_OPTIMIZER: {
            return {
                ...state,
                sets: state.sets.map((set) => {
                    return set.id === action.set.id ? action.set : set;
                }),
            };
        }
        case OptimizerTypeListEnum.SAVE_SET_OPTIMIZER: {
            return {
                ...state,
                sets: [...state.sets, action.set],
            };
        }
        case OptimizerTypeListEnum.SET_OPTIMIZER_REPORT_ACTIVE_TAB_ID: {
            const updateActiveTab = (report: ReportType) => ({
                ...report,
                activeTabId: action.tabReportId,
            });
            const updateReport = action.isUsedReport
                ? {
                      used: updateActiveTab(state.reports.used),
                  }
                : {
                      unused: updateActiveTab(state.reports.unused),
                  };
            return {
                ...state,
                reports: {
                    ...state.reports,
                    ...updateReport,
                },
            };
        }
        case OptimizerTypeListEnum.SET_OPTIMIZER_META: {
            return {
                ...state,
                metaReport: action.metaReport,
            };
        }
        case OptimizerTypeListEnum.SET_OPTIMIZER_DATA_REPORT: {
            const updateTabs = (report: ReportType) => ({
                ...report,
                tabs: {
                    ...report.tabs,
                    [action.tabReportId]: {
                        keywords: action.keywords,
                        emptyTableText: action.emptyTableText,
                    },
                },
            });
            const updateReport = action.isUsedReport
                ? {
                      used: updateTabs(state.reports.used),
                  }
                : {
                      unused: updateTabs(state.reports.unused),
                  };
            return {
                ...state,
                reports: {
                    ...state.reports,
                    ...updateReport,
                },
            };
        }
        case OptimizerTypeListEnum.SET_OPTIMIZER_SUMMARY_REPORT: {
            return {
                ...state,
                summaryReport: action.summaryReport,
            };
        }
        case OptimizerTypeListEnum.RESET_OPTIMIZER_DATA_REPORT: {
            return {
                ...state,
                summaryReport: {},
                reports: {
                    used: UsedReportInitialState,
                    unused: UnusedReportInitialState,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default optimizer;
