import { ReviewsAnalysisReportResponseType, ReviewsAnalysisStatsResponseType } from './analysisResponseTypes';

export type ReviewsAnalysisItemReportType = Omit<ReviewsAnalysisReportResponseType, 'stats_count' | 'total_percent' | 'stats'> & {
    statsCount: number;
    totalPercent: number;
    country: string;
    average: number;
    ratings: number[];
    displayedAllTime?: number;
};

export type ReviewsAnalysisReportType = ReviewsAnalysisItemReportType[];

export type ChartDataType = {
    date: string;
    data?: {
        value: string;
        stats: ReviewsAnalysisStatsResponseType;
    }[];
    cumulativeData?: ReviewsAnalysisStatsResponseType;
}[];

export type ReviewsAnalysisInitialStateType = {
    filters: {
        languages: string[];
        countries: string[];
    };
    filtersChart: {
        dataChartType: DataChartTypeEnum;
        modeChartType: ModeChartTypeEnum;
        dateChartType: DateChartTypeEnum;
        lineChartType: LineChartTypeEnum;
    };
    breakDownType: BreakDownTypeEnum;
};

export type ReviewsAnalysisDataType = {
    chart: ChartDataType;
    summary: ReviewsAnalysisStatsResponseType;
    report: ReviewsAnalysisReportType;
};

export type ChartFilterType = DataChartTypeEnum | ModeChartTypeEnum | DateChartTypeEnum | LineChartTypeEnum;

export enum BreakDownTypeEnum {
    COUNTRY = 'country',
    VERSION = 'version',
    LANGUAGE = 'language',
}

export enum ChartFilterTypeEnum {
    DATA_CHART = 'dataChartType',
    MODE_CHART = 'modeChartType',
    DATE_CHART = 'dateChartType',
    LINE_CHART = 'lineChartType',
}

export enum DataChartTypeEnum {
    DEFAULT = 'default',
    CUMULATIVE = 'cumulative',
}

export enum LineChartTypeEnum {
    LINEAR = 'linear',
    LOGARITHMIC = 'logarithmic',
}

export enum ModeChartTypeEnum {
    DEFAULT = 'default',
    RELATIVE = 'relative',
}

export enum DateChartTypeEnum {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

export enum ReviewsAnalysisStatsEnum {
    TOTAL = 'total',
    STAR_5 = '5star',
    STAR_4 = '4star',
    STAR_3 = '3star',
    STAR_2 = '2star',
    STAR_1 = '1star',
    AVERAGE = 'average',
    DISPLAYED_ALL_TIME = 'displayed_all_time',
}
