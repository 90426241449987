import { RequestInfoActionType } from 'state/requestInfo/actions';
import { RequestInfoActionEnum } from 'state/requestInfo/actionTypes';
import { RequestInfoStateType } from './RequestInfoStateType';

const InitialState: RequestInfoStateType = {
    availableFeatures: {},
    permissions: {
        canManageApplications: false,
        canManageCampaigns: false,
        canManageCompetitors: false,
        canManageKeywords: false,
        canManageSubscriptions: false,
        canManageTeammates: false,
        canUseApi: false,
        canManageReviews: false,
        canManageStoreConsole: false,
        canManageIntegrations: false,
        canManageAsoDashboard: false,
    },
    isLoading: true,
    userCountry: null,
    isTrial: false,
    welcomeOfferAvailable: false,
};

const requestInfo = (state: RequestInfoStateType = InitialState, action: RequestInfoActionType): RequestInfoStateType => {
    switch (action.type) {
        case RequestInfoActionEnum.SET_REQUEST_INFO_LOADING: {
            return { ...state, isLoading: action.isLoading };
        }
        case RequestInfoActionEnum.SET_REQUEST_INFO: {
            return { ...state, ...action.user, isLoading: false };
        }
        case RequestInfoActionEnum.CLEAN_REQUEST_INFO: {
            return InitialState;
        }
        case RequestInfoActionEnum.SET_AVAILABLE_ACCOUNT: {
            return {
                ...state,
                availableAccount: action.account,
            };
        }
        case RequestInfoActionEnum.SET_VISIBILITY_ONBOARDING: {
            return {
                ...state,
                isShowOnboardingModal: action.isShowOnboardingModal,
            };
        }
        default: {
            return state;
        }
    }
};

export default requestInfo;
