import React, { useEffect, useState, FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useRequestInfo } from 'hooks/state/useRequestInfo';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { useUserLimits } from 'hooks/useUserLimits';
import { useAppSelector } from 'hooks/useAppSystem';

export const CheckUserLimitRoute: FC = ({ children }) => {
    useRequestInfo();

    const { hasLocation } = useUserLimits();

    const { accountPricingUrl } = useRoutesUrl();

    const { isLoaded: isLoadedRequestInfo, isCurrentPlanLimitsExceeded } = useRequestInfoService();

    const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

    const isLogged = useAppSelector((state) => state.user.isLogged);

    useEffect(() => {
        if (hasLocation && isCurrentPlanLimitsExceeded && isLogged) {
            setRedirectUrl(accountPricingUrl);
        }

        return () => {
            setRedirectUrl(undefined);
        };
    }, [isLoadedRequestInfo, isLogged, isCurrentPlanLimitsExceeded, hasLocation]);

    return <>{redirectUrl ? <Navigate to={redirectUrl} replace /> : children}</>;
};
