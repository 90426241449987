import { OptimizerInputNameEnum } from 'constants/optimizerTagsCountants';
import { getTagsFromString, getStringFromTags, decodeHtml } from 'utils/helpers/optimizerTags';
import { SPECIAL_SYMBOLS_REGEXP } from 'constants/regExpConstants';
import { transformedLimits } from 'hooks/useRange';
import {
    LocalesInfoType,
    LocalMetaConvertedType,
    LocalMetaType,
    OptimizerCountTagsType,
    OptimizerInputTagsType,
    OptimizerMetaStateType,
    OptimizerMetaType,
    OptimizerReportResponseType,
    OptimizerSetStateType,
    OptimizerTagsConvertedResponseType,
    OptimizerTagsResponseType,
    SetResponseType,
    OptimizerSummaryResponseType,
    OptimizerKeywordReportType,
    OptimizerSummaryReportType,
} from './optimizerTypes';
import { MetaInitialState } from './initialState';
import { TabType } from '../keywordAnalytics/KeywordAnalyticsDataType';

const getResponseMeta = (meta: LocalMetaType) => {
    return Object.keys(meta).map((lang) => {
        const currentMeta = meta[lang];
        return {
            ...currentMeta,
            lang,
        };
    });
};

const getResponseSet = (set: SetResponseType): OptimizerSetStateType => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id, name, meta, created_at, sqr_used_words_count } = set;
    return {
        id,
        name,
        meta: getResponseMeta(meta),
        dateCreated: created_at,
        searchQueriesCount: sqr_used_words_count,
    };
};

const getLanguageName = (langName: string) => {
    const separator = '(';
    if (!langName.includes(separator)) return langName;
    return langName.split(separator)[0].trim();
};

const convertOptimizerData = (data: OptimizerTagsResponseType): OptimizerTagsConvertedResponseType => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { is_asc_connected, locales_info, stop_words, opt_sets, initial_set_data } = data;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { additional_locales, main_locale } = locales_info;
    const adaptAdditionalLocal =
        additional_locales && additional_locales.length
            ? additional_locales.map((local) => ({ ...local, name: getLanguageName(local.name) }))
            : [];
    return {
        isAscConnected: is_asc_connected,
        localesInfo: {
            main: main_locale && { ...main_locale, name: getLanguageName(locales_info.main_locale.name) },
            additional: adaptAdditionalLocal,
        },
        stopWords: stop_words,
        sets: opt_sets.length ? opt_sets.map((set) => getResponseSet(set)) : [],
        initialSet: initial_set_data,
    };
};

const convertOptimizerReportData = (data: OptimizerReportResponseType): OptimizerKeywordReportType[] => {
    return Object.keys(data).reduce((acc: OptimizerKeywordReportType[], lang: string) => {
        if (!data[lang].length) return acc;
        const currentData = data[lang].map(
            ({
                keyword,
                id,
                popularity,
                avg_popularity,
                traffic_score,
                avg_traffic_score,
                translation,
                translation_from,
                total_apps,
                rank,
                ipad_rank,
                metadata,
                count,
                has_rank,
                has_ipad_rank,
            }) => ({
                lang,
                keyword,
                id,
                popularity,
                avgPopularity: avg_popularity,
                usersPerDay: traffic_score,
                avgUsersRerDay: avg_traffic_score,
                translation,
                translationFrom: translation_from,
                totalApps: total_apps,
                rank,
                ipadRank: ipad_rank,
                metaData: metadata,
                count,
                hasRank: has_rank,
                hasIpadRank: has_ipad_rank,
            })
        );
        return [...acc, ...currentData];
    }, []);
};

const convertOptimizerSummaryData = (data: OptimizerSummaryResponseType) => {
    return Object.keys(data).reduce((acc: OptimizerSummaryReportType, report: string) => {
        const currentData = data[report];
        return {
            ...acc,
            [report]: {
                totalSearch: currentData.total_count,
                totalRank: currentData.total_rank,
                totalIpadRank: currentData.total_ipad_rank,
                avgUsersPerDay: currentData.avg_traffic_score,
                avgPopularity: currentData.avg_searchads_popularity,
            },
        };
    }, {});
};

const sortOptimizerTabs = (tabs: TabType[]): TabType[] => {
    const value = 'all';
    return tabs.sort((a, b) => {
        switch (true) {
            case a.name === value:
                return -1;
            case b.name === value:
                return 1;
            default:
                return 0;
        }
    });
};

const getCountsTags = (tags: string[], stopTags: string[] | null): OptimizerCountTagsType => {
    return tags.reduce((acc: OptimizerCountTagsType, tagName: string) => {
        const tagNameToLowerCase = tagName.toLowerCase();
        let counts;
        switch (true) {
            case tagName.length === 1 && tagName.search(SPECIAL_SYMBOLS_REGEXP) !== -1:
                counts = -1;
                break;
            case stopTags && stopTags.includes(tagNameToLowerCase):
                counts = 0;
                break;
            default:
                counts = (acc[tagNameToLowerCase] || 0) + 1;
        }
        return { ...acc, [tagNameToLowerCase]: counts };
    }, {});
};

const getLimit = (isAppStore: boolean, name: OptimizerInputNameEnum, limits: transformedLimits) => {
    const limitApp = isAppStore ? limits?.appStore : limits?.googlePlay;
    return limitApp && limitApp[name];
};

const getInitialInputs = (
    currentMeta: {
        [key: string]: string;
    },
    isAppStore: boolean,
    limits: transformedLimits
) => {
    return Object.keys(currentMeta).map((item) => {
        const meta = decodeHtml(currentMeta[item]);
        const isKeywords = item === OptimizerInputNameEnum.KEYWORDS;
        const isAppStoreKeywords = isAppStore && isKeywords;
        const tags = getTagsFromString(meta, isAppStoreKeywords);
        const stringTags = getStringFromTags(tags, isAppStoreKeywords);
        return {
            name: item as OptimizerInputNameEnum,
            description: meta,
            stringTags,
            initialStringTags: stringTags,
            tags,
            initialTags: tags,
            limit: getLimit(isAppStore, item as OptimizerInputNameEnum, limits),
        };
    });
};

const getInitialMeta = (
    meta: OptimizerMetaType,
    isAppStore: boolean,
    stopWords: string[],
    localesInfo: LocalesInfoType,
    limits: transformedLimits
) => {
    const inputs = getInitialInputs(meta, isAppStore, limits);
    inputs.sort((inputA, inputAB) => inputAB.name.localeCompare(inputA.name));
    const allTags = inputs.map((input) => input.tags).flat();
    const mainMeta = {
        lang: localesInfo.main.lang,
        isShow: true,
        inputs,
        countTags: getCountsTags(allTags, isAppStore ? stopWords : null),
    };
    const addMeta = localesInfo.additional.map((local) => {
        return {
            lang: local.lang,
            isShow: false,
            inputs: getInitialInputs(MetaInitialState, isAppStore, limits),
            countTags: {},
        };
    });
    return [mainMeta, ...addMeta];
};

const getCurrentInputs = (initialInputs: OptimizerInputTagsType[], metaSetItem: LocalMetaConvertedType, isAppStore: boolean) => {
    return initialInputs.map((initialInput) => {
        const { name } = initialInput;
        const tags = metaSetItem[name];
        const stringTags = getStringFromTags(tags, isAppStore && name === OptimizerInputNameEnum.KEYWORDS);
        return {
            ...initialInput,
            name,
            stringTags,
            tags: tags.filter((item) => item !== ''),
        };
    });
};

const getCurrentMeta = (
    metaSet: LocalMetaConvertedType[],
    initialMeta: OptimizerMetaStateType[],
    isAppStore: boolean,
    stopWords: string[]
) => {
    return initialMeta.map((initialMetaItem) => {
        const { lang } = initialMetaItem;
        const localMeta = metaSet.find((item) => item.lang === lang);
        if (!localMeta) return initialMetaItem;
        const inputs = getCurrentInputs(initialMetaItem.inputs, localMeta, isAppStore);
        const allTags = inputs.map((input) => input.tags).flat();
        return {
            lang,
            isShow: true,
            inputs,
            countTags: getCountsTags(allTags, isAppStore ? stopWords : null),
        };
    });
};

const updateMeta = (
    meta: OptimizerMetaStateType[],
    newInput: OptimizerInputTagsType,
    language: string,
    hasSameTags: boolean,
    stopWords: string[] | null
) => {
    return meta.map((item: OptimizerMetaStateType) => {
        if (item.lang === language) {
            const newInputs = item.inputs.map((input) => (input.name === newInput.name ? newInput : input));
            const allTags = newInputs.map((input) => input.tags).flat();
            return {
                ...item,
                isShow: true,
                inputs: newInputs,
                countTags: hasSameTags ? item.countTags : getCountsTags(allTags, stopWords),
            };
        }
        return item;
    });
};

const updateMetaCounts = (meta: OptimizerMetaStateType[], stopWords: string[]) => {
    return meta.map((item: OptimizerMetaStateType) => {
        const allTags = item.inputs.map((input) => input.tags).flat();
        return {
            ...item,
            countTags: getCountsTags(allTags, stopWords),
        };
    });
};

const getMetaTags = ({ metaTags, hasKeywordsName }: { metaTags: OptimizerMetaStateType[]; hasKeywordsName: boolean }) => {
    const getKey = (name: string) => {
        switch (true) {
            case hasKeywordsName && OptimizerInputNameEnum.KEYWORDS === name:
                return 'desc_keywords';
            case hasKeywordsName:
                return `${name}_keywords`;
            default:
                return name;
        }
    };
    return metaTags.map((meta) => {
        const newInputs = meta.inputs.reduce((result, input) => {
            const currentTags = input.tags.length ? input.tags : [''];
            return {
                ...result,
                [getKey(input.name)]: currentTags,
            };
        }, {});
        return {
            lang: meta.lang,
            ...newInputs,
        };
    });
};

const getCurrentInput = (meta: OptimizerMetaStateType[], language: string, inputName: OptimizerInputNameEnum) => {
    const metaLang = meta.find((item: OptimizerMetaStateType) => item.lang === language);
    return metaLang && metaLang.inputs.find((item: OptimizerInputTagsType) => item.name === inputName);
};

const getNewInput = (
    keywords: string,
    inputName: OptimizerInputNameEnum,
    currentInput: OptimizerInputTagsType,
    isAppStore: boolean
) => {
    const isAppStoreKeywords = isAppStore && OptimizerInputNameEnum.KEYWORDS === inputName;
    const currentTags = getTagsFromString(keywords, isAppStoreKeywords);
    const newTags = [...currentInput.tags, ...currentTags];
    const newStringTags = getStringFromTags(newTags, isAppStoreKeywords);
    return { ...currentInput, tags: newTags, stringTags: newStringTags };
};

export const StateOptimizerAdapter = {
    getInitialInputs,
    convertOptimizerData,
    getResponseMeta,
    getResponseSet,
    getCountsTags,
    getInitialMeta,
    getCurrentMeta,
    updateMeta,
    updateMetaCounts,
    getMetaTags,
    sortOptimizerTabs,
    convertOptimizerReportData,
    convertOptimizerSummaryData,
    getCurrentInput,
    getNewInput,
};
