export const CONTEXT_MENU_MOBILE_NAME_APP_ACTIONS = 'context-menu-app-actions';
export const CONTEXT_MENU_KEYWORD_ANALYTICS_TABLE_ACTIONS = 'context-menu-keyword-analytics-table-actions';
export const CONTEXT_MENU_KEYWORD_ANALYTICS_ROW_ACTIONS = 'context-menu-keyword-analytics-row-actions';

export const CONTEXT_MENU_COMPETITOR_ACTIONS = 'context-menu-competitor-actions';

export const PROFILE_ACTIONS_CONTEXT_MENU = 'profile-actions-context-menu';
export const PLAN_ACTIONS_CONTEXT_MENU = 'plan-actions-context-menu';

export const HEADER_MENU_ACTIONS_CONTEXT_MENU = 'header-menu-actions-context-menu';
