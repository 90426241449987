import {
    HelpdeskIntegrationAppType,
    HelpdeskIntegrationListType,
    HelpdeskTypeIntegrationEnum,
    InitialCreateIntegrationType,
    SelectedHelpdeskIntegrationAppsType,
} from 'state/integrationsHelpdesk/helpdeskIntegrationType';
import { HelpdeskSettingsStepEnum } from 'state/integrationsHelpdesk/helpdeskSettingsStepEnum';
import { ReviewChangeRequestEnum } from 'state/integrationsHelpdesk/reviewChangeRequestEnum';
import { ImportHistoryRequestEnum } from 'state/integrationsHelpdesk/importHistoryRequestEnum';
import { MaxReviewLengthRequestEnum } from 'state/integrationsHelpdesk/maxReviewLengthRequestEnum';
import { DEFAULT_COUNTRIES, DEFAULT_LANGUAGES } from 'constants/localizationsConstants';
import {
    getInitialIntegrationByTypeDesk,
    initialCreateIntegrationState,
} from 'state/integrationsHelpdesk/initialCreateIntegrationAdapter';
import { AuthIntegrationStatusEnum } from 'state/integrations/IntegrationsResponseType';
import { IntegrationsHelpdeskActionTypeEnum } from './actionsTypes';
import { HelpdeskIntegrationsActionType } from './actions';

export type HelpdeskIntegrationsStateType = {
    [key in HelpdeskTypeIntegrationEnum]: InitialCreateIntegrationType & {
        integrations: HelpdeskIntegrationListType;
    };
};

const initialState: HelpdeskIntegrationsStateType = {
    [HelpdeskTypeIntegrationEnum.ZENDESK]: {
        integrations: [],
        ...getInitialIntegrationByTypeDesk(HelpdeskTypeIntegrationEnum.ZENDESK),
    },
    [HelpdeskTypeIntegrationEnum.OMNIDESK]: {
        integrations: [],
        ...getInitialIntegrationByTypeDesk(HelpdeskTypeIntegrationEnum.OMNIDESK),
    },
    [HelpdeskTypeIntegrationEnum.USEDESK]: {
        integrations: [],
        ...getInitialIntegrationByTypeDesk(HelpdeskTypeIntegrationEnum.USEDESK),
    },
};

export const defaultAppSettings: Omit<HelpdeskIntegrationAppType, 'storeId' | 'countries' | 'storeType'> = {
    byChanges: ReviewChangeRequestEnum.ALL,
    rating: [1, 2, 3, 4, 5],
    contentLength: MaxReviewLengthRequestEnum.ALL,
    preLoadCount: ImportHistoryRequestEnum.NO_IMPORT,
    attachInfoAsTags: true,
    settings: -1,
};

const helpdeskIntegrations = (state = initialState, action: HelpdeskIntegrationsActionType): HelpdeskIntegrationsStateType => {
    switch (action.type) {
        case IntegrationsHelpdeskActionTypeEnum.SET_HELPDESK_INTEGRATIONS: {
            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    integrations: action.integrations,
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.ADD_HELPDESK_INTEGRATION: {
            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    integrations: [...state[action.integrationType].integrations, action.integration],
                    selectedIntegration: action.integration,
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.UPDATE_HELPDESK_INTEGRATION: {
            const integrationIndex = state[action.integrationType].integrations.findIndex(
                ({ id }) => id === action.integration.id
            );

            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    integrations: [
                        ...state[action.integrationType].integrations.slice(0, integrationIndex),
                        action.integration,
                        ...state[action.integrationType].integrations.slice(integrationIndex + 1),
                    ],
                    selectedIntegration: action.integration,
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.REMOVE_HELPDESK_INTEGRATION: {
            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    integrations: state[action.integrationType].integrations.filter(({ id }) => id !== action.integrationId),
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.ADD_HELPDESK_INTEGRATION_APP: {
            const integrationIndex = state[action.integrationType].integrations.findIndex(
                ({ id }) => id === action.integrationId
            );
            const integrations = [
                ...state[action.integrationType].integrations.slice(0, integrationIndex),
                {
                    ...state[action.integrationType].integrations[integrationIndex],
                    applications: [...state[action.integrationType].integrations[integrationIndex].applications, action.app],
                },
                ...state[action.integrationType].integrations.slice(integrationIndex + 1),
            ];

            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    integrations,
                    selectedIntegration: integrations[integrationIndex],
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.REMOVE_HELPDESK_INTEGRATION_APP: {
            const integrationIndex = state[action.integrationType].integrations.findIndex(
                ({ id }) => id === action.integrationId
            );
            const updatedApplications = state[action.integrationType].integrations[integrationIndex].applications.filter(
                (app) => app.storeId !== action.storeId
            );
            const integrations = [
                ...state[action.integrationType].integrations.slice(0, integrationIndex),
                {
                    ...state[action.integrationType].integrations[integrationIndex],
                    applications: updatedApplications,
                },
                ...state[action.integrationType].integrations.slice(integrationIndex + 1),
            ];

            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    integrations,
                    selectedIntegration: integrations[integrationIndex],
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.UPDATE_HELPDESK_INTEGRATION_APP: {
            const integrationIndex = state[action.integrationType].integrations.findIndex(
                ({ id }) => id === action.integrationId
            );

            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    integrations: [
                        ...state[action.integrationType].integrations.slice(0, integrationIndex),
                        {
                            ...state[action.integrationType].integrations[integrationIndex],
                            applications: state[action.integrationType].integrations[integrationIndex].applications.map(
                                (application) => {
                                    return application.storeId === action.storeId ? action.app : application;
                                }
                            ),
                        },
                        ...state[action.integrationType].integrations.slice(integrationIndex + 1),
                    ],
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.SELECT_HELPDESK_SETTINGS_APPS: {
            const selectedApps = action.apps.reduce<SelectedHelpdeskIntegrationAppsType>(
                (appList, application) => ({ ...appList, [application.storeId]: application }),
                {}
            );

            const selectedAppList = Object.values(selectedApps);
            const notUpdatedAppIndex = selectedAppList.findIndex(({ wasUpdated }) => !wasUpdated);

            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    selectedApps,
                    currentApp: Object.values(selectedApps)[notUpdatedAppIndex],
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.SET_HELPDESK_SETTINGS_CURRENT_APP: {
            const currentSelectedApp = state[action.integrationType].selectedApps[action.storeId];

            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    currentApp: {
                        ...currentSelectedApp,
                        ...(action.isSelectedAllApps
                            ? {
                                  countries: state[action.integrationType].currentApp?.countries || currentSelectedApp.countries,
                                  languages: state[action.integrationType].currentApp?.languages || currentSelectedApp.languages,
                              }
                            : {}),
                    },
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.SET_HELPDESK_SETTINGS_STEP: {
            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    step: action.step,
                    lastAchievedStep:
                        state[action.integrationType].lastAchievedStep < action.step
                            ? action.step
                            : state[action.integrationType].lastAchievedStep,
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.UPDATE_SELECTED_HELPDESK_APP: {
            const { countries, languages, ...appProps } = action.app;

            const currentApp = state[action.integrationType].selectedApps[action.storeId];

            const updatedCountries = countries || state[action.integrationType]?.currentApp?.countries;
            const updatedLanguages = languages || state[action.integrationType]?.currentApp?.languages;

            const updatedApp = {
                ...currentApp,
                ...appProps,
                ...(currentApp.storeType === 'appstore' ? { countries: updatedCountries } : { languages: updatedLanguages }),
                wasUpdated: true,
            };

            const updatedSelectedApps = {
                ...state[action.integrationType].selectedApps,
                [action.storeId]: updatedApp,
            };

            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    selectedApps: updatedSelectedApps,
                    currentApp: {
                        ...updatedApp,
                        // countries and languages at the same time can only be in currentApp
                        countries: updatedCountries,
                        languages: updatedLanguages,
                    },
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.SET_HELPDESK_GROUPS: {
            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    groups: action.groups,
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.ADD_HELPDESK_GROUP: {
            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    groups: [...state[action.integrationType].groups, action.group],
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.CLEAN_HELPDESK_INTEGRATION_MODAL_DATA: {
            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    ...initialCreateIntegrationState,
                    lastAchievedStep: initialCreateIntegrationState.step,
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.SET_ACTIVE_HELPDESK_INTEGRATION: {
            const selectedApps = action.integration.applications.reduce<SelectedHelpdeskIntegrationAppsType>(
                (appList, application) => ({ ...appList, [application.storeId]: application }),
                {}
            );

            const isAuthorized = action.integration.authIntegrationStatus === AuthIntegrationStatusEnum.AUTHORIZED;

            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    step: HelpdeskSettingsStepEnum.ADD_APP,
                    lastAchievedStep: isAuthorized
                        ? HelpdeskSettingsStepEnum.FINALIZE_SETTING
                        : HelpdeskSettingsStepEnum.INTEGRATE_HELPDESK,
                    selectedApps,
                    currentApp: Object.values(selectedApps)[0],
                    selectedIntegration: action.integration,
                    groups: [],
                },
            };
        }
        case IntegrationsHelpdeskActionTypeEnum.UPDATE_HELPDESK_ALL_SELECTED_APPS: {
            const { currentApp, selectedApps } = state[action.integrationType];

            let updatedSelectedApps = selectedApps;

            if (currentApp) {
                const { byChanges, preLoadCount, rating, countries, languages, contentLength, attachInfoAsTags } = currentApp;

                const appIds = Object.keys(selectedApps);

                updatedSelectedApps = appIds.reduce((result, appId) => {
                    const app = selectedApps[appId];

                    return {
                        ...result,
                        [appId]: {
                            ...app,
                            byChanges,
                            preLoadCount,
                            rating,
                            ...(app.storeType === 'appstore'
                                ? { countries: countries || DEFAULT_COUNTRIES }
                                : { languages: languages || DEFAULT_LANGUAGES }),
                            contentLength,
                            attachInfoAsTags,
                        },
                    };
                }, {});
            }

            return {
                ...state,
                [action.integrationType]: {
                    ...state[action.integrationType],
                    selectedApps: updatedSelectedApps,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default helpdeskIntegrations;
