import { useEffect, useState } from 'react';

const SCREEN_MOBILE_WIDTH = 960;

const onResetPlatformStyles = () => {
    document.body.classList.remove('asodesk-desktop');
    document.body.classList.remove('asodesk-mobile');
};

export const getInitialIsMobile = () => window.innerWidth <= SCREEN_MOBILE_WIDTH;

export const useCheckDeviceType = () => {
    const [isMobile, setIsMobile] = useState(getInitialIsMobile());

    const onWindowResize = () => {
        onResetPlatformStyles();

        if (getInitialIsMobile()) {
            document.body.classList.add('asodesk-mobile');
            setIsMobile(true);
        } else {
            document.body.classList.add('asodesk-desktop');
            setIsMobile(false);
        }
    };

    useEffect(() => {
        onWindowResize();

        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);

    return {
        isMobile,
    };
};
