export enum IntegrationsHelpdeskActionTypeEnum {
    SET_HELPDESK_INTEGRATIONS = 'SET_HELPDESK_INTEGRATIONS',
    ADD_HELPDESK_INTEGRATION = 'ADD_HELPDESK_INTEGRATION',
    REMOVE_HELPDESK_INTEGRATION = 'REMOVE_HELPDESK_INTEGRATION',
    ADD_HELPDESK_INTEGRATION_APP = 'ADD_HELPDESK_INTEGRATION_APP',
    REMOVE_HELPDESK_INTEGRATION_APP = 'REMOVE_HELPDESK_INTEGRATION_APP',
    UPDATE_HELPDESK_INTEGRATION_APP = 'UPDATE_HELPDESK_INTEGRATION_APP',
    SELECT_HELPDESK_SETTINGS_APPS = 'SELECT_HELPDESK_SETTINGS_APPS',
    UPDATE_SELECTED_HELPDESK_APP = 'UPDATE_SELECTED_HELPDESK_APP',
    SET_HELPDESK_SETTINGS_STEP = 'SET_HELPDESK_SETTINGS_STEP',
    SET_HELPDESK_SETTINGS_CURRENT_APP = 'SET_HELPDESK_SETTINGS_CURRENT_APP',
    SET_HELPDESK_GROUPS = 'SET_HELPDESK_GROUPS',
    ADD_HELPDESK_GROUP = 'ADD_HELPDESK_GROUP',
    CLEAN_HELPDESK_INTEGRATION_MODAL_DATA = 'CLEAN_HELPDESK_INTEGRATION_MODAL_DATA',
    SET_ACTIVE_HELPDESK_INTEGRATION = 'SET_ACTIVE_HELPDESK_INTEGRATION',
    UPDATE_HELPDESK_INTEGRATION = 'UPDATE_HELPDESK_INTEGRATION',
    UPDATE_HELPDESK_ALL_SELECTED_APPS = 'UPDATE_HELPDESK_ALL_SELECTED_APPS',
}
