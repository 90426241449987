import React, { Suspense } from 'react';
import { useTogglePopup } from 'hooks/state/usePopup';
import { MODAL_NAME_REMOVE_APP } from 'constants/modalNameConstants';
import { AskRemoveAppModalPayloadDataType } from 'modules/dashboard/askRemoveAppModal/AskRemoveAppModalPayloadDataType';
import { AsyncAskRemoveAppModalContainer } from 'modules/dashboard/askRemoveAppModal/AsyncAskRemoveAppModalContainer';
import { useSystem } from 'hooks/state/useSystem';

const AskRemoveAppModalWrapper: React.FC = () => {
    const { locale } = useSystem();
    const { isOpen, data: appData } = useTogglePopup<AskRemoveAppModalPayloadDataType>(MODAL_NAME_REMOVE_APP);

    return isOpen && appData && !!locale ? (
        <Suspense fallback="">
            <AsyncAskRemoveAppModalContainer appLogoUrl={appData.logo} appName={appData.name} />
        </Suspense>
    ) : null;
};

export const AskRemoveAppModalWrapperContainer = React.memo(AskRemoveAppModalWrapper);
