import './buttonElement.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { PermissionIcon } from 'components/icons/permissionIcon/permissionIcon';
import { AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import { InlineLoader, InlineLoaderSizeEnum } from 'components/loader/inlineLoader/InlineLoader';
import { Icons } from 'types/icon-types';
import { Icon } from 'newComponents/icons/asodeskIcons/Icon';
import { ButtonSizeEnum, ButtonStateEnum, ButtonTypeEnum } from './buttonEnum';

type ButtonContentType = {
    fontIconName?: Icons;
    iconName?: string;
    iconClassName?: string;
    icon?: {
        src: string;
        retinaSrc?: string;
    };
    text?: React.ReactNode;
    loading?: boolean;
    feature?: keyof AvailableFeaturesType;
    type?: ButtonTypeEnum;
    state?: ButtonStateEnum;
    size?: ButtonSizeEnum;
    textClassName?: string;
    fontIconSize?: any;
};

export type ButtonElementType = React.HTMLAttributes<HTMLButtonElement> &
    React.HTMLAttributes<HTMLLinkElement> &
    ButtonContentType & {
        clickHandler?: () => void;
        size?: ButtonSizeEnum;
        isBlock?: boolean;
        disabled?: boolean;
        url?: string;
        target?: string;
        noBorder?: boolean;
        isAnimate?: boolean;
        feature?: keyof AvailableFeaturesType;
        tooltipFeature?: keyof AvailableFeaturesType;
        isNarrow?: boolean;
        inBorder?: boolean;
        withoutStopPropagation?: boolean;
        to?: string;
        buttonType?: 'submit';
        isRounded?: boolean;
        isLight?: boolean;
        showDisabledIcon?: boolean;
        className?: string;
    };

const ButtonContent: React.FC<ButtonContentType> = ({
    iconName,
    iconClassName,
    text,
    loading,
    feature,
    type,
    state,
    icon,
    fontIconName,
    size,
    textClassName,
    fontIconSize = 20,
}: ButtonContentType) => {
    const isWhiteStateLoader =
        type === ButtonTypeEnum.SOLID && state !== ButtonStateEnum.DEFAULT && state !== ButtonStateEnum.HIGHLIGHT;
    const isSmallLoader = size && size === ButtonSizeEnum.XS;

    return (
        <>
            {fontIconName && <Icon name={fontIconName} size={fontIconSize} className={classNames('mr-4', iconClassName)} />}
            {iconName && (
                <i
                    className={classNames('buttonElement__icon fa', `fa-${iconName}`, iconClassName, {
                        'buttonElement__icon--indent': text,
                    })}
                    data-testid={`button-icon-${iconName}`}
                />
            )}
            {icon && (
                <img
                    className="buttonElement__imageIcon"
                    src={icon.src}
                    srcSet={icon.retinaSrc && `${icon.retinaSrc} 2x`}
                    alt=""
                />
            )}
            {text && (
                <span className={classNames('inline-flex items-center buttonElement__text justify-center', textClassName)}>
                    {text}
                </span>
            )}
            {loading && (
                <span className="flex items-center relative ml-10 buttonElement__loader" data-testid="button-loading">
                    <InlineLoader
                        isWhite={isWhiteStateLoader}
                        size={isSmallLoader ? InlineLoaderSizeEnum.SM : InlineLoaderSizeEnum.LG}
                    />
                </span>
            )}
            {feature && <PermissionIcon feature={feature} />}
        </>
    );
};

export const ButtonElement: React.FC<ButtonElementType> = ({
    clickHandler,
    state = ButtonStateEnum.PRIMARY,
    size = ButtonSizeEnum.LG,
    type = ButtonTypeEnum.SOLID,
    isBlock,
    iconName,
    icon,
    iconClassName,
    fontIconName,
    text,
    children,
    disabled,
    url,
    target = '_self',
    to,
    noBorder = false,
    loading = false,
    feature,
    isAnimate = false,
    isNarrow = false,
    className,
    showDisabledIcon = false,
    inBorder = false,
    withoutStopPropagation = false,
    buttonType,
    isRounded,
    isLight,
    textClassName,
    ...props
}: ButtonElementType) => {
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!withoutStopPropagation) e.stopPropagation();

        if (disabled || loading) return;

        clickHandler && clickHandler();
    };

    const classes = classNames(
        'buttonElement',
        `buttonElement--${state}`,
        `buttonElement--${size}`,
        `buttonElement--${type}`,
        { 'buttonElement--block': !!isBlock },
        { 'buttonElement--disabled': !!disabled },
        { 'buttonElement--no-border': !!noBorder },
        { 'buttonElement--icon': !!iconName && !text },
        { 'buttonElement--animate': isAnimate },
        { 'buttonElement--narrow': isNarrow },
        { 'buttonElement--inBorder': inBorder },
        { 'rounded-4': isRounded },
        { '!bg-opacity-10': isLight },
        className
    );

    const ButtonContentElement = (
        <ButtonContent
            iconName={iconName}
            iconClassName={iconClassName}
            icon={icon}
            text={children || text}
            loading={loading}
            feature={feature}
            type={type}
            state={state}
            fontIconName={fontIconName}
            size={size}
            textClassName={textClassName}
        />
    );

    if (url) {
        return (
            <a href={url} target={target} onClick={onClick} className={classes}>
                {ButtonContentElement}
            </a>
        );
    }

    if (to) {
        return (
            <Link to={to} onClick={onClick} className={classes}>
                {ButtonContentElement}
            </Link>
        );
    }

    return (
        <button onClick={onClick} className={classes} type={buttonType} {...props} disabled={disabled}>
            {ButtonContentElement}{' '}
            {showDisabledIcon && (
                <Icon name={Icons.LockSolid} data-testid="button-locked" size={20} lineHeight={20} className={iconClassName} />
            )}
        </button>
    );
};
