import { AppStoreType } from 'state/appProfile/AppResponseType';
import { AppToolBarSettingsStateTypeEnum } from './actionsTypes';
import { AppToolBarSettingsActionType } from './actions';

export type AppToolBarSettingsStateType = {
    definedAppStore?: AppStoreType;
};

const systemInitialState: AppToolBarSettingsStateType = {};

const appToolBarSettings = (state = systemInitialState, action: AppToolBarSettingsActionType) => {
    switch (action.type) {
        case AppToolBarSettingsStateTypeEnum.SET_APP_TOOLBAR_APP_STORE: {
            return {
                ...state,
                definedAppStore: action.store,
            };
        }
        default: {
            return state;
        }
    }
};

export default appToolBarSettings;
