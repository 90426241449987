import React from 'react';
import { SingleValueProps } from 'react-select';
import { TextItem } from 'newComponents/listItem/textItem/TextItem';
import classNames from 'classnames';
import { TextOptionType } from 'newComponents/forms/select/customComponents/options/textOption/TextOption';

export const TextSingleValue: React.FC<SingleValueProps<TextOptionType>> = ({
    innerProps,
    data,
    options,
}: SingleValueProps<TextOptionType>) => {
    const isOneValue = options.length === 1;

    return (
        <TextItem
            subText={data.subValue}
            text={data.value}
            className={classNames('pt-0 pb-0 !border-transparent', data.className)}
            itemClassName={classNames({ '!text-gray-600': isOneValue })}
            {...innerProps}
        />
    );
};
