import { CampaignPaymentType } from 'state/campaignPayment/campaignPaymentTypes';
import { CampaignPaymentActionType } from './actions';
import { CampaignPaymentActionTypeListEnum } from './actionsTypes';

export type CampaignPaymentStateType = {
    campaign?: CampaignPaymentType;
};

export const campaignPaymentInitialState = {};

const campaignPayment = (
    state: CampaignPaymentStateType = campaignPaymentInitialState,
    action: CampaignPaymentActionType
): CampaignPaymentStateType => {
    switch (action.type) {
        case CampaignPaymentActionTypeListEnum.SET_CAMPAIGN_DATA: {
            return {
                ...state,
                campaign: action.campaign,
            };
        }
        case CampaignPaymentActionTypeListEnum.UPDATE_CAMPAIGN_PRICE: {
            return {
                ...state,
                ...(state.campaign
                    ? {
                          campaign: {
                              ...state.campaign,
                              ...action.recalculatePrices,
                          },
                      }
                    : {}),
            };
        }
        default: {
            return state;
        }
    }
};

export default campaignPayment;
