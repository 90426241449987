export const FULL_DATE = 'DD.MM.YYYY hh:mm A';
export const FORMAT_DDMMYY = 'DD.MM.YY';
export const FORMAT_DDMMMMYY_HHMM_A = 'DD MMMM, YYYY hh:mm A';
export const MINUTE_FORMAT = 'm:ss';
export const FORMAT_DMY_H_MM = 'DD/MM/YYYY H:mm';

export const dateFormatDMYY = 'DD.MM.YYYY';
export const dateFormatDMY = 'DD/MM/YYYY';
export const dateFormatDDMMMYYYY = 'DD MMM YYYY';
export const dateFormatYYYYMM = 'YYYYMM';
