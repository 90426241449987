import { useLocation } from 'react-router-dom';
import { AvailableFeaturesKeyEnum } from 'state/appProfile/AppProfileResponseType';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { useSystem } from 'hooks/state/useSystem';
import {
    getPartialAsoToolsRouteKeywordChartsUrl,
    getPartialAsoToolsRouteNoLocaleUrl,
    getPartialAsoToolsRouteTrafficSourcesUrl,
    getPartialReviewsRouteRatingAnalysisUrl,
    partialKeywordBoostRouteUrl,
} from 'utils/routeUrls';
import { useAppType } from 'hooks/useAppType';

const routes = [
    getPartialAsoToolsRouteTrafficSourcesUrl(),
    getPartialAsoToolsRouteKeywordChartsUrl(),
    getPartialAsoToolsRouteNoLocaleUrl(),
    getPartialReviewsRouteRatingAnalysisUrl(),
    partialKeywordBoostRouteUrl,
];

export const useRustore = (locale?: string) => {
    const { pathname } = useLocation();

    const { toolName } = useSystem();

    const { isRustoreAppType } = useAppType();

    const { isAvailableFeature } = useRequestInfoService();

    const availableRustoreFeature = isAvailableFeature(AvailableFeaturesKeyEnum.RUSTORE);

    const isNotSupportedRustoreTool = !!(toolName && routes.filter((url) => `/${url}`.includes(`/${toolName}/`)).length);

    const isHiddenTool = isRustoreAppType && isNotSupportedRustoreTool;

    const hasRustoreAlias = pathname.includes('-rs');

    const isNotRuLocale = locale !== 'ru';

    return {
        availableRustoreFeature,
        isHiddenTool,
        isNotSupportedRustoreTool,
        hasRustoreAlias,
        isNotRuLocale,
    };
};
