import { removedDowngradePlanMeta } from 'state/plans/plansDowngradeMeta/controller';
import { FREE_PLAN_ID } from 'state/plans/customEmptyPlan';
import { setGlobalAppLoaderShow, setStatsUserLimitData } from 'state/userFrontendSettings/controller';
import { getTrackedApps } from 'state/trackedApps/controller';
import { getRequestInfo } from 'state/requestInfo/controller';
import { useDispatch } from 'react-redux';
import { useSystem } from 'hooks/state/useSystem';

export const useReloadPlanInfo = () => {
    const dispatch = useDispatch();

    const { locale } = useSystem();

    const reloadPlanInfo = async (hasDowngrade = true) => {
        await dispatch(setGlobalAppLoaderShow(true));
        hasDowngrade && (await dispatch(removedDowngradePlanMeta(FREE_PLAN_ID)));
        await dispatch(setStatsUserLimitData());
        if (locale) {
            await dispatch(getTrackedApps(locale));
        }
        await dispatch(getRequestInfo());
        await dispatch(setGlobalAppLoaderShow(false));
    };

    return { reloadPlanInfo };
};
