import { AppStoreType, AppResponseType } from 'state/appProfile/AppResponseType';

export type AppCategoryType = {
    id: string;
    name: string;
};

export type InAppPaymentsType = {
    id: number;
    name: number;
    price: number;
    pricing_parameters: number;
};

export type PermissionsType = {
    can_manage_applications: boolean;
    can_manage_campaigns: boolean;
    can_manage_competitors: boolean;
    can_manage_keywords: boolean;
    can_manage_subscriptions: boolean;
    can_manage_teammates: boolean;
    can_use_api: boolean;
    can_manage_reviews: boolean;
    can_manage_storeconsole: boolean;
    can_manage_integrations: boolean;
    can_manage_aso_dashboard: boolean;
};

export enum AvailableFeaturesKeyEnum {
    REPLY_BOARD_BULK_ACTIONS = 'reply-board-bulk-actions',
    REVIEW_TRANSLATION = 'review-translation',
    REVIEW_AUTO_TRANSLATION = 'review-auto-translation',
    REPLY_BOARD_REPORT_CONCERN = 'reply-board-report-concern',
    REVIEW_TAGS = 'review-tags',
    REVIEW_TEMPLATES = 'review-templates',
    REPLY_BOARD_REPLY_WITH_TEMPLATE = 'reply-board-reply-with-template',
    REVIEW_FILTERS = 'review-filters',
    REVIEW_PRESETS = 'review-presets',
    RATING_ANALYSIS = 'rating-analysis',
    REVIEW_AUTO_TAGS = 'review-auto-tags',
    ACTIVITY_LOG = 'activity-log',
    REVIEW_AUTO_REPLIES = 'review-auto-replies',
    KEYWORD_BRANDED = 'keyword-branded',
    KM_KEYWORD_SHUFFLER = 'km-keyword-shuffler',
    KA_ADVANCED_FILTERS = 'ka-advanced-filters',
    OMNIDESK_API = 'omnidesk-api',
    USEDESK_API = 'usedesk-api',
    DIFFICULTY_HISTORY = 'difficulty-history',
    KEYWORD_DIFFICULTY = 'keyword-difficulty',
    TRAFFIC_SCORE_HISTORY = 'traffic-score-history',
    SEARCHADS_POPULARITY_HISTORY = 'searchads-popularity-history',
    CUSTOM_REPORTS_APP_OVERVIEW_REPORT = 'custom-reports-app-overview-report',
    CUSTOM_REPORTS_APP_UPDATES_ALERTS = 'custom-reports-app-updates-alert',
    CUSTOM_REPORTS_ASO_REPORT = 'custom-reports-aso-report',
    CUSTOM_REPORTS_KEYWORDS_REPORT = 'custom-reports-keywords-report',
    CUSTOM_REPORTS_NEW_REVIEW_ALERT = 'custom-reports-new-review',
    CUSTOM_REPORTS_NEW_REVIEW_WITH_TAG_ALERT = 'custom-reports-new-review-with-tag-alert',
    CUSTOM_REPORTS_NUMBER_OF_NEGATIVE_REVIEWS_ALERT = 'custom-reports-number-of-negative-reviews-alert',
    CUSTOM_REPORTS_REPLY_TO_REVIEW_REPORT = 'custom-reports-reply-to-review-report',
    CUSTOM_REPORTS_REVIEWS_REPORT = 'custom-reports-reviews-report',
    CUSTOM_REPORTS_TRENDING_SEARCHES_ALERT = 'custom-reports-trending-searches-alert',
    CUSTOM_REPORTS_FEATURED_APP_ALERT = 'custom-reports-featured-app-alert',
    KEYWORD_ANALYTICS_ESTIMATED_INSTALLS = 'ka-estimated-installs',
    KEYWORD_TRANSLATION = 'keyword-translation',
    KEYWORD_NOTES = 'keyword-notes',
    TRAFFIC_SCORE = 'traffic-score',
    KEYWORD_MANAGER_EXPERT = 'keyword-manager-expert',
    KA_TABS_AVAILABLE = 'tabs-available',
    EXTENDED_EXPORT = 'extended-export',
    KM_TRAFFIC_SCORE = 'km-traffic-score',
    KM_COMPETITOR_KEYWORDS_ALL_TABS = 'km-competitor-keywords-all-tabs',
    KM_COMPETITOR_KEYWORDS = 'km-competitor-keywords',
    KM_SEARCHADS_SUGGESTIONS = 'km-searchads-suggestions',
    APPS = 'apps',
    KEYWORD_MANAGER_ADVANCED = 'keyword-manager-advanced',
    CUSTOM_TRAFFIC_SCORE = 'custom-traffic-score',
    SIMPLE_EXPORT = 'simple-export',
    WEEK_EXPORT = 'week-export',
    CAN_UPDATE_KEYWORDS = 'can-update-keywords',
    KEYWORD_STATS_LIMIT = 'keyword-stats-limit',
    ORGANIC_REPORT = 'organic-report',
    COMPARATIVE_REPORT = 'comparative-report',
    REVIEW_REPLIES_LIMIT = 'review-replies-limit',
    TEAMMATES = 'teammates',
    OPTIMIZER_BASE = 'optimizer-base',
    OPTIMIZER_TRANSLATION = 'optimizer-translation',
    OPTIMIZER_TRAFFIC_SCORE = 'optimizer-traffic-score',
    OPTIMIZER_EXPORT = 'optimizer-export',
    OPTIMIZER_SETS = 'optimizer-sets',
    KAS_MOST_POPULAR_KEYWORDS = 'kas-most-popular-keywords',
    KAS_BEST_KEYWORDS = 'kas-best-keywords',
    KAS_LONG_TAIL = 'kas-long-tail',
    KAS_LEAST_COMPETITIVE = 'kas-least-competitive',
    KAS_GOOD_FOR_BOOSTING = 'kas-good-for-boosting',
    KAS_EXPECTED_TO_BE_TRENDING = 'kas-expected-to-be-trending',
    KAS_TRAFFIC_SCORE = 'kas-traffic-score',
    APP_STORE_CONNECT = 'appstoreconnect',
    GOOGLE_PLAY_CONSOLE = 'googleplayconsole',
    ZENDESK_API = 'zendesk-api',
    KEYWORD_HIGHLIGHTS = 'keyword-highlights-tool',
    FAVORITE_KEYWORDS = 'favorites-keyword-tool',
    FEATURED_REVIEWS = 'featured-reviews',
    ASO_DASHBOARD = 'aso-dashboard',
    TOP_CHARTS = 'top-charts',
    TRENDING_SEARCHES = 'trending-searches',
    TAG_ANALYSIS = 'tag-analysis',
    KEYWORD_DYNAMICS = 'keyword-dynamics',
    RUSTORE = 'rustore',
    APP_PROFILE = 'app-profile',
    APP_REVIEWS = 'app-reviews',
    ASO_AUDIT = 'aso-audit',
    ASO_PRO_FEATURES = 'aso-pro-features',
    ASO_PRO_TOOLS_BUSINESS = 'aso-pro-tools-business',
    ASO_PRO_TOOLS_PLUS = 'aso-pro-tools-plus',
    CAN_UPDATE_APPS = 'can-update-apps',
    CATEGORY_RANKING_BASIC = 'category-ranking-basic',
    CATEGORY_RANKING_EXTENDED = 'category-ranking-extended',
    COUNTRIES = 'countries',
    EMAIL_REPORTS = 'email-reports',
    EXPORT_REVIEWS = 'export-reviews',
    GENERAL_ASO = 'general-aso',
    HISTORICAL_DATA = 'historical-data',
    KEYWORD_SUGGESTIONS = 'keyword-suggestions',
    KEYWORDS = 'keywords',
    REPORTS = 'reports',
    STORE_ANALYTICS = 'store-analytics',
    VIP_SUPPORT = 'vip-support',
    KEYWORD_MANAGER_PRO = 'keyword-manager-pro',
    KAS_KEYWORD_TRANSLATION = 'kas-keyword-translation',
    KM_TEXT_ANALYZER = 'km-text-analyzer',
    CVR_BENCHMARK = 'cvr-benchmark',
}

export type AvailableFeatureInformationType = {
    key: keyof AvailableFeaturesType;
    name: string;
    description: string | null;
    value?: number;
};

export type AvailableFeaturesType = {
    [key in AvailableFeaturesKeyEnum]?: AvailableFeatureInformationType;
};

export type TrackedAppsAppProfile = { store_id: string };

export type AppProfileResponseType = {
    add_suggested_keywords_onload?: boolean;
    app_id: number;
    available_features: AvailableFeaturesType;
    campaigns_available?: boolean;
    can_force_update?: boolean;
    categories?: AppCategoryType[];
    comparativeReportBuckets: string[];
    competitor_limit?: number;
    competitors?: AppResponseType[];
    country_code: string;
    country_name: string;
    current_version?: string;
    description?: string;
    developer?: string;
    developer_storeid?: string;
    first_release_date?: string;
    first_slice_date?: string;
    has_available_keywords?: boolean;
    inapp_payments?: InAppPaymentsType[];
    installs?: null;
    isDemo: boolean;
    is_app?: boolean;
    is_appstore: boolean;
    is_bundle?: boolean;
    is_connected: boolean;
    is_developer?: boolean;
    is_editorial?: boolean;
    is_fetching?: boolean;
    is_free?: boolean;
    is_googleplay: boolean;
    is_inapp?: boolean;
    is_mac_app?: boolean;
    is_on_market?: boolean;
    keyword_count?: number;
    languages?: {
        code: string;
        name: string;
        timestamp: string;
    }[];
    last_update?: null | string;
    latest_release_date?: string;
    logged_in?: boolean;
    logo?: string;
    minimum_organic_plan?: { id: number; name: string };
    name?: string;
    permissions: PermissionsType;
    price?: string;
    promotext?: string;
    rating?: number;
    recent_changes?: string;
    related_apps?: any[];
    reviews_update?: string;
    screenshots?: any[];
    short_description?: string;
    similar_apps?: [];
    slack_connected?: boolean;
    store_id: string;
    subtitle?: string;
    suggestionsKeywordsLimit?: number;
    trackedApps: TrackedAppsAppProfile[];
    type: AppStoreType;
    url: string;
    google_integration_features?: {
        reply: boolean;
        report: boolean;
    };
    store_events?: { date: string; text: string; countries: string[] }[];
};
