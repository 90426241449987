import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppProfileService } from 'hooks/data/useAppProfileService';
import { addCompetitorApp, removeCompetitorApp } from 'state/competitors/actions';
import { AppResponseType } from 'state/appProfile/AppResponseType';
import { addTrackedAppAction } from 'state/trackedApps/actions';
import { useAppProfileLoadMethods } from 'hooks/state/useAppProfileLoadMethods';
import { useSystem } from 'hooks/state/useSystem';

export const useAppProfile = () => {
    const dispatch = useDispatch();
    const params = useSystem();
    const { loadAppProfile } = useAppProfileLoadMethods();

    const { appProfile, isFetching } = useAppProfileService();

    const loadAppProfileByParams = () => {
        if (params.locale && params.appId) loadAppProfile(params.locale, params.appId);
    };
    const trackApp = (event: CustomEvent<AppResponseType>) => {
        const app = event.detail;
        dispatch(addTrackedAppAction(app));
    };
    const addCompetitor = (event: CustomEvent<AppResponseType>) => {
        const competitor = event.detail;
        dispatch(addCompetitorApp(competitor));
    };
    const removeCompetitor = (event: CustomEvent<string>) => {
        const competitorStoreId = event.detail;
        dispatch(removeCompetitorApp(competitorStoreId));
    };

    useEffect(() => {
        loadAppProfileByParams();
    }, [params.locale, params.appId]);

    useEffect(() => {
        window.addEventListener('trackApp', trackApp as EventListener);
        window.addEventListener('addCompetitor', addCompetitor as EventListener);
        window.addEventListener('removeCompetitor', removeCompetitor as EventListener);

        return () => {
            window.removeEventListener('trackApp', trackApp as EventListener);
            window.removeEventListener('addCompetitor', addCompetitor as EventListener);
            window.removeEventListener('removeCompetitor', removeCompetitor as EventListener);
        };
    }, []);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (isFetching) {
            const FIVE_SECONDS = 5000;
            timer = setTimeout(loadAppProfileByParams, FIVE_SECONDS);
        }

        return () => {
            timer && clearTimeout(timer);
        };
    }, [appProfile.isLoaded]);
};
