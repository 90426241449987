import { ToolTypesEnum } from 'types/toolTypes';
import { TagCategorySortingEnum } from 'modules/reviews/reviewTags/sortingDropdownMenu/sortingOptions';
import { StatsUserLimitsType } from './StatsUserLimitsType';

export type SidebarSectionsIdsType = ToolTypesEnum[];
export type SidebarSectionsByIdsType = {
    [key: string]: {
        id: ToolTypesEnum;
        collapsed: boolean;
    };
};

export type SidebarSectionsType = {
    ids: SidebarSectionsIdsType;
    byIds: SidebarSectionsByIdsType;
};

export enum KeywordExplorerWidgetEnum {
    ESTIMATED_INSTALLS = 'estimated_installs',
    SUGGESTIONS = 'suggestions',
    RELATED_SUGGESTIONS = 'related_suggestions',
}

export type UserFrontendSettingsInitialStateType = {
    isOpenSidebarOnDesktop: boolean;
    isOpenSidebarOnMobile: boolean;
    sidebarSections: SidebarSectionsType;
    statsUserLimit?: StatsUserLimitsType;
    favoriteTools: string[];
    isReplyBoardRandomTemplateSet: boolean;
    isReplyBoardAutoNextReviewSet: boolean;
    isKeywordCountryFlagShow: boolean;
    keywordAnalyticsColumnTableOrder: string[];
    keywordAnalyticsColumnTableInvisibility: string[];
    appReviewAutoTranslate: {
        appIds: string[];
        appByIds: {
            [appId: string]: {
                appId: string;
                isShowInfo: boolean;
                timeStart: number;
            };
        };
    };
    showTrialExpiredPlan: boolean;
    showTrialExpiredTomorrowPlan: boolean;
    isShowWelcomeOfferModal: boolean;
    isGlobalAppLoaderShow: boolean;
    keywordExplorerWidgetOrder: KeywordExplorerWidgetEnum[];
    tagsCategoriesSort: TagCategorySortingEnum;
    isNewNavigation: boolean;
    lastCurrentAppId?: string;
    lastCurrentLocale?: string;
};
