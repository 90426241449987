import React, { FC, memo, ReactNode } from 'react';
import { useTheme } from 'hooks/state/useTheme';
import { withMainLayoutModals } from 'modules/mainLayout/withMainLayoutModals';
import { AsyncMobileMainLayoutContainer } from './mobile/AsyncMobileMainLayoutContainer';
import { AsyncDesktopMainLayoutContainer } from './desktop/AsyncDesktopMainLayoutContainer';

type PropsType = {
    children: ReactNode;
};

const MainLayout: FC<PropsType> = ({ children }: PropsType) => {
    const { isMobile } = useTheme();

    return (
        <>
            {isMobile ? (
                <AsyncMobileMainLayoutContainer>{children}</AsyncMobileMainLayoutContainer>
            ) : (
                <AsyncDesktopMainLayoutContainer>{children}</AsyncDesktopMainLayoutContainer>
            )}
        </>
    );
};

export const MainLayoutContainer = memo(withMainLayoutModals(({ children }) => <MainLayout>{children}</MainLayout>));
