import { useAppSelector } from 'hooks/useAppSystem';

export const useSystem = () => {
    const { locale, appId, toolName, hasPageDemoData } = useAppSelector((state) => state.system);

    return {
        locale,
        appId,
        toolName,
        hasPageDemoData,
    };
};
