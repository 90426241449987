import { AppLocalesPageInfoType, AppLocalesResultType } from 'state/dashboard/AppLocalesResultType';
import { DashboardActionTypeEnum } from 'state/dashboard/actionsTypes';
import { DashboardActionType } from './actions';

export type DashboardStateType = {
    apps: AppLocalesResultType[];
    page: number;
    isInitialized: boolean;
} & AppLocalesPageInfoType;

const initialState: DashboardStateType = {
    apps: [],
    count: 0,
    pageSize: 1,
    page: 1,
    isInitialized: false,
};

const dashboard = (state = initialState, action: DashboardActionType) => {
    switch (action.type) {
        case DashboardActionTypeEnum.INIT_EMPTY_DASHBOARD_APPS: {
            return { ...initialState };
        }
        case DashboardActionTypeEnum.SET_DASHBOARD_APPS: {
            return {
                ...state,
                apps: action.apps,
                isInitialized: true,
                ...action.pageInfo,
            };
        }
        case DashboardActionTypeEnum.ADD_NEXT_PAGE: {
            return {
                ...state,
                page: action.page,
                apps: [...state.apps, ...action.apps],
                ...action.pageInfo,
            };
        }
        case DashboardActionTypeEnum.TOGGLE_DASHBOARD_FAVORITE_APP: {
            const updatedAppIndex = state.apps.findIndex(({ app }) => app.storeId === action.storeId);
            const updatedApp = {
                ...state.apps[updatedAppIndex],
                favorite: action.isFavorite,
            };

            return {
                ...state,
                apps: [...state.apps.slice(0, updatedAppIndex), updatedApp, ...state.apps.slice(updatedAppIndex + 1)],
            };
        }
        case DashboardActionTypeEnum.REMOVE_DASHBOARD_APP: {
            return {
                ...state,
                apps: state.apps.filter(({ app }) => app.storeId !== action.storeId),
            };
        }
        default: {
            return state;
        }
    }
};

export default dashboard;
