export enum PaymentActionTypeListEnum {
    SET_PLAN_DATA = 'SET_PLAN_DATA',
    SET_BILLING_INFORMATION_DATA = 'SET_BILLING_INFORMATION_DATA',
    SET_CARDS_DATA = 'SET_CARDS_DATA',
    SET_SUBSCRIPTIONS_DATA = 'SET_SUBSCRIPTIONS_DATA',
    SET_SUBSCRIPTION_DATA = 'SET_SUBSCRIPTION_DATA',

    CHANGE_PROMO_CODE = 'CHANGE_PROMO_CODE',
    CHANGE_PAYMENT_MODAL_STEP_TYPE = 'CHANGE_PAYMENT_MODAL_STEP_TYPE',
    CHANGE_PAYMENT_TYPE = 'CHANGE_PAYMENT_TYPE',
    CHANGE_PAYMENT_PERIOD = 'CHANGE_PAYMENT_PERIOD',
    CHANGE_SELECTED_CARD_ID = 'CHANGE_SELECTED_CARD_ID',
    CHANGE_SUBSCRIPTION_TYPE = 'CHANGE_SUBSCRIPTION_TYPE',
    UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION',
    UPDATE_PRICE_SUBSCRIPTION = 'UPDATE_PRICE_SUBSCRIPTION',
    CHANGE_PAYMENT_MODAL_SETTINGS = 'CHANGE_PAYMENT_MODAL_SETTINGS',

    ADD_NEW_CARD = 'ADD_NEW_CARD',
    SET_DEFAULT_CARD = 'SET_DEFAULT_CARD',
    REMOVE_CARD = 'REMOVE_CARD',
    UPDATE_CARD = 'UPDATE_CARD',
}
