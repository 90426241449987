import { TopKeywordsType } from 'state/topKeywords/topKeywordsType';
import { TopKeywordsTypeEnum } from 'state/topKeywords/actionsTypes';
import { AppStoreType } from 'state/appProfile/AppResponseType';
import { TopKeywordsActionType } from './actions';

export type TopKeywordsStateType = {
    storeType: AppStoreType;
    topKeywordsInfo?: TopKeywordsType;
};

const initialState: TopKeywordsStateType = {
    storeType: 'appstore',
};

const topKeywords = (state: TopKeywordsStateType = initialState, action: TopKeywordsActionType) => {
    switch (action.type) {
        case TopKeywordsTypeEnum.SET_TOP_KEYWORDS: {
            return {
                ...state,
                topKeywordsInfo: action.topKeywordsInfo,
            };
        }
        case TopKeywordsTypeEnum.SET_TOP_KEYWORDS_STORE: {
            return {
                ...state,
                storeType: action.storeType,
            };
        }
        default: {
            return state;
        }
    }
};

export default topKeywords;
