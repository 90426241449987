import { KeywordShuffleActionTypeListEnum } from './actionsTypes';
import { KeywordShuffleActionType } from './actions';
import { KeywordShufflerResponseAdaptedType } from './KeywordShufflerResponseType';

export type KeywordShuffleStateType = {
    keywords: KeywordShufflerResponseAdaptedType[][];
    selectedKeywords: string[];
    availableAddingKeywords: string[][];
};

const keywordShuffleInitialState: KeywordShuffleStateType = {
    keywords: [],
    selectedKeywords: [],
    availableAddingKeywords: [],
};

const keywordsShuffle = (state = keywordShuffleInitialState, action: KeywordShuffleActionType) => {
    switch (action.type) {
        case KeywordShuffleActionTypeListEnum.SET_KEYWORD_SHUFFLE: {
            const availableAddingKeywords = action.keywords.map((keywordColumn) =>
                keywordColumn.filter((keyword) => keyword.canAdd).map(({ keyword }) => keyword)
            );
            return {
                ...state,
                keywords: action.keywords,
                selectedKeywords: [],
                availableAddingKeywords,
            };
        }
        case KeywordShuffleActionTypeListEnum.SELECT_ALL_KEYWORD_SHUFFLE: {
            const { keywords } = state;
            const selectedData = keywords.reduce((result: string[], keywordColumn) => {
                const adaptedKeywordColumn = keywordColumn.map(({ keyword }) => keyword);
                return [...result, ...adaptedKeywordColumn];
            }, []);
            return {
                ...state,
                selectedKeywords: selectedData,
            };
        }
        case KeywordShuffleActionTypeListEnum.CLEAR_SELECTED_KEYWORD_SHUFFLE: {
            return {
                ...state,
                selectedKeywords: [],
            };
        }
        case KeywordShuffleActionTypeListEnum.SELECT_TOGGLE_ONE_KEYWORD_SHUFFLE: {
            let { selectedKeywords } = state;

            if (selectedKeywords.indexOf(action.selectedKeyword) === -1) {
                selectedKeywords = [...selectedKeywords, action.selectedKeyword];
            } else {
                selectedKeywords = selectedKeywords.filter((keyword) => keyword !== action.selectedKeyword);
            }

            return {
                ...state,
                selectedKeywords,
            };
        }
        case KeywordShuffleActionTypeListEnum.SELECT_TOGGLE_COLUMN_KEYWORD_SHUFFLE: {
            let { selectedKeywords } = state;
            const { keywords } = state;

            const columnKeywords = keywords[action.columnId].map((keywordColumn) => keywordColumn.keyword);

            const currentColumnSelectedKeywords = selectedKeywords.filter((keyword) => columnKeywords.indexOf(keyword) !== -1);

            const otherColumnSelectedKeywords = selectedKeywords.filter((keyword) => columnKeywords.indexOf(keyword) === -1);

            if (currentColumnSelectedKeywords.length === columnKeywords.length) {
                selectedKeywords = otherColumnSelectedKeywords;
            } else {
                selectedKeywords = [...otherColumnSelectedKeywords, ...columnKeywords];
            }

            return {
                ...state,
                selectedKeywords,
            };
        }
        case KeywordShuffleActionTypeListEnum.ADD_KEYWORD_SHUFFLE_TO_KEYWORDS: {
            const { keywords, availableAddingKeywords } = state;
            const updatedKeywords = keywords.map((column, index) => {
                return index === action.columnId
                    ? column.map((keywordItem) => {
                          return keywordItem.keyword === action.keyword
                              ? {
                                    ...keywordItem,
                                    canAdd: false,
                                }
                              : keywordItem;
                      })
                    : column;
            });
            const updateAvailableAddingKeywords = availableAddingKeywords.map((column, index) => {
                return index === action.columnId ? column.filter((keyword) => keyword !== action.keyword) : column;
            });

            return {
                ...state,
                keywords: updatedKeywords,
                availableAddingKeywords: updateAvailableAddingKeywords,
            };
        }
        case KeywordShuffleActionTypeListEnum.ADD_COLUMN_KEYWORD_SHUFFLE_TO_KEYWORDS: {
            const { keywords, availableAddingKeywords } = state;
            const currentColumn = keywords[action.columnId];
            keywords[action.columnId] = currentColumn.map((keywordColumn) => {
                return keywordColumn.canAdd
                    ? {
                          ...keywordColumn,
                          canAdd: false,
                      }
                    : keywordColumn;
            });

            const updateAvailableAddingKeywords = availableAddingKeywords.map((column, index) => {
                return index === action.columnId ? [] : column;
            });

            return {
                ...state,
                keywords,
                availableAddingKeywords: updateAvailableAddingKeywords,
            };
        }
        default: {
            return state;
        }
    }
};

export default keywordsShuffle;
