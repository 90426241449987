import { PaymentActionType } from './actions';
import { PaymentActionTypeListEnum } from './actionsTypes';
import {
    BillingInformationType,
    BillingUserType,
    CardType,
    PaymentModalSettingsType,
    PaymentModalStepEnum,
    PaymentPeriodEnum,
    PaymentTypeEnum,
    PlanType,
    SubscriptionsType,
    SubscriptionType,
    SubscriptionTypeEnum,
} from './paymentTypes';
import {
    isEditSubscriptionParams,
    paymentTypeParams,
    stepModalIdParams,
    subscriptionTypeParams,
    isEnterPromoCodeParams,
    isShowPaymentPeriodParams,
    isShowPlanPricePeriodParams,
    paymentPeriodParams,
} from './utils';

export type PaymentStateType = {
    paymentModalStep: PaymentModalStepEnum;
    paymentType: PaymentTypeEnum;
    paymentPeriod: PaymentPeriodEnum;
    plan?: PlanType;
    subscription?: SubscriptionType;
    billingInformation: BillingInformationType;
    promoCode?: string;
    cards: CardType[];
    selectedCardId?: number;
    subscriptions: SubscriptionsType;
    subscriptionType: SubscriptionTypeEnum;
    modalSettings: PaymentModalSettingsType;
    isShowPlanFeatures?: boolean;
};

export const initialPaymentModalSettings = {
    isEditSubscription: false,
    isShowPaymentPeriod: true,
    isEnterPromoCode: true,
    isShowPlanPricePeriod: true,
};

export const paymentInitialState = {
    paymentModalStep: stepModalIdParams || PaymentModalStepEnum.CONFIGURE_PAYMENT,
    paymentType: paymentTypeParams || PaymentTypeEnum.STRIPE,
    paymentPeriod: paymentPeriodParams || PaymentPeriodEnum.ANNUAL,
    billingInformation: {
        fullName: '',
        companyName: '',
        vatNumber: '',
        isValidVatNumber: false,
        city: '',
        address: '',
        userType: BillingUserType.CORPORATE,
        isAutoRenew: true,
    },
    cards: [],
    subscriptions: {
        [SubscriptionTypeEnum.SUBSCRIPTIONS]: [],
        [SubscriptionTypeEnum.API_SUBSCRIPTIONS]: [],
        [SubscriptionTypeEnum.CAMPAIGNS]: [],
        [SubscriptionTypeEnum.SERVICES]: [],
    },
    subscriptionType: subscriptionTypeParams || SubscriptionTypeEnum.SUBSCRIPTIONS,
    modalSettings: {
        isEditSubscription:
            isEditSubscriptionParams !== undefined ? isEditSubscriptionParams : initialPaymentModalSettings.isEditSubscription,
        isShowPaymentPeriod:
            isShowPaymentPeriodParams !== undefined ? isShowPaymentPeriodParams : initialPaymentModalSettings.isShowPaymentPeriod,
        isEnterPromoCode:
            isEnterPromoCodeParams !== undefined ? isEnterPromoCodeParams : initialPaymentModalSettings.isEnterPromoCode,
        isShowPlanPricePeriod:
            isShowPlanPricePeriodParams !== undefined
                ? isShowPlanPricePeriodParams
                : initialPaymentModalSettings.isShowPlanPricePeriod,
    },
};

const payment = (state: PaymentStateType = paymentInitialState, action: PaymentActionType): PaymentStateType => {
    switch (action.type) {
        case PaymentActionTypeListEnum.SET_PLAN_DATA: {
            return {
                ...state,
                plan: action.plan,
            };
        }
        case PaymentActionTypeListEnum.CHANGE_PROMO_CODE: {
            return {
                ...state,
                promoCode: action.promoCode,
            };
        }
        case PaymentActionTypeListEnum.CHANGE_PAYMENT_MODAL_STEP_TYPE: {
            return {
                ...state,
                paymentModalStep: action.paymentModalStep,
            };
        }
        case PaymentActionTypeListEnum.CHANGE_PAYMENT_TYPE: {
            return {
                ...state,
                paymentType: action.paymentType,
            };
        }
        case PaymentActionTypeListEnum.CHANGE_PAYMENT_PERIOD: {
            return {
                ...state,
                paymentPeriod: action.paymentPeriod,
            };
        }
        case PaymentActionTypeListEnum.SET_BILLING_INFORMATION_DATA: {
            return {
                ...state,
                billingInformation: action.billingInformation,
            };
        }
        case PaymentActionTypeListEnum.SET_CARDS_DATA: {
            return {
                ...state,
                cards: action.cards,
            };
        }
        case PaymentActionTypeListEnum.CHANGE_SELECTED_CARD_ID: {
            return {
                ...state,
                selectedCardId: action.cardId,
            };
        }
        case PaymentActionTypeListEnum.ADD_NEW_CARD: {
            return {
                ...state,
                cards: [...state.cards, action.card],
            };
        }
        case PaymentActionTypeListEnum.SET_SUBSCRIPTIONS_DATA: {
            return {
                ...state,
                subscriptions: action.subscriptions,
            };
        }
        case PaymentActionTypeListEnum.SET_SUBSCRIPTION_DATA: {
            return {
                ...state,
                subscription: action.subscription,
            };
        }
        case PaymentActionTypeListEnum.CHANGE_PAYMENT_MODAL_SETTINGS: {
            return {
                ...state,
                modalSettings: {
                    ...state.modalSettings,
                    ...action.modalSettings,
                },
            };
        }
        case PaymentActionTypeListEnum.SET_DEFAULT_CARD: {
            return {
                ...state,
                cards: state.cards.map((card) => ({
                    ...card,
                    isDefault: card.id === action.cardId,
                })),
            };
        }
        case PaymentActionTypeListEnum.REMOVE_CARD: {
            return {
                ...state,
                cards: state.cards.filter((card) => card.id !== action.cardId),
            };
        }
        case PaymentActionTypeListEnum.UPDATE_CARD: {
            return {
                ...state,
                cards: state.cards.map((card) => {
                    if (card.id === action.card.id) {
                        return {
                            ...card,
                            ...action.card,
                        };
                    }

                    return card;
                }),
            };
        }
        case PaymentActionTypeListEnum.CHANGE_SUBSCRIPTION_TYPE: {
            return {
                ...state,
                subscriptionType: action.subscriptionType,
            };
        }
        case PaymentActionTypeListEnum.UPDATE_PRICE_SUBSCRIPTION: {
            return {
                ...state,
                ...(state.subscription
                    ? {
                          subscription: {
                              ...state.subscription,
                              ...action.recalculatePrices,
                          },
                      }
                    : {}),
            };
        }
        case PaymentActionTypeListEnum.UPDATE_SUBSCRIPTION: {
            return {
                ...state,
                subscriptions: {
                    ...state.subscriptions,
                    [state.subscriptionType]: state.subscriptions[state.subscriptionType].map((subscription) => {
                        if (subscription.id === action.subscription.id) {
                            return {
                                ...subscription,
                                ...action.subscription,
                            };
                        }

                        return subscription;
                    }),
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default payment;
