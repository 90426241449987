import { TagRuleActionTypeListEnum } from './actionsTypes';
import { ReviewsTagRuleActionType } from './actions';
import { NormalizeTagRulesType } from './tagRuleTypes';

const tagRuleInitialState = {
    tagRulesIds: [],
    tagRulesByIds: {},
};

export type TagRuleInitialStateType = NormalizeTagRulesType;

const tagRule = (state: TagRuleInitialStateType = tagRuleInitialState, action: ReviewsTagRuleActionType) => {
    switch (action.type) {
        case TagRuleActionTypeListEnum.SET_CREATE_TAG_RULES: {
            return {
                ...state,
                ...action.normalizeData,
            };
        }
        case TagRuleActionTypeListEnum.CREATE_TAG_RULE: {
            return {
                ...state,
                tagRulesIds: [...state.tagRulesIds, action.data.id],
                tagRulesByIds: {
                    ...state.tagRulesByIds,
                    [action.data.id]: action.data,
                },
            };
        }
        case TagRuleActionTypeListEnum.UPDATE_TAG_RULE: {
            return {
                ...state,
                tagRulesByIds: {
                    ...state.tagRulesByIds,
                    [action.data.id]: action.data,
                },
            };
        }
        case TagRuleActionTypeListEnum.REMOVE_TAG_RULE: {
            return {
                ...state,
                tagRulesIds: state.tagRulesIds.filter((tagRuleId) => tagRuleId !== action.ruleId),
            };
        }
        default: {
            return state;
        }
    }
};

export default tagRule;
