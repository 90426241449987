import { PlanCardStateEnum } from 'newComponents/plans/planCard/PlanCard';
import { useWelcomeOffer } from 'hooks/useWelcomeOffer';
import { PaymentPeriodEnum } from 'state/payment/paymentTypes';

type UsePlanTabsType = {
    state?: PlanCardStateEnum;
    paymentPeriod: PaymentPeriodEnum;
    isBestPlan: boolean;
};

export const usePlanTabs = ({ state, paymentPeriod, isBestPlan }: UsePlanTabsType) => {
    const { getIs3MonthsWelcomeOffer } = useWelcomeOffer();

    const has3MonthsWelcomeOffer = getIs3MonthsWelcomeOffer(paymentPeriod);

    const getStatePlanCard = () => {
        if (state) return state;

        if (has3MonthsWelcomeOffer) return PlanCardStateEnum.OFFER;

        return isBestPlan ? PlanCardStateEnum.PRIMARY : PlanCardStateEnum.DEFAULT;
    };

    const planCardPeriod = has3MonthsWelcomeOffer ? '3 month' : 'month';
    const statePlanCard = getStatePlanCard();

    return {
        planCardPeriod,
        statePlanCard,
    };
};
