import axios from 'axios';
import { apiUrls } from 'utils/Urls';
import { SentProductAnalyticsEventType } from 'state/productAnalytics/productAnalyticsTypes';

export const sentProductAnalyticsEvent = async ({ actionName, toolName, extras }: SentProductAnalyticsEventType) => {
    try {
        await axios.post(apiUrls.createProductAnalyticsEvent(), {
            action_name: actionName,
            tool_name: toolName,
            extras,
        });
    } catch (e) {
        console.error(e);
    }
};
