import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HubSpotContext } from 'provider/HubSpotProvider';
import { addedNewHubspotGetParam } from 'utils/hubspotUtils';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { asodeskProductTourCsHubspot, asodeskProductTourHubspot } from 'utils/Urls';

export const useHubspot = () => {
    const [productTourUrl, setProductTourUrl] = useState('');
    const [isRefreshingHubspot, setIsRefreshingHubspot] = useState(false);

    const { hasLoaded, setIsHidden } = useContext(HubSpotContext);

    const [activeConversation, setActiveConversation] = useState(false);
    const eventRef = useRef<((payload: any) => void) | null>(null);

    useEffect(() => {
        eventRef.current = (payload) => {
            setActiveConversation(payload.conversation.conversationId);
        };

        if (hasLoaded) {
            window.HubSpotConversations.on('conversationStarted', eventRef.current);
        }

        return () => {
            if (hasLoaded) {
                window.HubSpotConversations.off('conversationStarted', eventRef.current);
            }
        };
    }, [hasLoaded]);

    const openHandler = useCallback(() => {
        if (hasLoaded) {
            window.HubSpotConversations.widget.open();
        }
    }, [hasLoaded]);

    const openAfterRefreshHandler = useCallback(() => {
        if (hasLoaded) {
            window.HubSpotConversations.widget.refresh({ openToNewThread: true });
            window.HubSpotConversations.widget.open();
        }
    }, [hasLoaded]);

    const closeHandler = useCallback(() => {
        if (hasLoaded) {
            window.HubSpotConversations.widget.close();
        }
    }, [hasLoaded]);

    const hideHandler = useCallback(() => {
        if (hasLoaded) {
            setIsHidden(true);
            window.HubSpotConversations.widget.remove();
        }
    }, [hasLoaded]);

    const showHandler = useCallback(() => {
        if (hasLoaded) {
            setIsHidden(false);
            window.HubSpotConversations.widget.load();
        }
    }, [hasLoaded]);

    const resetAndReloadWidget = useCallback(() => {
        if (hasLoaded) {
            window.HubSpotConversations.resetAndReloadWidget();
        }
    }, [hasLoaded]);

    const openHubspotWithGetParam = useCallback(
        (hubspotEventName: string) => {
            if (hasLoaded) {
                setIsRefreshingHubspot(true);
                addedNewHubspotGetParam(hubspotEventName);
                window.HubSpotConversations.widget.refresh({ openToNewThread: true });
                const WAIT_LOCATION_QUERY_UPDATE = 700;
                setTimeout(() => {
                    window.HubSpotConversations.widget.open();
                    setIsRefreshingHubspot(false);
                }, WAIT_LOCATION_QUERY_UPDATE);
            }
        },
        [hasLoaded]
    );

    const { isShowOnboardingModal, isProductTourCs, isLoaded: isLoadedRequestInfo } = useRequestInfoService();

    useEffect(() => {
        if (isShowOnboardingModal) {
            hideHandler();
        } else {
            showHandler();
        }
    }, [hideHandler, showHandler, isShowOnboardingModal]);

    const getHubspotCalendarUrl = () => {
        return { url: productTourUrl, isLoading: !isLoadedRequestInfo };
    };

    const handleOpenHSCalendarModal = () => {
        if (isLoadedRequestInfo) window.open(productTourUrl);
    };

    useEffect(() => {
        if (isLoadedRequestInfo) {
            const asodeskProductTourUrl = isProductTourCs ? asodeskProductTourCsHubspot : asodeskProductTourHubspot;

            setProductTourUrl(asodeskProductTourUrl);
        }
    }, [isProductTourCs, isLoadedRequestInfo]);

    return {
        hasLoaded,
        activeConversation,
        openHandler,
        closeHandler,
        hideHandler,
        showHandler,
        openAfterRefreshHandler,
        openHubspotWithGetParam,
        isRefreshingHubspot,
        resetAndReloadWidget,
        getHubspotCalendarUrl,
        handleOpenHSCalendarModal,
        productTourUrl,
    };
};
