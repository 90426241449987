import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    setPageCategoryAction,
    setSystemAppIdAction,
    setSystemLocaleAction,
    setSystemToolNameAction,
} from 'state/system/actions';
import { SidebarCategoriesEnum } from 'hooks/useSidebarCategories';
import { parseQueryString, stringifyUrl } from 'utils/helpers/queryString';
import { isString, isUndefined } from 'utils/typeGuard';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { storesAnalyticsRouteSearchExplorerUrl } from 'utils/routeUrls';
import { useRustore } from 'hooks/useRustore';
import { useAppSelector } from 'hooks/useAppSystem';
import { useRoutesUrl } from 'hooks/useRoutesUrl';

const INDEX_TOOL_NAME = 'index';

export const getSplitPathName = (pathname: string) => {
    const [pageCategory, toolName, appId] = pathname.split('/').filter((itemPath) => itemPath);

    let adaptedAppId = appId;

    // TODO: removed after remove old nav
    if (adaptedAppId) {
        const [appIdWithoutParams] = appId.split('?');
        adaptedAppId = appIdWithoutParams;
    }

    return {
        pageCategory,
        toolName,
        appId: adaptedAppId,
    };
};

export const useSystemSetter = () => {
    const dispatch = useDispatch();

    const { pathname, search } = useLocation();

    const navigate = useNavigate();

    const { myAppsUrl } = useRoutesUrl();

    const { locale, ...queryParams } = parseQueryString(search);

    const { userCountry } = useRequestInfoService();

    const lastCurrentLocale = useAppSelector((state) => state.userFrontendSettings.lastCurrentLocale);

    // TODO: removed after remove old nav
    const [_, pathnameLocale] = pathname.split('?locale=');

    const { pageCategory, toolName, appId } = getSplitPathName(pathname);

    const getAdaptedLocale = () => {
        const adaptedLocale = locale || pathnameLocale;

        if (isString(adaptedLocale)) {
            return adaptedLocale.toLowerCase();
        }

        return adaptedLocale;
    };

    const adaptedLocale = lastCurrentLocale || userCountry;

    useEffect(() => {
        if (adaptedLocale && (appId || pathname.includes(storesAnalyticsRouteSearchExplorerUrl)) && !getAdaptedLocale()) {
            navigate(
                stringifyUrl({
                    query: {
                        ...queryParams,
                        locale: adaptedLocale.toLowerCase(),
                    },
                })
            );
        }
    }, [adaptedLocale]);

    useEffect(() => {
        const prepareToolName = isUndefined(toolName) || toolName === INDEX_TOOL_NAME ? pageCategory : toolName;

        dispatch(setPageCategoryAction(pageCategory as SidebarCategoriesEnum));
        dispatch(setSystemToolNameAction(prepareToolName));
        dispatch(setSystemAppIdAction(appId));
        dispatch(setSystemLocaleAction(getAdaptedLocale() as string));
    }, [pageCategory, toolName, appId, locale, pathnameLocale]);

    const { hasRustoreAlias, isNotRuLocale, isNotSupportedRustoreTool } = useRustore(locale as string);

    useEffect(() => {
        if (hasRustoreAlias) {
            if (isNotSupportedRustoreTool) {
                navigate(myAppsUrl);
            } else if (isNotRuLocale) {
                navigate(
                    stringifyUrl({
                        url: pathname,
                        query: { ...queryParams, locale: 'ru' },
                    })
                );
            }
        }
    }, [hasRustoreAlias, isNotRuLocale, isNotSupportedRustoreTool, appId]);
};
