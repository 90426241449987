export enum ReplyBoardActionTypeListEnum {
    SET_SINGLE_REVIEW_ID = 'SET_SINGLE_REVIEW_ID',
    SET_MULTIPLE_REVIEW_IDS = 'SET_MULTIPLE_REVIEW_IDS',
    UNSET_REVIEW_IDS = 'UNSET_REVIEW_IDS',
    CREATE_NEW_REVIEW_ANSWER = 'CREATE_NEW_REVIEW_ANSWER',
    SET_SINGLE_REVIEW = 'SET_SINGLE_REVIEW',
    SET_REVIEWS = 'SET_REVIEWS',
    RESET_NEW_ANSWER_REVIEWS = 'RESET_NEW_ANSWER_REVIEWS',
    SET_APP_RATINGS_REVIEWS = 'SET_APP_RATINGS_REVIEWS',
    ADD_USER_TAG = 'ADD_USER_TAG',
    REMOVE_USER_TAGS = 'REMOVE_USER_TAGS',
    REMOVE_LAST_DEVELOPER_ANSWER = 'REMOVE_LAST_DEVELOPER_ANSWER',
    EDIT_REVIEW_ANSWER = 'EDIT_REVIEW_ANSWER',
    ACCEPT_REVIEWS_ANSWER = 'ACCEPT_REVIEWS_ANSWER',
    DECLINE_REVIEWS_ANSWER = 'DECLINE_REVIEWS_ANSWER',
    UPDATE_REVIEW_TRANSLATE = 'UPDATE_REVIEW_TRANSLATE',
    UPDATE_APP_SETTINGS = 'UPDATE_APP_SETTINGS',
    TOGGLE_VISIBILITY_TRANSLATE = 'TOGGLE_VISIBILITY_TRANSLATE',
    ADD_TO_FAVORITE_REVIEW = 'ADD_TO_FAVORITE_REVIEW',
    REMOVE_FROM_FAVORITE_REVIEW = 'REMOVE_FROM_FAVORITE_REVIEW',
}
