import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { getUnix } from 'utils/helpers/date/date';
import { saveFrontendSettings } from 'state/userFrontendSettings/controller';
import { Urls } from 'utils/Urls';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { useAppSelector } from 'hooks/useAppSystem';

// TODO: Change this date (ms) to actual, after relize new navigation
const START_DATE_NEW_NAVIGATION = 1692707474709;

const oldPath = [Urls.keyword_density_counter(), Urls.keyword_shuffler(), Urls.locales()];

export const useNewNavigation = () => {
    const dispatch = useDispatch();

    const { pathname } = useLocation();

    const { myAppsUrl } = useRoutesUrl();

    const isNewNavigation = useAppSelector((state) => state.userFrontendSettings.isNewNavigation);
    const { userDateJoined } = useRequestInfoService();

    const isOldUser = userDateJoined && getUnix(userDateJoined) * 1000 < START_DATE_NEW_NAVIGATION;

    const toggleShowingNewDesign = async () => {
        await dispatch(saveFrontendSettings(!isNewNavigation));

        if (oldPath.includes(pathname)) {
            window.location.href = myAppsUrl;
        } else {
            window.location.reload();
        }
    };

    return {
        isOldUser,
        toggleShowingNewDesign,
        isNewNavigation,
    };
};
