import { Dispatch } from 'redux';
import axios from 'axios';
import { Urls } from 'utils/Urls';
import { TrackedAppsResponseType } from 'state/trackedApps/TrackedAppsResponseType';
import { addTrackedAppAction, setTrackedAppsAction, removeTrackedAppAction } from 'state/trackedApps/actions';
import { toast } from 'react-toastify';
import { AppLocalesListResponseType } from 'state/trackedApps/AppLocalesListResponseType';
import { TabResponseType } from 'state/keywordAnalytics/AppDataStatsResponseType';
import { UntrackAppResponseType } from 'state/trackedApps/UntrackAppResponseType';
import { TrackAppResponseType } from 'state/trackedApps/trackAppResponseType';
import { removeDashboardAppAction } from 'state/dashboard/actions';
import { AppResponseType } from 'state/appProfile/AppResponseType';
import { ThunkDispatch } from 'types/react-redux-thunk';
import { keywordAnalyticsTabListAdapter } from 'state/keywordAnalytics/keywordAnalyticsTabAdapter';
import { AppUntrackedInfoResponseType } from 'state/trackedApps/AppUntrackedInfoResponseType';
import { adaptAppUntrackedInfoResponse } from 'state/trackedApps/adaptAppUntrackedInfoResponse';
import { removeUntrackedAppFromCustomReportsAction } from 'state/customReports/actions';
import { AvailableFeaturesKeyEnum } from 'state/appProfile/AppProfileResponseType';
import { featurePermissionModalDispatch } from 'state/popup/featurePermissionModalDispatch';
import { addUserLimitsAmountAction } from 'state/userFrontendSettings/actions';
import { StatsUserLimitIdsEnum } from 'state/userFrontendSettings/SidebarResponseType';
import { trackedAppsAdapter } from 'state/trackedApps/trackedAppsAdapter';
import { RootState } from 'state/store';
import { getAppProfileStore } from 'utils/storeUtils';
import { GetStringKeywordsResponseType } from './GetStringKeywordsResponseType';
import { TrackAppByUrlResponseType } from './trackAppByUrlResponseType';
import { KeywordSuggestionsController } from '../keywordSuggestions/controller';
import { getAsodeskHeaderInfo } from '../asodeskHeader/controller';

export const getTrackedApps = (country?: string, storeId?: string) => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get<TrackedAppsResponseType>(Urls.tracked_apps(), {
            params: {
                country,
                app_storeid: storeId,
            },
        });

        dispatch(setTrackedAppsAction(trackedAppsAdapter(response.data)));
    } catch (error) {
        console.error(error);
    }
};

const sendAddTrackedAppReactEvent = (app: AppResponseType) => {
    window.dispatchEvent(
        new CustomEvent('addTrackedAppReact', {
            detail: app,
        })
    );
};

const showAppLimitPopup = (dispatch: ThunkDispatch, getOwnState: () => RootState) => {
    featurePermissionModalDispatch(dispatch, AvailableFeaturesKeyEnum.APPS, getOwnState);
};

export const LIMIT_REACHED_RESPONSE_ERROR = 'Application limits reached';

export const trackApp = (country: string, trackedStoreId: string, showToast = true) => async (
    dispatch: ThunkDispatch,
    getOwnState: () => RootState
) => {
    try {
        const { data } = await axios.post<TrackAppResponseType>(Urls.track_app(country, trackedStoreId));

        dispatch(addTrackedAppAction(data.app));
        sendAddTrackedAppReactEvent(data.app);

        if (showToast) toast.success(data.success);
    } catch (e) {
        if (e.response && e.response.data && e.response.data.error) {
            if (e.response.data.error === LIMIT_REACHED_RESPONSE_ERROR) {
                showAppLimitPopup(dispatch, getOwnState);
            } else {
                toast.error(e.response.data.error);
            }
        }
        console.error(e);
    }
};

export const trackAppByUrl = (country?: string, appUrl?: string, addSuggestions = false) => async (
    dispatch: ThunkDispatch,
    getOwnState: () => RootState
) => {
    try {
        const { data } = await axios.post<TrackAppByUrlResponseType>(Urls.track_app_by_url(country), {
            add_suggestion: addSuggestions,
            app_url: appUrl,
        });

        if (addSuggestions) {
            const countryCode = data.app.country_code && data.app.country_code.toLowerCase();
            const trackedAppId = data.app.isDemo ? 'demo' : data.app.store_id;

            await dispatch(KeywordSuggestionsController.addTopKeywordSuggestions(countryCode, trackedAppId));
            dispatch(getAsodeskHeaderInfo());
        }

        sendAddTrackedAppReactEvent(data.app);
        dispatch(addTrackedAppAction(data.app));
        dispatch(addUserLimitsAmountAction(StatsUserLimitIdsEnum.APPLICATIONS, 1));
        toast.success(data.success);

        return data;
    } catch (e) {
        if (e.response && e.response.data && e.response.data.error) {
            if (e.response.data.error === LIMIT_REACHED_RESPONSE_ERROR) {
                showAppLimitPopup(dispatch, getOwnState);
                throw e;
            }
            toast.error(e.response.data.error);
            return;
        }
        throw e;
    }
};

export const getUntrackedAppInfo = (storeId: string) => async () => {
    const response = await axios.get<AppUntrackedInfoResponseType>(Urls.appUntrackedInfo(storeId));
    return adaptAppUntrackedInfoResponse(response.data);
};

const sendSuccessToast = (text: string) => {
    toast.success(text);
};

export const removeTrackedApp = (storeId: string, country?: string, callback = sendSuccessToast) => async (
    dispatch: Dispatch
) => {
    try {
        const response = await axios.post<UntrackAppResponseType>(Urls.untrackApp(country, storeId));
        dispatch(removeTrackedAppAction(response.data.storeId));
        dispatch(removeDashboardAppAction(storeId));
        dispatch(removeUntrackedAppFromCustomReportsAction(storeId));

        window.dispatchEvent(
            new CustomEvent('removeTrackedAppReact', {
                detail: {
                    storeId: response.data.storeId,
                },
            })
        );

        callback(response.data.success);
    } catch (e) {
        toast.error(e.response.data.error);
        console.error(e);
    }
};

export const getLocales = (trackedStoreId: string) => async (dispatch: Dispatch, store: () => RootState) => {
    try {
        const { country } = getAppProfileStore(store);

        const response = await axios.get<AppLocalesListResponseType>(Urls.api_locales_list(country, trackedStoreId));

        return response.data.data.map((locale) => ({
            value: locale.name,
            code: locale.code,
            count: locale.count,
        }));
    } catch (e) {
        console.error(e);
    }
};

export const getTabList = (country: string, appId: string) => async () => {
    try {
        const response = await axios.get<TabResponseType[]>(Urls.tabs_list(country, appId));

        return keywordAnalyticsTabListAdapter(response.data);
    } catch (e) {
        console.error(e);
    }
};

export const getStringKeywords = (storeId: string, locales: string[], tabIds: number[] | null = null) => async (
    dispatch: Dispatch,
    store: () => RootState
) => {
    try {
        const { country, appId } = getAppProfileStore(store);
        const response = await axios.post<GetStringKeywordsResponseType>(Urls.get_string_keywords(country, appId), {
            store_id: storeId,
            tab_ids: tabIds,
            locales,
        });

        return {
            ...response.data.countries,
            ...response.data.tabs,
        };
    } catch (e) {
        console.error(e);
    }
};
