import React, { FC } from 'react';
import classNames from 'classnames';
import { TabControlPropsType } from 'newComponents/tab/TabControlPropsType';
import styles from './defaultTab.module.scss';

type PrimaryTabControlsType = TabControlPropsType;

export const DefaultTabControls: FC<PrimaryTabControlsType> = ({ tabs, activeId, className }: PrimaryTabControlsType) => (
    <div className={classNames('flex items-center py-10', className)}>
        {tabs.map(({ id, text, onClickHandler = () => {}, itemClassName }) => {
            const isActiveTab = activeId === id;

            const itemClasses = `cursor-pointer relative text-12 text-rb-font-primary leading-20 font-semibold
             mr-8 px-8 py-4 rounded-4 hover:!no-underline hover:bg-rb-btn-secondary-hover hover:!text-rb-font-primary
              focus:!no-underline focus:!text-rb-font-primary nowrap`;

            return (
                <div
                    key={id}
                    onClick={() => onClickHandler(id)}
                    className={classNames(itemClassName, itemClasses, {
                        [styles.activeItem]: isActiveTab,
                    })}
                >
                    {text}
                </div>
            );
        })}
    </div>
);
