import { parseQueryString } from 'utils/helpers/queryString';
import { HelpdeskSettingsStepEnum } from 'state/integrationsHelpdesk/helpdeskSettingsStepEnum';
import { HelpdeskTypeIntegrationEnum, InitialCreateIntegrationType } from 'state/integrationsHelpdesk/helpdeskIntegrationType';

export const initialCreateIntegrationState: InitialCreateIntegrationType = {
    step: HelpdeskSettingsStepEnum.ADD_APP,
    lastAchievedStep: HelpdeskSettingsStepEnum.ADD_APP,
    selectedApps: {},
    groups: [],
    selectedIntegration: undefined,
    currentApp: undefined,
};

const { helpdeskType, ...params } = parseQueryString(window.location.search);

export const helpdeskTypeParam = helpdeskType && typeof helpdeskType === 'string' && JSON.parse(helpdeskType);

export const getInitialIntegrationByTypeDesk = (desk: HelpdeskTypeIntegrationEnum) => {
    const getInitialIntegration = (): InitialCreateIntegrationType => {
        const initialCreateIntegrationKeys = Object.keys(initialCreateIntegrationState) as Array<
            keyof InitialCreateIntegrationType
        >;

        return initialCreateIntegrationKeys.reduce<InitialCreateIntegrationType>((result, key) => {
            const valueParams = params[key];

            return {
                ...result,
                [key]:
                    valueParams && typeof valueParams === 'string' ? JSON.parse(valueParams) : initialCreateIntegrationState[key],
            };
        }, initialCreateIntegrationState);
    };

    return {
        ...initialCreateIntegrationState,
        ...(helpdeskTypeParam === desk ? getInitialIntegration() : {}),
    };
};
