import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle, Ref } from 'react';
import classNames from 'classnames';
import styles from './shadowBlock.module.scss';

export enum ShadowBlockPositionEnum {
    BOTTOM,
    TOP,
}

export enum ShadowSizeEnum {
    SM,
    MD,
}

export type PropsType = React.HTMLAttributes<HTMLDivElement> & {
    shadowPosition: ShadowBlockPositionEnum;
    scrollWindow?: boolean;
    shadowSize?: ShadowSizeEnum;
    wrapperClassName?: string;
    scrollParentRef?: HTMLElement | null;
};

export const ShadowBlock = forwardRef(
    (
        {
            className,
            children,
            shadowPosition,
            scrollWindow,
            shadowSize = ShadowSizeEnum.SM,
            wrapperClassName,
            scrollParentRef,
        }: PropsType,
        shadowBlockForwardRef
    ) => {
        const shadowBlockRef = useRef<HTMLDivElement>(null);
        const [hasTopShadow, setTopShadow] = useState(false);
        const [hasBottomShadow, setBottomShadow] = useState(false);

        useImperativeHandle(shadowBlockForwardRef, () => shadowBlockRef.current);

        useEffect(() => {
            const scroll = (e: Event) => {
                const shadowBlockElement = shadowBlockRef.current;
                const wrapper = e.target as HTMLDivElement;

                if (shadowBlockElement && wrapper) {
                    if (shadowPosition === ShadowBlockPositionEnum.TOP) {
                        const shadowBlockBottom = shadowBlockElement.getBoundingClientRect().bottom;

                        const isBlockInBottom = scrollWindow
                            ? shadowBlockBottom < window.innerHeight ||
                              document.body.clientHeight === shadowBlockBottom + window.scrollY
                            : wrapper.scrollHeight - wrapper.scrollTop === wrapper.clientHeight;

                        setTopShadow(!isBlockInBottom);
                    }

                    if (shadowPosition === ShadowBlockPositionEnum.BOTTOM) {
                        const isBlockInTop = scrollWindow ? window.pageYOffset === 0 : wrapper.scrollTop === 0;

                        setBottomShadow(!isBlockInTop);
                    }
                }
            };

            const shadowBlockElement = shadowBlockRef.current;

            const scrolledWrapper = scrollWindow
                ? window
                : scrollParentRef || (shadowBlockElement?.parentElement as HTMLDivElement);

            const dispatchScroll = () => {
                if (scrolledWrapper) scrolledWrapper.dispatchEvent(new Event('scroll'));
            };

            if (scrolledWrapper) {
                scrolledWrapper.addEventListener('scroll', scroll);
                scrolledWrapper.dispatchEvent(new Event('scroll'));
                window.addEventListener('resize', dispatchScroll);
            }

            return () => {
                (scrolledWrapper as HTMLDivElement).removeEventListener('scroll', scroll);
                window.removeEventListener('resize', dispatchScroll);
            };
        }, [shadowBlockRef, scrollParentRef]);

        useEffect(() => {
            if (scrollWindow) return () => {};

            const shadowBlockElement = shadowBlockRef.current;
            const scrolledWrapper = shadowBlockElement?.parentElement as HTMLDivElement;

            if (!scrolledWrapper) return () => {};

            const config = {
                childList: true,
                subtree: true,
            };

            const observer = new MutationObserver(() => {
                scrolledWrapper.dispatchEvent(new Event('scroll'));
            });
            observer.observe(scrolledWrapper, config);

            return () => {
                if (observer) observer.disconnect();
            };
        }, [shadowBlockRef]);

        return (
            <div
                className={classNames(className, {
                    [styles.roundedBottomShadow16]: hasBottomShadow && shadowSize === ShadowSizeEnum.MD,
                    'shadow-top-8': hasTopShadow && shadowSize === ShadowSizeEnum.SM,
                    'shadow-bottom-8': hasBottomShadow && shadowSize === ShadowSizeEnum.SM,
                })}
                ref={shadowBlockRef}
            >
                <div className={classNames(wrapperClassName, 'w-full relative z-1')}>{children}</div>
            </div>
        );
    }
);
