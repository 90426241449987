import { ReviewFiltersActionTypeListEnum } from './actionsTypes';
import { ReviewFiltersActionType } from './actions';
import { FiltersReviewsEnum, ReviewFiltersInitialStateType } from './reviewFiltersTypes';
import { defaultReviewFilterSetListWithParams, reviewFilterSetList } from './reviewFilterSetList';

const filterReviewsInitialState = defaultReviewFilterSetListWithParams;

const reviewFilters = (state: ReviewFiltersInitialStateType = filterReviewsInitialState, action: ReviewFiltersActionType) => {
    switch (action.type) {
        case ReviewFiltersActionTypeListEnum.SET_SAVED_FILTER_PRESET: {
            return action.savedReviewFilters;
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_FILTER_PRESET: {
            return {
                ...state,
                ...reviewFilterSetList[action.presetId],
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_PENDING_REPLY: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_PENDING_REPLIES]: action.presetId,
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_ACTION_STATUS: {
            return {
                ...state,
                [FiltersReviewsEnum.ACTION_STATUS_REPLIES]: action.presetId,
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_SIMPLE_FILTER: {
            return {
                ...state,
                [action.filterId]: action.presetId,
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_LENGTH_REVIEW_FILTER: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEW_LENGTH]: {
                    from: action.lengthRange.from,
                    to: action.lengthRange.to,
                },
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_FILTER_DATE: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_DATE]: action.datePicker,
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_FILTER_VERSION_DATE: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_VERSION_DATE]: action.datePicker,
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_FILTER_RATING: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_RATING]: action.ratings,
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_FILTER_SWITCH_TYPE: {
            return {
                ...state,
                [action.filterType]: action.filterActiveValue,
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_FILTER_CHANGED_REVIEWS: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_CHANGE]: {
                    type: action.activeType,
                    switchOption: action.activeSwitch || state[FiltersReviewsEnum.REVIEWS_CHANGE].switchOption,
                },
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_FILTER_SORT: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_SORT]: {
                    type: action.sortId,
                    direction: action.sortDirection,
                },
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_FILTER_TAG: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_TAG]: {
                    ...state[FiltersReviewsEnum.REVIEWS_TAG],
                    ...(action.tagSearchType && { tagSearchType: action.tagSearchType }),
                    ...(action.tags && { tags: action.tags }),
                    ...(action.excludeTags && { excludeTags: action.excludeTags }),
                },
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_SEARCH_TEXT: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_SEARCH_TEXT]: action.search,
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_FILTER_REPLY: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_REPLY]: {
                    type: action.replyId,
                    teammatesIds: action.teammatesIds,
                },
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_REVIEWS_COUNTRIES: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_COUNTRIES]: action.countries,
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_REVIEWS_LANGUAGES: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_LANGUAGE]: action.languages,
            };
        }
        case ReviewFiltersActionTypeListEnum.CHANGE_FILTER_WITHOUT_TAG: {
            return {
                ...state,
                [FiltersReviewsEnum.REVIEWS_WITHOUT_TAG]: action.isWithoutTag,
            };
        }
        default: {
            return state;
        }
    }
};

export default reviewFilters;
