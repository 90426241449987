import { TOGGLE_KEYWORD_SUGGESTION_MODAL } from "state/keywordSuggestionsModal/actionTypes";
import { SuggestionModalDataType } from "state/keywordSuggestionsModal/controller";


export function toggleKeywordSuggestionModal(keywordInfo: SuggestionModalDataType) {
    return {
        type: TOGGLE_KEYWORD_SUGGESTION_MODAL,
        keywordInfo
    };
}
