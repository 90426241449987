import React, { FC } from 'react';
import classNames from 'classnames';
import { Icon } from 'newComponents/icons/asodeskIcons/Icon';
import { Icons } from 'types/icon-types';
import { getIsGuruAso, getIsPowerAso, getIsUltimateAso } from 'modules/modal/planModal/utils';
import { usePromo } from 'modules/promo/usePromo';
import styles from './asoFreeInfo.module.scss';

type PropsType = {
    planName: string;
};

export const AsoFreeInfo: FC<PropsType> = ({ planName }) => {
    const getBannerDescription = () => {
        if (getIsGuruAso(planName)) {
            return '1 Free Semantic Core';
        }

        if (getIsPowerAso(planName)) {
            return '1 Free Textual ASO';
        }

        if (getIsUltimateAso(planName)) {
            return '2 Free Textual ASO';
        }
    };

    const title = getBannerDescription();

    const { hasPromo } = usePromo();

    if (hasPromo) return null;

    return (
        <div className={classNames('flex px-12 py-16 text-white -mx-16 mt-16', styles.bgFreeInfo)}>
            <Icon name={Icons.Plus} size={24} lineHeight={24} className="mr-8" />
            <div>
                <div className="text-14 font-extrabold mt-2">{title}</div>
            </div>
        </div>
    );
};
