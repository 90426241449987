import { AppResponseType } from "state/appProfile/AppResponseType";
import { ADD_COMPETITOR_APP, REMOVE_COMPETITOR_APP, SET_COMPETITORS } from "state/competitors/actionTypes";

export function setCompetitorsAction(competitors: AppResponseType[]) {
    return {
        type: SET_COMPETITORS,
        competitors,
    };
}

export function addCompetitorApp(app: AppResponseType) {
    return {
        type: ADD_COMPETITOR_APP,
        app,
    };
}

export function removeCompetitorApp(storeId: string) {
    return {
        type: REMOVE_COMPETITOR_APP,
        storeId,
    };
}
