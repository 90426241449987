import {
    KeywordSuggestionsResponseType,
    KeywordSuggestionsType,
    SuggestionsKeywordsResponseType,
    SuggestionsKeywordsType,
} from 'state/keywordSuggestions/KeywordSuggestionsResponseType';

export const KeywordSuggestionsTypeAdapter = ({
    no_data,
    suggestions,
    related,
}: KeywordSuggestionsResponseType): KeywordSuggestionsType => {
    const adaptSuggestions = (suggestionByKeywords: SuggestionsKeywordsResponseType) => {
        const nweObject: SuggestionsKeywordsType = {};
        Object.keys(suggestionByKeywords).forEach((keyword) => {
            const keywordSuggestions = suggestions[keyword];
            nweObject[keyword] = keywordSuggestions.map(
                ({ keyword, id, traffic_score, translation, translation_from, in_userkeywords }) => ({
                    keyword,
                    keywordId: id,
                    trafficScore: traffic_score,
                    translation,
                    translationFrom: translation_from,
                    inUserKeywords: in_userkeywords,
                })
            );
        });
        return nweObject;
    };

    return {
        noData: no_data,
        suggestions: adaptSuggestions(suggestions),
        related: adaptSuggestions(related),
    };
};
