export enum Icons {
  Academy = 'academy',
  ActivityLog = 'activity-log',
  Add = 'add',
  AndroidLogo = 'android-logo',
  AnnualCalendar = 'annual-calendar',
  AppProfile = 'app-profile',
  AppleLogo = 'apple-logo',
  AppleSearchAds = 'apple-search-ads',
  ArrowBottomLine = 'arrow-bottom-line',
  ArrowBottomSolid = 'arrow-bottom-solid',
  ArrowBottom = 'arrow-bottom',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowTop = 'arrow-top',
  AsoTools = 'aso-tools',
  Aso = 'aso',
  Automation = 'automation',
  Back = 'back',
  BarChartDynamics = 'bar-chart-dynamics',
  BarChart = 'bar-chart',
  BellLineCrossed = 'bell-line-crossed',
  BellLine = 'bell-line',
  Bell = 'bell',
  Billing = 'billing',
  Boost = 'boost',
  Brand = 'brand',
  CalendarLine = 'calendar-line',
  Calendar = 'calendar',
  Chart = 'chart',
  Check = 'check',
  ChevronBottom = 'chevron-bottom',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronTop = 'chevron-top',
  Color = 'color',
  Community = 'community',
  ConnectionsHub = 'connections-hub',
  Consulting = 'consulting',
  CopySolid = 'copy-solid',
  Cource = 'cource',
  CreditCard = 'credit-card',
  Cross = 'cross',
  Dollar_2 = 'dollar-2',
  DollarApi = 'dollar-api',
  Dollar = 'dollar',
  Download = 'download',
  Drag = 'drag',
  ErrorIcon = 'error-icon',
  Eye = 'eye',
  FavoritesLine = 'favorites-line',
  Favorites = 'favorites',
  Filter = 'filter',
  Folder = 'folder',
  FreeTools = 'free-tools',
  Gear = 'gear',
  Help = 'help',
  Home = 'home',
  IconAppStoreLocalizations = 'icon-app-store-localizations',
  IconAppleStore = 'icon-apple-store',
  IconAsoComparativeReport = 'icon-aso-comparative-report',
  IconAutoReplies = 'icon-auto-replies',
  IconAutoSuggestions = 'icon-auto-suggestions',
  IconAutoTags = 'icon-auto-tags',
  IconBurger = 'icon-burger',
  IconCategoriesRanking = 'icon-categories-ranking',
  IconCharts = 'icon-charts',
  IconCompetitors = 'icon-competitors',
  IconDescriptionCreator = 'icon-description-creator',
  IconFavoriteKeywords = 'icon-favorite-keywords',
  IconFeaturedReviews = 'icon-featured-reviews',
  IconFindTrack = 'icon-find-track',
  IconGooglePlay = 'icon-google-play',
  IconGuide = 'icon-guide',
  IconLivePositions = 'icon-live-positions',
  IconMail = 'icon-mail',
  IconMore_3Dots = 'icon-more-3-dots',
  IconOrganicReport = 'icon-organic-report',
  IconPhraseMixer = 'icon-phrase-mixer',
  IconRatingAnalysis = 'icon-rating-analysis',
  IconReviewsAnalysis = 'icon-reviews-analysis',
  IconSelectedKeywords = 'icon-selected-keywords',
  IconTagsAnalysis = 'icon-tags-analysis',
  IconTags = 'icon-tags',
  IconTargetBlank = 'icon-target-blank',
  IconTemplates = 'icon-templates',
  IconTextMetadataBuilder = 'icon-text-metadata-builder',
  IconTrafficSources = 'icon-traffic-sources',
  IconZoom = 'icon-zoom',
  Info = 'info',
  Instant = 'instant',
  Integrations = 'integrations',
  Invite = 'invite',
  Lamp = 'lamp',
  LineChart = 'line-chart',
  LockLine = 'lock-line',
  LockSolid = 'lock-solid',
  Log = 'log',
  Mail = 'mail',
  Menu = 'menu',
  Minus = 'minus',
  Monitoring = 'monitoring',
  MoreDotsHorizontal = 'more-dots-horizontal',
  MoreDotsVertical = 'more-dots-vertical',
  MyApps = 'my-apps',
  Next = 'next',
  NoteSolid = 'note-solid',
  Notification = 'notification',
  Off = 'off',
  PencilEdit = 'pencil-edit',
  Percent = 'percent',
  Plus = 'plus',
  Profile = 'profile',
  Question = 'question',
  Rank = 'rank',
  Refresh = 'refresh',
  Report = 'report',
  Resources = 'resources',
  Reviews_2 = 'reviews-2',
  Reviews = 'reviews',
  Rocket = 'rocket',
  Rustore = 'rustore',
  Sale = 'sale',
  SettingsNav = 'settings-nav',
  Shield = 'shield',
  SignOut = 'sign-out',
  Sort = 'sort',
  StarList = 'star-list',
  StoreAnalytics_2 = 'store-analytics-2',
  StoreAnalytics = 'store-analytics',
  StoreConsole = 'store-console',
  Team = 'team',
  Teammates = 'teammates',
  TimerClockCrossed = 'timer-clock-crossed',
  TimerClockSolid = 'timer-clock-solid',
  TimerClock = 'timer-clock',
  Translate = 'translate',
  TrashLine = 'trash-line',
  TrashSolid = 'trash-solid',
  User = 'user',
  ZoomNotFound = 'zoom-not-found',
  Zoom = 'zoom',
}
