import { SpecialSubPlanResponseType } from 'state/plans/PlansResponseType';
import { PromoSubPlanType } from 'state/plans/PlansType';
import { featuresAdapter, getToolsFromFeaturesAndPlans } from 'state/plans/basePlansAdapter';

export const promoSubPlanAdapter = (plans: SpecialSubPlanResponseType[]): PromoSubPlanType[] =>
    plans.map(({ features, pricing, ...planProps }) => ({
        features: featuresAdapter(features),
        tools: getToolsFromFeaturesAndPlans(featuresAdapter(features)),
        pricing: pricing.map(({ price_saving, duration_months, total_price, total_price_discount, price, ...priceProps }) => ({
            priceSaving: price_saving,
            durationMonths: duration_months,
            totalPrice: total_price,
            totalPriceDiscount: total_price_discount,
            price: +price,
            ...priceProps,
        })),
        ...planProps,
    }));
