import { Action } from "redux";
import { ADD_KEYWORD_ROWS_ON_PAGE, REMOVE_KEYWORD_ROWS_ON_PAGE, SET_KEYWORD_ROWS_ON_PAGE } from "state/keywordRowsOnPage/actionTypes";
import { AppStatisticsRowStateType } from "state/keywordAnalytics/AppDataStatsStateType";

export type updateSelectedKeywordsAction = Action & {
    keywordList: AppStatisticsRowStateType[]
    keywordIdsList: number[]
}

export type ActionType = updateSelectedKeywordsAction

const keywordRowsOnPage = (state: AppStatisticsRowStateType[] = [], action: ActionType) => {
    switch (action.type) {
        case SET_KEYWORD_ROWS_ON_PAGE: {
            return [...action.keywordList];
        }
        case REMOVE_KEYWORD_ROWS_ON_PAGE: {
            action.keywordIdsList.forEach((id) => {
                state = state.filter((selectedRow) => selectedRow.id !== id)
            });

            return [...state];
        }
        case ADD_KEYWORD_ROWS_ON_PAGE: {

            return [
                ...state,
                ...action.keywordList
            ];
        }
        default: {
            return state;
        }
    }
};

export default keywordRowsOnPage;
