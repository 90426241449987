import { SubPlanType } from 'state/plans/PlansType';
import { FREE_PLAN_ID } from 'state/plans/customEmptyPlan';
import { useAppSelector } from 'hooks/useAppSystem';

export const usePlansGetter = () => {
    const plans = useAppSelector((state) => state.plans);
    const {
        isTrial,
        currentPlan,
        aso,
        reviews,
        reviewsAso,
        services,
        promo,
        enterprisePlan,
        lastExpiredTrial,
        plansDowngradeMeta,
    } = plans;

    const isCurrentPlanFree = Number(currentPlan?.id) === FREE_PLAN_ID;

    const reviewsPlans = reviews.reduce((result: SubPlanType[], reviewPlan) => [...result, ...reviewPlan.plans], []);
    const reviewsPlansIds = reviewsPlans.map(({ id }) => id);

    const asoPlans = aso.reduce((result: SubPlanType[], asoPlan) => [...result, ...asoPlan.plans], []);
    const asoPlansIds = asoPlans.map(({ id }) => id);
    const asoPlansNextIds = asoPlans.map(({ nextPlanId }) => nextPlanId);

    const reviewsAsoPlans = reviewsAso.reduce(
        (result: SubPlanType[], reviewsAsoPlan) => [...result, ...reviewsAsoPlan.plans],
        []
    );
    const reviewsAsoPlansIds = reviewsAsoPlans.map(({ id }) => id);
    const reviewsAsoPlansNextIds = reviewsAsoPlans.map(({ nextPlanId }) => nextPlanId);

    return {
        isTrial,
        currentPlan,
        isCurrentPlanFree,
        aso,
        reviews,
        reviewsAso,
        services,
        promo,
        enterprisePlan,
        lastExpiredTrial,
        reviewsPlans,
        reviewsPlansIds,
        asoPlans,
        asoPlansIds,
        reviewsAsoPlans,
        reviewsAsoPlansIds,
        plansDowngradeMeta,
        asoPlansNextIds,
        reviewsAsoPlansNextIds,
    };
};
