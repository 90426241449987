import { OnboardingStepEnum } from 'state/onboarding/types';

export const onboardingSteps = [
    { id: 0, steps: [] },
    {
        id: 1,
        steps: [
            OnboardingStepEnum.YOURSELF_STEP_FIRST,
            OnboardingStepEnum.YOURSELF_STEP_SECOND,
            OnboardingStepEnum.YOURSELF_STEP_THIRD,
            OnboardingStepEnum.YOURSELF_STEP_FOURTH,
        ],
    },
    {
        id: 2,
        steps: [
            OnboardingStepEnum.SELECT_COUNTRY_STEP,
            OnboardingStepEnum.ADD_FIRST_APP_STEP,
            OnboardingStepEnum.GLOBAL_NAVIGATION_STEP,
            OnboardingStepEnum.LOCAL_NAVIGATION_AND_TOOLS_STEP,
            OnboardingStepEnum.POSSIBILITIES_OF_ASODESK_STEP,
            OnboardingStepEnum.REVIEWS_RATINGS_STEP,
            OnboardingStepEnum.STORES_ANALYTICS_STEP,
            OnboardingStepEnum.CONNECTIONS_HUB_STEP,
            OnboardingStepEnum.KEYWORD_BOOST_STEP,
            OnboardingStepEnum.SCHEDULE_A_DEMO_STEP,
        ],
    },
    {
        id: 3,
        steps: [OnboardingStepEnum.CHOOSE_A_PLAN_STEP, OnboardingStepEnum.FINISH_ONBOARDING_STEP],
    },
];
