import React, { FC } from 'react';
import { Route, Navigate } from 'react-router-dom';
import {
    partialConnectionsHubRouteApiUrl,
    partialConnectionsHubRouteIntegrationsUrl,
    partialConnectionsHubRouteSettingsUrl,
    partialConnectionsHubRouteTimelineUrl,
} from 'utils/routeUrls';
import { AsyncAllReportsSettingsPageContainer } from 'pages/settings/reportsAndAlerts/allReportsSettings/AsyncAllReportsSettingsPageContainer';
import { AsyncReportsTimelinePageContainer } from 'pages/settings/reportsAndAlerts/reportsTimeline/AsyncReportsTimelinePageContainer';
import { AsyncIntegrationsPageContainer } from 'pages/settings/integrations/AsyncIntegrationsPageContainer';
import { AsyncApiPlansPageContainer } from 'pages/settings/apiPlans/AsyncApiPlansPageContainer';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { CheckPrivateRoute } from 'modules/routers/new/CheckPrivateRoute';
import { FaroRoutes } from '@grafana/faro-react';

export const ConnectionsHubSettingsRedirect: FC = () => {
    const { connectionsHubSettingsUrl } = useRoutesUrl();

    return <Navigate to={connectionsHubSettingsUrl} replace />;
};

export const ConnectionsHubRoutes: FC = () => (
    <FaroRoutes>
        <Route path="*" element={<ConnectionsHubSettingsRedirect />} />
        <Route path={partialConnectionsHubRouteSettingsUrl} element={<AsyncAllReportsSettingsPageContainer />} />
        <Route path={partialConnectionsHubRouteTimelineUrl} element={<AsyncReportsTimelinePageContainer />} />
        <Route path={partialConnectionsHubRouteIntegrationsUrl} element={<AsyncIntegrationsPageContainer />} />
        <Route
            path={partialConnectionsHubRouteApiUrl}
            element={
                <CheckPrivateRoute>
                    <AsyncApiPlansPageContainer />
                </CheckPrivateRoute>
            }
        />
    </FaroRoutes>
);
