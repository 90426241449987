import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { authRouteLoginUrl } from 'utils/routeUrls';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { BallLoader } from 'components/loader/ballLoader/BallLoader';
import { useRequestInfo } from 'hooks/state/useRequestInfo';
import { useAppSelector } from 'hooks/useAppSystem';

export const CheckPrivateRoute: FC = ({ children }) => {
    useRequestInfo();

    const { isLoaded: isLoadedRequestInfo } = useRequestInfoService();

    const isLogged = useAppSelector((state) => state.user.isLogged);

    if (!isLoadedRequestInfo) return <BallLoader pageCenter />;

    return <>{isLogged ? children : <Navigate to={authRouteLoginUrl} replace />}</>;
};
