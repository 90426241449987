export enum ToolTypesEnum {
    APP_MONITORING = 'app-monitoring',
    APP_STORE_OPTIMIZATIONS = 'app-store-optimizations',
    APPS = 'apps',
    REVIEWS_RATINGS = 'reviews-ratings',
    STORE_ANALYTICS = 'store-analytics',
    STORE_CONSOLE = 'store-console',
    SETTINGS = 'settings',
    FREE_TOOLS = 'free-tools',
    RESOURCES = 'resources',
}
