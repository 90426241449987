import { PlansStateType } from 'state/plans/PlansType';

export const PlansInitialState: PlansStateType = {
    reviews: [],
    aso: [],
    reviewsAso: [],
    services: [],
    promo: [],
    enterprisePlan: {
        features: [],
        additionalFeatures: [],
        customerSupportFeatures: [],
    },
    plansFeaturesWereLoaded: false,
    plansFeatures: {},
    plansDowngradeMeta: {},
};
