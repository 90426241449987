export const isMacOS = () => navigator.platform.includes('Mac');

export const isWindowsOS = () => navigator.platform.includes('Win');

export const isAndroid = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    return userAgent.includes('android') && userAgent.includes('mobile');
};

export const isIOS = () => {
    const { userAgent } = navigator;

    return /iPad|iPhone|iPod/.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
};
