import { HighlightsKeywordsTypeEnum, KeywordsCounterResponseType, Top5AppsType } from './keywordAnalyzerResponseTypes';

export enum DirectionTypeEnum {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

export type KeywordDataItemType = {
    canStartCampaign: boolean;
    countryCode: string;
    difference: string;
    differenceDescription: string;
    firstRank: number | string;
    lastRank: number | string;
    id: number;
    keyword: string;
    popularity: number;
    top5apps: Top5AppsType;
    totalApps: number;
    translation: string;
    translationFrom: string;
    updateDate: string;
    usersPerDay: number;
    isNeedsUpdate: boolean;
};

export type FavoriteKeywordsDataType = {
    [country: string]: KeywordDataItemType[];
};

export type HighlightsKeywordType = {
    [key in HighlightsKeywordsTypeEnum]: KeywordDataItemType[];
};

export type HighlightsKeywordsDataType = {
    [country: string]: HighlightsKeywordType;
};

export type KeywordsCounterType = KeywordsCounterResponseType;
