import React from 'react';
import { AvailableFeaturesType } from 'state/appProfile/AppProfileResponseType';
import classNames from 'classnames';
import { useAppProfileService } from 'hooks/data/useAppProfileService';
import { PermissionTooltip } from 'components/tooltips/permissionTooltip';

type PropsType = {
    feature?: keyof AvailableFeaturesType;
    checkDemo?: boolean;
    hideTooltip?: boolean;
    additionText?: string;
    lg?: boolean;
    forceShow?: boolean;
    className?: string;
};

const PermissionIconView: React.FC<PropsType> = ({
    feature,
    checkDemo,
    hideTooltip,
    additionText,
    lg,
    forceShow,
    className,
}: PropsType) => {
    const { isAvailableFeature, isDemo } = useAppProfileService();
    const isNotAvailableFeature = feature && !isAvailableFeature(feature);
    const showForDemo = checkDemo ? isDemo : false;
    const show = isNotAvailableFeature || showForDemo;

    return show || forceShow ? (
        <span className={classNames('ib', { ml5: !className }, className)}>
            <PermissionTooltip additionText={additionText} feature={feature} hideTooltip={hideTooltip}>
                <i className={classNames('fa fa-lock', { 'fa-lg': lg })} />
            </PermissionTooltip>
        </span>
    ) : null;
};

export const PermissionIcon = React.memo(PermissionIconView);
