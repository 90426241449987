import React, { FC } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { CheckPrivateRoute } from 'modules/routers/new/CheckPrivateRoute';
import { AsyncCampaignsPage } from 'pages/apps/campaigns/AsyncCampaignsPage';
import { AsyncCampaignPage } from 'pages/apps/campaign/AsyncCampaignPage';
import { getPartialKeywordBoostRouteByCampaignIdUrl, getPartialKeywordBoostRouteIndexUrl } from 'utils/routeUrls';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { FaroRoutes } from '@grafana/faro-react';

export const KeywordBoostRedirect: FC = () => {
    const { keywordBoostUrl } = useRoutesUrl();

    return <Navigate to={keywordBoostUrl} replace />;
};

export const KeywordBoostRoutes: FC = () => (
    <FaroRoutes>
        <Route path="*" element={<KeywordBoostRedirect />} />
        <Route
            index
            path={getPartialKeywordBoostRouteIndexUrl(':appId')}
            element={
                <CheckPrivateRoute>
                    <AsyncCampaignsPage />
                </CheckPrivateRoute>
            }
        />
        <Route
            path={getPartialKeywordBoostRouteByCampaignIdUrl(':appId', ':campaignId')}
            element={
                <CheckPrivateRoute>
                    <AsyncCampaignPage />
                </CheckPrivateRoute>
            }
        />
    </FaroRoutes>
);
