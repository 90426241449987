import axios from 'axios';
import { apiUrls } from 'utils/Urls';

export const getHubspotToken = () => async () => {
    try {
        const response = await axios.get<{ token: string }>(apiUrls.getHubspotToken());

        return response.data.token;
    } catch (error) {
        console.error(error);
    }
};
