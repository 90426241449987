import { TagsType } from 'state/optimizer/optimizerTypes';
import {
    EMOJI_REGEXP,
    KEYWORD_SEPARATES_OPTIMIZER_REGEXP,
    KEYWORD_NEW_LINE_SEPARATES_REGEXP,
    SPECIAL_SYMBOLS_REGEXP,
} from 'constants/regExpConstants';
import { optimizerInputs } from 'constants/optimizerTagsCountants';
import { ClipboardEvent } from 'react';
import { KeyEnum } from 'constants/keyCodeConstants';
import { ElementColors } from 'constants/colorConstants';
import { withPlural } from 'utils/helpers/string/text';
import { AppStoreType } from 'state/appProfile/AppResponseType';

export const getStringFromTags = (tags: TagsType, isAppStoreKeywords: boolean): string => {
    if (!tags.length) return '';
    const separator = isAppStoreKeywords ? KeyEnum.Comma : KeyEnum.Space;
    return tags.reduce((result, tag, index) => {
        return !index || (!isAppStoreKeywords && tag.search(SPECIAL_SYMBOLS_REGEXP) !== -1)
            ? `${result}${tag}`
            : `${result}${separator}${tag}`;
    }, '');
};

const replaceSpecialSymbols = (match: string, p1?: string, p2?: string, p3?: string, offset?: number): string => {
    switch (true) {
        case !!p1 && !!offset:
            return `${KeyEnum.Space}${match}`;
        case !!p2 && !!offset:
            return `${KeyEnum.Space}${match}`;
        case !!p3 && !offset:
            return `${match}${KeyEnum.Space}`;
        default:
            return match;
    }
};

export const getTagsAndSpecialSymbols = (str: string): TagsType => {
    if (str === KeyEnum.Space) return [];
    const matchAll = str.trim().replace(SPECIAL_SYMBOLS_REGEXP, replaceSpecialSymbols);
    return matchAll.split(KeyEnum.Space).map((tag: string) => tag.trim());
};

export const getTagsFromString = (data: string, isAppStoreKeywords: boolean): TagsType => {
    if (!data.length) return [];
    const separators = isAppStoreKeywords
        ? `${KeyEnum.Comma}|${KEYWORD_SEPARATES_OPTIMIZER_REGEXP}|${KEYWORD_NEW_LINE_SEPARATES_REGEXP}`
        : `${KeyEnum.Space}|${KEYWORD_SEPARATES_OPTIMIZER_REGEXP}|${KEYWORD_NEW_LINE_SEPARATES_REGEXP}`;
    const stringWithoutEmoji = data.replace(EMOJI_REGEXP, KeyEnum.Space);
    return stringWithoutEmoji
        .split(new RegExp(separators))
        .map((tag: string) => {
            if (isAppStoreKeywords) return tag.trim();
            return getTagsAndSpecialSymbols(tag);
        })
        .flat()
        .filter((tag: string) => !!tag);
};

type ColorTagType = {
    textTooltip?: string;
    color?: ElementColors;
};

export const getColorTag = (countTag: number, tag: string, isAppStore: boolean): ColorTagType => {
    const wordsLength = tag.length;
    const addText = `${wordsLength} ${withPlural('symbol', wordsLength)}`;
    switch (true) {
        case countTag === -1:
            return {
                textTooltip: addText,
                color: ElementColors.LightGray2,
            };
        case countTag === 0:
            return {
                textTooltip: `Stop-word.\n${addText}`,
                color: ElementColors.CorporateBlue,
            };
        case wordsLength > 10:
            return {
                textTooltip: `More than 10 symbols.\n${addText}`,
                color: ElementColors.Red,
            };
        case countTag > 1 && !isAppStore:
            return {
                textTooltip: `Keyword is repeated more than 1 times.\n${addText}`,
                color: ElementColors.Warning,
            };
        case countTag > 2:
            return {
                textTooltip: `Keyword is repeated more than 2 times.\n${addText}`,
                color: ElementColors.Red,
            };
        case countTag === 2:
            return {
                textTooltip: `Keyword is repeated twice.\n${addText}`,
                color: ElementColors.Warning,
            };
        case countTag === 1:
            return {
                textTooltip: addText,
                color: ElementColors.Success,
            };
        default:
            return {};
    }
};

export const getLabelOptimizerInput = (name: string, storeType: AppStoreType): string | null => {
    const inputs = optimizerInputs[storeType];
    const input = inputs.find((item) => item.name === name);
    return input ? input.label : null;
};

export const getLabelOptimizerInputByShortName = (shortName: string, storeType: AppStoreType): string | null => {
    const inputs = optimizerInputs[storeType];
    const input = inputs.find((item) => item.short === shortName);
    return input ? input.label : null;
};

export const getNameCountryFlag = (lang: string): string => {
    const separator = '-';
    if (!lang.includes(separator)) return lang;
    return lang.toLowerCase().split(separator)[1];
};

export const getClipboardData = (e: ClipboardEvent): string => {
    return e.clipboardData ? e.clipboardData.getData('text/plain') : '';
};

export const decodeHtml = (html: string) => {
    if (!html || !html.includes('&')) return html;
    const text = document.createElement('textarea');
    text.innerHTML = html;
    return text.value;
};

export const getUniqueKeywordList = (keywordList: string[]): string[] => {
    return keywordList.reduce((acc: string[], keyword: string) => {
        const value = keyword.trim();
        if (!acc.includes(value)) return [...acc, value];
        return acc;
    }, []);
};
