import axios from 'axios';
import { Dispatch } from 'redux';
import { Urls } from 'utils/Urls';
import { formDataHeaders } from 'constants/formDataHeaders';
import { setReactChartsSize } from 'utils/helpers/setChartsSize';
import { ToolTypesEnum } from 'types/toolTypes';
import { setToggleHidingToolsAction } from 'state/system/actions';
import { ThunkDispatch } from 'types/react-redux-thunk';
import { FrontendSettingsResponseType } from 'state/userFrontendSettings/FrontendSettingsResponseType';
import { adapterFrontendSettings } from 'state/userFrontendSettings/adapterFrontendSettings';
import { denormalizeAppReviewAutoTranslate } from 'state/userFrontendSettings/adapterAppReviewAutoTranslate';
import { KeywordExplorerWidgetEnum } from 'state/userFrontendSettings/UserFrontendSettingsInitialStateType';
import { TagCategorySortingEnum } from 'modules/reviews/reviewTags/sortingDropdownMenu/sortingOptions';
import { isUndefined } from 'utils/typeGuard';
import { RootState } from 'state/store';
import {
    toggleCollapsedSidebarSectionAction,
    updateSidebarSettingsAction,
    setStatsUserLimitDataAction,
    toggleOpenedDesktopSidebarAction,
    toggleFavoriteToolAction,
    changeFavoriteLinkPositionAction,
    setReplyBoardRandomTemplateFlagAction,
    setReplyBoardAutoNextReviewFlagAction,
    setKeywordCountryFlagShowAction,
    changeKeywordAnalyticsTableColumnOrderAction,
    addKeywordAnalyticsTableColumnInvisibilityAction,
    removeKeywordAnalyticsTableColumnInvisibilityAction,
    changeShowTrialByTypeAction,
    changeKeywordExplorerWidgetOrderAction,
    setTagsCategorySortingAction,
    setGlobalAppLoaderShowAction,
} from './actions';
import { StatsUserLimitResponseType } from './SidebarResponseType';
import { statsUserLimitDataConverter } from './sidebarDataConverter';

export const updateSidebarSettings = () => async (dispatch: Dispatch, store: () => RootState) => {
    const {
        userFrontendSettings: {
            isOpenSidebarOnMobile,
            sidebarSections: { ids: defaultIds, byIds: defaultByIds },
        },
    } = store();

    const response = await axios.get<FrontendSettingsResponseType>(Urls.ui_config(), {
        headers: formDataHeaders.headersJson,
    });

    const { toggleHidingTools, sections } = response.data;

    if (sections) {
        const updatedData = adapterFrontendSettings(response.data, { defaultIds, defaultByIds });
        dispatch(
            updateSidebarSettingsAction({
                isOpenSidebarOnMobile,
                ...updatedData,
            })
        );
        dispatch(setToggleHidingToolsAction(toggleHidingTools));
    }
};

export const saveFrontendSettings = (localIsNewNavigation?: boolean) => async (dispatch: Dispatch, store: () => RootState) => {
    const {
        system: { toggleHidingTools },
        userFrontendSettings: {
            isOpenSidebarOnDesktop,
            sidebarSections: { ids, byIds },
            favoriteTools,
            isReplyBoardRandomTemplateSet,
            isReplyBoardAutoNextReviewSet,
            isKeywordCountryFlagShow,
            keywordAnalyticsColumnTableOrder,
            keywordAnalyticsColumnTableInvisibility,
            appReviewAutoTranslate,
            showTrialExpiredPlan,
            showTrialExpiredTomorrowPlan,
            keywordExplorerWidgetOrder,
            tagsCategoriesSort,
            isShowWelcomeOfferModal,
            isGlobalAppLoaderShow,
            isNewNavigation,
            lastCurrentAppId,
            lastCurrentLocale,
        },
    } = store();

    const settings = ids.map((id: ToolTypesEnum) => byIds[id]);

    const frontendSettingsData: FrontendSettingsResponseType = {
        isOpen: isOpenSidebarOnDesktop,
        sections: settings,
        toggleHidingTools,
        favorites: favoriteTools,
        isReplyBoardRandomTemplateSet,
        isReplyBoardAutoNextReviewSet,
        isKeywordCountryFlagShow,
        keywordAnalyticsColumnTableOrder,
        keywordAnalyticsColumnTableInvisibility,
        appReviewAutoTranslate: denormalizeAppReviewAutoTranslate(appReviewAutoTranslate),
        showTrialExpiredPlan,
        showTrialExpiredTomorrowPlan,
        keywordExplorerWidgetOrder,
        tagsCategoriesSort,
        isShowWelcomeOfferModal,
        isGlobalAppLoaderShow,
        ...(isUndefined(localIsNewNavigation) ? { isNewNavigation } : { isNewNavigation: localIsNewNavigation }),
        lastCurrentAppId,
        lastCurrentLocale: lastCurrentLocale?.toLowerCase(),
    };

    const jsonSettings = JSON.stringify(frontendSettingsData);

    await axios.post<string>(Urls.ui_config(), jsonSettings, {
        headers: formDataHeaders.headersJson,
    });
};

export const toggleCollapsedSidebarSection = (sectionId: ToolTypesEnum) => (dispatch: ThunkDispatch) => {
    dispatch(toggleCollapsedSidebarSectionAction(sectionId));
    dispatch(saveFrontendSettings());
};

export const setReplyBoardRandomTemplateFlag = (randomFlag: boolean) => (dispatch: ThunkDispatch, store: () => RootState) => {
    const {
        userFrontendSettings: { isReplyBoardRandomTemplateSet },
    } = store();

    if (isReplyBoardRandomTemplateSet !== randomFlag) {
        dispatch(setReplyBoardRandomTemplateFlagAction(randomFlag));
        dispatch(saveFrontendSettings());
    }
};

export const setReplyBoardAutoNextReviewFlag = (randomFlag: boolean) => (dispatch: ThunkDispatch, store: () => RootState) => {
    const {
        userFrontendSettings: { isReplyBoardAutoNextReviewSet },
    } = store();

    if (isReplyBoardAutoNextReviewSet !== randomFlag) {
        dispatch(setReplyBoardAutoNextReviewFlagAction(randomFlag));
        dispatch(saveFrontendSettings());
    }
};

export const setShowExpiredFlag = (show: boolean) => async (dispatch: ThunkDispatch) => {
    dispatch(changeShowTrialByTypeAction('showTrialExpiredPlan', show));
    await dispatch(saveFrontendSettings());
};

export const setStatsUserLimitData = () => async (dispatch: Dispatch) => {
    const response = await axios.get<StatsUserLimitResponseType>(Urls.sidebar_info());

    if (response.data.subscription) {
        const convertedData = statsUserLimitDataConverter(response.data);

        dispatch(setStatsUserLimitDataAction(convertedData));
    }
};

export const toggleOpenedDesktopSidebar = () => (dispatch: ThunkDispatch) => {
    dispatch(toggleOpenedDesktopSidebarAction());
    dispatch(saveFrontendSettings());
    setReactChartsSize();
};

export const toggleFavoriteTool = (toolName: string) => (dispatch: ThunkDispatch) => {
    dispatch(toggleFavoriteToolAction(toolName));
    dispatch(saveFrontendSettings());
};

export const changeFavoriteLinkPosition = (oldIndex: number, newIndex: number) => (dispatch: ThunkDispatch) => {
    dispatch(changeFavoriteLinkPositionAction(oldIndex, newIndex));
    dispatch(saveFrontendSettings());
};

export const setKeywordCountryFlagShow = (isShow: boolean) => (dispatch: ThunkDispatch) => {
    dispatch(setKeywordCountryFlagShowAction(isShow));
    dispatch(saveFrontendSettings());
};

export const setGlobalAppLoaderShow = (isShow: boolean) => async (dispatch: ThunkDispatch) => {
    dispatch(setGlobalAppLoaderShowAction(isShow));
    await dispatch(saveFrontendSettings());
};

export const changeKeywordAnalyticsTableColumnOrder = (columnsIds: string[]) => (dispatch: ThunkDispatch) => {
    dispatch(changeKeywordAnalyticsTableColumnOrderAction(columnsIds));
    dispatch(saveFrontendSettings());
};

export const changeKeywordAnalyticsTableColumnInvisibility = (columnsId: string, needHide: boolean) => (
    dispatch: ThunkDispatch
) => {
    if (needHide) {
        dispatch(addKeywordAnalyticsTableColumnInvisibilityAction(columnsId));
    } else {
        dispatch(removeKeywordAnalyticsTableColumnInvisibilityAction(columnsId));
    }

    dispatch(saveFrontendSettings());
};

export const changeKeywordExplorerWidgetOrder = (order: KeywordExplorerWidgetEnum[]) => (dispatch: ThunkDispatch) => {
    dispatch(changeKeywordExplorerWidgetOrderAction(order));
    dispatch(saveFrontendSettings());
};

export const setTagsCategorySorting = (sorting: TagCategorySortingEnum) => async (dispatch: ThunkDispatch) => {
    dispatch(setTagsCategorySortingAction(sorting));
    await dispatch(saveFrontendSettings());
};
