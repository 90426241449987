import React from 'react';
import loader from 'images/inlineStyleImages/loader/keyword-loader.svg';
import classNames from 'classnames';
import styles from 'components/loader/inlineLoader/inlineLoader.module.scss';

export enum InlineLoaderSizeEnum {
    XXL = 'xxl',
    XL = 'xl',
    LG = 'lg',
    SM = 'sm',
    XS = 'xs',
}

type PropsType = {
    isWhite?: boolean;
    className?: string;
    size?: InlineLoaderSizeEnum;
};

const InlineLoaderElement: React.FC<PropsType> = ({ isWhite, className, size = InlineLoaderSizeEnum.LG }: PropsType) => (
    <img
        src={loader}
        className={classNames(className, styles[size], {
            [styles.white]: isWhite,
        })}
        alt="loader"
        data-testid="inline-loader"
    />
);

export const InlineLoader = React.memo(InlineLoaderElement);
