import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'types/react-redux-thunk';
import axios from 'axios';
import { Urls } from 'utils/Urls';
import { hijack } from 'constants/backendConfig';
import {
    RESPONSE_STATUS_CODE_FORBIDDEN,
    RESPONSE_STATUS_CODE_INTERNAL_SERVER_ERROR,
    RESPONSE_STATUS_CODE_SERVICE_UNAVAILABLE,
    RESPONSE_STATUS_CODE_UNAUTHORIZED,
} from 'constants/responseStatusCodeConstants';
import { checkUserAuth } from 'state/account/controller';
import { useAsodeskErrorBoundary } from 'hooks/useAsodeskErrorBoundary';
import { getCookieAccessToken } from 'utils/axios-settings/user-token-service';
import { RefreshTokenSettingsType, updateAccessToken } from 'utils/helpers/jwtTokenHelpers';
import { authRouteLoginUrl } from 'utils/routeUrls';
import { logoutState } from 'state/user/controller';
import { initFaro } from 'faro/faroInit';
import { useCheckDeviceType } from 'hooks/state/useCheckDeviceType';

type AxiosInterceptorContainerType = {
    children: ReactNode;
};

const refreshSettings: RefreshTokenSettingsType = {
    isRefreshing: false,
    refreshSubscribers: [],
};

export const AxiosInterceptorContainer: FC<AxiosInterceptorContainerType> = ({ children }) => {
    const dispatch = useDispatch<ThunkDispatch>();

    const { isMobile } = useCheckDeviceType();

    const navigate = useNavigate();

    const [isReadyAxiosInterceptors, setIsReadyAxiosInterceptors] = useState(false);
    const [isLoadFaro, setIsLoadFaro] = useState(false);

    const { setInternalServerError } = useAsodeskErrorBoundary();

    const { pathname } = useLocation();

    useEffect(() => {
        const loadFaro = async () => {
            await initFaro(isMobile);

            setIsLoadFaro(true);
        };

        loadFaro();
    }, []);

    useEffect(() => {
        let interceptorRequest: number | undefined;
        let interceptorResponse: number | undefined;
        let internalServerErrorUrl: string | undefined;

        const onResetError = (url?: string) => {
            if (url === internalServerErrorUrl) {
                internalServerErrorUrl = undefined;
                setInternalServerError();
            }
        };

        const onSetError = (error: any) => {
            const internalServerErrors = [RESPONSE_STATUS_CODE_INTERNAL_SERVER_ERROR, RESPONSE_STATUS_CODE_SERVICE_UNAVAILABLE];

            const hasServerError = error?.response && internalServerErrors.includes(error.response.status);

            if (hasServerError) {
                const internalServerError = new Error(
                    'A failure occurred, data may take longer than usual to load. We are already working on a solution to the problem and will fix it soon.'
                );

                internalServerErrorUrl = error.response.config.url;

                setInternalServerError({ url: error.response.config.url, error: internalServerError });
            }
        };

        const onSetErrorBridge = (event: CustomEvent) => {
            const error = event?.detail?.error;

            onSetError(error);
        };

        const onResetErrorBridge = (event: CustomEvent) => {
            const url = event?.detail?.url;

            onResetError(url);
        };

        const onReadyAxiosInterceptors = () => {
            axios.defaults.withCredentials = true;

            interceptorRequest = axios.interceptors.request.use(async (config) => {
                if (config.url !== Urls.auth_token_refresh() && getCookieAccessToken() && !hijack) {
                    await updateAccessToken({
                        refreshSettings,
                        config,
                        updateToken: async () => {
                            await dispatch(checkUserAuth(refreshSettings));
                        },
                    });

                    config.headers.Authorization = `Token ${getCookieAccessToken()}`;
                }

                onResetError(config.url);

                return config;
            });

            interceptorResponse = axios.interceptors.response.use(
                async (response) => response,
                async (error) => {
                    if (
                        error.response &&
                        [
                            RESPONSE_STATUS_CODE_INTERNAL_SERVER_ERROR,
                            RESPONSE_STATUS_CODE_UNAUTHORIZED,
                            RESPONSE_STATUS_CODE_FORBIDDEN,
                        ].includes(error.response.status) &&
                        error.response.config.url.includes(Urls.auth_token_refresh())
                    ) {
                        dispatch(logoutState());
                        navigate(authRouteLoginUrl);
                    }

                    onSetError(error);

                    return Promise.reject(error);
                }
            );

            setIsReadyAxiosInterceptors(true);
        };

        if (isLoadFaro) {
            onReadyAxiosInterceptors();

            window.addEventListener('setResponseErrorAngularToReact', onSetErrorBridge as EventListener);
            window.addEventListener('resetResponseErrorAngularToReact', onResetErrorBridge as EventListener);
        }

        return () => {
            interceptorRequest && axios.interceptors.request.eject(interceptorRequest);
            interceptorResponse && axios.interceptors.response.eject(interceptorResponse);

            window.removeEventListener('setResponseErrorAngularToReact', onSetErrorBridge as EventListener);
            window.removeEventListener('resetResponseErrorAngularToReact', onResetErrorBridge as EventListener);
        };
    }, [isLoadFaro]);

    useEffect(() => {
        setInternalServerError();
    }, [pathname]);

    return <>{isReadyAxiosInterceptors && children}</>;
};
