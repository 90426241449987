import { StoreConsoleType } from 'state/storeConsoleAccounts/StoreConsoleAccountsStateType';
import { updateArray } from 'utils/helpers/array/updateArray';
import { StoreConsoleActionTypeEnum } from './actionsTypes';
import { StoreConsoleActionType } from './actions';

export type StoreConsoleAccountsStateType = {
    consoleList: StoreConsoleType[];
};

const initialState: StoreConsoleAccountsStateType = {
    consoleList: [],
};

export const storeConsoleAccounts = (state = initialState, action: StoreConsoleActionType): StoreConsoleAccountsStateType => {
    const findUpdatedConsole = (consoleId: number) => {
        const updatedConsoleIndex = state.consoleList.findIndex((console) => console.id === consoleId);
        const updatedConsole = state.consoleList[updatedConsoleIndex];
        const needCreateEmpty = updatedConsole && !updatedConsole.id;

        return {
            console: needCreateEmpty ? { id: action.data, apps: [] } : updatedConsole,
            index: updatedConsoleIndex,
        };
    };

    switch (action.type) {
        case StoreConsoleActionTypeEnum.RECEIVED_ACC_CONSOLES: {
            return {
                ...state,
                consoleList: action.data,
            };
        }
        case StoreConsoleActionTypeEnum.CONSOLE_CREATED: {
            return {
                ...state,
                consoleList: state.consoleList.concat(action.data),
            };
        }
        case StoreConsoleActionTypeEnum.RECEIVED_CONSOLE_APPS: {
            const { console, index } = findUpdatedConsole(action.data.consoleId);
            if (console) console.apps = action.data.apps;

            return {
                ...state,
                consoleList: updateArray(state.consoleList, console, index) as StoreConsoleType[],
            };
        }
        case StoreConsoleActionTypeEnum.REMOVED_ACC_CONSOLE: {
            return {
                ...state,
                consoleList: state.consoleList.filter((console) => console.id !== action.data),
            };
        }
        case StoreConsoleActionTypeEnum.CONSOLE_UPDATED: {
            const { console, index } = findUpdatedConsole(action.data.consoleId);

            return {
                ...state,
                consoleList: updateArray(state.consoleList, { ...console, ...action.data }, index),
            };
        }
        case StoreConsoleActionTypeEnum.APP_TOGGLE_TRACK: {
            const { console, index } = findUpdatedConsole(action.data.consoleId);
            const appIndex = console.apps.findIndex((consoleApp) => consoleApp.id === action.data.app.id);
            const updatedApp = { ...console.apps[appIndex], tracked: action.data.state };

            return {
                ...state,
                consoleList: updateArray(
                    state.consoleList,
                    { ...console, apps: updateArray(console.apps, updatedApp, appIndex) },
                    index
                ) as StoreConsoleType[],
            };
        }
        default:
            return state;
    }
};
