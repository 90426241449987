// @ts-nocheck
import { StoreConsoleApplicationsActionTypeEnum } from './actionsTypes';

const defaultAppState = {
    apps: {},
    editorFields: {},
    quickFields: {},
    preSubmits: {},
    autoSaveTime: '',
};

export const storeConsoleApplications = (state = defaultAppState, action) => {
    const apps = { ...state.apps };
    const currentApp = action.data ? apps[action.data.appId] : null;
    switch (action.type) {
        case StoreConsoleApplicationsActionTypeEnum.RECEIVED_APP_LOCALES:
            currentApp.app_locales = action.data.appLocales;
            return {
                ...state,
                apps,
            };
        case StoreConsoleApplicationsActionTypeEnum.RECEIVED_APP_OVERVIEW:
            apps[action.data.appId] = {
                ...apps[action.data.appId],
                ...action.data.overview,
            };
            return {
                ...state,
                apps,
            };
        case StoreConsoleApplicationsActionTypeEnum.RECEIVED_VERSIONS:
            apps[action.data.appId].versions_by_locale = apps[action.data.appId].versions_by_locale || {};
            apps[action.data.appId].versions_by_locale[action.data.localeCode] = action.data.versions;
            return {
                ...state,
                apps,
            };
        case StoreConsoleApplicationsActionTypeEnum.RECEIVED_APP_FIELDS:
            return {
                ...state,
                editorFields: {
                    ...state.editorFields,
                    [action.data.appId]: {
                        ...state.editorFields[action.data.appId],
                        [action.data.localeCode]: action.data.fields,
                    },
                },
            };
        case StoreConsoleApplicationsActionTypeEnum.RECEIVED_QUICK_FIELDS:
            return {
                ...state,
                quickFields: {
                    ...state.quickFields,
                    [action.data.appId]: {
                        [action.data.fieldName]: action.data.fields,
                    },
                },
            };
        case StoreConsoleApplicationsActionTypeEnum.RECEIVED_APP_PROFILE:
            apps[action.data.appId] = {
                ...apps[action.data.appId],
                profile: action.data.profile,
            };

            return {
                ...state,
                apps,
            };
        case StoreConsoleApplicationsActionTypeEnum.RECEIVED_PRE_SUBMIT:
            return {
                ...state,
                preSubmits: {
                    ...state.preSubmits,
                    [action.data.appId]: action.data.presubmits,
                },
            };
        case StoreConsoleApplicationsActionTypeEnum.UPDATE_PRE_SUBMIT:
            const newPresubmits = [...state.preSubmits[action.data.appId]];
            const updatedPresubmitIndex = newPresubmits.findIndex(
                (presubmit) => action.data.updatedPresubmit.id === presubmit.id
            );
            newPresubmits[updatedPresubmitIndex] = action.data.updatedPresubmit;

            return {
                ...state,
                preSubmits: {
                    ...state.preSubmits,
                    [action.data.appId]: newPresubmits,
                },
            };
        case StoreConsoleApplicationsActionTypeEnum.RECEIVED_KEYWORDS_SCORE:
            return {
                ...state,
                keywordsScore: {
                    [action.data.appId]: action.data.score,
                },
            };
        case StoreConsoleApplicationsActionTypeEnum.RECEVIED_AUTOSAVETIME:
            return {
                ...state,
                autoSaveTime: action.data,
            };
        default:
            return state;
    }
};
