import { AppResponseType } from 'state/appProfile/AppResponseType';
import { ADD_COMPETITOR_APP, REMOVE_COMPETITOR_APP, SET_COMPETITORS } from 'state/competitors/actionTypes';
import { Action } from 'redux';

type SetAppProfileActionType = Action & {
    competitors: AppResponseType[];
};

type AddAppActionType = Action & {
    app: AppResponseType;
};

type RemoveAppActionType = Action & {
    storeId: string;
};

const competitors = (state: AppResponseType[] = [], action: Action) => {
    switch (action.type) {
        case SET_COMPETITORS: {
            return [...(action as SetAppProfileActionType).competitors];
        }
        case ADD_COMPETITOR_APP: {
            state.push((action as AddAppActionType).app);
            return [...state];
        }
        case REMOVE_COMPETITOR_APP: {
            return state.filter((app) => app.store_id !== (action as RemoveAppActionType).storeId);
        }
        default: {
            return state;
        }
    }
};

export default competitors;
