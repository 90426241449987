import { US_LOCALE } from 'constants/localizationsConstants';

export const INFINITE_NUMBER = 9999999999999;

export const getFormattedNumber = (number: number) => {
    return number.toLocaleString(US_LOCALE);
};

const KILO = 'K';
const MILLION = 'M';
const BILLION = 'B';
const TRILLION = 'T';

const ranksNumber = ['', KILO, MILLION, BILLION, TRILLION];

export const getRankedNumber = (number: number, precision = 1) => {
    const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3);
    const order = Math.max(0, Math.min(unrangifiedOrder, ranksNumber.length - 1));
    const suffix = ranksNumber[order];

    const formatterNumber = number / Math.pow(10, order * 3);

    const calculatePrecision = formatterNumber % 1 === 0 ? 0 : precision;

    return `${formatterNumber.toFixed(calculatePrecision)}${suffix}`;
};
