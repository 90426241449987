import React from 'react';
import { IndicatorProps } from 'react-select';
import { Icons } from 'types/icon-types';
import { Icon } from 'newComponents/icons/asodeskIcons/Icon';

export const DropdownIndicator = <T,>({ selectProps, options }: IndicatorProps<T>) => {
    const isOneValue = options.length === 1;

    const iconName = selectProps.menuIsOpen ? Icons.ChevronTop : Icons.ChevronBottom;

    if (isOneValue) return null;

    return <Icon name={iconName} className="baseSelector__indicator text-primary" />;
};
