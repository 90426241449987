import { isString } from 'utils/typeGuard';
import { DEFAULT_LANGUAGE } from 'constants/localizationsConstants';

type NumberFormatType = {
    number: number | string;
    locale?: string;
    minimumFractionDigits?: number;
};

export const numberFormat = ({ number, locale = DEFAULT_LANGUAGE, minimumFractionDigits = 0 }: NumberFormatType) => {
    let value = number;
    if (isString(number)) value = parseInt(number, 10);

    return value.toLocaleString(locale, { minimumFractionDigits });
};
