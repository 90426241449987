export enum ReviewFiltersActionTypeListEnum {
    SET_SAVED_FILTER_PRESET = 'SET_SAVED_FILTER_PRESET',
    CHANGE_FILTER_PRESET = 'CHANGE_FILTER_PRESET',
    CHANGE_FILTER_DATE = 'CHANGE_FILTER_DATE',
    CHANGE_FILTER_VERSION_DATE = 'CHANGE_FILTER_VERSION_DATE',
    CHANGE_FILTER_RATING = 'CHANGE_FILTER_RATING',
    CHANGE_FILTER_SWITCH_TYPE = 'CHANGE_FILTER_SWITCH_TYPE',
    CHANGE_FILTER_CHANGED_REVIEWS = 'CHANGE_FILTER_CHANGED_REVIEWS',
    CHANGE_FILTER_SORT = 'CHANGE_FILTER_SORT',
    CHANGE_FILTER_TAG = 'CHANGE_FILTER_TAG',
    CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT',
    CHANGE_FILTER_REPLY = 'CHANGE_FILTER_REPLY',
    CHANGE_REVIEWS_COUNTRIES = 'CHANGE_REVIEWS_COUNTRIES',
    CHANGE_REVIEWS_LANGUAGES = 'CHANGE_REVIEWS_LANGUAGES',
    CHANGE_PENDING_REPLY = 'CHANGE_PENDING_REPLY',
    CHANGE_ACTION_STATUS = 'CHANGE_ACTION_STATUS',
    CHANGE_SIMPLE_FILTER = 'CHANGE_SIMPLE_FILTER',
    CHANGE_LENGTH_REVIEW_FILTER = 'CHANGE_LENGTH_REVIEW_FILTER',
    CHANGE_FILTER_WITHOUT_TAG = 'CHANGE_FILTER_WITHOUT_TAG',
}
